import styled from 'styled-components';

export const Container = styled.label`
  display: block;
  position: relative;
  .selected {
    padding: 3px 10px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #457bd0;
    color: #fff;
    &:first-child {
      border-radius: 3px 3px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 0px 3px 3px;
    }
    button {
      padding: 0;
      margin: 0;
      color: #fff;
    }
  }
  div.selected + div.selected {
    border-top: 2px solid #8a470e;
  }

  .autocomplete-box {
    position: absolute;
    top: 4rem;
    width: 100%;
    max-width: 300px;
    z-index: 9;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #c9c9c9;
    button {
      padding: 0.3rem 0.6rem;
      width: 100%;
      text-align: left;
      font-size: 0.8rem;
      transition: 300ms;
      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
    button + button {
      border-top: 1px solid #999;
    }
  }
`;

export const Label = styled.span`
  color: #2d3047;
  font-weight: bold;
  display: block;
  font-size: 12px;
  margin-bottom: 2px;
  margin-left: 4px;
`;

interface IInput {
  error: boolean;
}

export const Input = styled.input`
  width: 100%;
  padding: 6px 10px;
  transition: 300ms;
  border-radius: 4px;
  background: #eee;
  border: none;
  border-bottom: 2px solid
    ${(props: IInput) => (props.error ? '#e04848' : '#eee')};
  font-size: 12px;
  color: #2d3047;
  &:disabled {
    opacity: 0.7;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 6px 10px;
  transition: 300ms;
  border-radius: 4px;
  background: #eee;
  border: none;
  border-bottom: 2px solid
    ${(props: IInput) => (props.error ? '#e04848' : '#eee')};
  font-size: 12px;
  color: #2d3047;
  &:disabled {
    opacity: 0.7;
  }
`;

export const HelperText = styled.small`
  color: #e04848;
  display: block;
  margin-top: 6px;
`;
