import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toastr } from 'react-redux-toastr';
import Modal from '~/components/Modal';
import api from '~/services/api';

// import { Container } from './styles';

export const Webhook: React.FC<{
  onHide: () => void;
  onUpdate: () => void;
  id: number | null;
}> = ({ onHide, onUpdate, id }) => {
  const [type, setType] = useState<
    | null
    | 'get-products'
    | 'get-products-paginated'
    | 'new-order'
    | 'verify-stock'
  >(null);

  const [loading, setLoading] = useState(false);

  const [customData, setCustomData] = useState({
    endpoint: '',
    authenticationType: '',
    authenticationToken: '',
    method: '',
  });

  useEffect(() => {
    async function handle() {
      setLoading(true);
      try {
        const { data } = await api.get(`/marketplace-webhook/config/${id}`);
        setCustomData({
          endpoint: data.endpoint,
          authenticationToken: data.authenticationToken,
          authenticationType: data.authenticationType,
          method: data.method,
        });
        setType(data.type);
      } catch (err) {
        //
      }
      setLoading(false);
    }
    if (id) {
      handle();
    }
  }, [id]);

  async function handleSubmit() {
    setLoading(true);
    try {
      const body: any = {
        type,
        ...customData,
      };
      if (id) {
        await api.put(`/marketplace-webhook/config/${id}`, body);
      } else {
        await api.post(`/marketplace-webhook/config`, body);
      }
      toastr.info(`Sucesso`, 'Webhook configurado');
      onUpdate();
    } catch (err) {
      if ((err as any).response) {
        toastr.error(`Erro`, (err as any).response.data.message);
      } else {
        toastr.error(`Erro`, (err as any).message);
      }
    }
    setLoading(false);
  }

  return (
    <Modal size="xl">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="m-0">Configurar Webhook</h6>
        <button type="button" onClick={onHide}>
          <FaTimes />
        </button>
      </div>
      <strong className="d-block mt-3 mb-2">Escolha o tipo de hook</strong>
      <div className="d-flex align-items-center mt-0">
        <button
          type="button"
          className={`${
            type === 'get-products' ? 'bg-primary text-white' : 'bg-white'
          } pl-3 pr-3 pt-2 pb-2 radius-4 shadow-sm mr-2`}
          onClick={() => setType('get-products')}
          disabled={loading}
        >
          <strong>Buscar Produtos</strong>
        </button>
        <button
          type="button"
          className={`${
            type === 'get-products-paginated'
              ? 'bg-primary text-white'
              : 'bg-white'
          } pl-3 pr-3 pt-2 pb-2 radius-4 shadow-sm mr-2`}
          onClick={() => setType('get-products-paginated')}
          disabled={loading}
        >
          <strong>Buscar Produtos (Paginado)</strong>
        </button>
        <button
          type="button"
          className={`${
            type === 'new-order' ? 'bg-primary text-white' : 'bg-white'
          } pl-3 pr-3 pt-2 pb-2 radius-4 shadow-sm mr-2`}
          onClick={() => setType('new-order')}
          disabled={loading}
        >
          <strong>Novo Pedido</strong>
        </button>
        <button
          type="button"
          className={`${
            type === 'verify-stock' ? 'bg-primary text-white' : 'bg-white'
          } pl-3 pr-3 pt-2 pb-2 radius-4 shadow-sm mr-2`}
          onClick={() => setType('verify-stock')}
          disabled={loading}
        >
          <strong>Verificar Estoque</strong>
        </button>
      </div>
      <p className="mb-0 mt-2 ml-2">
        <a
          href="https://political-baboon-3cb.notion.site/Webhooks-Lojas-Imp-rio-174b2cb489a54e80b67f109fee98ad6a"
          target="blank"
        >
          Clique aqui
        </a>{' '}
        para conferir nossa documentação acerca dos webhooks.
      </p>
      <div className="row mt-3 mb-3">
        <div className="col-md-8">
          Informe o endpoint que deseja que seja feito o hook
          <div className="d-flex mb-2">
            <select
              value={customData.method}
              onChange={e =>
                setCustomData({
                  ...customData,
                  method: e.target.value,
                })
              }
              className="form-control mr-2"
              disabled={loading}
              style={{ maxWidth: '100px' }}
            >
              <option value="">Método</option>
              <option value="POST">POST</option>
              <option value="GET">GET</option>
              <option value="PUT">PUT</option>
              <option value="DELETE">DELETE</option>
            </select>
            <input
              type="text"
              className="form-control"
              placeholder="URL"
              disabled={loading}
            />
          </div>
          <div className="d-flex mb-2">
            <select
              value={customData.authenticationType}
              onChange={e =>
                setCustomData({
                  ...customData,
                  authenticationType: e.target.value,
                })
              }
              className="form-control"
              disabled={loading}
              style={{ maxWidth: '140px' }}
            >
              <option value="">Autenticação</option>
              <option value="Bearer">Bearer</option>
              <option value="Basic">Basic</option>
            </select>
            <input
              type="text"
              placeholder="TOKEN"
              value={customData.authenticationToken}
              onChange={e =>
                setCustomData({
                  ...customData,
                  authenticationToken: e.target.value,
                })
              }
              disabled={customData.authenticationType === '' || loading}
              className="form-control w-100 ml-2"
            />
          </div>
        </div>
      </div>
      {type !== null && (
        <div className="d-flex justify-content-center mt-2">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={loading}
            className="btn btn-primary"
          >
            Salvar
          </button>
          <button
            type="button"
            onClick={onHide}
            disabled={loading}
            className="btn btn-outline-secondary ml-2"
          >
            Cancelar
          </button>
        </div>
      )}
    </Modal>
  );
};
