import React, { useEffect, useState } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container } from './styles';

interface IRole {
  id: number;
  slug: string;
  name: string;
}

const Create: React.FC = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [userMarketplaces, setUserMarketplaces] = useState<number[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [rolesData, setRolesData] = useState<IRole[]>([]);
  const [loading, setLoading] = useState(false);

  const [marketplaces, setMarketplaces] = useState<
    { id: number; name: string }[]
  >([]);
  useEffect(() => {
    async function handle() {
      const { data } = await api.get('/marketplaces?per_page=1000');
      setMarketplaces(data.data);
    }
    handle();
  }, []);

  const history = useHistory();

  useEffect(() => {
    async function loadRole() {
      try {
        const { data: rolesDataA } = await api.get(
          '/roles?page=1&perPage=10000',
        );
        setRolesData(rolesDataA.data);
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadRole();
  }, []); // eslint-disable-line

  async function handleAdd() {
    setLoading(true);
    try {
      const response = await api.post(`/users`, {
        name,
        cpf,
        email,
        password,
        roles,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/users/list');
        toastr.success('Usuário criado', '');
      }
    } catch (err) {
      toastr.error(
        'Ocorreu um erro',
        (err as any)?.data?.message || 'Contate o suporte',
      );
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/users/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Criar Usuário</h3>
        </div>
        <div className="body_block">
          <div className="row mb-3">
            <div className="col-md-6">
              <Input
                label="Nome"
                name="name"
                required
                onChange={e => setName(String(e.value))}
                disabled={loading}
              />
              <Input
                label="E-mail"
                name="email"
                type="email"
                required
                onChange={e => setEmail(String(e.value))}
                disabled={loading}
              />
              <Input
                label="CPF"
                name="cpf"
                required={false}
                onChange={e => setCpf(String(e.value))}
                disabled={loading}
              />
              <Input
                label="Senha"
                name="password"
                type="password"
                required
                onChange={e => setPassword(String(e.value))}
                disabled={loading}
              />
              <Input
                label="Marketplace"
                name="marketplace_id"
                select
                multipleSelect
                initialValue={userMarketplaces?.map(u => String(u))}
                options={marketplaces?.map(p => {
                  return {
                    title: p.name,
                    value: String(p.id),
                  };
                })}
                onChange={e =>
                  typeof e.value !== 'string' &&
                  setUserMarketplaces(e.value.map(v => Number(v)))
                }
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Perfís"
                name="roles"
                required
                select
                options={rolesData.map(p => {
                  return {
                    title: p.name,
                    value: String(p.id),
                  };
                })}
                multipleSelect
                onChange={e => typeof e.value !== 'string' && setRoles(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="footer_block">
          <Button type="button" disabled={loading} onClick={handleAdd}>
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Create;
