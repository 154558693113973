import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  height: 100%;
  background: #e0ecef;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .content-form-block {
    width: 100%;
    max-width: 750px;
    background: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    .logo-block {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 2rem 1rem;
      height: 100%;
      background-image: url(https://www.lojasimperio.com.br/images/sobre-nos.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(1, 107, 178, 0.9);
      }
      span {
        z-index: 2;
        color: #fff;
      }
    }
    form {
      padding: 1.5rem 1rem;
      span {
        display: block;
        padding: 0;
        margin-bottom: 6px;
        margin-top: 10px;
      }
      input {
        display: block;
      }
      a {
        display: block;
        font-size: 0.8rem;
        margin-top: 4px;
        margin-bottom: 10px;
      }
    }
  }
`;

export const SignForm = styled.form`
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  padding: 40px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  h1 {
    align-self: center;
    font-size: 20px;
  }

  a {
    align-self: center;
    margin-top: 8px;
    color: #016cb2;
    font-weight: bold;
  }

  a:visited {
    color: #016cb2;
  }

  img {
    width: 100%;
    max-width: 180px;
    margin: 0 auto;
  }
  span {
    color: #444;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-top: 15px;
  }
  input {
    height: 40px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: rgba(0, 0, 0, 0.1);
    color: #222;
    margin-top: 8px;
    transition: border 0.15s ease;
    font-size: 16px;

    &:focus {
      border-color: #016cb2;
    }
  }

  button {
    margin-top: 20px;
  }
`;
