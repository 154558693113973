import React, { useState, useEffect } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container } from './styles';
import { states } from '~/utils/states';
import { normalizeCep, normalizeNumber } from '~/utils/normalize';

interface IChange {
  name: string;
  value: string | string[];
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const [values, setValues] = useState({
    code: '',
    name: '',
    fullAddress: '',
    zip_code: '',
    street: '',
    number: '',
    complement: '',
    city: '',
    district: '',
    state: '',
    phone: '',
    active: 1,
  });
  const [openingHours, setOpeningHours] = useState({
    start_1: '',
    end_1: '',
    start_2: '',
    end_2: '',
    start_3: '',
    end_3: '',
    start_4: '',
    end_4: '',
    start_5: '',
    end_5: '',
    start_6: '',
    end_6: '',
    start_7: '',
    end_7: '',
  });
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    async function loadStore() {
      try {
        const { data } = await api.get(`/stores/${match.params.id}`);
        setValues({
          ...data,
        });
        if (data.opening_hours) {
          const obj: any = { ...openingHours };
          const hours = JSON.parse(data.opening_hours);
          const keys = Object.keys(hours);
          keys.forEach(k => {
            if (hours[k]) {
              const [start, end] = hours[k].split(' - ');
              obj[`start_${k}`] = start;
              obj[`end_${k}`] = end;
            }
          });
          if (hours['0']) {
            const [start, end] = hours['0'].split(' - ');
            obj.start_7 = start;
            obj.end_7 = end;
          }
          setOpeningHours(obj);
        }
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadStore();
  }, [match.params.id]); // eslint-disable-line

  async function handleEdit() {
    setLoading(true);
    try {
      let opening_hours: any = {
        '1':
          openingHours.start_1.length === 5 && openingHours.end_1.length === 5
            ? `${openingHours.start_1} - ${openingHours.end_1}`
            : null,
        '2':
          openingHours.start_2.length === 5 && openingHours.end_2.length === 5
            ? `${openingHours.start_2} - ${openingHours.end_2}`
            : null,
        '3':
          openingHours.start_3.length === 5 && openingHours.end_3.length === 5
            ? `${openingHours.start_3} - ${openingHours.end_3}`
            : null,
        '4':
          openingHours.start_4.length === 5 && openingHours.end_4.length === 5
            ? `${openingHours.start_4} - ${openingHours.end_4}`
            : null,
        '5':
          openingHours.start_5.length === 5 && openingHours.end_5.length === 5
            ? `${openingHours.start_5} - ${openingHours.end_5}`
            : null,
        '6':
          openingHours.start_6.length === 5 && openingHours.end_6.length === 5
            ? `${openingHours.start_6} - ${openingHours.end_6}`
            : null,
        '7':
          openingHours.start_7.length === 5 && openingHours.end_7.length === 5
            ? `${openingHours.start_7} - ${openingHours.end_7}`
            : null,
        '0':
          openingHours.start_7.length === 5 && openingHours.end_7.length === 5
            ? `${openingHours.start_7} - ${openingHours.end_7}`
            : null,
      };
      if (!Object.keys(opening_hours).find(k => opening_hours[k])) {
        opening_hours = null;
      }
      const response = await api.put(`/stores/${match.params.id}`, {
        ...values,
        zip_code: normalizeNumber(values.zip_code),
        fullAddress: `${values.street}, ${values.number} - ${
          values.complement
        }, ${values.district}, ${values.city}, ${values.state} - ${normalizeCep(
          values.zip_code,
        )}`,
        opening_hours: JSON.stringify(opening_hours),
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/stores/list');
        toastr.success('Loja atualizado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/stores/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/stores/list');
        toastr.success('Loja deletada', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  function handleChange(e: IChange) {
    setValues({
      ...values,
      [e.name]: String(e.value),
    });
  }

  const normalizeHour = (value: string) => {
    if (!value) return value;

    const onlyNums = normalizeNumber(value);

    if (onlyNums.length <= 2) {
      return onlyNums;
    }

    return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)}`;
  };

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/stores/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Editar Loja</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row mb-3">
                <div className="col-md-8">
                  <Input
                    label="Nome"
                    name="name"
                    required
                    initialValue={values.name}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    label="Código"
                    name="code"
                    required
                    initialValue={values.code}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="CEP"
                    name="zip_code"
                    required
                    initialValue={values.zip_code}
                    onChange={handleChange}
                    normalize={normalizeCep}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-5">
                  <Input
                    label="Logradouro"
                    name="street"
                    required
                    initialValue={values.street}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Nº"
                    name="number"
                    required
                    initialValue={values.number}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Complemento"
                    name="complement"
                    initialValue={values.complement}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="Bairro"
                    name="district"
                    required
                    initialValue={values.district}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="Cidade"
                    name="city"
                    required
                    initialValue={values.city}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Estado"
                    name="state"
                    required
                    initialValue={values.state}
                    onChange={handleChange}
                    disabled={loading}
                    select
                    options={states.map(s => ({
                      title: s.desc,
                      value: s.uf,
                    }))}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Telefone"
                    name="phone"
                    required
                    initialValue={values.phone}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Loja Ativa"
                    name="active"
                    initialValue={String(values.active)}
                    onChange={handleChange}
                    select
                    options={[
                      {
                        title: 'Sim',
                        value: '1',
                      },
                      {
                        title: 'Não',
                        value: '0',
                      },
                    ]}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-6">
                  <h6 className="mt-3 ml-3 mb-0">Horário de Funcionamento</h6>
                  <small className="text-muted d-block mb-2 ml-3">
                    Dias que não abrem devem ficar vazios
                  </small>
                  <div className="p-3 radius-4 bg-white shadow-sm">
                    <div className="row">
                      <div className="col-md-4 pt-2">Segunda-Feira</div>
                      <div className="col-md-8 d-flex align-items-center">
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.start_1}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="start_1"
                        />
                        <span>até</span>
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.end_1}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="end_1"
                        />
                      </div>
                      <div className="col-md-4 pt-2">Terça-Feira</div>
                      <div className="col-md-8 d-flex align-items-center">
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.start_2}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="start_2"
                        />
                        <span>até</span>
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.end_2}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="end_2"
                        />
                      </div>
                      <div className="col-md-4 pt-2">Quarta-Feira</div>
                      <div className="col-md-8 d-flex align-items-center">
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.start_3}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="start_3"
                        />
                        <span>até</span>
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.end_3}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="end_3"
                        />
                      </div>
                      <div className="col-md-4 pt-2">Quinta-Feira</div>
                      <div className="col-md-8 d-flex align-items-center">
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.start_4}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="start_4"
                        />
                        <span>até</span>
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.end_4}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="end_4"
                        />
                      </div>
                      <div className="col-md-4 pt-2">Sexta-Feira</div>
                      <div className="col-md-8 d-flex align-items-center">
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.start_5}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="start_5"
                        />
                        <span>até</span>
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.end_5}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="end_5"
                        />
                      </div>
                      <div className="col-md-4 pt-2">Sábado</div>
                      <div className="col-md-8 d-flex align-items-center">
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.start_6}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="start_6"
                        />
                        <span>até</span>
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.end_6}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="end_6"
                        />
                      </div>
                      <div className="col-md-4 pt-2">Domingo</div>
                      <div className="col-md-8 d-flex align-items-center">
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.start_7}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="start_7"
                        />
                        <span>até</span>
                        <Input
                          type="text"
                          label=" "
                          initialValue={openingHours.end_7}
                          normalize={normalizeHour}
                          onChange={e => {
                            setOpeningHours({
                              ...openingHours,
                              [e.name]: e.value,
                            });
                          }}
                          name="end_7"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer_block d-flex justify-content-end">
              <Button type="button" disabled={loading} onClick={handleEdit}>
                {loading ? 'Salvando...' : 'Salvar'}
              </Button>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
