import styled from 'styled-components';

export const Container = styled.div``;

export const Label = styled.span`
  color: #2d3047;
  font-weight: bold;
  display: block;
  font-size: 12px;
  margin-bottom: 2px;
  margin-left: 4px;
`;

export const PhotoList = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  label#add {
    border: 2px dashed #c9c9c9;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 6px;
    cursor: pointer;
    width: 150px;
    height: 150px;
    color: #999;
    font-weight: bold;
    font-size: 12px;
    input {
      display: none;
    }
  }
  div.add {
     {
      border: 2px dashed #c9c9c9;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 6px;
      cursor: pointer;
      width: 150px;
      height: 150px;
      color: #999;
      font-weight: bold;
      font-size: 12px;
      input {
        display: none;
      }
    }
  }
  div.img {
    width: 150px;
    height: 150px;
    background-size: contain !important;
    position: relative;
    border-radius: 4px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin: 6px;
    button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: #fff;
      color: #f00;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      font-size: 18px;
    }
  }
`;
