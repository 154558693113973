import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { format, subHours } from 'date-fns';
import api from '~/services/api';
import { Container } from './styles';
import {
  normalizeCpf,
  normalizeNumber,
  normalizePhone,
} from '~/utils/normalize';
import useSelector from '~/store/useSelector';
import { TableComponent } from '~/components/TableV2';

const List: React.FC = () => {
  const { permissions } = useSelector(state => state.auth);
  const history = useHistory();
  return (
    <Container>
      <TableComponent
        filters={[
          {
            key: 'name',
            title: 'Nome',
            type: 'text',
            col: 2,
          },
          {
            key: 'cpf',
            title: 'CPF',
            type: 'text',
            normalize: normalizeCpf,
            col: 2,
          },
          {
            key: 'email',
            title: 'E-mail',
            type: 'text',
            col: 2,
          },
          {
            key: 'phone',
            title: 'Telefone',
            type: 'text',
            normalize: normalizePhone,
            col: 2,
          },
        ]}
        labels={[
          {
            key: 'name',
            title: 'Nome',
          },
          {
            key: 'cpf',
            title: 'CPF',
            formater: (cpf, s) => {
              return normalizeCpf(s.cpf);
            },
          },
          {
            key: 'email',
            title: 'E-mail',
          },
          {
            key: 'phone',
            title: 'Telefone',
            formater: (phone, s) => {
              return normalizePhone(s.phone);
            },
          },
          {
            key: 'created_at',
            title: 'Data de criação',
            formater: (date, s) => {
              return s.created_at
                ? format(
                    subHours(new Date(s.created_at), 3),
                    'dd/MM/yyyy HH:mm',
                  )
                : '';
            },
          },
        ]}
        getData={async params => {
          const res = await api.get(`/customers`, {
            params: {
              ...params,
              cpf: normalizeNumber(params.cpf),
              phone: normalizeNumber(params.phone),
            },
          });
          return res;
        }}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            {permissions.find(p => p.slug === 'ver_clientes') ? (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => history.push(`/customers/view/${item.id}`)}
                >
                  Editar
                  <AiOutlineArrowRight />
                </button>
              </>
            ) : null}
          </div>
        )}
      />
    </Container>
  );
};

export default List;
