import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 15px;
  background: #016cb2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  color: #fff;
  div:first-child {
    display: flex;
    align-items: center;
    h3 {
      margin-left: 14px;
      font-size: 14px;
    }
    @media screen and (max-width: 600px) {
      h3 {
        display: none;
      }
    }
  }
  img {
    height: 100%;
    max-height: 35px;
  }
`;

export const LateralMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  button {
    background: none;
    padding: 0px;
    margin: 0px;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-weight: bolder;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-left: 6px;
    }
    &::before {
      content: '';
      position: absolute;
      transition: 300ms;
      height: 2px;
      border-radius: 1px;
      background: #fff;
      width: 0px;
      bottom: -6px;
      left: 50%;
    }
    &:hover {
      &::before {
        left: calc(50% - 20px);
        width: 40px;
      }
    }
  }
  button + button {
    margin-left: 16px;
  }
`;
