/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
};

const JoditEditorComponent = ({
  value,
  onChange,
  placeholder,
}: Props): JSX.Element => {
  const editor = useRef<any>(null);

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || 'Digite aqui...',
    }),
    [placeholder],
  );

  return (
    <JoditEditor
      ref={editor}
      value={value}
      config={config as any}
      onBlur={newContent => onChange(newContent)}
      // tabIndex={1}
    />
  );
};

JoditEditorComponent.defaultProps = {
  placeholder: 'Digite aqui...',
};

export default JoditEditorComponent;
