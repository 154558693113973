import React, { useState } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { cpfIsValid } from '~/utils/validation';

import { Container } from './styles';
import { normalizeNumber } from '~/utils/normalize';

const Create: React.FC = () => {
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [cpf, setCpf] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  async function handleAdd() {
    if (!cpfIsValid(cpf)) {
      toastr.error('Validação', 'CPF Informado inválido');
      return;
    }
    setLoading(true);
    try {
      const response = await api.post(`/sellers`, {
        name,
        code,
        cpf: normalizeNumber(cpf),
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/sellers/list');
        toastr.success('Vendedor criado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/sellers/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Criar Vendedor</h3>
        </div>
        <div className="body_block">
          <div className="row mb-3">
            <div className="col-md-4">
              <Input
                label="Nome"
                name="name"
                required
                onChange={e => setName(String(e.value))}
                disabled={loading}
              />
            </div>
            <div className="col-md-4">
              <Input
                label="CPF"
                name="cpf"
                required
                onChange={e => setCpf(String(e.value))}
                disabled={loading}
              />
            </div>
            <div className="col-md-4">
              <Input
                label="Código"
                name="code"
                required
                onChange={e => setCode(String(e.value))}
                disabled={loading}
              />
            </div>
          </div>
        </div>
        <div className="footer_block d-flex justify-content-end">
          <Button type="button" onClick={handleAdd}>
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Create;
