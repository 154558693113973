import React, { useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { toastr } from 'react-redux-toastr';
import { useDispatch } from 'react-redux';
import Button from '~/styles/components/Button';
import { Container } from '../styles';
import api from '~/services/api';
import { signInRequest } from '~/store/modules/auth/actions';

const SignIn: React.FC = () => {
  const [step, setStep] = useState<'login' | 'code'>('login');

  const dispatch = useDispatch();

  const recatpchaRef = useRef<ReCAPTCHA>(null);
  const [values, setValues] = useState({
    email: '',
    password: '',
    code: '',
  });

  const [loading, setLoading] = useState(false);

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    setLoading(true);

    const gResponse = await recatpchaRef?.current?.executeAsync();
    recatpchaRef?.current?.reset();

    if (!gResponse) {
      toastr.error('Erro de validação', 'Captcha não informado');
      setLoading(false);
      return;
    }

    try {
      const { data } = await api.post('/authentication/user', {
        email: values.email,
        password: values.password,
        g_response: gResponse,
      });
      sessionStorage.setItem('tmp_token', data.tmp_token);
      setStep('code');
    } catch (err) {
      toastr.error(
        'Erro',
        (err as any)?.data?.message ||
          (err as any)?.response?.data?.message ||
          'Confira se os dados estão válidos',
      );
    }

    setLoading(false);
  }

  async function handleSubmitCode(e: any) {
    e.preventDefault();

    setLoading(true);

    const tmp_token = sessionStorage.getItem('tmp_token');

    const gResponse = await recatpchaRef?.current?.executeAsync();
    recatpchaRef?.current?.reset();

    if (!gResponse) {
      toastr.error('Erro de validação', 'Captcha não informado');
      setLoading(false);
      return;
    }

    try {
      dispatch(signInRequest(tmp_token || '', values.code, gResponse));
    } catch (err) {
      //
    }

    setLoading(false);
  }

  return (
    <Container>
      <div className="content-form-block">
        <div className="row">
          <div className="col-md-4">
            <div className="logo-block">
              <span>Acessar Conta</span>
            </div>
          </div>
          <div className="col-md-8">
            {step === 'login' ? (
              <form onSubmit={handleSubmit}>
                <div className="d-flex align-items-center">
                  <img
                    src="https://lojasimperio.com.br/images/logo.png"
                    alt="Império"
                    className="mr-2"
                  />
                  <span>- Administrativo</span>
                </div>
                <span>E-MAIL</span>
                <input
                  type="email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  required
                />

                <span>SENHA</span>
                <input
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleInputChange}
                  required
                />

                <Link to="forgot-password">Esqueci minha senha</Link>

                <div className="d-flex mt-4">
                  <Button size="big" type="submit" disabled={loading}>
                    {loading && '...'} Entrar
                  </Button>
                </div>
              </form>
            ) : null}
            {step === 'code' ? (
              <form onSubmit={handleSubmitCode}>
                <div className="d-flex align-items-center">
                  <img
                    src="https://lojasimperio.com.br/images/logo.png"
                    alt="Império"
                    className="mr-2"
                  />
                  <span>- Administrativo</span>
                </div>
                <span>Código</span>
                <input
                  name="code"
                  value={values.code}
                  onChange={handleInputChange}
                  required
                />

                <span>
                  <i>
                    Um código foi enviado para o e-mail{' '}
                    <strong>{values.email || '*******************'}</strong>,
                    <br />
                    confira a caixa de entrada ou spam e preencha o campo acima
                    para acessar.
                  </i>
                </span>

                <button
                  type="button"
                  className="btn btn-link btn-sm"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  Não recebeu o código? Clique aqui para enviar novamente
                </button>

                <div className="d-flex mt-4">
                  <Button size="big" type="submit" disabled={loading}>
                    {loading && '...'} Enviar
                  </Button>
                </div>
              </form>
            ) : null}
          </div>
        </div>
        <ReCAPTCHA
          sitekey="6Lf8NtUaAAAAAEJyuqWiJAo9K4-y_2KqBfP6A_VM"
          size="invisible"
          ref={recatpchaRef}
        />
      </div>
    </Container>
  );
};

export default SignIn;
