import React, { useState, useEffect } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import api from '~/services/api';
import Input from '~/components/Input';
import InputFile from '~/components/InputFile';
import Button from '~/styles/components/Button';
import Checkbox from '~/components/Checkbox';
import { normalizeNumber } from '~/utils/normalize';

import { Container } from './styles';

interface IChange {
  name: string;
  value: string | string[];
}

interface IBrand {
  image: File | null;
  name: string;
  preview: string;
  active: boolean;
  index: string;
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const [values, setValues] = useState<IBrand>({
    image: null,
    name: '',
    preview: '',
    active: false,
    index: '',
  });
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmImageDelete, setConfirmImageDelete] = useState(false);

  const history = useHistory();

  useEffect(() => {
    async function loadStore() {
      try {
        const { data } = await api.get(`/brands/${match.params.id}`);
        setValues({
          name: data.name,
          image: null,
          preview: data.image,
          active: data.active,
          index: String(data.index),
        });
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadStore();
  }, [match.params.id]); // eslint-disable-line

  async function handleEdit() {
    setLoading(true);
    try {
      const obj = new FormData();
      let url = '';
      if (values.image) {
        obj.append('file', values.image);
        url = `/brands/image/${match.params.id}?name=${String(
          values.name,
        )}&active=${String(values.active)}&index=${String(values.index)}`;
      } else {
        url = `/brands/${match.params.id}?name=${String(
          values.name,
        )}&active=${String(values.active)}&index=${String(values.index)}`;
      }
      const response = await api.put(url, obj, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        toastr.success('Marca atualizada', '');
        history.push('/brands/list');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/brands/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/brands/list');
        toastr.success('Marca deletada', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleImageDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/brands/image/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => { // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        setValues({ ...values, preview: '' });
        setConfirmImageDelete(false);
        toastr.success('Imagem excluida', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  function handleChange(e: IChange) {
    setValues({
      ...values,
      [e.name]: String(e.value),
    });
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/brands/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Editar Marca</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row mb-3">
                <div className="col-md-12">
                  <InputFile
                    label="Imagem"
                    name="file"
                    required
                    initialValue={values.preview}
                    onChange={e =>
                      setValues({
                        ...values,
                        image: e.value,
                      })
                    }
                    onError={() => console.log(1) /* eslint-disable-line */}
                    onReset={() => console.log(1) /* eslint-disable-line */}
                    disabled={loading}
                    minHeight="200px"
                  />
                </div>
                {values.preview && !confirmImageDelete && (
                  <div className="footer_block d-flex justify-content-end">
                    <Button
                      type="button"
                      color="danger"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmImageDelete(true)}
                    >
                      {loading ? '...' : 'Excluir Imagem'}
                    </Button>
                  </div>
                )}
                {values.preview && confirmImageDelete && (
                  <div className="footer_block d-flex justify-content-end">
                    <div>
                      <h3 style={{ textAlign: 'right' }}>
                        Você tem certeza que deseja excluir esta imagem?
                      </h3>
                      <div className="d-flex mt-2 justify-content-end">
                        <Button
                          type="button"
                          color="danger"
                          disabled={loading}
                          onClick={handleImageDelete}
                        >
                          {loading ? 'Excluindo...' : 'Excluir'}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <Input
                    label="Nome"
                    name="name"
                    initialValue={String(values.name)}
                    required
                    onChange={handleChange}
                    disabled={loading}
                    initialChanges
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    initialValue={String(values.index)}
                    initialChanges
                    label="Ordem"
                    name="index"
                    required
                    type="number"
                    onChange={handleChange}
                    disabled={loading}
                  />
                </div>
                <div>
                  <Checkbox
                    name="active"
                    label="Ativa*"
                    checked={values.active}
                    onChange={(e: any) => {
                      setValues({ ...values, active: e.target.checked });
                    }}
                  />
                </div>
              </div>
            </div>
            {!confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <Button type="button" disabled={loading} onClick={handleEdit}>
                  {loading ? 'Salvando...' : 'Salvar'}
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="ml-2"
                  disabled={loading}
                  onClick={() => setConfirmDelete(true)}
                >
                  {loading ? '...' : 'Excluir Marca'}
                </Button>
              </div>
            )}
            {confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <div>
                  <h3 style={{ textAlign: 'right' }}>
                    Você tem certeza que deseja excluir esta marca?
                  </h3>
                  <div>
                    <Button
                      type="button"
                      color="danger"
                      disabled={loading}
                      onClick={handleDelete}
                    >
                      {loading ? 'Excluindo...' : 'Excluir'}
                    </Button>
                    <Button
                      type="button"
                      color="gray"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmDelete(false)}
                    >
                      {loading ? '...' : 'Cancelar'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
