export const validatePass = (nextApi: number, rejected?: boolean): string => {
  if (rejected) {
    return 'Solicitação Rejeitada';
  }
  switch (nextApi) {
    case 0:
      return 'Consulta de Solicitação';
    case 1:
      return 'Inicio de Contratação';
    case 2:
      return 'Acolher Aceites';
    case 3:
      return 'Dados Pessoais';
    case 4:
      return 'Dados Básicos';
    case 5:
      return 'Dados Identificação';
    case 6:
      return 'Dados de Endereço';
    case 7:
      return 'Ocupação';
    case 8:
      return 'Imagens e Documentos';
    case 9:
      return 'Pendências';
    case 10:
      return 'Consultar Limite';
    case 11:
      return 'Solicitar criação de Cartão';
    case 13:
      return 'Cancelar Solicitação';
    case 14:
      return 'Listar Telefones';
    case 15:
      return 'Enviar Token SMS';
    case 16:
      return 'Validar SMS';
    case 17:
      return 'Listar Endereços para envio do Cartão';
    case 18:
      return 'Registrar Endereço escolhido';
    case 99:
      return 'Solicitar envio de Cartão Virtual';
    default:
      return '';
  }
};
