import styled from 'styled-components';

export const Container = styled.div`
  .nav-tabs-c {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    border-bottom: 1px solid #dee2e6;
  }
  .nav-item-c {
  }
  .nav-link-c {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    &.active {
      color: #495057;
      background-color: #fff;
      border-color: #dee2e6 #dee2e6 #fff;
    }
  }
  .tab-content-c {
    padding: 10px;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    display: block;
  }
  .tab-pane-c {
    margin-top: 20px;
  }
  .address {
    border: 1px solid #dee2e6;
    padding: 10px;
  }
  .favorites {
    border: 1px solid #dee2e6;
    padding: 10px;
    display: flex;
    flex-direction: column;
    .priceOld {
      font-size: 14px;
      text-decoration: line-through;
      margin-bottom: 3px;
    }
    .price {
      font-size: 16px;
    }
    strong {
      margin-bottom: 7.5px;
      small {
      }
    }
  }
`;

export const Label = styled.span`
  color: #2d3047;
  font-weight: bold;
  display: block;
  font-size: 12px;
  margin-bottom: 2px;
  margin-left: 4px;
`;
