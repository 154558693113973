import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaBox, FaCheck, FaChevronRight, FaTimes } from 'react-icons/fa';
import { toastr } from 'react-redux-toastr';
import api from '~/services/api';
import Button from '~/styles/components/Button';
import Can from '~/components/Can';
import useSelector from '~/store/useSelector';
import { TableComponent } from '~/components/TableV2';
import Modal from '~/components/Modal';
import { Container } from './styles';

interface IPhotos {
  id: number;
  path: string;
  order: string;
  type: 'image' | 'video';
}

const ModalCategories = ({
  items,
  onHide,
  onSave,
  marketplaces,
}: {
  items: any[];
  onHide: () => void;
  onSave: () => void;
  marketplaces: any[];
}) => {
  const [product, setProduct] = useState({
    categories: [],
  } as any);

  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    api
      .get(`/products/${items[0].id}`)
      .then(res =>
        setProduct({
          ...res.data,
          categories: res.data.categories.reverse(),
        }),
      )
      .finally(() => setLoading(false));
    api
      .get('/categories', { params: { perpage: 1000000, tmp: 1 } })
      .then(res => {
        const arr = [] as any[];
        res?.data?.data?.forEach((category: any) => {
          arr.push({
            ...category,
          });

          if (category.categories) {
            category.categories.forEach((subcategory: any) => {
              arr.push({
                ...subcategory,
              });
              if (subcategory.categories) {
                subcategory.categories.forEach((deepcategory: any) => {
                  arr.push({
                    ...deepcategory,
                  });
                });
              }
            });
          }
        });
        setCategories(arr);
      });
  }, [items]);

  async function handleSubmit() {
    setLoading(true);
    const reversedCategories = [...product.categories].reverse();
    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      try {
        await api.post('/tmp-product-category', {
          product_id: item.id,
          categories: reversedCategories.map((c: any) => c.id),
        });
        toastr.success(
          'Sucesso',
          `Categorias atualizadas do produto ${item.name}`,
        );
      } catch (err) {
        //
      }
    }
    onSave();
    setLoading(false);
  }

  if (loading) {
    return (
      <Modal>
        <div className="d-flex justify-content-center">
          <div className="spinner spinner-border" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal size="xl">
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <span className="m-0 p-0 text-left d-block">
            Categorias do produto{' '}
          </span>
          <button type="button" onClick={onHide}>
            <FaTimes />
          </button>
        </div>
        <br />
        <div
          style={{ maxHeight: '50vh', overflowY: 'auto', overflowX: 'hidden' }}
        >
          {items.map(item => (
            <div
              className="row mb-2 pb-3"
              style={{ borderBottom: '1px solid #c9c9c9' }}
              key={item.id}
            >
              <div className="col-md-2">
                <img
                  src={
                    item?.photos?.filter((p: IPhotos) => p.type === 'image')[0]
                      ?.path
                  }
                  alt={item.name}
                  className="w-100"
                />
              </div>

              <div className="col-md-10">
                <strong className="d-block">{item?.name}</strong>
                <small className="mr-2">SKU: {item?.sku}</small>
                {item?.status ? (
                  <span className="ativo">Ativo</span>
                ) : (
                  <span className="inativo">Inativo</span>
                )}
                <span className="d-block pl-0">
                  Vendido por:{' '}
                  {marketplaces?.find(m => m.id === item?.marketplace_id)?.name}
                </span>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex align-items-center mt-3">
          {product?.categories?.map((category: any, index: number) => (
            <Fragment key={category.id}>
              {index > 0 ? <span>{'>'}</span> : null}
              <select
                className="form-control form-control-sm"
                value={category.id}
                style={{ maxWidth: '150px' }}
                onChange={e => {
                  const categoryItem = categories.find(
                    c => c.id === Number(e.target.value),
                  );
                  if (!categoryItem) {
                    setProduct({
                      ...product,
                      categories: [...product.categories].filter(
                        (_, i) => i < index,
                      ),
                    });
                  } else {
                    setProduct({
                      ...product,
                      categories: [...product.categories]
                        .filter((_, i) => i > index)
                        .map((c, i) => {
                          if (i === index) {
                            return categoryItem;
                          }
                          return c;
                        }),
                    });
                  }
                }}
              >
                <option value="">Sem categoria</option>
                {categories
                  ?.filter(
                    categoryOption =>
                      categoryOption.category_id === category.category_id,
                  )
                  ?.map(categoryOption => (
                    <option value={categoryOption.id}>
                      {categoryOption.name}
                    </option>
                  ))}
              </select>
            </Fragment>
          ))}
          {product?.categories?.length < 3 ? (
            <>
              <span>{'>'}</span>
              <select
                className="form-control form-control-sm"
                style={{ maxWidth: '150px' }}
                value=""
                onChange={e => {
                  const categoryItem = categories.find(
                    c => c.id === Number(e.target.value),
                  );
                  if (categoryItem) {
                    setProduct({
                      ...product,
                      categories: [...product.categories, categoryItem],
                    });
                  }
                }}
              >
                <option value="">Sem categoria</option>
                {categories
                  ?.filter(
                    categoryOption =>
                      categoryOption.category_id ===
                      (product.categories[product.categories.length - 1]?.id ||
                        null),
                  )
                  ?.map(categoryOption => (
                    <option value={categoryOption.id}>
                      {categoryOption.name}
                    </option>
                  ))}
              </select>
            </>
          ) : null}
        </div>
        <div className="d-flex justify-content-start mt-3">
          <button type="submit" className="btn btn-primary">
            <FaCheck /> Salvar
          </button>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-outline-secondary ml-2"
          >
            <FaTimes /> Cancelar
          </button>
        </div>
      </form>
    </Modal>
  );
};

const List: React.FC = () => {
  const history = useHistory();
  const { permissions } = useSelector(state => state.auth);

  const [marketplaces, setMarketplaces] = useState<any[]>([]);

  const [viewCategories, setViewCategories] = useState({
    open: false,
    items: [] as any,
  });

  const [hardRefresh, setHardRefresh] = useState(false);

  useEffect(() => {
    if (hardRefresh) {
      setHardRefresh(false);
    }
  }, [hardRefresh]);

  useEffect(() => {
    api.get('marketplaces').then(res => setMarketplaces(res.data));
  }, []);

  return (
    <Container>
      {viewCategories.open ? (
        <ModalCategories
          items={viewCategories.items}
          marketplaces={marketplaces}
          onHide={() =>
            setViewCategories({
              open: false,
              items: [],
            })
          }
          onSave={() => setHardRefresh(true)}
        />
      ) : null}
      <div className="d-flex justify-content-between mb-1">
        <h5>
          <FaBox /> Produtos
        </h5>
        <Can checkPermission="product_store">
          <Button
            type="button"
            onClick={() => history.push('/products/create')}
          >
            Novo produto
          </Button>
        </Can>
      </div>
      <TableComponent
        filters={[
          {
            key: 'sku',
            title: 'SKU',
            col: 2,
          },
          {
            key: 'name',
            title: 'Nome',
            type: 'text',
            col: 2,
          },
          {
            key: 'status',
            title: 'Status',
            defaultTo: '1',
            type: 'select',
            options: [
              {
                title: 'Ativo',
                value: '1',
              },
              {
                title: 'Inativo',
                value: '0',
              },
            ],
            col: 2,
          },
          {
            key: 'marketplace_id',
            title: 'Marketplace',
            type: 'select',
            options: marketplaces.map(m => ({
              value: m.id,
              title: m.name,
            })),
            col: 2,
          },
          {
            key: 'has_category',
            title: 'Tem categoria',
            type: 'select',
            options: [
              {
                title: 'Sim',
                value: '1',
              },
              {
                title: 'Não',
                value: '0',
              },
            ],
            col: 2,
          },
        ]}
        labels={[
          {
            key: 'image',
            title: 'Imagem',
            formater: (_, d) =>
              d.photos.length > 0 &&
              d.photos.filter((p: IPhotos) => p.type === 'image')[0].path ? (
                <img
                  src={
                    d.photos.filter((p: IPhotos) => p.type === 'image')[0].path
                  }
                  alt=" "
                  width="80"
                />
              ) : (
                '-'
              ),
            ordenable: false,
          },
          {
            key: 'sku',
            title: 'Skus',
          },
          {
            key: 'name',
            title: 'Name',
          },
          {
            key: 'marketplace_id',
            title: 'Marketplace',
            formater: marketplace_id =>
              marketplaces?.find(m => m.id === marketplace_id)?.name,
          },
          {
            key: 'categories',
            title: 'Categorias',
            ordenable: false,
            formater: (categories: any[]) => {
              const categoriesReversed = [...categories].reverse();
              return (
                <div style={{ minWidth: '110px' }}>
                  {categoriesReversed.map(c => (
                    <small key={c.id} className="d-block">
                      {c.name};
                    </small>
                  ))}
                </div>
              );
            },
          },
          {
            key: 'status',
            title: 'Status',
            formater: status =>
              status ? (
                <span className="ativo">Ativo</span>
              ) : (
                <span className="inativo">Inativo</span>
              ),
            ordenable: false,
          },
        ]}
        getData={async params => {
          const res = await api.get(`/products`, {
            params,
          });
          return res;
        }}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            {permissions.find(p => p.slug === 'product_update') ? (
              <>
                <button
                  type="button"
                  className="btn btn-link btn-sm"
                  onClick={() => {
                    setViewCategories({
                      open: true,
                      items: [item],
                    });
                  }}
                >
                  Categorias
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => history.push(`/products/edit/${item.id}`)}
                >
                  Editar <FaChevronRight />
                </button>
              </>
            ) : null}
          </div>
        )}
        batchActions={[
          {
            title: 'Categorizar',
            onClick: items => {
              setViewCategories({
                items,
                open: true,
              });
            },
          },
        ]}
        defaultOrder={{
          name: 'sku',
          direction: 'asc',
        }}
        hardRefresh={hardRefresh}
      />
    </Container>
  );
};

export default List;
