import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { RootState } from './useSelector';

export default (reducers: any) => { // eslint-disable-line
  const persistedReducer = persistReducer<RootState>(
    {
      key: '@Omninative',
      storage,
      whitelist: ['auth'],
    },
    reducers,
  );
  return persistedReducer;
};
