import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container } from './styles';

const Settings: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState({
    featured: '',
  });

  useEffect(() => {
    async function loadConfig() {
      try {
        const { data } = await api.get('search-configs');
        setValues({
          featured: data.featured,
        });
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadConfig();
  }, []);

  async function handleSubmit() {
    try {
      setLoading(true);
      const { featured } = values;

      if (featured === '') {
        toastr.error('Erro', 'Preencha todos os campos');
        return;
      }

      await api.put('/search-configs', {
        featured,
      });

      toastr.success('Sucesso', 'Configuração alterada com sucesso');
    } catch (err: any) {
      if (err.data?.message) {
        toastr.error('Erro', err.data.message);
      } else {
        toastr.error('Erro', 'Erro ao alterar configuração');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <h4>Open search</h4>
      {!loading ? (
        <div>
          <div className="row">
            <div className="col-md-3">
              <div className="bg-white p-3 radius-4 shadow-sm">
                <h5 className="mb-3">Configuração de Score</h5>
                <Input
                  label="Produto em destaque"
                  name="featured"
                  initialValue={values.featured || ''}
                  onChange={e =>
                    setValues({
                      ...values,
                      featured: String(e.value),
                    })
                  }
                  disabled={loading}
                />
                <legend className="mb-4 small">
                  Valor acrescido ao score do produto em destaque
                </legend>
                <Button type="button" onClick={() => handleSubmit()}>
                  Salvar
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Container>
  );
};

export default Settings;
