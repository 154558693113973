import React, { useState, useEffect } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container } from './styles';

interface ICategories {
  id: number;
  name: string;
}

interface ICategory {
  id: number;
  name: string;
  categories: ICategory[];
}

interface IPercentCategory {
  id: number;
  path: string;
  category_id: number;
  loading: boolean;
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const [category, setCategory] = useState<IPercentCategory>({
    id: 0,
    path: '',
    category_id: 0,
    loading: false,
  });
  const [category_id, setCategory_id] = useState('');
  const [path, setPath] = useState('');
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    async function loadCategory() {
      try {
        const { data } = await api.get(
          `/percent-categories/${match.params.id}`,
        );
        setCategory({
          ...category,
          category_id: data.category_id === null ? '0' : data.category_id,
        });

        setCategory_id(data.category_id === null ? '0' : data.category_id);
        setPath(data.path);

        const { data: categoriesData } = await api.get(
          '/categories?page=1&perpage=100000',
        );

        const dataSelect = [
          {
            id: 0,
            name: 'Raiz',
          },
          ...categoriesData.data
            .filter((d: any) => d.id !== match.params.id)
            .reduce((categoryArr: ICategory[], c: ICategory) => {
              categoryArr.push(c);

              const subcategories = c.categories;

              if (subcategories.length > 0) {
                for (let i = 0; i < subcategories.length; i += 1) {
                  const subcategory = subcategories[i];

                  const subcategoryCopy = { ...subcategory };

                  subcategoryCopy.name = `${c.name} > ${subcategory.name}`;

                  categoryArr.push(subcategoryCopy);

                  if (subcategory.categories.length > 0) {
                    subcategory.categories.forEach(subsubcategory => {
                      subsubcategory.name = `${c.name} > ${subcategory.name} > ${subsubcategory.name}`;

                      categoryArr.push(subsubcategory);
                    });
                  }
                }
              }

              return categoryArr;
            }, []),
        ];

        setCategories(dataSelect);
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadCategory();
  }, [match.params.id]); // eslint-disable-line

  async function handleEdit() {
    setLoading(true);
    try {
      const response = await api.put(`/percent-categories/${match.params.id}`, {
        category_id: category_id === '0' ? null : Number(category_id),
      });

      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/percent-categories/list');
        toastr.success('Relacionamento da Categoria atualizada', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/percent-categories/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Editar Relacionamento da Categoria - {path}</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row mb-3">
                <div className="col-md-6 mb-2">
                  <Input
                    label="Categoria Pai"
                    name="category_id"
                    required
                    select
                    initialValue={
                      category.category_id ? String(category.category_id) : ''
                    }
                    options={categories.map(p => {
                      return {
                        title: p.name,
                        value: String(p.id),
                      };
                    })}
                    onChange={e =>
                      typeof e.value === 'string' && setCategory_id(e.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="footer_block d-flex justify-content-end">
              <Button type="button" disabled={loading} onClick={handleEdit}>
                {loading ? 'Salvando...' : 'Salvar'}
              </Button>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
