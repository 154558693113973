import styled from 'styled-components';

interface IContainer {
  open: boolean;
}

export const Container = styled.aside`
  position: ${(props: IContainer) => {
    if (props.open) {
      return 'relative';
    }
    return 'absolute';
  }};
  z-index: 100;
  #burger {
    display: none;
    position: fixed;
    top: 65px;
    left: ${(props: IContainer) => {
      if (props.open) {
        return '210px';
      }
      return '10px';
    }};
    z-index: 100;
    background: none;
    padding: 0px;
    width: 25px;
    border: none;
    transition: 400ms;
    .bar {
      width: 100%;
      height: 3px;
      border-radius: 1.5px;
      background: #1d1d1d;
      display: block;
    }
    .bar + .bar {
      margin-top: 4px;
    }
  }
  @media screen and (max-width: 500px) {
    #burger {
      display: block;
    }
  }
  background: #1d1d1d;
  transition: 400ms;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 200px;
  width: 100%;

  left: ${(props: IContainer) => {
    if (props.open) {
      return '0px';
    }
    return '-200px';
  }};

  overflow: ${(props: IContainer) => {
    if (props.open) {
      return 'auto';
    }
    return 'hidden';
  }};
  &::-webkit-scrollbar-track {
    background-color: #333;
  }
  &::-webkit-scrollbar {
    width: 6px;
    background: #222;
  }
  &::-webkit-scrollbar-thumb {
    background: #999;
  }
`;
export const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const SideMenuPretend = styled.div`
  width: 100%;
  > span {
    color: #999;
    font-weight: bolder;
    padding: 10px 20px;
    position: relative;
    width: 100%;
    display: block;
    font-size: 12px;
    background: #1d1d1d;
  }
  > button {
    color: #999;
    font-weight: bolder;
    padding: 10px 0px 10px 30px;
    position: relative;
    width: 100%;
    display: block;
    font-size: 12px;
    background: #333;
  }
  button + button {
    border-top: 1px solid #444;
  }
`;

export const MenuItem = styled.button`
  padding: 10px 25px;
  color: #fff;
  width: 100%;
  margin: 0px;
  background: none;
  border: none;
  text-align: left;
  font-size: 12px;
  transition: 300ms;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  &.active {
    background: #ff9300;
    color: #fff;
  }
`;
