import React from 'react';
// import FlixPlayer from 'https://admin.flix.tupi.io/embed_player.min.js';

type Props = {
  url: string;
  // eslint-disable-next-line react/require-default-props
  width?: string | number;
  // eslint-disable-next-line react/require-default-props
  height?: string | number;
};

export default function IFramePlayer({ url, width, height }: Props) {
  return (
    <iframe
      title="product video"
      itemType="text/javascript"
      src={url}
      style={{
        height: height || '100%',
        width: width || '100%',
        aspectRatio: '16/9',
        border: 'none',
      }}
      allowFullScreen
      allow="autoplay *; fullscreen *; encrypted-media *"
    />
  );
}
