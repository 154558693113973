import { createGlobalStyle, keyframes } from 'styled-components';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const load = keyframes`
  0%, 100% {
    opacity: 1;
  }50% {
    opacity: 0;
  }
`;

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
  
  * {
    padding:0;
    margin:0;
    outline:0;
    box-sizing: border-box;
    font-family: 'Source Sans Pro', sans-serif !important;
  }

  body {
    background: #f0f9fb;
    color: #444;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }

  .loading {
    animation: ${load} 1.5s infinite;
  }

  .border-1 {
    border: 1px solid transparent;
  }

  .border-muted {
    border-color: #eee;
  }

  .bg-gray {
    background-color: rgba(0,0,0,.1);
  }
  .bg-blues {
    background-color: rgba(86,124,168,.1);
  }
  .radius-4 {
    border-radius: 0.6rem;
  }

  html, body, div#root {
    height: 100%;
  }

  input, button {
    font-family: 'Source Sans Pro', sans-serif;
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
  }

  .block_white {
    background: #fdfdfd;
    box-shadow: 0px 0px 5px rgba(0,0,0,.2);
    border-radius: 4px;
    width: 100%;
    .header_block {
      padding: 15px 20px;
      border-bottom: 1px solid #cecece;
      color: #555;
    }
    .body_block {
      padding: 15px;
    }
    .footer_block {
      padding: 15px 25px;
      border-top: 1px solid #cecece;
      color: #555;
    }
  }

  .text-center {
    text-align: center !important;
  }

  .avulse_table {
    text-align: left;
    width: 100%;
    border-collapse: collapse;
    th, td {
      padding: 0.5rem;
      vertical-align: middle;
      font-size: 0.9rem;
    }
    tbody {
      tr:nth-child(odd) {
        background: rgba(0,0,0,.04);
      }
      tr:hover {
        background: rgba(0,0,0,.1);
      }
    }
    
    th {
      border-bottom: 2px solid #999;
    }
    td {
      border-bottom: 1px solid #c9c9c9;
    }
  }

  .d-flex {
    display: flex;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .align-items-center {
    align-items: center;
  }

  .datepicker-input {
    cursor: pointer;
    width: 100%;
    padding: 6px 10px;
    border-radius: 4px;
    background: #eee;
    border: none;
    font-size: 12px;
    color: #2d3047;
  }

  .sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: #f9f9f9;
    text-align: center !important;
  }
  .floating-col {
    width: 100px;
    right: 0px;
    border-left: 2px solid #999;
  }

  .white-space-no-wrap {
    white-space: nowrap;
  }
`;
