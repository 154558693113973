import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 55px);
  display: flex;
  align-items: stretch;
`;

export const Operative = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: scroll;
  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;

    h1 {
      font-size: 20px;
    }

    div {
      button {
        margin-left: 10px;
      }
    }
  }
`;
