import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import Button from '~/styles/components/Button';
import { Container } from '../styles';
import api from '~/services/api';

const SignIn: React.FC = () => {
  const [values, setValues] = useState({
    email: '',
  });

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!values.email) {
      toastr.error('Erro', 'Preencha o campo email');
      return;
    }

    const { email } = values;

    try {
      await api.post('/users/forgot', { email });
      toastr.success(
        'Sucesso',
        'Enviamos um e-mail para confirmar a recuperação de senha, cheque sua caixa de mensagem',
      );
    } catch (err) {
      toastr.error('Erro', 'Não foi possível enviar o e-mail');
    }
  }

  return (
    <Container>
      <div className="content-form-block">
        <div className="row">
          <div className="col-md-4">
            <div className="logo-block">
              <span>Recuperar senha</span>
            </div>
          </div>
          <div className="col-md-8">
            <form onSubmit={handleSubmit}>
              <div className="d-flex align-items-center">
                <img
                  src="https://lojasimperio.com.br/images/logo.png"
                  alt="Império"
                  className="mr-2"
                />
                <span>- Administrativo</span>
              </div>
              <span>E-MAIL</span>
              <input
                type="email"
                name="email"
                value={values.email}
                onChange={e => setValues({ email: e.target.value })}
              />
              <div className="d-flex mt-4">
                <Button size="big" type="submit" className="mt-1 mr-2">
                  Recuperar
                </Button>
                <Link to="/signin">
                  <Button size="big" color="gray" type="button">
                    Voltar para o login
                  </Button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SignIn;
