import React from 'react';
import { useHistory } from 'react-router-dom';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { Container } from './styles';
import api from '~/services/api';
import Button from '~/styles/components/Button';
import { TableComponent } from '~/components/TableV2';
import Can from '~/components/Can';

const List: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <div className="d-flex justify-content-end">
        <Can checkPermission="edit_brand">
          <Button type="button" onClick={() => history.push('/brands/create')}>
            Nova Marca
          </Button>
        </Can>
      </div>

      <TableComponent
        labels={[
          {
            key: 'image',
            title: 'Imagem',
            formater: (url, d) => (
              <img
                src={url || '/sem-imagem.png'}
                width={url ? '120' : '120'}
                alt={String(d.position)}
              />
            ),

            ordenable: false,
          },
          {
            key: 'name',
            title: 'Nome da Marca',
          },

          {
            key: 'active',
            title: 'Status',
            formater: status =>
              status ? (
                <span className="ativo">Ativo</span>
              ) : (
                <span className="inativo">Inativo</span>
              ),
            ordenable: false,
          },
          {
            key: 'index',
            title: 'Ordem',
          },
        ]}
        filters={[
          {
            key: 'active',
            title: 'Status',
            defaultTo: '1',
            type: 'select',
            options: [
              {
                title: 'Ativo',
                value: '1',
              },
              {
                title: 'Inativo',
                value: '0',
              },
            ],
            col: 2,
          },
        ]}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            <Can checkPermission="edit_brand">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={() => history.push(`/brands/edit/${item.id}`)}
              >
                Edite
                <AiOutlineArrowRight />
              </button>
            </Can>
          </div>
        )}
        getData={async params => {
          const res = await api.get(`/brands`, {
            params,
          });
          return res;
        }}
      />
    </Container>
  );
};

export default List;
