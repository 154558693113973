import { format, subHours } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import {
  FaBoxOpen,
  FaCheckCircle,
  FaClipboard,
  FaTimesCircle,
} from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import api from '~/services/api';
import Button from '~/styles/components/Button';
import {
  normalizeCpf,
  normalizeCurrency,
  normalizeNumber,
} from '~/utils/normalize';

// import { Container } from './styles';

const PaymentLinksRegister: React.FC<{ match: any }> = ({ match }) => {
  const [values, setValues] = useState({
    id: 0,
    title: '',
    description: '',
    max_installments: 0,
    store_id: 0,
    active: true,
    completed: false,
    value: 0,
    responses: [] as {
      id: number;
      tid: string;
      nsu: string;
      payment_link_id: string;
      gateway: string;
      document_number: string;
      method_payment: string;
      success: boolean;
      response: string;
      request: string;
      ip: string;
      created_at: string;
    }[],
  });
  const [loading, setLoading] = useState(false);

  const [filterResponse, setFilterResponse] = useState<
    'all' | 'success' | 'unsuccess'
  >('all');

  const { id } = match.params;

  const [stores, setStores] = useState<any[]>([]);
  useEffect(() => {
    async function handle() {
      const { data } = await api.get(`/stores`, {
        params: {
          perpage: 10000,
        },
      });
      const first = data.data.find((d: any) => d.code === '510003');
      const second = data.data.find((d: any) => d.code === 'SERV');
      let arr = [] as any[];
      if (first) {
        arr.push(first);
      }
      if (second) {
        arr.push(second);
      }
      arr = [
        ...arr,
        ...data.data.filter((d: any) => !['510003', 'SERV'].includes(d.code)),
      ];
      setStores(arr);
    }
    handle();
  }, []);

  async function handleData() {
    setLoading(true);
    try {
      const { data } = await api.get(`/payment-links/${id}`);
      setValues(data);
    } catch (err) {
      //
    }
    setLoading(false);
  }

  useEffect(() => {
    if (id) {
      handleData();
    }
  }, [id]);

  const history = useHistory();

  const installments = useMemo(() => {
    const arr: { label: string; value: number }[] = [];
    for (let i = 1; i <= 24; i += 1) {
      if (values.value / i >= 100) {
        arr.push({
          label: `${i}x de R$ ${normalizeCurrency(
            String(Math.round(values.value / i)),
          )}`,
          value: i,
        });
      }
    }
    return arr;
  }, [values.value]);

  async function handleSubmit() {
    setLoading(true);
    try {
      if (id) {
        await api.put(`/payment-links/${id}`, {
          ...values,
        });
        toastr.success('Sucesso', 'Link de pagamento atualizado');
      } else {
        const { data } = await api.post(`/payment-links`, {
          ...values,
        });
        history.push(`/payment-links/register/${data.id}`);
        toastr.success('Sucesso', 'Link de pagamento criado');
      }
    } catch (err) {
      if ((err as any)?.response?.data?.message) {
        toastr.error('Erro', (err as any)?.response?.data?.message);
      } else {
        toastr.error('Ocorreu um erro inesperado', 'Contate o suporte');
      }
    }
    setLoading(false);
  }

  if (loading) {
    return (
      <div>
        <h5 className="mb-1">Link de Pagamento</h5>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/payment-links')}
          className="p-0"
        >
          <IoIosArrowBack /> Voltar
        </Button>
        <div className="w-100 bg-gray radius-4 bg-load p-5">
          <h5 className="mb-0">
            <div className="spinner spinner-border" /> Carregando... aguarde
          </h5>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h5 className="mb-1">Link de Pagamento</h5>
      <Button
        filled={false}
        type="button"
        onClick={() => history.push('/payment-links')}
        className="p-0"
      >
        <IoIosArrowBack /> Voltar
      </Button>
      <div className="mt-1 bg-white p-3 radius-4 shadow-sm">
        <div className="row">
          <div className="col-md-3">
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="row">
                <div className="col-md-12 mb-2">
                  <div>Loja de faturamento</div>
                  <select
                    value={values.store_id}
                    onChange={e =>
                      setValues({
                        ...values,
                        store_id: Number(e.target.value),
                      })
                    }
                    className="form-control"
                    disabled={values.id !== 0}
                    required
                  >
                    <option value="">Selecionar</option>
                    {stores.map(store => (
                      <option value={store.id} key={store.id}>
                        [{store.code}] {store.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12 mb-2">
                  <div>Título</div>
                  <input
                    type="text"
                    value={values.title}
                    onChange={e =>
                      setValues({
                        ...values,
                        title: e.target.value,
                      })
                    }
                    className="form-control"
                    disabled={values.id !== 0}
                    required
                  />
                </div>
                <div className="col-md-12 mb-2">
                  <div>Descrição</div>
                  <textarea
                    value={values.description}
                    onChange={e =>
                      setValues({
                        ...values,
                        description: e.target.value,
                      })
                    }
                    disabled={values.id !== 0}
                    className="form-control"
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <div>Valor</div>
                  <input
                    type="text"
                    value={normalizeCurrency(String(values.value))}
                    onChange={e =>
                      setValues({
                        ...values,
                        value: Number(normalizeNumber(e.target.value)),
                      })
                    }
                    className="form-control"
                    inputMode="numeric"
                    disabled={values.id !== 0}
                    required
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <div>Parcelamento máximo</div>
                  <select
                    value={values.max_installments}
                    onChange={e =>
                      setValues({
                        ...values,
                        max_installments: Number(e.target.value),
                      })
                    }
                    className="form-control"
                    disabled={values.id !== 0}
                    required
                  >
                    <option value="">Selecionar</option>
                    {installments.map(installment => (
                      <option value={installment.value} key={installment.value}>
                        {installment.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-12 d-flex align-items-center mb-3">
                  <div>
                    <input
                      type="checkbox"
                      checked={values.active}
                      onChange={() =>
                        setValues({
                          ...values,
                          active: !values.active,
                        })
                      }
                      disabled={values.completed}
                    />{' '}
                    Ativo
                  </div>
                  {values.completed &&
                  values?.responses?.findIndex(
                    r => r.method_payment === 'refund' && r.success,
                  ) === -1 ? (
                    <strong className="text-success ml-4">
                      <FaCheckCircle /> Link pago
                    </strong>
                  ) : null}
                  {values.completed &&
                  values?.responses?.findIndex(
                    r => r.method_payment === 'refund' && r.success,
                  ) > -1 ? (
                    <strong className="text-danger ml-4">
                      Pagamento estornado
                    </strong>
                  ) : null}
                </div>
              </div>
              <div className="d-flex">
                <Button type="submit">Salvar</Button>
                {values.id !== 0 ? (
                  <button
                    type="button"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://pagamento.lojasimperio.com.br/${values.id}`,
                      );
                      toastr.info('Link copiado', '');
                    }}
                    className="ml-2"
                  >
                    <FaClipboard /> Copiar Link
                  </button>
                ) : null}
              </div>
            </form>
          </div>
          <div className="col-md-9">
            <strong>Transações/Tentativas</strong>

            {values?.responses?.length === 0 ? (
              <div
                className="border-1 border-muted p-5 d-flex flex-column justify-content-center align-items-center text-center radius-4"
                style={{ minHeight: '30vh' }}
              >
                <FaBoxOpen size={30} />
                <strong>Nenhuma tentativa ou transação</strong>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-2">Exibir apenas:</div>
                <div className="col-md-3 d-flex align-items-center">
                  <div>
                    <input
                      type="checkbox"
                      checked={filterResponse === 'success'}
                      onChange={() => {
                        if (filterResponse === 'success') {
                          setFilterResponse('all');
                        } else {
                          setFilterResponse('success');
                        }
                      }}
                    />{' '}
                    Sucesso
                  </div>
                  <div className="ml-3">
                    <input
                      type="checkbox"
                      checked={filterResponse === 'unsuccess'}
                      onChange={() => {
                        if (filterResponse === 'unsuccess') {
                          setFilterResponse('all');
                        } else {
                          setFilterResponse('unsuccess');
                        }
                      }}
                    />{' '}
                    Falha
                  </div>
                </div>
              </div>
            )}
            {values?.responses
              ?.filter(response => {
                if (filterResponse === 'success' && response.success) {
                  return response;
                }
                if (filterResponse === 'unsuccess' && !response.success) {
                  return response;
                }
                if (filterResponse === 'all') {
                  return response;
                }
                return false;
              })
              ?.map(response => (
                <div
                  className="p-3 radius-4 border-1 border-muted mt-3"
                  key={response.id}
                >
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="badge badge-secondary">
                        {response.gateway?.toUpperCase()}
                      </div>{' '}
                      {response.method_payment === 'refund' ? (
                        <div className="badge badge-warning mr-1">Estorno</div>
                      ) : null}
                      {response.success ? (
                        <>
                          <FaCheckCircle size={16} className="text-success" />{' '}
                          Sucesso
                        </>
                      ) : (
                        <>
                          <FaTimesCircle size={16} className="text-danger" />{' '}
                          Falhou
                        </>
                      )}
                    </div>
                    <strong className="d-block text-muted">
                      {response.created_at
                        ? format(
                            subHours(new Date(response.created_at), 3),
                            'dd/MM/yyyy HH:mm',
                          )
                        : ''}
                    </strong>
                  </div>
                  <hr />
                  <div className="row mb-2">
                    <div className="col-md-3">
                      <div>
                        <strong>CPF:</strong>{' '}
                        {normalizeCpf(response.document_number)}
                      </div>
                    </div>
                    {response.success ? (
                      <>
                        <div className="col-md-2">
                          <div>
                            <strong>NSU:</strong> {response.nsu || '-'}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div>
                            <strong>Autorização:</strong>{' '}
                            {response?.response
                              ? JSON.parse(response?.response)
                                  ?.authorizationCode || '-'
                              : '-'}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div>
                            <strong>ID:</strong> {response.tid || '-'}
                          </div>
                        </div>
                      </>
                    ) : null}
                    {response.method_payment !== 'refund' ? (
                      <div className="col-md-3">
                        <div>
                          <strong>Parcelamento:</strong>{' '}
                          {response?.request
                            ? `${
                                JSON.parse(response.request)?.card?.installments
                              }x`
                            : '-'}
                        </div>
                      </div>
                    ) : null}
                    {!response.success ? (
                      <div className="col-md-6 text-danger">
                        <strong>Resposta:</strong>{' '}
                        {response?.response
                          ? JSON.parse(response?.response)?.message
                          : '-'}
                      </div>
                    ) : null}
                    {response.success &&
                    response.method_payment !== 'refund' ? (
                      <div className="col-md-4">
                        <div>
                          <strong>Cartão:</strong>{' '}
                          {response?.response
                            ? `[${
                                response?.response
                                  ? JSON.parse(response?.request)?.card
                                      ?.brand || '-'
                                  : '-'
                              }]${
                                JSON.parse(response.response)?.json?.cardBin
                              }******${
                                JSON.parse(response.response)?.json?.last4
                              }`
                            : '-'}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => window.alert(response.request)}
                      >
                        Ver Detalhes de Envio
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm ml-2"
                        onClick={() => window.alert(response.response)}
                      >
                        Ver Detalhes de Resposta
                      </button>
                    </div>
                    {response.success &&
                    response.method_payment !== 'refund' ? (
                      <button
                        type="button"
                        className="btn btn-warning btn-sm"
                        onClick={() => {
                          const confirm = window.confirm(
                            'Você tem certeza que deseja estornar o pagamento? Essa ação não pode ser desfeita',
                          );
                          if (confirm) {
                            setLoading(true);
                            api
                              .post(
                                `payment-link-responses/${response.id}/refund`,
                              )
                              .then(() => {
                                toastr.success('Pagamento estornado', '');
                                handleData();
                              })
                              .catch(err => {
                                if (err?.response?.data?.message) {
                                  toastr.error(
                                    'Erro',
                                    err?.response?.data?.message,
                                  );
                                } else {
                                  toastr?.error(
                                    'Erro',
                                    'Ocorreu um erro inesperado, contate o suporte',
                                  );
                                }
                              })
                              .finally(() => setLoading(false));
                          }
                        }}
                        disabled={
                          values.responses.findIndex(
                            r =>
                              r.success &&
                              r.method_payment === 'refund' &&
                              r.tid === response.tid,
                          ) > -1
                        }
                      >
                        Estornar pagamento
                      </button>
                    ) : null}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentLinksRegister;
