import styled from 'styled-components';

export const Container = styled.div``;

export const Label = styled.span`
  color: #2d3047;
  font-weight: bold;
  display: block;
  font-size: 12px;
  margin-bottom: 2px;
  margin-left: 4px;
`;
