import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { toastr } from 'react-redux-toastr';
import { format } from 'date-fns';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';
import { normalizeCpf, normalizeDate, normalizePhone } from '~/utils/normalize';

import { Container } from './styles';

interface IAddresses {
  id: number;
  customer_id: number;
  zip_code: string;
  street: string;
  number: string;
  complement: string;
  city: string;
  district: string;
  state: string;
}
interface IFavorites {
  id: number;
  sku: string;
  name: string;
  price: number;
  special_price: number;
}

interface ICustomer {
  name: string;
  lastname: string;
  cpf: string;
  email: string;
  phone: string;
  birthdate: string;
  newsletter: string;
  addresses: IAddresses[];
  favorites: IFavorites[];
  loading: boolean;
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const View: React.FC<IProps> = ({ match }) => {
  const [customer, setCustomer] = useState<ICustomer>({
    name: '',
    lastname: '',
    cpf: '',
    email: '',
    phone: '',
    birthdate: '',
    newsletter: '',
    addresses: [],
    favorites: [],
    loading: false,
  });

  const [tab, setTab] = useState('customer');

  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [phone, setPhone] = useState('');
  const [newsletter, setNewsletter] = useState('');
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  async function loadPage() {
    try {
      const { data } = await api.get(`/customers/${match.params.id}`);

      let bdate = '';

      if (data.birthdate) {
        const [year, month, day] = data.birthdate.split('T')[0].split('-');
        bdate = `${day}/${month}/${year}`;
      }

      setCustomer({
        ...customer,
        name: data.name,
        lastname: data.lastname,
        cpf: normalizeCpf(data.cpf),
        email: data.email,
        birthdate: bdate,
        phone: normalizePhone(data.phone),
        addresses: data.addresses,
        favorites: data.favorites,
        newsletter: data.newsletter,
      });

      setName(data.name);
      setLastname(data.lastname);
      setCpf(normalizeCpf(data.cpf));
      setEmail(data.email);
      setPhone(normalizePhone(data.phone));
      setBirthdate(bdate);
      setNewsletter(data.newsletter);

      setLoading(false);
    } catch (err) {
      toastr.error(
        'Ocorreu um erro',
        'Durante a busca nossos servidores não puderam responder',
      );
    }
  }

  useEffect(() => {
    loadPage();
  }, [match.params.id]); // eslint-disable-line

  async function handleEdit() {
    if (!birthdate) {
      toastr.error('Validação', 'Informe uma data de nascimento');
      return;
    }

    const [day, month, year] = birthdate.split('/');

    setLoading(true);
    try {
      const response = await api.put(`/customers/${match.params.id}`, {
        name,
        lastname,
        email,
        phone,
        birthdate: birthdate ? `${year}-${month}-${day}T03:00:00` : undefined,
        newsletter,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        toastr.success('Cliente atualizado', '');

        const { data } = await api.get(`/customers/${match.params.id}`);

        let bdate = '';

        if (data.birthdate) {
          const [yearR, monthR, dayR] = data.birthdate.split('T')[0].split('-');
          bdate = `${dayR}/${monthR}/${yearR}`;
        }

        setCustomer({
          ...customer,
          name: data.name,
          lastname: data.lastname,
          cpf: normalizeCpf(data.cpf),
          email: data.email,
          phone: normalizePhone(data.phone),
          birthdate: bdate,
          addresses: data.addresses,
          favorites: data.favorites,
          newsletter: data.newsletter,
        });

        setName(data.name);
        setLastname(data.lastname);
        setCpf(normalizeCpf(data.cpf));
        setEmail(data.email);
        setPhone(normalizePhone(data.phone));
        setBirthdate(bdate);
        setNewsletter(data.newsletter);
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/customers/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Visualizar Cliente</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <ul className="nav-tabs-c">
                <li className="nav-item-c">
                  <button
                    onClick={() => setTab('customer')}
                    className={`nav-link ${tab === 'customer' ? 'active' : ''}`}
                    type="button"
                  >
                    Dados cadastrais
                  </button>
                </li>
                <li className="nav-item-c">
                  <button
                    onClick={() => setTab('address')}
                    className={`nav-link ${tab === 'address' ? 'active' : ''}`}
                    type="button"
                  >
                    Endereços
                  </button>
                </li>
                <li className="nav-item-c">
                  <button
                    onClick={() => setTab('favorite')}
                    className={`nav-link ${tab === 'favorite' ? 'active' : ''}`}
                    type="button"
                  >
                    Produtos favoritados
                  </button>
                </li>
              </ul>
              <div className="tab-content-c">
                {tab === 'customer' && (
                  <div className="tab-pane-c">
                    <div className="row mb-2">
                      <div className="col-md-4">
                        <Input
                          label="CPF"
                          name="cpf"
                          initialValue={customer.cpf}
                          required
                          onChange={e => setCpf(String(e.value))}
                          disabled
                        />
                      </div>
                      <div className="col-md-4">
                        <Input
                          label="Nome"
                          name="name"
                          initialValue={customer.name}
                          required
                          onChange={e => setName(String(e.value))}
                          disabled={loading}
                        />
                      </div>
                      <div className="col-md-4">
                        <Input
                          label="Sobrenome"
                          name="lastname"
                          initialValue={customer.lastname}
                          required
                          onChange={e => setLastname(String(e.value))}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-4">
                        <Input
                          label="E-mail"
                          name="email"
                          initialValue={customer.email}
                          required
                          onChange={e => setEmail(String(e.value))}
                          disabled={loading}
                        />
                      </div>
                      <div className="col-md-4">
                        <Input
                          label="Telefone"
                          name="phone"
                          initialValue={customer.phone}
                          required
                          normalize={value => normalizePhone(value)}
                          onChange={e => setPhone(String(e.value))}
                          disabled={loading}
                        />
                      </div>
                      <div className="col-md-4">
                        <Input
                          label="Data de Nascimento"
                          name="birthdate"
                          initialValue={customer.birthdate}
                          required
                          onChange={e => setBirthdate(String(e.value))}
                          normalize={e => normalizeDate(e)}
                          disabled={loading}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        type="button"
                        disabled={loading}
                        onClick={handleEdit}
                      >
                        {loading ? 'Salvando...' : 'Salvar'}
                      </Button>
                    </div>
                  </div>
                )}
                {tab === 'address' && (
                  <div className="tab-pane-c">
                    <div className="ml-1 mr-1 row">
                      {customer.addresses &&
                        customer.addresses.map(e => {
                          return (
                            <p
                              className="mb-3 ml-1 mr-1 col-3 address"
                              key={e.id}
                            >
                              {`${e.street}, ${e.district}, ${e.number}, ${
                                e.complement ? `${e.complement}, ` : ''
                              } ${e.city} - ${e.state} - CEP ${e.zip_code}`}
                            </p>
                          );
                        })}
                    </div>
                  </div>
                )}
                {tab === 'favorite' && (
                  <div className="tab-pane-c">
                    <div className="ml-1 mr-1 row">
                      {customer.favorites &&
                        customer.favorites.map(e => {
                          return (
                            <div
                              className="mb-3 ml-1 mr-1 col-3 favorites"
                              key={e.id}
                            >
                              <strong>
                                {e.name} - <small>{e.sku}</small>
                              </strong>
                              {e.special_price ? (
                                <>
                                  <p className="priceOld">
                                    R$ {(e.price / 100).toFixed(2)}
                                  </p>
                                  <p className="price">
                                    R$ {(e.special_price / 100).toFixed(2)}
                                  </p>
                                </>
                              ) : (
                                <p className="price">
                                  {(e.price / 100).toFixed(2)}
                                </p>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default View;
