import React, { useState, useEffect } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { FaCheck } from 'react-icons/fa';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container } from './styles';

interface IPermission {
  id: number;
  slug: string;
  name: string;
  pivot: {
    permission_id: number;
  };
}

interface IRole {
  slug: string;
  name: string;
  permissions: IPermission[];
  loading: boolean;
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const [role, setRole] = useState<IRole>({
    name: '',
    slug: '',
    permissions: [],
    loading: false,
  });
  const [slug, setSlug] = useState('');
  const [name, setName] = useState('');
  const [permissions, setPermissions] = useState<number[]>([]);
  const [permissionsData, setPermissionsData] = useState<IPermission[]>([]);
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const history = useHistory();

  useEffect(() => {
    async function loadRole() {
      try {
        const { data } = await api.get(`/roles/${match.params.id}`);
        setRole({
          ...role,
          name: data.name,
          slug: data.slug,
          permissions: data.permissions.map((p: any) => p.id),
        });
        setPermissions(data.permissions.map((p: any) => p.id));
        const { data: permissionsDataA } = await api.get(
          '/permissions?page=1&perpage=10000',
        );
        setSlug(data.slug);
        setName(data.name);
        setPermissionsData(permissionsDataA.data);
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadRole();
  }, [match.params.id]); // eslint-disable-line

  function handleSelect(value: number) {
    if (!permissions.find(p => p === value)) {
      setPermissions([...permissions, value]);
    } else {
      setPermissions(permissions.filter(p => p !== value));
    }
  }

  async function handleEdit() {
    if (permissions.length === 0) {
      toastr.error('Validação', 'Nenhuma permissão selecionada');
      return;
    }
    setLoading(true);
    try {
      const response = await api.put(`/roles/${match.params.id}`, {
        name: name || role.name,
        slug: slug || role.slug,
        permissions:
          permissions.length === 0
            ? role.permissions.map(p => p.pivot.permission_id)
            : permissions,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/roles/list');
        toastr.success('Perfil atualizado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/roles/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          history.push('/roles/list');
          toastr.error(i.message, '');
        });
      } else {
        history.push('/roles/list');
        toastr.success('Perfil excluído', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/roles/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="p-3 bg-white radius-4 shadow-sm">
        <h5>Editar Perfil</h5>
        <hr />
        {!loading && (
          <>
            <div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <Input
                    label="Nome"
                    name="name"
                    required
                    initialValue={role.name}
                    onChange={e => setName(String(e.value))}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="Slug"
                    name="slug"
                    required
                    initialValue={role.slug}
                    onChange={e => setSlug(String(e.value))}
                  />
                </div>
                <div className="col-md-12">
                  <h5>Permissões *</h5>
                  <div className="row">
                    {permissionsData.map(p => (
                      <div key={p.id} className="col-md-3">
                        <button
                          type="button"
                          onClick={() => handleSelect(p.id)}
                          style={{
                            borderRadius: '4px',
                          }}
                          className="d-flex align-items-center justify-content-between mr-2 mt-2 border-1 border-muted p-2 w-100"
                        >
                          {p.name}
                          <div
                            className="bg-gray d-flex justify-content-center align-items-center"
                            style={{
                              width: '24px',
                              height: '24px',
                              marginLeft: '4px',
                            }}
                          >
                            {permissions.findIndex(i => i === p.id) > -1 ? (
                              <FaCheck color="#222" />
                            ) : (
                              ''
                            )}
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {!confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <Button type="button" disabled={loading} onClick={handleEdit}>
                  {loading ? 'Salvando...' : 'Salvar'}
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="ml-2"
                  disabled={loading}
                  onClick={() => setConfirmDelete(true)}
                >
                  {loading ? '...' : 'Excluir Perfil'}
                </Button>
              </div>
            )}
            {confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <div>
                  <h3 style={{ textAlign: 'right' }}>
                    Você tem certeza que deseja excluir este perfil?
                  </h3>
                  <div>
                    <Button
                      type="button"
                      color="danger"
                      disabled={loading}
                      onClick={handleDelete}
                    >
                      {loading ? 'Excluindo...' : 'Excluir'}
                    </Button>
                    <Button
                      type="button"
                      color="gray"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmDelete(false)}
                    >
                      {loading ? '...' : 'Cancelar'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
