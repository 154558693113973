import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { toastr } from 'react-redux-toastr';
import { format, parseISO, subHours } from 'date-fns';
import api from '~/services/api';
import Button from '~/styles/components/Button';

import {
  normalizeCpf,
  normalizePhone,
  normalizeCurrency,
} from '~/utils/normalize';

import { Container } from './styles';

interface ICustomer {
  id: number;
  name: string;
  lastname: string;
  cpf: string;
  email: string;
  phone: string;
  newsletter: boolean;
}

interface IOrderStatus {
  id: number;
  name: string;
}

interface IProduct {
  name: string;
  marketplace_id: number;
}

interface IOrderProducts {
  price: number;
  product_id: number;
  qtd: number;
  sku: string;
  product: IProduct;
}

interface IService {
  name: string;
}

interface IOrderServices {
  price: number;
  service_id: number;
  service_sku: number;
  service: IService;
}

interface IStore {
  name: string;
  code: string;
  fullAddress: string;
}

interface IorderDelivery {
  days: number;
  delivery_type: string;
  price: number;
  store_code: string;
  store_id: number;
  store?: IStore;
}

interface IorderDeliveryAddress {
  city: string;
  district: string;
  state: string;
  street: string;
  streetComplement: string;
  streetNumber: string;
  zipCode: string;
}

interface IorderBillingAddress {
  city: string;
  district: string;
  state: string;
  street: string;
  streetComplement: string;
  streetNumber: string;
  zipCode: string;
}

interface IorderPaymentCardDeclined {
  id: number;
  data: string;
  created_at: string;
}

interface IOrder {
  id: number;
  customer: ICustomer;
  // customer_id: null;
  // seller_id: null;
  // order_status_id: null;
  code_coupon?: string;
  subtotal: number;
  service: number;
  discount: number;
  frete: number;
  total: number;
  orderStatus: IOrderStatus;
  // customer: null;
  seller?: {
    code: string;
    name: string;
  };
  // orderPercent: null;
  orderProducts: IOrderProducts[];
  orderServices: IOrderServices[];
  orderBillingAddress: IorderBillingAddress;
  orderDeliveryAddress: IorderDeliveryAddress;
  orderDelivery: IorderDelivery;
  orderPaymentCardDeclined: IorderPaymentCardDeclined[];
  created_at: string;
  loading: boolean;
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const View: React.FC<IProps> = ({ match }) => {
  const [order, setOrder] = useState<IOrder>({
    id: 0,
    customer: {
      id: 0,
      name: '',
      lastname: '',
      cpf: '',
      email: '',
      phone: '',
      newsletter: false,
    },
    orderBillingAddress: {
      city: '',
      district: '',
      state: '',
      street: '',
      streetComplement: '',
      streetNumber: '',
      zipCode: '',
    },
    orderDeliveryAddress: {
      city: '',
      district: '',
      state: '',
      street: '',
      streetComplement: '',
      streetNumber: '',
      zipCode: '',
    },
    orderDelivery: {
      days: 0,
      delivery_type: '',
      price: 0,
      store_code: '',
      store_id: 0,
    },
    orderProducts: [],
    orderServices: [],
    orderPaymentCardDeclined: [],
    service: 0,
    subtotal: 0,
    discount: 0,
    frete: 0,
    total: 0,
    orderStatus: {
      id: 0,
      name: '',
    },
    created_at: '',
    loading: false,
  });

  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    async function loadPage() {
      try {
        const { data } = await api.get(`/abandoned/orders/${match.params.id}`);
        setOrder({
          ...order,
          id: data.id,
          discount: data.discount,
          frete: data.frete,
          subtotal: data.subtotal,
          service: data.service,
          total: data.total,
          created_at: data.created_at,
          customer: data.customer,
          orderBillingAddress: data.orderBillingAddress,
          orderDeliveryAddress: data.orderDeliveryAddress,
          orderDelivery: data.orderDelivery,
          orderProducts: data.orderProducts,
          orderServices: data.orderServices,
          orderPaymentCardDeclined: data.orderPaymentCardDeclined,
          orderStatus: data.orderStatus,
          seller: data.seller,
          code_coupon: data.code_coupon,
        });

        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadPage();
  }, [match.params.id]); // eslint-disable-line

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/orders/abandoned/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Detalhamento do Pedido</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row">
                <div className="col-12 header-title">
                  <p>
                    Pedido n.º{' '}
                    <strong>
                      {order.orderProducts[0].product.marketplace_id === 1
                        ? 'IMP'
                        : 'MKT'}
                      -{order.id}
                    </strong>
                  </p>
                  <p>
                    Data do pedido{' '}
                    <strong>
                      {format(
                        subHours(parseISO(String(order.created_at)), 3),
                        'dd/MM/yyyy HH:mm',
                      )}
                    </strong>
                  </p>
                </div>
              </div>
              {order.orderDeliveryAddress && <hr />}
              <div className="row mb-3">
                {order.orderDeliveryAddress && (
                  <div className="col-6">
                    <p className="order-customer ml-5 mr-4">
                      Pedido de{' '}
                      <strong>
                        {order.customer.name} {order.customer.lastname}
                      </strong>
                      <br />
                      <br />
                      <strong>CPF:</strong> {normalizeCpf(order.customer.cpf)}
                      <br />
                      <strong>Telefone:</strong>{' '}
                      {normalizePhone(order.customer.phone)}
                      <br />
                      <strong>E-mail:</strong> {order.customer.email}
                      <br />
                      <strong>Endereço:</strong>{' '}
                      {`${order.orderDeliveryAddress.street}, ${
                        order.orderDeliveryAddress.streetNumber
                      }${
                        order.orderDeliveryAddress.streetComplement
                          ? `, ${order.orderDeliveryAddress.streetComplement}`
                          : ''
                      } - ${order.orderDeliveryAddress.district} - ${
                        order.orderDeliveryAddress.zipCode
                      } | ${order.orderDeliveryAddress.city} - ${
                        order.orderDeliveryAddress.state
                      }`}
                      <br />
                    </p>
                  </div>
                )}
                {order.seller && (
                  <div className="col-12 mt-5">
                    <p className="order-customer ml-5 mr-4">
                      <strong>Vendendor(a)</strong>
                      <br />
                      {order.seller.code} | {order.seller.name}
                    </p>
                  </div>
                )}
                {order.code_coupon && (
                  <div className="col-12 mt-5">
                    <p className="order-customer ml-5 mr-4">
                      <strong>Cupom</strong>
                      <br />
                      {order.code_coupon}
                    </p>
                  </div>
                )}
                {order.orderDelivery && (
                  <div className="col-12 mt-5">
                    <p className="order-customer ml-5 mr-4">
                      <strong>
                        {order.orderDelivery.delivery_type === 'cliqueeretire'
                          ? 'Retirar na loja'
                          : 'Receber em casa'}
                      </strong>
                      <br />
                      {order.orderDelivery.store && (
                        <p>
                          Pedido para:{' '}
                          <strong>
                            {order.orderDelivery.store.name} -{' '}
                            {order.orderDelivery.store.code}
                          </strong>
                          <br />
                          Endereço: {order.orderDelivery.store.fullAddress}
                          <br />
                        </p>
                      )}
                    </p>
                  </div>
                )}
                {order.orderPaymentCardDeclined.length > 0 && (
                  <div className="col-12 mt-5">
                    <p className="order-customer ml-5 mr-4">
                      <strong>Pagamento negados</strong>
                      <br />
                      <br />
                      {order.orderPaymentCardDeclined.map(payment => {
                        const json = JSON.parse(payment.data);
                        return (
                          <p key={payment.id}>
                            - {json.msg} (
                            {format(
                              subHours(parseISO(payment.created_at), 3),
                              'dd/MM/yyyy HH:mm',
                            )}
                            )
                          </p>
                        );
                      })}
                    </p>
                  </div>
                )}
              </div>
              {order.orderDeliveryAddress && <hr />}
              <div className="row mb-3">
                <div className="col-12 mb-5">
                  <h4 className="ml-5">Produtos</h4>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Cód</th>
                        <th>Item</th>
                        <th>Qtd</th>
                        <th>Preço Unitário</th>
                        <th className="text-right">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.orderProducts.map(e => {
                        return (
                          <tr>
                            <td>{e.sku}</td>
                            <td>
                              <span>{e.product.name}</span>
                            </td>
                            <td>{e.qtd}</td>
                            <td>R$ {normalizeCurrency(String(e.price))}</td>
                            <td className="text-right">
                              R$ {normalizeCurrency(String(e.price * e.qtd))}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {order.orderServices && (
                    <>
                      <h4 className="ml-5 mt-5">Serviços</h4>
                      <table className="table mb-5">
                        <thead>
                          <tr>
                            <th>Cód</th>
                            <th>Item</th>
                            <th className="text-right">Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.orderServices.map(e => {
                            return (
                              <tr>
                                <td>{e.service_sku}</td>
                                <td>
                                  <span>{e.service.name}</span>
                                </td>
                                <td className="text-right">
                                  R$ {normalizeCurrency(String(e.price))}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  )}
                  <div className="row table-calc">
                    <div className="col-sm-4">
                      <table>
                        <tbody>
                          <tr>
                            <td>Serviços</td>
                            <td className="text-right">
                              R$ {normalizeCurrency(String(order.service))}
                            </td>
                          </tr>
                          <tr>
                            <td>Subtotal</td>
                            <td className="text-right">
                              R$ {normalizeCurrency(String(order.subtotal))}
                            </td>
                          </tr>
                          <tr>
                            <td>Frete</td>
                            <td className="text-right">
                              R$ {normalizeCurrency(String(order.frete))}
                            </td>
                          </tr>
                          <tr>
                            <td>Desconto</td>
                            <td className="text-right">
                              R$ {normalizeCurrency(String(order.discount))}
                            </td>
                          </tr>
                          <tr>
                            <td>Valor total</td>
                            <td className="text-right">
                              <strong>
                                R$ {normalizeCurrency(String(order.total))}
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default View;
