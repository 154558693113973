import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { toastr } from 'react-redux-toastr';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container } from './styles';

const Create: React.FC = () => {
  const [name, setName] = useState('');
  const [synonyms, setSynonyms] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  async function handleAdd() {
    setLoading(true);
    try {
      const response = await api.post(`/search-synonyms`, {
        name,
        synonyms,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        toastr.success('Sinônimo criado', '');
        history.push('/synonyms/list');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/synonyms/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Criar Sinônimo</h3>
        </div>
        <div className="body_block">
          <div className="row mb-2">
            <div className="col-md-12">
              <Input
                label="Palavra"
                name="name"
                required
                onChange={e => setName(String(e.value))}
                disabled={loading}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-12">
              <Input
                label="Sinônimos"
                name="synonyms"
                required
                onChange={e => setSynonyms(String(e.value))}
                disabled={loading}
              />
              <legend className="mb-4 small">
                Separe os sinônimos por vírgula sem espaço (ex: carro,moto)
              </legend>
            </div>
          </div>
        </div>
        <div className="footer_block d-flex justify-content-end">
          <Button type="button" onClick={handleAdd}>
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Create;
