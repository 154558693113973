import {
  AuthActionTypes,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
  SIGN_UP_REQUEST,
  GET_PERMISSIONS_SUCCESS,
  IRole,
} from './types';

export function signInRequest(
  tmp_token: string,
  code: string,
  g_response: string,
): AuthActionTypes {
  return {
    type: SIGN_IN_REQUEST,
    payload: { tmp_token, code, g_response },
  };
}

export function signInSuccess(token: string): AuthActionTypes {
  return {
    type: SIGN_IN_SUCCESS,
    payload: { token },
  };
}

export function signOut(): AuthActionTypes {
  return {
    type: SIGN_OUT,
  };
}

export function signUpRequest(
  name: string,
  email: string,
  password: string,
): AuthActionTypes {
  return {
    type: SIGN_UP_REQUEST,
    payload: {
      name,
      email,
      password,
    },
  };
}

export function getPermissionsSuccess(
  roles: IRole[],
  permissions: IRole[],
): AuthActionTypes {
  return {
    type: GET_PERMISSIONS_SUCCESS,
    payload: {
      roles,
      permissions,
    },
  };
}
