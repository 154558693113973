import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { MdSettings } from 'react-icons/md';
import api from '~/services/api';
import { Delivery } from './components/Delivery';
import { Integration } from './components/Integration';
import { Webhook } from './components/Webhook';

// import { Container } from './styles';

export const ConfigMarketplace: React.FC = () => {
  const [configDelivery, setConfigDelivery] = useState({
    open: false,
    id: null as null | number,
  });
  const [configIntegration, setConfigIntegration] = useState({
    open: false,
    id: null as null | number,
  });
  const [configWebhook, setConfigWebhook] = useState({
    open: false,
    id: null as null | number,
  });

  const [deliveries, setDeliveries] = useState<
    {
      _id: string;
      type: string;
      createdAt: string;
    }[]
  >([]);

  const [integrations, setIntegrations] = useState<
    {
      _id: string;
      type: string;
      createdAt: string;
    }[]
  >([]);

  const [webhooks, setWebhooks] = useState<
    {
      _id: string;
      type: string;
      method: 'POST' | 'GET' | 'PUT' | 'DELETE';
      endpoint: string;
      createdAt: string;
    }[]
  >([]);

  const loadDeliveries = async () => {
    const { data } = await api.get(`/marketplace-delivery/config`);
    setDeliveries(data);
  };
  const loadIntegrations = async () => {
    const { data } = await api.get(`/marketplace-integration/config`);
    setIntegrations(data);
  };
  const loadWebhooks = async () => {
    const { data } = await api.get(`/marketplace-webhook/config`);
    setWebhooks(data);
  };

  useEffect(() => {
    loadDeliveries();
    loadIntegrations();
    loadWebhooks();
  }, []);

  const traitMethod = (method: 'POST' | 'GET' | 'PUT' | 'DELETE') => {
    switch (method) {
      case 'GET':
        return <div className="badge badge-success">GET</div>;
      case 'POST':
        return <div className="badge badge-primary">POST</div>;
      case 'PUT':
        return <div className="badge badge-warning">PUT</div>;
      case 'DELETE':
        return <div className="badge badge-danger">DELETE</div>;
      default:
        return '';
    }
  };

  return (
    <div>
      {configDelivery.open && (
        <Delivery
          id={configDelivery.id}
          onHide={() =>
            setConfigDelivery({
              open: false,
              id: null,
            })
          }
          onUpdate={() => {
            setConfigDelivery({
              open: false,
              id: null,
            });
            loadDeliveries();
          }}
        />
      )}
      {configIntegration.open && (
        <Integration
          id={configIntegration.id}
          onHide={() =>
            setConfigIntegration({
              open: false,
              id: null,
            })
          }
          onUpdate={() => {
            setConfigIntegration({
              open: false,
              id: null,
            });
            loadDeliveries();
          }}
        />
      )}
      {configWebhook.open && (
        <Webhook
          id={configWebhook.id}
          onHide={() =>
            setConfigWebhook({
              open: false,
              id: null,
            })
          }
          onUpdate={() => {
            setConfigWebhook({
              open: false,
              id: null,
            });
            loadDeliveries();
          }}
        />
      )}

      <h5 className="m-0">Configuração do Marketplace</h5>
      <div className="row mt-3">
        <div className="col-md-4">
          <div className="bg-white shadow-sm radius-4 p-3">
            <h5 className="m-0">Serviços de Entrega</h5>
            <div>
              {deliveries.length === 0 && (
                <div className="d-flex align-items-center text-danger mt-2">
                  <FaTimesCircle className="mr-2" /> Nenhum serviço de entrega
                  configurado
                </div>
              )}
              <p className="text-muted mb-0">
                Necessário configurar um serviço de entrega para ter seus
                produtos disponibilizados em nosso site.
              </p>
              <button
                type="button"
                onClick={() =>
                  setConfigDelivery({
                    open: true,
                    id: null,
                  })
                }
                className="btn btn-primary btn-sm mt-2"
              >
                <MdSettings /> Configurar Serviço
              </button>
            </div>

            <div>
              <table className="w-100 mt-3">
                <tbody>
                  {deliveries.map(delivery => (
                    <tr key={delivery._id}>
                      <td style={{ width: '120px' }}>
                        {delivery.type === 'correios' && (
                          <img
                            src="https://brasiliashopping.com.br/wp-content/uploads/2021/01/Mudanc%CC%A7as-no-PAc-dos-Correios-Idealize-Tecnologia-1.png"
                            alt="Correios"
                            style={{ maxWidth: '100px' }}
                          />
                        )}
                      </td>
                      <td className="text-success">
                        <FaCheckCircle /> <strong>Integrado</strong>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="bg-white shadow-sm radius-4 p-3">
            <h5 className="m-0">Integrações em suas mídias</h5>
            <div>
              {integrations.length === 0 && (
                <div className="d-flex align-items-center text-danger mt-2">
                  <FaTimesCircle className="mr-2" /> Nenhuma integração
                  configurada
                </div>
              )}
              <p className="text-muted mb-0">
                A Integração é importante para notifica-lo de novos pedidos,
                buscar informações de seus produtos e estoque de forma
                automatizada.
              </p>
              <button
                type="button"
                onClick={() =>
                  setConfigIntegration({
                    open: true,
                    id: null,
                  })
                }
                className="btn btn-primary btn-sm mt-2"
              >
                <MdSettings /> Configurar Integração
              </button>
            </div>

            <div>
              <table className="w-100 mt-3">
                <tbody>
                  {integrations.map(integration => (
                    <tr key={integration._id}>
                      <td style={{ width: '120px' }}>
                        {integration.type === 'woocomerce' && (
                          <img
                            src="https://www.monetran.com/wp-content/uploads/2022/01/Was-ist-WooCommerce.png"
                            alt="WooComerce"
                            style={{ maxWidth: '100px' }}
                          />
                        )}
                      </td>
                      <td className="text-success">
                        <FaCheckCircle /> <strong>Integrado</strong>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="bg-white shadow-sm radius-4 p-3">
            <h5 className="m-0">Webhooks</h5>
            <div>
              <p className="text-muted mb-0 mt-2">
                Webhooks são feitos para enviar requisições para sua aplicação
                assim que um evento é realizado (novo pedido, buscar produto,
                verificar estoque), no padrão descrito nos manuais de cada tipo
                de webhook.
              </p>
              <button
                type="button"
                onClick={() =>
                  setConfigWebhook({
                    open: true,
                    id: null,
                  })
                }
                className="btn btn-primary btn-sm mt-2"
              >
                <MdSettings /> Configurar Webhook
              </button>
            </div>
            <div>
              <table className="w-100 mt-3">
                <tbody>
                  {webhooks.map(webhook => (
                    <tr key={webhook._id}>
                      <td>{traitMethod(webhook.method)}</td>
                      <td className="text-muted">{webhook.endpoint}</td>
                      <td>
                        <div className="badge badge-light mr-2">
                          {webhook.type === 'get-products' && 'Buscar Produtos'}
                          {webhook.type === 'get-products-paginated' &&
                            'Buscar Produtos (Paginado)'}
                          {webhook.type === 'new-order' && 'Novo Pedido'}
                          {webhook.type === 'verify-stock' &&
                            'Verificar Estoque'}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
