import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { toastr } from 'react-redux-toastr';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container } from './styles';

interface ISynonym {
  name: string;
  synonyms: string;
  loading: boolean;
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const [synonym, setSynonym] = useState<ISynonym>({
    name: '',
    synonyms: '',
    loading: false,
  });
  const [name, setName] = useState('');
  const [synonyms, setSynonyms] = useState('');
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function loadPage() {
      try {
        const { data } = await api.get(`/search-synonyms/${match.params.id}`);
        setSynonym({
          ...synonym,
          name: data.name,
          synonyms: data.synonyms,
        });
        setName(data.name);
        setSynonyms(data.synonyms);
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadPage();
  }, [match.params.id]); // eslint-disable-line

  async function handleEdit() {
    setLoading(true);
    try {
      const response = await api.put(`/search-synonyms/${match.params.id}`, {
        name,
        synonyms,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/synonyms/list');
        toastr.success('Sinônimo atualizado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/search-synonyms/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/synonyms/list');
        toastr.success('Sinônimo apagado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/synonyms/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Editar Sinônimo</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row mb-2">
                <div className="col-md-12">
                  <Input
                    label="Palavra"
                    name="name"
                    required
                    initialValue={synonym.name}
                    onChange={e => setName(String(e.value))}
                    disabled={loading}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-md-12">
                  <Input
                    label="Sinônimos"
                    name="synonyms"
                    required
                    initialValue={synonym.synonyms}
                    onChange={e => setSynonyms(String(e.value))}
                    disabled={loading}
                  />
                  <legend className="mb-4 small">
                    Separe os sinônimos por vírgula sem espaço (ex: carro,moto)
                  </legend>
                </div>
              </div>
            </div>
            {!confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <Button type="button" disabled={loading} onClick={handleEdit}>
                  {loading ? 'Salvando...' : 'Salvar'}
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="ml-2"
                  disabled={loading}
                  onClick={() => setConfirmDelete(true)}
                >
                  {loading ? '...' : 'Excluir Sinônimo'}
                </Button>
              </div>
            )}
            {confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <div>
                  <h3 style={{ textAlign: 'right' }}>
                    Você tem certeza que deseja excluir esta Sinônimo?
                  </h3>
                  <div>
                    <Button
                      type="button"
                      color="danger"
                      disabled={loading}
                      onClick={handleDelete}
                    >
                      {loading ? 'Excluindo...' : 'Excluir'}
                    </Button>
                    <Button
                      type="button"
                      color="gray"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmDelete(false)}
                    >
                      {loading ? '...' : 'Cancelar'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
