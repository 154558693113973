import React, { useState, useEffect, Fragment } from 'react';

import { toastr } from 'react-redux-toastr';

import { useHistory } from 'react-router-dom';
import { FaFilter } from 'react-icons/fa';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { IFilter } from '~/components/Table';
import {
  Container as ContainerTable,
  TableFilters,
  Table,
  TablePagination,
  FilterContainer,
} from '~/components/Table/styles';
import { Container } from './styles';
import api from '~/services/api';

import Button from '~/styles/components/Button';

interface IPercentCategory {
  id: number;
  category_id: number | null;
  path: string;
  category?: {
    id: number;
    name: string;
  };
}

interface IStatePage {
  data: IPercentCategory[];
  total: number;
  perPage: number;
  page: number;
  lastPage: number;
  loading: boolean;
  orderBy: string;
  order: 'ASC' | 'DESC';
}

const List: React.FC = () => {
  const [categories, setCategories] = useState<IStatePage>({
    data: [],
    total: 0,
    perPage: 25,
    page: 1,
    lastPage: 1,
    loading: false,
    orderBy: 'path',
    order: 'ASC',
  });

  const history = useHistory();

  async function applyFilters(data: IFilter[]) {
    const dataFilter: any = {}; // eslint-disable-line
    data.forEach(i => {
      dataFilter[i.name] = i.value;
    });
    setCategories({
      ...categories,
      ...dataFilter,
    });
  }

  useEffect(() => {
    async function loadData() {
      setCategories({ ...categories, loading: true });
      try {
        const { data } = await api.get(
          `percent-categories?perpage=${categories.perPage}&page=${
            categories.page
          }&ordername=${
            categories.orderBy
          }&orderdirection=${categories.order.toLowerCase()}`,
        );

        setCategories({
          data: data.data,
          total: data.total,
          perPage: data.perPage,
          page: data.page,
          lastPage: data.lastPage,
          orderBy: categories.orderBy,
          order: categories.order,
          loading: false,
        });
      } catch (err) {
        setCategories({ ...categories, loading: false });
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadData();
  }, [
    categories.perPage,
    categories.page,
    categories.orderBy,
    categories.order,
  ]); // eslint-disable-line

  function onNextPage() {
    setCategories({ ...categories, page: categories.page + 1 });
  }
  function onPreviousPage() {
    setCategories({ ...categories, page: categories.page - 1 });
  }

  const [openFilter, setOpenFilter] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState<IFilter[]>([
    {
      name: 'perPage',
      title: 'Results. p/página:',
      type: 'text',
      value: String(categories.perPage),
    },
    {
      name: 'orderBy',
      title: 'Ordenar por:',
      type: 'text',
      value: categories.orderBy,
    },
    {
      name: 'order',
      title: 'Ordem:',
      type: 'text',
      value: categories.order,
    },
  ]);

  function handleCancelFilters() {
    setOpenFilter(false);
    setDefaultFilters([
      {
        name: 'perPage',
        title: 'Results. p/página:',
        type: 'text',
        value: String(categories.perPage),
      },
      {
        name: 'orderBy',
        title: 'Ordenar por:',
        type: 'text',
        value: categories.orderBy,
      },
      {
        name: 'order',
        title: 'Ordem:',
        type: 'text',
        value: categories.order,
      },
    ]);
  }

  function handleChangeDefaultFilterValue(name: string, newValue: string) {
    setDefaultFilters(
      defaultFilters.map(f => {
        if (f.name === name) {
          return {
            ...f,
            value: f.normalize ? f.normalize(newValue) : newValue,
          };
        }
        return f;
      }),
    );
  }

  return (
    <Container>
      <ContainerTable fullWidth>
        {openFilter && (
          <FilterContainer>
            <h4>Filtros</h4>
            {defaultFilters.map((filter, index) => (
              <label
                className="input"
                htmlFor={filter.name}
                key={String(index)}
              >
                <p>{filter.title}</p>
                <select
                  id={filter.name}
                  name={filter.name}
                  value={filter.value}
                  onChange={e => {
                    handleChangeDefaultFilterValue(filter.name, e.target.value);
                  }}
                >
                  {filter.name === 'perPage' && (
                    <>
                      {[25, 50, 100].map(option => (
                        <option key={option} value={String(option)}>
                          {option}
                        </option>
                      ))}
                    </>
                  )}
                  {filter.name === 'orderBy' &&
                    [
                      {
                        name: 'path',
                        title: 'Caminho',
                      },
                    ].map(label => (
                      <option key={label.name} value={label.name}>
                        {label.title}
                      </option>
                    ))}
                  {filter.name === 'order' && (
                    <>
                      <option value="ASC">Crescente</option>
                      <option value="DESC">Decrescente</option>
                    </>
                  )}
                </select>
              </label>
            ))}
            <div id="action_filter">
              <Button
                type="button"
                onClick={() => {
                  applyFilters([...defaultFilters]);
                  setOpenFilter(false);
                }}
                disabled={categories.loading}
              >
                Aplicar
              </Button>
              <Button
                type="button"
                color="gray"
                onClick={handleCancelFilters}
                disabled={categories.loading}
              >
                Cancelar
              </Button>
            </div>
          </FilterContainer>
        )}
        <TableFilters loading={categories.loading}>
          <h3>Relacionamento de Categorias</h3>
          <button
            type="button"
            className={openFilter ? 'filled' : ''}
            onClick={() => setOpenFilter(!openFilter)}
            disabled={categories.loading}
          >
            <FaFilter />
          </button>
        </TableFilters>
        <Table>
          <thead>
            <tr>
              {[
                {
                  name: 'path',
                  title: 'Caminho',
                },
                {
                  name: 'category',
                  title: 'Categoria',
                },
              ].map(label => (
                <th key={label.name}>{label.title}</th>
              ))}
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {categories.data
              .map(d => {
                return {
                  ...d,
                  category: d.category?.name,
                };
              })
              .map(
                (
                  item: any, // eslint-disable-line
                  index,
                ) => {
                  return (
                    <Fragment key={String(index)}>
                      <tr style={{ background: 'rgba(0,0,0,.1)' }}>
                        {[
                          {
                            name: 'path',
                            title: 'Caminho',
                          },
                          {
                            name: 'category',
                            title: 'Categoria',
                          },
                        ].map(field => (
                          <td key={field.name}>{item[field.name]}</td>
                        ))}
                        <td>
                          <button
                            type="button"
                            onClick={() =>
                              history.push(
                                `/percent-categories/edit/${item.id}`,
                              )
                            }
                            style={{
                              border: '1px solid #444',
                              borderRadius: '4px',
                              marginLeft: '8px',
                              padding: '5px 15px',
                            }}
                          >
                            Editar
                            <IoIosArrowForward />
                          </button>
                        </td>
                      </tr>
                    </Fragment>
                  );
                },
              )}
          </tbody>
        </Table>
        <TablePagination>
          <div>
            <strong>{categories.total} Resultado(s)</strong>
          </div>
          <div id="pages">
            <button
              type="button"
              onClick={onPreviousPage}
              disabled={categories.page === 1 || categories.loading}
            >
              <IoIosArrowBack />
            </button>
            <span>{categories.page}</span>
            <button
              type="button"
              onClick={onNextPage}
              disabled={
                categories.loading || categories.page === categories.lastPage
              }
            >
              <IoIosArrowForward />
            </button>
          </div>
        </TablePagination>
      </ContainerTable>
    </Container>
  );
};

export default List;
