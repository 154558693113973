import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import Button from '~/styles/components/Button';
import { Container } from '../styles';
import api from '~/services/api';
import useQuery from '../../../hooks/query';

const Reset: React.FC = () => {
  const history = useHistory();
  const query = useQuery();

  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
  });

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { password, confirmPassword } = values;

    const token = query.get('token');

    if (!password || !confirmPassword) {
      toastr.error('Erro', 'Preencha todos os campos');
      return;
    }

    if (password.length < 6) {
      toastr.error('Erro', 'A senha deve ter no mínimo 6 caracteres');
      return;
    }

    if (password !== confirmPassword) {
      toastr.error('Erro', 'As senhas não são iguais');
      return;
    }

    try {
      api.defaults.headers.apptoken = token;

      await api.put('/users/resetPassword', {
        password,
      });

      toastr.success('Sucesso', 'Sua senha foi alterada com sucesso');

      history.push('/signin');
    } catch (err) {
      toastr.error(
        'Erro',
        (err as any)?.data
          ? (err as any)?.data?.message
          : 'Não foi possível redefinir a senha, entre em contato com o suporte',
      );
    }
  }

  return (
    <Container>
      <div className="content-form-block">
        <div className="row">
          <div className="col-md-4">
            <div className="logo-block">
              <span>Resetar senha</span>
            </div>
          </div>
          <div className="col-md-8">
            <form onSubmit={handleSubmit}>
              <div className="d-flex align-items-center">
                <img
                  src="https://lojasimperio.com.br/images/logo.png"
                  alt="Império"
                  className="mr-2"
                />
                <span>- Administrativo</span>
              </div>
              <span>Nova senha</span>
              <input
                type="password"
                name="password"
                value={values.password}
                onChange={e =>
                  setValues({ ...values, password: e.target.value })
                }
              />

              <span>Confirmar senha</span>
              <input
                type="password"
                name="password"
                value={values.confirmPassword}
                onChange={e =>
                  setValues({ ...values, confirmPassword: e.target.value })
                }
              />
              <div className="mt-4">
                <Button size="big" type="submit">
                  Resetar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Reset;
