import React, { useState } from 'react';
import { FaCamera } from 'react-icons/fa';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import { Container, Preview } from './styles';

const EcommerceMain: React.FC = () => {
  const [previews, setPreviews] = useState({} as any);

  return (
    <Container>
      <h5>Personalizar página principal</h5>
      <div className="row">
        <div className="col-md-8">
          <div className="bg-white shadow-sm p-3 radius-4">
            <h6>Carrossel Principal</h6>
            <div className="d-flex" style={{ overflowX: 'auto' }}>
              {[1, 2, 3, 4].map(i => (
                <div className="mr-3" key={i}>
                  <Preview
                    size="carroussel"
                    image={
                      previews[`file_${i}`] && previews[`file_${i}`].preview
                        ? previews[`file_${i}`].preview
                        : ''
                    }
                    htmlFor={`file-carroussel-${i}`}
                  >
                    <div className="bg-white pt-1 pb-1 pl-2 pr-2 radius-4">
                      <FaCamera /> <span>1150x450px</span>
                    </div>
                  </Preview>
                  <div className="d-flex justify-content-between">
                    <IoIosArrowBack className="back-arrow" />
                    <span>Posição {i}</span>
                    <IoIosArrowForward className="forward-arrow" />
                  </div>
                  <div className="mt-2 mb-2">
                    <span className="pl-0 pr-0">Link</span>
                    <input type="text" className="w-100" />
                  </div>
                  <div>
                    <span className="pl-0 pr-0">Texto alternativo</span>
                    <input type="text" className="w-100" />
                  </div>
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    id={`file-carroussel-${i}`}
                    onChange={e => {
                      if (e.target.files && e.target.files[0]) {
                        setPreviews({
                          ...previews,
                          [`file_${i}`]: {
                            file: e.target.files[0],
                            preview: URL.createObjectURL(e.target.files[0]),
                          },
                        });
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white shadow-sm p-3 mt-3 radius-4">
            <h6>Banner Abaixo</h6>
            <div>
              <Preview size="banner-bottom">
                <div className="bg-white pt-1 pb-1 pl-2 pr-2 radius-4">
                  <FaCamera /> <span>1100x100px</span>
                </div>
              </Preview>
              <div className="mt-2 mb-2">
                <span className="pl-0 pr-0">Link</span>
                <input type="text" className="w-100" />
              </div>
              <div>
                <span className="pl-0 pr-0">Texto alternativo</span>
                <input type="text" className="w-100" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="bg-white shadow-sm p-3 radius-4">
            <h6>Banners Laterais</h6>
            <div>
              <div>
                <Preview size="banner-right">
                  <div className="bg-white pt-1 pb-1 pl-2 pr-2 radius-4">
                    <FaCamera /> <span>410x215px</span>
                  </div>
                </Preview>
                <div className="mt-2 mb-2">
                  <span className="pl-0 pr-0">Link</span>
                  <input type="text" className="w-100" />
                </div>
                <div>
                  <span className="pl-0 pr-0">Texto alternativo</span>
                  <input type="text" className="w-100" />
                </div>
              </div>
              <hr />
              <div>
                <Preview size="banner-right">
                  <div className="bg-white pt-1 pb-1 pl-2 pr-2 radius-4">
                    <FaCamera /> <span>410x215px</span>
                  </div>
                </Preview>
                <div className="mt-2 mb-2">
                  <span className="pl-0 pr-0">Link</span>
                  <input type="text" className="w-100" />
                </div>
                <div>
                  <span className="pl-0 pr-0">Texto alternativo</span>
                  <input type="text" className="w-100" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default EcommerceMain;
