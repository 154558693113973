import React, { useRef, useState } from 'react';

import JoditEditor from 'jodit-react';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { toastr } from 'react-redux-toastr';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container, Label } from './styles';

const Create: React.FC = () => {
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');
  const [device, setDevice] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  async function handleAdd() {
    setLoading(true);
    try {
      const response = await api.post(`/pages`, {
        title,
        code,
        status: status === 'true',
        content,
        device,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        toastr.success('Página criada', '');
        history.push('/pages/list');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/pages/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Criar Página</h3>
        </div>
        <div className="body_block">
          <div className="row mb-2">
            <div className="col-md-2">
              <Input
                label="Status"
                name="status"
                required
                onChange={e => setStatus(String(e.value))}
                select
                options={[
                  {
                    title: 'Ativo',
                    value: 'true',
                  },
                  {
                    title: 'Inativo',
                    value: 'false',
                  },
                ]}
                disabled={loading}
              />
            </div>
            <div className="col-md-2">
              <Input
                label="Dispositivo"
                name="device"
                onChange={e => setDevice(String(e.value))}
                required
                select
                options={[
                  {
                    title: 'APP',
                    value: 'app',
                  },
                  {
                    title: 'Site',
                    value: 'site',
                  },
                ]}
                disabled={loading}
              />
            </div>
            <div className="col-md-2">
              <Input
                label="Código"
                name="code"
                onChange={e => setCode(String(e.value).toUpperCase())}
                required
                disabled={loading}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-12">
              <Input
                label="Título"
                name="title"
                required
                onChange={e => setTitle(String(e.value))}
                disabled={loading}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-12">
              <Label>Conteúdo</Label>
              <JoditEditor
                ref={editor}
                value={content}
                onChange={newContent => setContent(newContent)}
              />
            </div>
          </div>
        </div>
        <div className="footer_block d-flex justify-content-end">
          <Button type="button" onClick={handleAdd}>
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Create;
