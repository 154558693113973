/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useRef, useState, useEffect } from 'react';

import JoditEditor from 'jodit-react';
import { useHistory } from 'react-router-dom';
import {
  AiFillPicture,
  AiFillCloseCircle,
  AiFillVideoCamera,
} from 'react-icons/ai';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { toastr } from 'react-redux-toastr';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { normalizeCurrency, normalizePercentage } from '~/utils/normalize';

import { Container, Label, PhotoList } from './styles';
import FlixPlayer from '~/components/IFramePlayer';
import Modal from '~/components/Modal';

interface IProduct {
  brand_id: string;
  sku: string;
  name: string;
  max_installment: string;
  price: number;
  special_price: number;
  price_discount: number;
  price_discount_percentage: number;
  description: string;
  short_description: string;
  reference: string;
  featured: boolean;
  status: boolean;
  loading: boolean;
  max_per_cart: number;
}

interface IBrand {
  id: number;
  name: string;
}

interface ICategory {
  id: number;
  category_id: number;
  name: string;
}

interface IPhoto {
  id: number;
  order: number;
  path: string;
  product_id: number;
  type: 'image' | 'video';
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const editor = useRef(null);
  const [product, setProduct] = useState<IProduct>({
    brand_id: '',
    sku: '',
    name: '',
    max_installment: '',
    price: 0,
    price_discount: 0,
    price_discount_percentage: 0,
    special_price: 0,
    description: '',
    short_description: '',
    reference: '',
    max_per_cart: 3,
    featured: false,
    status: false,
    loading: false,
  });
  const [brands, setBrands] = useState<IBrand[]>();
  const [categories, setCategories] = useState<ICategory[]>();

  const [photosP, setPhotosP] = useState<IPhoto[]>([]);
  const [removedPhotos, setRemovedPhotos] = useState<number[]>([]);

  const [brand_id, setBrand_id] = useState('');
  const [sku, setSku] = useState('');
  const [name, setName] = useState('');
  const [reference, setReference] = useState('');
  const [max_installment, setMax_installment] = useState('');
  const [price, setPrice] = useState('');
  const [special_price, setSpecial_price] = useState('');
  const [description, setDescription] = useState('');
  const [short_description, setShort_description] = useState('');
  const [featured, setFeatured] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [priceDiscount, setPriceDiscount] = useState('');
  const [priceInFull, setPriceInFull] = useState('');
  const [priceEnd, setPriceEnd] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [thumbnailUrl, setVideoThumbnailUrl] = useState('');
  const [addVideoModal, setAddVideoModal] = useState(false);
  const [maxPerCart, setMaxPerCart] = useState('');

  const [priceDiscountPercentage, setPriceDiscountPercentage] = useState('');

  const [percentData, setPercentData] = useState<{
    preco: number;
    preco_avista: number;
    preco_promocional: number;
  } | null>(null);
  const [loadingPercentData, setloadingPercentData] = useState(true);

  const [bigPriceChangeModal, setBigPriceChangeModal] = useState<{
    messages: string[];
    active: boolean;
  }>({
    messages: [],
    active: false,
  });

  const history = useHistory();

  useEffect(() => {
    const specialPrice = Number(special_price.replace(/[^\d]/g, '')) / 100;

    if (specialPrice) {
      const percentage =
        Number(priceDiscountPercentage.replace(/[^\d]/g, '')) / 100;

      const discount = (specialPrice * percentage) / 100;

      const discountPrice = Number(specialPrice - discount).toFixed(2);

      setPriceEnd(normalizeCurrency(String(specialPrice * 100)));

      if (Number(discountPrice) > 0) {
        setPriceDiscount(normalizeCurrency(discountPrice));
      } else {
        setPriceDiscount(normalizeCurrency('000'));
      }
    } else {
      const percentage =
        Number(priceDiscountPercentage.replace(/[^\d]/g, '')) / 100;

      const price_end = Number(price.replace(/[^\d]/g, '')) / 100;

      const discount = (price_end * percentage) / 100;

      const discountPrice = Number(price_end - discount).toFixed(2);

      setPriceEnd(normalizeCurrency(String(price)));

      if (Number(discountPrice) > 0) {
        setPriceDiscount(normalizeCurrency(discountPrice));
      } else {
        setPriceDiscount(normalizeCurrency('000'));
      }
    }
  }, [special_price, priceDiscountPercentage, priceEnd, price]);

  useEffect(() => {
    if (!sku) return;
    async function loadPercentData() {
      try {
        const { data: responsePercentData } = await api.get(
          `/percent/product-on-file?sku=${sku}`,
        );

        setPercentData(responsePercentData.item);
      } catch (err) {
        //
      }
      setloadingPercentData(false);
    }
    loadPercentData();
  }, [sku]);

  useEffect(() => {
    async function loadPage() {
      try {
        const { data: dataBrand } = await api.get('/brands?perpage=1000');
        setBrands(dataBrand.data);

        const { data: dataCategory } = await api.get(
          '/categories?all=1&perpage=1000',
        );
        setCategories(dataCategory.data);

        const { data } = await api.get(`/products/updated/${match.params.id}`);
        setProduct({
          ...product,
          brand_id: data.brand_id,
          sku: data.sku,
          name: data.name,
          max_installment: data.max_installment,
          price: data.price * 100,
          special_price: data.special_price ? data.special_price * 100 : 0,
          description: data.description,
          short_description: data.short_description,
          featured: data.featured,
          status: data.status,
          reference: data.reference,
          max_per_cart: data.max_per_cart,
        });

        setPhotosP(data.photos);

        const hasVideo =
          data.photos.filter((p: IPhoto) => p.type === 'video').length > 0;

        if (hasVideo) {
          setVideoUrl(
            data.photos.filter((p: IPhoto) => p.type === 'video')[0].path_large,
          );
          setVideoThumbnailUrl(
            data.photos.filter((p: IPhoto) => p.type === 'video')[0].path,
          );
        }

        setBrand_id(data.brand_id);
        setSku(data.sku);
        setName(data.name);
        setReference(data.reference);
        setMax_installment(data.max_installment);
        setPrice(String(data.price));
        setPriceEnd(String(data.price_end ? data.price_end : 0));
        setSpecial_price(String(data.special_price ? data.special_price : 0));
        setPriceDiscount(String(data.price_discount ? data.price_discount : 0));
        setPriceInFull(String(data.price_in_full ? data.price_in_full : 0));
        setPriceDiscountPercentage(
          String(
            data.price_discount_percentage
              ? data.price_discount_percentage * 100
              : 0,
          ),
        );
        setDescription(data.description);
        setShort_description(data.short_description);
        setFeatured(data.featured);
        setStatus(data.status);
        setMaxPerCart(data.max_per_cart);
        setLoading(false);
      } catch (err: any) {
        if (err.data && err.data.message) {
          toastr.error(err.data.message, '');
        } else {
          toastr.error(
            'Ocorreu um erro',
            'Durante a busca nossos servidores não puderam responder',
          );
        }
      }
    }
    loadPage();
  }, [match.params.id]); // eslint-disable-line

  function handleRemoveImage(item: IPhoto) {
    const index = photosP.findIndex(p => p.id === item.id);
    if (index > -1) {
      setRemovedPhotos([...removedPhotos, item.id]);
      setPhotosP(
        photosP
          .map((p, i) => {
            if (i < index) {
              return {
                ...p,
                order: p.order - 1,
              };
            }
            if (i !== index) {
              return {
                ...p,
              };
            }
            return p;
          })
          .filter(p => p.id !== item.id),
      );
    }
  }

  async function handleAddImage(item: File) {
    setLoading(true);
    try {
      const obj = new FormData();
      obj.append('file', item);
      const { data } = await api.post(
        `/products/photo?order=${String(photosP.length + 1)}&product_id=${
          match.params.id
        }`,
        obj,
      );
      setPhotosP([...photosP, data]);
    } catch (err) {
      toastr.error(`Falha no upload da imagem ${photosP.length + 1}`, '');
    }
    setLoading(false);
  }

  async function handleAddVideo() {
    setLoading(true);
    try {
      const { data } = await api.post(
        `/products/photo?product_id=${match.params.id}`,
        {
          video_url: videoUrl,
          thumbnail_url: thumbnailUrl,
        },
      );
      setPhotosP([...photosP, data]);
      setVideoThumbnailUrl('');
      setVideoUrl('');
      setAddVideoModal(false);
    } catch (err) {
      toastr.error(`Falha no upload da imagem ${photosP.length + 1}`, '');
    }
    setLoading(false);
  }

  function formatPriceFromStringToNumber(value: string) {
    return Number(value.replace(/[^\d]/g, ''));
  }

  async function handleUpdateProduct() {
    setLoading(true);

    try {
      const response = await api.put(`/products/${match.params.id}`, {
        brand_id,
        sku,
        name,
        max_installment,
        price: formatPriceFromStringToNumber(price),
        special_price: formatPriceFromStringToNumber(special_price),
        description,
        short_description,
        price_in_full: formatPriceFromStringToNumber(priceInFull),
        price_discount: formatPriceFromStringToNumber(priceDiscount),
        price_discount_percentage: Number(
          (
            formatPriceFromStringToNumber(priceDiscountPercentage) / 100
          ).toFixed(2),
        ),
        featured: featured === 'true' || Number(featured) === 1 ? 1 : 0,
        status: status === 'true' || Number(status) === 1 ? 1 : 0,
        reference,
        video_url: videoUrl,
        thumbnail_url: thumbnailUrl,
        max_per_cart: formatPriceFromStringToNumber(String(maxPerCart)) || 3,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        for (let i = 0; i < photosP.length; i += 1) {
          try {
            await api.put(`/products/photo/${photosP[i].id}`, {
              order: photosP[i].order,
            });
          } catch (err) {
            toastr.error(`Falha ao atualizar imagem ${i + 1}`, '');
          }
        }
        for (let i = 0; i < removedPhotos.length; i += 1) {
          try {
            await api.delete(`/products/photo/${removedPhotos[i]}`);
          } catch (err) {
            toastr.error(
              `Falha ao deletar imagem ${i + 1 + photosP.length}`,
              '',
            );
          }
        }
        history.push('/products/list');
        toastr.success('Produto atualizado', '');
      }
    } catch (err: any) {
      if (err.data && err.data.message) {
        toastr.error(err.data.message, '');
      } else {
        toastr.error('Ocorreu um erro', '');
      }
    }
    setLoading(false);
    setVideoThumbnailUrl('');
    setVideoUrl('');
  }

  function validateProductUpdate() {
    function percentageDiffernce(oldNumber: number, newNumber: number) {
      return (oldNumber - newNumber) / oldNumber;
    }

    if (!percentData) {
      handleUpdateProduct();
      return;
    }

    const percentPrice = percentData.preco * 100;
    const percentSpecialPrice = percentData.preco_promocional * 100;
    const percentPriceInFull = percentData.preco_avista * 100;

    const messages: string[] = [];

    if (
      percentageDiffernce(percentPrice, formatPriceFromStringToNumber(price)) >
      0.1
    ) {
      messages.push(
        `O preço do produto não pode ter uma variação maior que 10% mais barato. Referência: R$ ${normalizeCurrency(
          String(percentPrice),
        )}, preço colocado: R$ ${price}).`,
      );
    }

    if (
      percentageDiffernce(
        percentSpecialPrice,
        formatPriceFromStringToNumber(special_price),
      ) > 0.1
    ) {
      messages.push(
        `O preço especial do produto não pode ter uma variação maior que 10% mais barato. Referência: R$ ${normalizeCurrency(
          String(percentSpecialPrice),
        )}, preço colocado: R$ ${special_price}).`,
      );
    }

    if (
      percentageDiffernce(
        percentPriceInFull,
        formatPriceFromStringToNumber(priceInFull),
      ) > 0.1
    ) {
      messages.push(
        `O preço à vista do produto não pode ter uma variação maior que 10% mais barato. Referência: R$ ${normalizeCurrency(
          String(percentPriceInFull),
        )}, preço colocado: R$ ${priceInFull})`,
      );
    }

    if (messages.length > 0) {
      setBigPriceChangeModal({
        active: true,
        messages,
      });
    } else {
      handleUpdateProduct();
    }
  }

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/products/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/products/list');
        toastr.success('Produto excluído', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      {addVideoModal && (
        <Modal>
          <h1>Adicionar Vídeo</h1>
          <div>
            <div>
              <Input
                label="URL do vídeo"
                name="url_video"
                initialValue={videoUrl || ''}
                onChange={e => setVideoUrl(String(e.value))}
                disabled={loading}
              />
            </div>
            <div>
              <Input
                label="Thumbnail do vídeo"
                name="thumbnail_video"
                initialValue={thumbnailUrl || ''}
                onChange={e => setVideoThumbnailUrl(String(e.value))}
                disabled={loading}
              />
            </div>
            <br />

            <div>
              {videoUrl && (
                <div>
                  <p>Prévia do vídeo:</p>
                  <FlixPlayer url={videoUrl} />
                </div>
              )}
              {thumbnailUrl && (
                <div>
                  <p>Prévia da Thumbnail:</p>
                  <img
                    src={thumbnailUrl}
                    alt="Thumbnail"
                    style={{ height: 150 }}
                  />
                </div>
              )}
            </div>
          </div>
          <Button
            className="mt-3"
            type="button"
            disabled={loading}
            onClick={handleAddVideo}
          >
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
          <Button
            color="gray"
            className="ml-2"
            onClick={() => setAddVideoModal(false)}
          >
            Cancelar
          </Button>
        </Modal>
      )}

      {bigPriceChangeModal.active && (
        <Modal>
          <h5>Alerta de mudança de preço</h5>
          {
            // eslint-disable-next-line
            bigPriceChangeModal.messages.map((message: string, i: number) => (
              <p key={Math.random()}>{message}</p>
            ))
          }

          <div className="footer_block d-flex justify-content-end">
            <Button
              type="button"
              disabled={loading}
              onClick={() => {
                setBigPriceChangeModal({ active: false, messages: [] });
                handleUpdateProduct();
              }}
            >
              Salvar mesmo assim
            </Button>
            <Button
              type="button"
              color="gray"
              className="ml-2"
              disabled={loading}
              onClick={() =>
                setBigPriceChangeModal({ active: false, messages: [] })
              }
            >
              Cancelar
            </Button>
          </div>
        </Modal>
      )}
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/products/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Editar Produto</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row mb-2">
                <div className="col-md-2">
                  <Input
                    label="Destaque"
                    name="featured"
                    required
                    initialValue={product.featured ? 'true' : 'false'}
                    onChange={e => setFeatured(String(e.value))}
                    select
                    options={[
                      {
                        title: 'Sim',
                        value: 'true',
                      },
                      {
                        title: 'Não',
                        value: 'false',
                      },
                    ]}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Status"
                    name="status"
                    required
                    initialValue={product.status ? 'true' : 'false'}
                    onChange={e => setStatus(String(e.value))}
                    select
                    options={[
                      {
                        title: 'Ativo',
                        value: 'true',
                      },
                      {
                        title: 'Inativo',
                        value: 'false',
                      },
                    ]}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Nome do produto"
                    name="name"
                    required
                    initialValue={name}
                    onChange={e => setName(String(e.value))}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Sku"
                    name="sku"
                    required
                    initialValue={sku}
                    onChange={e => setSku(String(e.value))}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-4">
                  <Input
                    label="Marca"
                    name="brand_id"
                    required
                    initialValue={brand_id}
                    onChange={e => setBrand_id(String(e.value))}
                    select
                    options={
                      brands &&
                      brands.map(p => {
                        return {
                          title: p.name,
                          value: String(p.id),
                        };
                      })
                    }
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Referência"
                    name="reference"
                    required
                    initialValue={reference}
                    onChange={e => setReference(String(e.value))}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Quantidade máxima por carrinho"
                    name="max_per_cart"
                    required
                    initialValue={maxPerCart}
                    onChange={e => setMaxPerCart(String(e.value))}
                    disabled={loading}
                  />
                </div>
                {/* <div className="col-md-5"> */}
                {/* <Input
                    label="Categoria"
                    name="category_id"
                    required
                    initialValue={brand_id}
                    onChange={e => setBrand_id(String(e.value))}
                    select
                    options={
                      categories &&
                      categories.map(p => {
                        return {
                          title: p.name,
                          value: String(p.id),
                        };
                      })
                    }
                    disabled={loading}
                  /> */}
                {/* </div> */}
              </div>
              <div className="row mb-2">
                <div className="col-md-3">
                  <Input
                    label="Preço"
                    name="price"
                    required
                    normalize={value => normalizeCurrency(value)}
                    initialValue={normalizeCurrency(String(price))}
                    onChange={e => setPrice(String(e.value))}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="Preço especial"
                    name="special_price"
                    required
                    normalize={value => normalizeCurrency(value)}
                    initialValue={normalizeCurrency(String(special_price))}
                    onChange={e => setSpecial_price(String(e.value))}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="Preço final"
                    name="price_end"
                    onChange={() => {
                      // eslint-disable-next-line
                      console.log('');
                    }}
                    normalize={value => normalizeCurrency(value)}
                    initialChanges
                    initialValue={normalizeCurrency(String(priceEnd))}
                    disabled
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="Preço desconto (PIX e Boleto)"
                    name="price_discount"
                    onChange={() => {
                      // eslint-disable-next-line
                      console.log('');
                    }}
                    normalize={value => normalizeCurrency(value)}
                    initialChanges
                    initialValue={normalizeCurrency(String(priceDiscount))}
                    disabled
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="Preço à vista (PIX, Boleto, 1x)"
                    name="price_in_full"
                    onChange={e => {
                      setPriceInFull(String(e.value));
                    }}
                    normalize={value => normalizeCurrency(value)}
                    initialChanges
                    initialValue={normalizeCurrency(String(priceInFull))}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="Percentual de desconto"
                    name="price_discount_percentage"
                    required
                    normalize={value => normalizePercentage(value)}
                    initialValue={normalizePercentage(
                      String(Number(priceDiscountPercentage)),
                    )}
                    initialChanges
                    onChange={e => setPriceDiscountPercentage(String(e.value))}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="Parcelamento máximo"
                    name="max_installment"
                    required
                    initialValue={max_installment}
                    onChange={e => setMax_installment(String(e.value))}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-9">
                  <Input
                    label="Descrição curta"
                    name="short_description"
                    required
                    initialValue={short_description}
                    onChange={e => setShort_description(String(e.value))}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-9">
                  <Label>Descrição</Label>
                  <JoditEditor
                    ref={editor}
                    value={description}
                    onChange={newDescription => setDescription(newDescription)}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-12">
                  <h3>Imagens do produto</h3>
                </div>
                <div className="col-md-12">
                  <PhotoList>
                    {photosP
                      // .filter(a => a.type === 'image')
                      .sort((a, b) => a.order - b.order)
                      .map((p, index) => (
                        <div key={String(index)}>
                          <div
                            className="img"
                            style={{
                              background: `url(${p.path}) 50% 50% no-repeat`,
                            }}
                          >
                            <button
                              type="button"
                              onClick={() => handleRemoveImage(p)}
                            >
                              <AiFillCloseCircle />
                            </button>
                          </div>
                          <div className="d-flex justify-content-around mt-3">
                            <button
                              type="button"
                              onClick={() => {
                                setPhotosP(
                                  photosP.map(i => {
                                    if (i.id === p.id) {
                                      return {
                                        ...i,
                                        order: i.order - 1,
                                      };
                                    }
                                    if (i.order === p.order - 1) {
                                      return {
                                        ...i,
                                        order: p.order,
                                      };
                                    }
                                    return i;
                                  }),
                                );
                              }}
                              disabled={p.order === 1}
                            >
                              <IoIosArrowBack />
                            </button>
                            <button
                              onClick={() => {
                                setPhotosP(
                                  photosP.map(i => {
                                    if (i.id === p.id) {
                                      return {
                                        ...i,
                                        order: i.order + 1,
                                      };
                                    }
                                    if (i.order === p.order + 1) {
                                      return {
                                        ...i,
                                        order: p.order,
                                      };
                                    }
                                    return i;
                                  }),
                                );
                              }}
                              type="button"
                              disabled={p.order === photosP.length}
                            >
                              <IoIosArrowForward />
                            </button>
                          </div>
                        </div>
                      ))}
                    <label id="add" htmlFor="file_p">
                      <AiFillPicture />
                      Adicionar Imagem
                      <input
                        type="file"
                        id="file_p"
                        accept="image/*"
                        onChange={e => {
                          if (e.target && e.target.files && e.target.files[0]) {
                            handleAddImage(e.target.files[0]);
                          }
                        }}
                      />
                    </label>
                    <div
                      id="add"
                      className="add"
                      onClick={() => {
                        setAddVideoModal(true);
                      }}
                      role="button"
                    >
                      <AiFillVideoCamera />
                      Adicionar Vídeo
                    </div>
                  </PhotoList>
                </div>
              </div>
            </div>
            {!confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <Button
                  type="button"
                  disabled={loading || loadingPercentData}
                  onClick={validateProductUpdate}
                >
                  {loadingPercentData ? (
                    'Carregando...'
                  ) : (
                    <>{loading ? 'Salvando...' : 'Salvar'}</>
                  )}
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="ml-2"
                  disabled={loading}
                  onClick={() => setConfirmDelete(true)}
                >
                  {loading ? '...' : 'Excluir Produto'}
                </Button>
              </div>
            )}
            {confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <div>
                  <h3 style={{ textAlign: 'right' }}>
                    Você tem certeza que deseja excluir este produto?
                  </h3>
                  <div>
                    <Button
                      type="button"
                      color="danger"
                      disabled={loading}
                      onClick={handleDelete}
                    >
                      {loading ? 'Excluindo...' : 'Excluir'}
                    </Button>
                    <Button
                      type="button"
                      color="gray"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmDelete(false)}
                    >
                      {loading ? '...' : 'Cancelar'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
