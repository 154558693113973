import styled from 'styled-components';

export const Container = styled.label`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  color: #2d3047;
  font-weight: bold;
  font-size: 12px;
  margin-left: 4px;
`;

export const Input = styled.input`
  background: #eee;
  color: #2d3047;
`;
