import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import Modal from '~/components/Modal';
import { TableComponent } from '~/components/TableV2';
import api from '~/services/api';
import Button from '~/styles/components/Button';

const ZipcodeTable: React.FC = () => {
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [formData, setFormData] = useState({
    title: '',
    shipping_days: '',
    start_zipcode: '',
    end_zipcode: '',
    is_default_zipcode: false,
  });
  const [showModal, setShowModal] = useState(false);

  const [hardRefresh, setHardRefresh] = useState(false);

  useEffect(() => {
    if (hardRefresh) {
      setHardRefresh(false);
    }
  }, [hardRefresh]);

  const clearForm = () => {
    setFormData({
      title: '',
      shipping_days: '',
      start_zipcode: '',
      end_zipcode: '',
      is_default_zipcode: false,
    });
    setSelectedId(null);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedId) {
      // Update existing record
      await api.put(`zipcode-fast-shipping/${selectedId}`, formData);
    } else {
      // Add new record
      await api.post('zipcode-fast-shipping', formData);
    }
    clearForm();
    setShowModal(false);
    setHardRefresh(true);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleDelete = async (id: number) => {
    await api.delete(`zipcode-fast-shipping/${id}`);
    setHardRefresh(true);
  };

  const formatZipcode = (zipcode: string) => {
    if (zipcode.length < 8) {
      return zipcode;
    }
    return `${zipcode.slice(0, 5)}-${zipcode.slice(5)}`;
  };

  return (
    <div className="container">
      {showModal && (
        <Modal>
          <div className="container">
            <div>
              <form onSubmit={handleSubmit} className="mb-4">
                <input
                  placeholder="Título"
                  className="form-control mb-2"
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleInputChange}
                  disabled={formData.is_default_zipcode}
                />
                <input
                  placeholder="Dias para Entrega"
                  className="form-control mb-2"
                  type="number"
                  name="shipping_days"
                  value={formData.shipping_days}
                  onChange={handleInputChange}
                />
                <input
                  placeholder="CEP Inicial"
                  className="form-control mb-2"
                  type="text"
                  name="start_zipcode"
                  value={formData.start_zipcode}
                  onChange={handleInputChange}
                  disabled={formData.is_default_zipcode}
                />
                <input
                  placeholder="CEP Final"
                  className="form-control mb-2"
                  type="text"
                  name="end_zipcode"
                  value={formData.end_zipcode}
                  onChange={handleInputChange}
                  disabled={formData.is_default_zipcode}
                />
                <Button type="submit">
                  {selectedId ? 'Atualizar' : 'Adicionar'}
                </Button>
                <Button
                  color="gray"
                  onClick={() => {
                    setShowModal(false);
                    clearForm();
                  }}
                >
                  Cancelar
                </Button>
              </form>
            </div>
          </div>
        </Modal>
      )}
      <Button
        onClick={() => {
          setShowModal(true);
          clearForm();
        }}
        className="mb-2"
      >
        Adicionar novo
      </Button>
      <TableComponent
        filters={[]}
        labels={[
          {
            key: 'title',
            title: 'Título',
            ordenable: false,
          },
          {
            key: 'shipping_days',
            title: 'Dias para Entrega',
            ordenable: false,
          },
          {
            key: 'start_zipcode',
            title: 'CEP Inicial',
            formater: zipcode => {
              return formatZipcode(zipcode.toString());
            },
            ordenable: false,
          },
          {
            key: 'end_zipcode',
            title: 'CEP Final',
            formater: zipcode => {
              return formatZipcode(zipcode.toString());
            },
            ordenable: false,
          },
        ]}
        getData={async params => {
          const res = await api.get('/zipcode-fast-shipping', { params });
          return res;
        }}
        additionalActions={item => {
          return (
            <div>
              <Button
                type="button"
                onClick={() => {
                  setSelectedId(item.id);
                  setFormData({
                    title: item.title,
                    shipping_days: item.shipping_days,
                    start_zipcode: item.start_zipcode,
                    end_zipcode: item.end_zipcode,
                    is_default_zipcode: item.is_default_zipcode,
                  });
                  setShowModal(true);
                }}
                className="btn btn-primary btn-sm"
              >
                Editar
              </Button>
              {!item.is_default_zipcode && (
                <Button
                  type="button"
                  onClick={() => {
                    handleDelete(item.id);
                    setHardRefresh(true);
                  }}
                  color="danger"
                  className="btn btn-danger btn-sm mt-2"
                >
                  Excluir
                </Button>
              )}
            </div>
          );
        }}
        hardRefresh={hardRefresh}
        showPagination={false}
      />
    </div>
  );
};

export default ZipcodeTable;
