import React, { useState, useEffect, Fragment } from 'react';

import { toastr } from 'react-redux-toastr';

import { useHistory } from 'react-router-dom';
import { FaFilter } from 'react-icons/fa';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { IFilter } from '~/components/Table';
import {
  Container as ContainerTable,
  TableFilters,
  Table,
  TablePagination,
  FilterContainer,
} from '~/components/Table/styles';
import { Container } from './styles';
import api from '~/services/api';

import Button from '~/styles/components/Button';

import Can from '~/components/Can';
import useSelector from '~/store/useSelector';

interface ICategory {
  id: number;
  parent?: string;
  category_id: number;
  name: string;
  description: string;
  image: string;
  status: number;
  categories?: ICategory[];
}
interface IStatePage {
  data: ICategory[];
  total: number;
  perPage: number;
  page: number;
  lastPage: number;
  loading: boolean;
  orderBy: string;
  order: 'ASC' | 'DESC';
}

const List: React.FC = () => {
  const [categories, setCategories] = useState<IStatePage>({
    data: [],
    total: 0,
    perPage: 25,
    page: 1,
    lastPage: 1,
    loading: false,
    orderBy: 'id',
    order: 'DESC',
  });

  const history = useHistory();

  const { roles, permissions } = useSelector(state => state.auth);

  async function applyFilters(data: IFilter[]) {
    const dataFilter: any = {}; // eslint-disable-line
    data.forEach(i => {
      dataFilter[i.name] = i.value;
    });
    setCategories({
      ...categories,
      ...dataFilter,
    });
  }
  const [expandedSubCategories, setExpandedSubCategories] = useState<number[]>(
    [],
  );

  useEffect(() => {
    async function loadData() {
      setCategories({ ...categories, loading: true });
      try {
        const { data } = await api.get(
          `categories?perpage=${categories.perPage}&page=${
            categories.page
          }&ordername=${
            categories.orderBy
          }&orderdirection=${categories.order.toLowerCase()}`,
        );

        const categoriesArr = [];
        for (let i = 0; i < data.data.length; i += 1) {
          const category = data.data[i];
          categoriesArr.push({
            parent: 'Raiz',
            category_id: category.category_id,
            description: category.description,
            id: category.id,
            image: category.image,
            name: category.name,
            status: category.status,
            categories: category.categories,
          });
        }

        setCategories({
          ...categories,
          data: categoriesArr,
          lastPage: data.lastPage,
          total: data.total,
        });
        setExpandedSubCategories([]);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadData();
  }, [
    categories.perPage,
    categories.page,
    categories.orderBy,
    categories.order,
  ]); // eslint-disable-line

  function onNextPage() {
    setCategories({ ...categories, page: categories.page + 1 });
  }
  function onPreviousPage() {
    setCategories({ ...categories, page: categories.page - 1 });
  }

  const [openFilter, setOpenFilter] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState<IFilter[]>([
    {
      name: 'perPage',
      title: 'Results. p/página:',
      type: 'text',
      value: String(categories.perPage),
    },
    {
      name: 'orderBy',
      title: 'Ordenar por:',
      type: 'text',
      value: categories.orderBy,
    },
    {
      name: 'order',
      title: 'Ordem:',
      type: 'text',
      value: categories.order,
    },
  ]);

  function handleCancelFilters() {
    setOpenFilter(false);
    setDefaultFilters([
      {
        name: 'perPage',
        title: 'Results. p/página:',
        type: 'text',
        value: String(categories.perPage),
      },
      {
        name: 'orderBy',
        title: 'Ordenar por:',
        type: 'text',
        value: categories.orderBy,
      },
      {
        name: 'order',
        title: 'Ordem:',
        type: 'text',
        value: categories.order,
      },
    ]);
  }

  function handleExpand(i: number) {
    if (expandedSubCategories.find(c => c === i)) {
      setExpandedSubCategories(expandedSubCategories.filter(c => c !== i));
    } else {
      setExpandedSubCategories([...expandedSubCategories, i]);
    }
  }

  function handleChangeDefaultFilterValue(name: string, newValue: string) {
    setDefaultFilters(
      defaultFilters.map(f => {
        if (f.name === name) {
          return {
            ...f,
            value: f.normalize ? f.normalize(newValue) : newValue,
          };
        }
        return f;
      }),
    );
  }

  return (
    <Container>
      <div className="d-flex justify-content-end">
        <Can checkPermission="category_store">
          <Button
            type="button"
            onClick={() => history.push('/categories/create')}
          >
            Nova Categoria
          </Button>
        </Can>
      </div>
      <ContainerTable fullWidth>
        {openFilter && (
          <FilterContainer>
            <h4>Filtros</h4>
            {defaultFilters.map((filter, index) => (
              <label
                className="input"
                htmlFor={filter.name}
                key={String(index)}
              >
                <p>{filter.title}</p>
                <select
                  id={filter.name}
                  name={filter.name}
                  value={filter.value}
                  onChange={e => {
                    handleChangeDefaultFilterValue(filter.name, e.target.value);
                  }}
                >
                  {filter.name === 'perPage' && (
                    <>
                      {[25, 50, 100].map(option => (
                        <option key={option} value={String(option)}>
                          {option}
                        </option>
                      ))}
                    </>
                  )}
                  {filter.name === 'orderBy' &&
                    [
                      {
                        name: 'image',
                        title: 'Imagem',
                      },
                      {
                        name: 'name',
                        title: 'Name',
                      },
                    ].map(label => (
                      <option key={label.name} value={label.name}>
                        {label.title}
                      </option>
                    ))}
                  {filter.name === 'order' && (
                    <>
                      <option value="ASC">Crescente</option>
                      <option value="DESC">Decrescente</option>
                    </>
                  )}
                </select>
              </label>
            ))}
            <div id="action_filter">
              <Button
                type="button"
                onClick={() => {
                  applyFilters([...defaultFilters]);
                  setOpenFilter(false);
                }}
                disabled={categories.loading}
              >
                Aplicar
              </Button>
              <Button
                type="button"
                color="gray"
                onClick={handleCancelFilters}
                disabled={categories.loading}
              >
                Cancelar
              </Button>
            </div>
          </FilterContainer>
        )}
        <TableFilters loading={categories.loading}>
          <h3>Categorias</h3>
          <button
            type="button"
            className={openFilter ? 'filled' : ''}
            onClick={() => setOpenFilter(!openFilter)}
            disabled={categories.loading}
          >
            <FaFilter />
          </button>
        </TableFilters>
        <Table>
          <thead>
            <tr>
              {[
                {
                  name: 'image',
                  title: 'Imagem',
                },
                {
                  name: 'parent',
                  title: 'Categoria Pai',
                },
                {
                  name: 'name',
                  title: 'Name',
                },
                {
                  name: 'status',
                  title: 'Status',
                },
              ].map(label => (
                <th key={label.name}>{label.title}</th>
              ))}
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {categories.data
              .map(d => {
                return {
                  ...d,
                  status: d.status === 1 ? 'Ativo' : 'Inativo',
                  image: d.image ? (
                    <img
                      src={d.image || '/sem-imagem.png'}
                      alt={d.name}
                      width="80"
                    />
                  ) : (
                    '-'
                  ),
                };
              })
              .map(
                (
                  item: any, // eslint-disable-line
                  index,
                ) => {
                  const expanded = expandedSubCategories.find(
                    c => c === item.id,
                  );
                  return (
                    <Fragment key={String(index)}>
                      <tr style={{ background: 'rgba(0,0,0,.1)' }}>
                        {[
                          {
                            name: 'image',
                            title: 'Imagem',
                          },
                          {
                            name: 'parent',
                            title: 'Categoria Pai',
                          },
                          {
                            name: 'name',
                            title: 'Name',
                          },
                          {
                            name: 'status',
                            title: 'Status',
                          },
                        ].map(field => (
                          <td key={field.name}>{item[field.name]}</td>
                        ))}
                        <td>
                          <button
                            type="button"
                            onClick={() => handleExpand(item.id)}
                            style={{
                              border: '1px solid #444',
                              borderRadius: '4px',
                              marginRight: '8px',
                              padding: '5px 15px',
                            }}
                          >
                            {expanded ? 'Ver menos' : 'Ver Subcategorias'}
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              history.push(`/categories/edit/${item.id}`)
                            }
                            style={{
                              border: '1px solid #444',
                              borderRadius: '4px',
                              marginLeft: '8px',
                              padding: '5px 15px',
                            }}
                          >
                            Editar
                            <IoIosArrowForward />
                          </button>
                        </td>
                      </tr>
                      {expanded &&
                        item.categories.map((sub: ICategory) => {
                          const expandedS = expandedSubCategories.find(
                            d => d === sub.id,
                          );
                          return (
                            <Fragment key={sub.id}>
                              <tr style={{ background: 'rgba(0,0,0,.05)' }}>
                                <td>
                                  <img
                                    src={sub.image || '/sem-imagem.png'}
                                    alt={sub.name}
                                    width="80"
                                  />
                                </td>
                                <td>{item.name}</td>
                                <td>{sub.name}</td>
                                <td>
                                  {sub.status === 1 ? 'Ativo' : 'Inativo'}
                                </td>
                                <td>
                                  {sub.categories && sub.categories.length > 0 && (
                                    <button
                                      type="button"
                                      onClick={() => handleExpand(sub.id)}
                                      style={{
                                        border: '1px solid #444',
                                        borderRadius: '4px',
                                        marginRight: '8px',
                                        padding: '5px 15px',
                                      }}
                                    >
                                      {expandedS
                                        ? 'Ver menos'
                                        : 'Ver Subcategorias'}
                                    </button>
                                  )}
                                  <button
                                    type="button"
                                    onClick={() =>
                                      history.push(`/categories/edit/${sub.id}`)
                                    }
                                    style={{
                                      border: '1px solid #444',
                                      borderRadius: '4px',
                                      marginLeft: '8px',
                                      padding: '5px 15px',
                                    }}
                                  >
                                    Editar
                                    <IoIosArrowForward />
                                  </button>
                                </td>
                              </tr>
                              {sub.categories &&
                                expandedS &&
                                sub.categories.map((subI: ICategory) => {
                                  const expandedSS = expandedSubCategories.find(
                                    d => d === subI.id,
                                  );

                                  return (
                                    <Fragment key={subI.id}>
                                      <tr
                                        style={{
                                          background: 'rgba(0,0,0,.01)',
                                        }}
                                      >
                                        <td>
                                          <img
                                            src={
                                              subI.image || '/sem-imagem.png'
                                            }
                                            alt={subI.name}
                                            width="80"
                                          />
                                        </td>
                                        <td>{sub.name}</td>
                                        <td>{subI.name}</td>
                                        <td>
                                          {subI.status === 1
                                            ? 'Ativo'
                                            : 'Inativo'}
                                        </td>
                                        <td>
                                          {subI.categories &&
                                            subI.categories.length > 0 && (
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  handleExpand(subI.id)
                                                }
                                                style={{
                                                  border: '1px solid #444',
                                                  borderRadius: '4px',
                                                  marginRight: '8px',
                                                  padding: '5px 15px',
                                                }}
                                              >
                                                {expandedSS
                                                  ? 'Ver menos'
                                                  : 'Ver Subcategorias'}
                                              </button>
                                            )}
                                          <button
                                            type="button"
                                            onClick={() =>
                                              history.push(
                                                `/categories/edit/${subI.id}`,
                                              )
                                            }
                                            style={{
                                              border: '1px solid #444',
                                              borderRadius: '4px',
                                              marginLeft: '8px',
                                              padding: '5px 15px',
                                            }}
                                          >
                                            Editar
                                            <IoIosArrowForward />
                                          </button>
                                        </td>
                                      </tr>
                                      {subI.categories &&
                                        expandedSS &&
                                        subI.categories.map(
                                          (subII: ICategory) => {
                                            const expandedSSS = expandedSubCategories.find(
                                              d => d === subII.id,
                                            );
                                            return (
                                              <Fragment key={subII.id}>
                                                <tr
                                                  style={{
                                                    background:
                                                      'rgba(0,0,0,.01)',
                                                  }}
                                                >
                                                  <td>
                                                    <img
                                                      src={
                                                        subII.image ||
                                                        '/sem-imagem.png'
                                                      }
                                                      alt={subII.name}
                                                      width="80"
                                                    />
                                                  </td>
                                                  <td>{subI.name}</td>
                                                  <td>{subII.name}</td>
                                                  <td>
                                                    {subII.status === 1
                                                      ? 'Ativo'
                                                      : 'Inativo'}
                                                  </td>
                                                  <td>
                                                    {subII.categories &&
                                                      subII.categories.length >
                                                        0 && (
                                                        <button
                                                          type="button"
                                                          onClick={() =>
                                                            handleExpand(
                                                              subII.id,
                                                            )
                                                          }
                                                          style={{
                                                            border:
                                                              '1px solid #444',
                                                            borderRadius: '4px',
                                                            marginRight: '8px',
                                                            padding: '5px 15px',
                                                          }}
                                                        >
                                                          {expandedSSS
                                                            ? 'Ver menos'
                                                            : 'Ver Subcategorias'}
                                                        </button>
                                                      )}
                                                    <button
                                                      type="button"
                                                      onClick={() =>
                                                        history.push(
                                                          `/categories/edit/${subII.id}`,
                                                        )
                                                      }
                                                      style={{
                                                        border:
                                                          '1px solid #444',
                                                        borderRadius: '4px',
                                                        marginLeft: '8px',
                                                        padding: '5px 15px',
                                                      }}
                                                    >
                                                      Editar
                                                      <IoIosArrowForward />
                                                    </button>
                                                  </td>
                                                </tr>
                                                {subII.categories &&
                                                  expandedSSS &&
                                                  subII.categories.map(
                                                    (subIII: ICategory) => {
                                                      // const expandedSSSS = expandedSubCategories.find(
                                                      //   d => d === subII.id,
                                                      // );
                                                      return (
                                                        <Fragment
                                                          key={subIII.id}
                                                        >
                                                          <tr
                                                            style={{
                                                              background:
                                                                'rgba(0,0,0,.01)',
                                                            }}
                                                          >
                                                            <td>
                                                              <img
                                                                src={
                                                                  subIII.image ||
                                                                  '/sem-imagem.png'
                                                                }
                                                                alt={
                                                                  subIII.name
                                                                }
                                                                width="80"
                                                              />
                                                            </td>
                                                            <td>
                                                              {subII.name}
                                                            </td>
                                                            <td>
                                                              {subIII.name}
                                                            </td>
                                                            <td>
                                                              {/* {subIII.categories &&
                                                                  subIII
                                                                    .categories
                                                                    .length >
                                                                    0 && (
                                                                    <button
                                                                      type="button"
                                                                      onClick={() =>
                                                                        handleExpand(
                                                                          subII.id,
                                                                        )
                                                                      }
                                                                      style={{
                                                                        border:
                                                                          '1px solid #444',
                                                                        borderRadius:
                                                                          '4px',
                                                                        marginRight:
                                                                          '8px',
                                                                        padding:
                                                                          '5px 15px',
                                                                      }}
                                                                    >
                                                                      {expandedSSS
                                                                        ? 'Ver menos'
                                                                        : 'Ver Subcategorias'}
                                                                    </button>
                                                                  )} */}
                                                              <button
                                                                type="button"
                                                                onClick={() =>
                                                                  history.push(
                                                                    `/categories/edit/${subIII.id}`,
                                                                  )
                                                                }
                                                                style={{
                                                                  border:
                                                                    '1px solid #444',
                                                                  borderRadius:
                                                                    '4px',
                                                                  marginLeft:
                                                                    '8px',
                                                                  padding:
                                                                    '5px 15px',
                                                                }}
                                                              >
                                                                Editar
                                                                <IoIosArrowForward />
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        </Fragment>
                                                      );
                                                    },
                                                  )}
                                              </Fragment>
                                            );
                                          },
                                        )}
                                    </Fragment>
                                  );
                                })}
                            </Fragment>
                          );
                        })}
                    </Fragment>
                  );
                },
              )}
          </tbody>
        </Table>
        <TablePagination>
          <div>
            <strong>{categories.total} Resultado(s)</strong>
          </div>
          <div id="pages">
            <button
              type="button"
              onClick={onPreviousPage}
              disabled={categories.page === 1 || categories.loading}
            >
              <IoIosArrowBack />
            </button>
            <span>{categories.page}</span>
            <button
              type="button"
              onClick={onNextPage}
              disabled={
                categories.loading || categories.page === categories.lastPage
              }
            >
              <IoIosArrowForward />
            </button>
          </div>
        </TablePagination>
      </ContainerTable>
    </Container>
  );
};

export default List;
