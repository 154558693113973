import React, { useState, useEffect } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { FaTimesCircle, FaCheck } from 'react-icons/fa';
import crypto from 'crypto';
import { format, setHours, addHours, subHours, parseISO } from 'date-fns';
import { normalizeCurrency } from '~/utils/normalize';
import api from '~/services/api';
import Input from '~/components/Input';

import Checkbox from '~/components/Checkbox';
import Button from '~/styles/components/Button';

import Modal from '~/components/Modal';

import { Container, AutocompleteOptions } from './styles';

interface IChange {
  name: string;
  value: string | string[];
}

interface ICoupon {
  code: string;
  limit: string;
  discountType: string;
  discountValue: string;
  minValue: string;
  minValueAll: boolean;
  freeShipping: string;
  startDate: string;
  endDate: string;
  active: boolean;
  allowMarketplace: boolean;
}

interface IProduct {
  id: number;
  code: string;
  title: string;
}
interface ICampaign {
  id: number;
  name: string;
}
interface IBrand {
  id: number;
  name: string;
}
interface ICategory {
  id: number;
  parent: string;
  category_id: number;
  categories: ICategory[];
  name: string;
  description: string;
  image: string;
}

interface ICondition {
  type: string;
  data: any; // eslint-disable-line
}

interface IItemCondition {
  id: number;
  name: string;
  sku?: string;
}

interface IConditionData {
  id: number;
  type: string;
  reference: IItemCondition[]; // eslint-disable-line
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const [values, setValues] = useState<ICoupon>({
    code: '',
    limit: '',
    discountType: '',
    discountValue: '',
    minValue: '',
    minValueAll: false,
    freeShipping: '',
    startDate: '',
    endDate: '',
    active: false,
    allowMarketplace: false,
  });
  const [loading, setLoading] = useState(false);
  const [typeCondition, setTypeCondition] = useState('');
  const [queryProduct, setQueryProduct] = useState('');
  const [products, setProducts] = useState<IProduct[]>([]);
  const [campaigns, setCampaigns] = useState<ICampaign[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [categories, setCategories] = useState<ICampaign>({} as ICampaign);
  const [productsData, setProductsData] = useState<IProduct[]>([]);
  const [campaignsData, setCampaignsData] = useState<ICampaign[]>([]);
  const [brandsData, setBrandsData] = useState<IBrand[]>([]);
  const [categoriesData, setCategoriesData] = useState<ICategory[]>([]);
  const [conditions, setConditions] = useState<ICondition[]>([]);
  const [conditionsRemoved, setConditionsRemoved] = useState<IConditionData[]>(
    [],
  );
  const [conditionsData, setConditionsData] = useState<IConditionData[]>([]);
  const history = useHistory();

  const [addCondition, setAddCondition] = useState(false);

  const { id } = match.params;

  useEffect(() => {
    async function loadAll() {
      const { data: campaignsDataA } = await api.get(
        `/campaigns?perpage=10000000&page=1&all=1&only_campaign=1&active=1`,
      );
      setCampaignsData(campaignsDataA.data);
    }
    loadAll();
  }, []);

  useEffect(() => {
    async function loadAll() {
      const { data: brandsDataA } = await api.get(
        `/brands?perpage=10000000&page=1&all=1`,
      );
      setBrandsData(brandsDataA.data);
    }
    loadAll();
  }, []);

  useEffect(() => {
    async function loadAll() {
      const { data: categoriesDataA } = await api.get(
        `/categories?perpage=10000000&page=1`,
      );
      setCategoriesData(
        categoriesDataA.data.reduce(
          (categoryArr: ICategory[], category: ICategory) => {
            categoryArr.push(category);

            const subcategories = category?.categories;

            if (subcategories && subcategories.length > 0) {
              for (let i = 0; i < subcategories.length; i += 1) {
                const subcategory = subcategories[i];

                const subcategoryCopy = { ...subcategory };

                subcategoryCopy.name = `${category.name} > ${subcategory.name}`;

                categoryArr.push(subcategoryCopy);

                if (
                  subcategory?.categories &&
                  subcategory.categories.length > 0
                ) {
                  subcategory.categories.forEach(subsubcategory => {
                    const subsubcategoryCopy = { ...subsubcategory };

                    subsubcategoryCopy.name = `${category.name} > ${subcategory.name} > ${subsubcategory.name}`;

                    categoryArr.push(subsubcategoryCopy);

                    if (
                      subsubcategory?.categories &&
                      subsubcategory.categories.length > 0
                    ) {
                      subsubcategory.categories.forEach(subsubsubcategory => {
                        const subsubsubcategoryCopy = { ...subsubsubcategory };

                        subsubsubcategoryCopy.name = `${category.name} > ${subcategory.name} > ${subsubcategory.name} > ${subsubsubcategory.name}`;

                        categoryArr.push(subsubsubcategoryCopy);

                        if (
                          subsubsubcategory?.categories &&
                          subsubsubcategory.categories.length > 0
                        ) {
                          subsubsubcategory.categories.forEach(
                            subsubsubsubcategory => {
                              const subsubsubsubcategoryCopy = {
                                ...subsubsubsubcategory,
                              };

                              subsubsubsubcategoryCopy.name = `${category.name} > ${subcategory.name} > ${subsubcategory.name} > ${subsubsubcategory.name} > ${subsubsubsubcategory.name}`;

                              categoryArr.push(subsubsubsubcategoryCopy);
                            },
                          );
                        }
                      });
                    }
                  });
                }
              }
            }

            return categoryArr;
          },
          [],
        ),
      );
    }
    loadAll();
  }, []);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      try {
        const { data } = await api.get(`/coupons/${id}`);
        setValues({
          ...data,
          limit: String(data.usageLimit),
          discountType: data.type,
          freeShipping: data.freeShipping === 0 ? 'false' : 'true',
          allowMarketplace: data.allowMarketplace === 1,
          discountValue: normalizeCurrency(String(data.value)),
          minValue: normalizeCurrency(String(data.minValue)),
          minValueAll: data.minValueAll === 1,
          startDate: format(parseISO(data.startDate), 'yyyy-MM-dd'),
          endDate: format(parseISO(data.endDate), 'yyyy-MM-dd'),
          active: data.active,
        });
        setConditionsData(data.conditions);
      } catch (err) {
        toastr.error(
          'Erro',
          'Ocorreu um erro ao tentar buscar cupom, contate a equipe técnica',
        );
      }
      setLoading(false);
    }
    loadData();
  }, [id]);

  async function handleSearchProduct(value: string) {
    setQueryProduct(value);
    if (value.length === 0) {
      setProductsData([]);
    } else {
      const { data } = await api.get(`products?name=${value}`);
      setProductsData(
        data.data.map((p: any) => {
          // eslint-disable-line
          return {
            id: p.id,
            title: p.name,
            code: p.sku,
          };
        }),
      );
    }
  }

  function handleAddCampaign(item: ICampaign) {
    if (!campaigns.find(p => p.id === item.id)) {
      setCampaigns([...campaigns, item]);
    } else {
      setCampaigns(campaigns.filter(p => p.id !== item.id));
    }
  }

  function handleAddBrand(item: IBrand) {
    if (!brands.find(p => p.id === item.id)) {
      setBrands([...brands, item]);
    } else {
      setBrands(brands.filter(p => p.id !== item.id));
    }
  }

  function handleDeleteProduct(idP: number) {
    setProducts(products.filter(i => i.id !== idP));
  }

  function handleSelect(product: IProduct) {
    if (!products.find(p => p.id === product.id)) {
      setProducts([...products, product]);
      setQueryProduct('');
      setProductsData([]);
    }
  }

  function handleAddCondition() {
    let data: any[] = []; // eslint-disable-line
    if (typeCondition === 'product') {
      data = products;
    } else if (typeCondition === 'category') {
      data = [categories];
    } else if (typeCondition === 'campaign') {
      data = campaigns;
    } else if (typeCondition === 'brand') {
      data = brands;
    } else {
      return;
    }
    setConditions([...conditions, { type: typeCondition, data }]);
    setProducts([]);
    setCampaigns([]);
    setBrands([]);
    setCategories({} as ICampaign);
    setQueryProduct('');
    setTypeCondition('');
    setAddCondition(false);
    setValues({ ...values, minValueAll: false });
  }

  // useEffect(() => {
  //   if (conditions.length > 0) {
  //     setValues({ ...values, minValueAll: false });
  //   }
  //   console.log(conditions);
  // }, [conditions]);

  async function handleAdd() {
    if (
      values.discountType === 'percentage' &&
      Number(String(values.discountValue).replace(/\D/g, '')) > 10000
    ) {
      return toastr.error(
        'Validação',
        'Porcentagem não pode ser maior que 100.00%',
      );
    }
    setLoading(true);
    try {
      const response = await api.put(`/coupons/${id}`, {
        code: values.code,
        usageLimit: values.limit || 0,
        type: values.discountType,
        value: Number(String(values.discountValue).replace(/\D/g, '')),
        minValue: Number(String(values.minValue).replace(/\D/g, '')),
        minValueAll: values.minValueAll,
        freeShipping: values.freeShipping === 'true' ? 1 : 0,
        startDate: format(
          addHours(parseISO(values.startDate), 3),
          'yyyy-MM-dd HH:mm:ss',
        ),
        endDate: format(
          addHours(parseISO(values.endDate), 3),
          'yyyy-MM-dd HH:mm:ss',
        ),
        allowMarketplace: values.allowMarketplace,
        active: values.active,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        for (let i = 0; i < conditionsRemoved.length; i += 1) {
          try {
            await api.delete(`/couponsconditions/${conditionsRemoved[i].id}`);
          } catch (err) {
            toastr.error('Erro', `Condição ${i + 1} não removida`);
          }
        }
        for (let i = 0; i < conditions.length; i += 1) {
          try {
            await api.post(`/couponsconditions`, {
              coupon_id: response.data.id,
              type: conditions[i].type,
              reference:
                typeof conditions[i].data !== 'number'
                  ? conditions[i].data.map((d: any) => d.id).join(',') // eslint-disable-line
                  : conditions[i].data.toString(),
            });
          } catch (err) {
            toastr.error('Erro', `Condição ${i + 1} não adicionada`);
          }
        }
        history.push('/coupons/list');
        toastr.success('Cupom editado', '');
      }
    } catch (err) {
      if (err.data && err.data.message) {
        toastr.error(err.data.message, '');
      } else {
        toastr.error('Ocorreu um erro', '');
      }
    }
    return setLoading(false);
  }

  useEffect(() => {
    if (values.discountType === 'freeShipping') {
      setValues({
        ...values,
        discountValue: '0',
      });
    }
  }, [values.discountType]); // eslint-disable-line

  function handleChange(e: IChange) {
    setValues({
      ...values,
      [e.name]: String(e.value),
    });
  }

  return (
    <Container>
      {addCondition && (
        <Modal size="xl">
          <Input
            label="Tipo de Condição"
            name="type_condition"
            required
            onChange={e => setTypeCondition(String(e.value))}
            options={[
              {
                value: 'product',
                title: 'Produto',
              },
              {
                value: 'category',
                title: 'Categoria',
              },
              {
                value: 'campaign',
                title: 'Campanha',
              },
              {
                value: 'brand',
                title: 'Marca',
              },
            ]}
            select
          />
          {typeCondition === 'product' && (
            <div style={{ position: 'relative' }}>
              <Input
                label="Produtos"
                name="productsQuery"
                initialValue={queryProduct}
                initialChanges
                required
                onChange={e => handleSearchProduct(String(e.value))}
                disabled={loading}
                autoComplete="off"
              />
              {queryProduct.length > 0 && productsData.length > 0 && (
                <AutocompleteOptions>
                  {productsData.map(p => (
                    <button
                      type="button"
                      key={p.id}
                      onClick={() => handleSelect(p)}
                    >
                      {p.code} - {p.title}
                    </button>
                  ))}
                </AutocompleteOptions>
              )}
              {products.map(p => (
                <div
                  key={p.id}
                  className="d-flex justify-content-between product-added"
                >
                  <p className="mb-0">
                    <strong>{p.code}</strong> - {p.title}
                  </p>
                  <button
                    className="text-danger ml-2"
                    type="button"
                    onClick={() => handleDeleteProduct(p.id)}
                  >
                    <FaTimesCircle />
                  </button>
                </div>
              ))}
            </div>
          )}
          {typeCondition === 'category' && (
            <div
              style={{
                display: 'grid',
                gridGap: '5px',
                gridTemplateColumns: '1fr',
              }}
            >
              <Input
                select
                label="Selecionar"
                name="categories"
                onChange={e => {
                  setCategories({
                    id: Number(e.value),
                    name:
                      categoriesData.find(c => c.id === Number(e.value))
                        ?.name || '',
                  });
                }}
                options={categoriesData.map(c => ({
                  title: c.name,
                  value: String(c.id),
                }))}
              />
            </div>
          )}
          {typeCondition === 'campaign' && (
            <div
              style={{
                display: 'grid',
                gridGap: '5px',
                gridTemplateColumns: '1fr 1fr 1fr',
              }}
            >
              {campaignsData.map(campaign => (
                <button
                  type="button"
                  key={campaign.id}
                  style={{
                    padding: '5px 10px',
                    background: '#eee',
                    borderRadius: '4px',
                  }}
                  onClick={() => handleAddCampaign(campaign)}
                  className="d-flex align-items-center justify-content-between mr-2 mt-2"
                >
                  {campaign.name}
                  {campaigns.find(i => i.id === campaign.id) ? <FaCheck /> : ''}
                </button>
              ))}
            </div>
          )}
          {typeCondition === 'brand' && (
            <div
              style={{
                display: 'grid',
                gridGap: '5px',
                gridTemplateColumns: '1fr 1fr 1fr',
              }}
            >
              {brandsData.map(brand => (
                <button
                  type="button"
                  key={brand.id}
                  style={{
                    padding: '5px 10px',
                    background: '#eee',
                    borderRadius: '4px',
                  }}
                  onClick={() => handleAddBrand(brand)}
                  className="d-flex align-items-center justify-content-between mr-2 mt-2"
                >
                  {brand.name}
                  {brands.find(i => i.id === brand.id) ? <FaCheck /> : ''}
                </button>
              ))}
            </div>
          )}
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: '10px' }}
          >
            <Button
              type="button"
              onClick={handleAddCondition}
              disabled={typeCondition === ''}
            >
              Salvar
            </Button>
            <Button
              type="button"
              color="gray"
              onClick={() => {
                setAddCondition(false);
                setTypeCondition('');
                setProducts([]);
                setCampaigns([]);
                setCategories({} as ICampaign);
              }}
            >
              Cancelar
            </Button>
          </div>
        </Modal>
      )}
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/coupons/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleAdd();
        }}
        className="block_white"
      >
        <div className="header_block">
          <h3>Editar Cupom</h3>
        </div>
        <div className="body_block">
          <div className="row mb-3">
            <div
              className="col-md-12 d-flex align-items-center"
              style={{ marginTop: '10px' }}
            >
              <Input
                label="Código do Cupom"
                name="code"
                initialChanges
                initialValue={values.code}
                required
                onChange={handleChange}
                disabled={loading}
              />
              <button
                type="button"
                style={{ display: 'block', marginLeft: '20px' }}
                onClick={() => {
                  setValues({
                    ...values,
                    code: crypto.randomBytes(4).toString('hex').toUpperCase(),
                  });
                }}
              >
                Gerar código de cupom
              </button>
            </div>
            <div
              className="col-md-12 d-flex align-items-center"
              style={{ marginTop: '10px' }}
            >
              <Input
                label="Limite de Uso"
                name="limit"
                type="number"
                initialChanges
                initialValue={values.limit}
                required
                onChange={handleChange}
                disabled={loading}
              />
              <span style={{ display: 'block', marginLeft: '20px' }}>
                0 = ilimitado
              </span>
            </div>
            <div
              className="col-md-12 d-flex align-items-center"
              style={{ marginTop: '10px' }}
            >
              <Input
                label="Tipo Desconto"
                name="discountType"
                required
                select
                initialChanges
                initialValue={values.discountType}
                options={[
                  {
                    title: 'Valor (R$)',
                    value: 'value',
                  },
                  {
                    title: 'Porcentagem (%)',
                    value: 'percentage',
                  },
                  {
                    title: 'Frete Grátis',
                    value: 'freeShipping',
                  },
                ]}
                onChange={handleChange}
                disabled={loading}
              />
              {values.discountType !== 'freeShipping' && (
                <Input
                  label="Valor/Porcentagem"
                  name="discountValue"
                  initialChanges
                  initialValue={values.discountValue}
                  type="text"
                  normalize={normalizeCurrency}
                  required
                  onChange={handleChange}
                  disabled={loading}
                />
              )}
            </div>
            <div
              className="col-md-12 d-flex align-items-center"
              style={{ marginTop: '10px' }}
            >
              <Input
                label="Aplicar frete grátis?"
                name="freeShipping"
                required
                select
                initialChanges
                initialValue={values.freeShipping}
                options={[
                  {
                    title: 'Sim',
                    value: 'true',
                  },
                  {
                    title: 'Não',
                    value: 'false',
                  },
                ]}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
            <div className="row mt-3 ml-0">
              <div className="col-md-4 d-flex align-items-center">
                <Input
                  label="Valor mínimo"
                  name="minValue"
                  initialChanges
                  initialValue={values.minValue}
                  normalize={normalizeCurrency}
                  required
                  onChange={handleChange}
                  disabled={loading}
                />
              </div>
              <div className="col-md-8">
                <div style={{ marginTop: '10px' }}>
                  <Checkbox
                    name="minValueAll"
                    label="Atribuir valor para todos produtos do carrrinho"
                    checked={values.minValueAll}
                    onChange={() => {
                      setValues({
                        ...values,
                        minValueAll: !values.minValueAll,
                      });
                      if (
                        !values.minValueAll &&
                        values.minValue === '0,00' &&
                        (values.limit === '0' || values.limit === '')
                      ) {
                        alert(
                          'Atenção: valor mínimo é 0 e limite de uso também é 0.',
                        );
                      }
                    }}
                    disabled={
                      conditions.length > 0 || conditionsData.length > 0
                    }
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <Checkbox
                    name="allowMarketplace"
                    label="Permitir para Marketplace"
                    checked={values.allowMarketplace}
                    onChange={() => {
                      setValues({
                        ...values,
                        allowMarketplace: !values.allowMarketplace,
                      });
                    }}
                  />
                </div>
                <div style={{ marginTop: '10px' }}>
                  <Checkbox
                    name="active"
                    label="Ativo"
                    checked={values.active}
                    onChange={() => {
                      setValues({ ...values, active: !values.active });
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className="col-md-12 d-flex align-items-center"
              style={{ marginTop: '10px' }}
            >
              <strong style={{ fontSize: '12px', display: 'block' }}>
                Vigência
              </strong>
              <br />
            </div>
            <div className="col-md-2 d-flex align-items-center">
              <Input
                label="Data início"
                name="startDate"
                type="date"
                required
                initialChanges
                initialValue={values.startDate}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
            <div className="col-md-2 d-flex align-items-center">
              <Input
                label="Data de término"
                name="endDate"
                type="date"
                required
                initialChanges
                initialValue={values.endDate}
                onChange={handleChange}
                disabled={loading}
              />
            </div>
            <div className="col-md-12" style={{ marginTop: '10px' }}>
              <strong style={{ fontSize: '12px', display: 'block' }}>
                Condições de Uso
              </strong>
              <br />
            </div>
            <div className="col-md-12">
              {categoriesData.length > 0 &&
                conditionsData.map((condition, index) => {
                  return (
                    <div
                      key={condition.type}
                      className="d-flex align-items-start justify-content-between"
                      style={{
                        background: '#eee',
                        padding: '15px',
                        borderRadius: '4px',
                        marginBottom: '4px',
                      }}
                    >
                      <div>
                        <h6>
                          {condition.type === 'product' &&
                            'Condição de Produtos'}
                          {condition.type === 'category' &&
                            'Condição de Categoria'}
                          {condition.type === 'campaign' &&
                            'Condição de Campanha'}
                          {condition.type === 'brand' && 'Condição de Marca'}
                        </h6>
                        <small style={{ fontSize: '10px' }}>
                          {condition.reference.map(i => (
                            <p key={i.id}>
                              {i.sku ? `${i.sku} - ` : ''}
                              {condition.type === 'category'
                                ? categoriesData.find(c => c.id === i.id)
                                    ?.name || i.name
                                : i.name}
                            </p>
                          ))}
                        </small>
                      </div>
                      <button
                        type="button"
                        style={{ color: '#f00' }}
                        onClick={() => {
                          setConditionsRemoved([
                            ...conditionsRemoved,
                            condition,
                          ]);
                          setConditionsData(
                            conditionsData.filter((item, ind) => ind !== index),
                          );
                        }}
                      >
                        <FaTimesCircle />
                      </button>
                    </div>
                  );
                })}
              {conditions.map((condition, index) => (
                <div
                  key={condition.type}
                  className="d-flex align-items-start justify-content-between"
                  style={{
                    background: '#eee',
                    padding: '15px',
                    borderRadius: '4px',
                    marginBottom: '4px',
                  }}
                >
                  <div>
                    <h6>
                      {condition.type === 'product' && 'Condição de Produtos'}
                      {condition.type === 'category' && 'Condição de Categoria'}
                      {condition.type === 'campaign' && 'Condição de Campanha'}
                      {condition.type === 'brand' && 'Condição de Marca'}
                    </h6>
                    <small style={{ fontSize: '10px' }}>
                      {condition.type === 'product' &&
                        (Array.isArray(condition.data)
                          ? condition.data.map((i: IProduct) => (
                              <p key={i.code}>
                                {i.code} - {i.title}
                              </p>
                            ))
                          : condition.data)}
                      {condition.type === 'category' &&
                        (Array.isArray(condition.data)
                          ? condition.data.map((i: ICategory) => (
                              <p key={i.name}>{i.name}</p>
                            ))
                          : condition.data)}
                      {condition.type === 'campaign' &&
                        (Array.isArray(condition.data)
                          ? condition.data.map((i: ICampaign) => (
                              <p key={i.name}>{i.name}</p>
                            ))
                          : condition.data)}
                      {condition.type === 'brand' &&
                        (Array.isArray(condition.data)
                          ? condition.data.map((i: IBrand) => (
                              <p key={i.name}>{i.name}</p>
                            ))
                          : condition.data)}
                    </small>
                  </div>
                  <button
                    type="button"
                    style={{ color: '#f00' }}
                    onClick={() =>
                      setConditions(
                        conditions.filter((item, ind) => ind !== index),
                      )
                    }
                  >
                    <FaTimesCircle />
                  </button>
                </div>
              ))}
              <Button
                type="button"
                style={{ background: '#0071af' }}
                onClick={() => setAddCondition(true)}
                disabled={values.minValueAll}
              >
                Adicionar Condição +
              </Button>
            </div>
          </div>
        </div>
        <div className="footer_block">
          <Button type="submit" disabled={loading}>
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default Edit;
