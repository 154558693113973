import React, { useState, useEffect } from 'react';

import { FaTimes, FaTimesCircle } from 'react-icons/fa';
import { Container, Label, Input, Select, HelperText } from './styles';

export interface IChange {
  name: string;
  value: string | string[];
}

interface IOption {
  value: string;
  title: string;
}

interface IProps {
  label: string;
  name: string;
  type?: 'text' | 'hidden' | 'number' | 'date' | 'email' | 'password' | 'file';
  required?: boolean;
  minLength?: number;
  initialValue?: string | string[];
  onChange: (e: IChange) => void;
  normalize?: (e: string) => string;
  onError?: (name: string) => void;
  onBlur?: () => void;
  onReset?: (name: string) => void;
  validation?: (value: string) => boolean;
  select?: boolean;
  options?: IOption[];
  multipleSelect?: boolean;
  disabled?: boolean;
  initialChanges?: boolean;
  autoComplete?: 'on' | 'off';
  autoCompleteConfig?: {
    getOptions: (input: string) => Promise<any[]>;
    valueColumn: string;
    labelColumn: string;
  };
}

const InputElement: React.FC<IProps> = ({
  label,
  name,
  type = 'text',
  required = false,
  initialValue = '',
  minLength,
  normalize,
  onError,
  onReset,
  onBlur,
  onChange,
  validation,
  select,
  options,
  multipleSelect,
  disabled,
  initialChanges = false,
  autoComplete = 'on',
  autoCompleteConfig,
}) => {
  const [value, setValue] = useState<string | string[]>(initialValue);
  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState(() => {
    if (autoCompleteConfig && initialValue) {
      return {
        [autoCompleteConfig.labelColumn]: initialValue,
        [autoCompleteConfig.valueColumn]: initialValue,
      } as any;
    }
    return {} as any;
  });
  const [autocompleteOptions, setAutocompleteOptions] = useState<any[]>([]);

  useEffect(() => {
    if (initialValue && initialChanges) {
      setValue(initialValue);
    }
  }, [initialValue, initialChanges]);

  const [error, setError] = useState('');

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    if (multipleSelect) {
      let v: string[] = [];
      if (typeof value === 'object') {
        v = value;
      }
      setValue([...v, e.target.value]);
      onChange({
        name,
        value: [...v, e.target.value],
      });
    } else {
      setValue(normalize ? normalize(e.target.value) : e.target.value);
      onChange({
        name,
        value: normalize ? normalize(e.target.value) : e.target.value,
      });
      if (autoCompleteConfig) {
        if (e.target.value.length === 0) {
          setAutocompleteOptions([]);
        } else {
          setLoading(true);
          autoCompleteConfig
            .getOptions(normalize ? normalize(e.target.value) : e.target.value)
            .then(opts => {
              setAutocompleteOptions(opts);
            })
            .catch(() => {
              //
            })
            .finally(() => setLoading(false));
        }
      }
    }
  }

  function handleValidation() {
    setError('');
    if (onReset) {
      onReset(name);
    }
    if (minLength && value && value.length < minLength) {
      if (onError) {
        onError(name);
      }
      return setError(`Mínimo de ${minLength} caracteres`);
    }
    if (validation && typeof value === 'string' && !validation(value)) {
      if (onError) {
        onError(name);
      }
      return setError('Valor inválido');
    }
    if (value && value.length === 0 && required) {
      if (onError) {
        onError(name);
      }
      return setError('Preencha este campo');
    }
    if (onReset) {
      return onReset(name);
    }
    if (onBlur !== undefined) {
      onBlur();
    }
    return '';
  }

  return (
    <Container
      htmlFor={name}
      style={{ display: type === 'hidden' ? 'none' : 'block' }}
    >
      <Label className="p-0">
        {label}
        {required && ' *'}
      </Label>
      {select ? (
        <>
          <Select
            value={multipleSelect ? '' : value}
            name={name}
            id={name}
            error={error !== ''}
            onChange={handleChange}
            onBlur={handleValidation}
            required={multipleSelect ? false : required}
            disabled={disabled}
          >
            <option value="" /> {/* eslint-disable-line */}
            {options &&
              options.map(option => {
                if (
                  multipleSelect &&
                  typeof value === 'object' &&
                  value.find(i => i === option.value)
                ) {
                  return '';
                }
                return (
                  <option key={option.value} value={option.value}>
                    {option.title}
                  </option>
                );
              })}
          </Select>
          {multipleSelect &&
            typeof value === 'object' &&
            value.map(i => (
              <div className="selected" key={i}>
                <strong>{options?.find(c => c.value === i)?.title}</strong>
                <button
                  type="button"
                  onClick={() => {
                    setValue(value.filter(c => c !== i));
                    onChange({
                      name,
                      value: value.filter(c => c !== i),
                    });
                  }}
                >
                  <FaTimes />
                </button>
              </div>
            ))}
        </>
      ) : (
        <div>
          {autoCompleteConfig?.valueColumn &&
          autoCompleteConfig?.labelColumn &&
          selected[autoCompleteConfig?.valueColumn] ? (
            <div className="pt-1 pb-1 pl-2 pr-2 bg-gray">
              <span className="p-0">
                {selected[autoCompleteConfig.labelColumn]}
              </span>
              <button
                type="button"
                onClick={() => {
                  setSelected({});
                  setValue('');
                  onChange({
                    name,
                    value: '',
                  });
                }}
              >
                <FaTimesCircle className="text-muted" />
              </button>
            </div>
          ) : (
            <Input
              placeholder={
                autoCompleteConfig
                  ? 'Comece a digitar para buscar resultados'
                  : ''
              }
              value={value}
              type={type}
              name={name}
              id={name}
              error={error !== ''}
              onChange={handleChange}
              onBlur={handleValidation}
              required={required}
              disabled={disabled}
              autoComplete={autoComplete}
              min={0}
            />
          )}

          {value &&
            value.length > 0 &&
            autoCompleteConfig &&
            autocompleteOptions &&
            autocompleteOptions.length > 0 && (
              <div className="autocomplete-box">
                {loading && <div className="p-2">Carregando...</div>}
                {autocompleteOptions.map(option => (
                  <button
                    type="button"
                    key={option[autoCompleteConfig.valueColumn]}
                    onClick={() => {
                      setSelected(option);
                      setValue(option[autoCompleteConfig.valueColumn]);
                      setAutocompleteOptions([]);
                      onChange({
                        name,
                        value: option[autoCompleteConfig.valueColumn],
                      });
                    }}
                  >
                    {option[autoCompleteConfig.labelColumn]}
                  </button>
                ))}
              </div>
            )}
        </div>
      )}

      <HelperText>{error}</HelperText>
    </Container>
  );
};

export default InputElement;
