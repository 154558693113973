import React, { useEffect, useState } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { FaCheck } from 'react-icons/fa';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container } from './styles';

interface IPermission {
  id: number;
  slug: string;
  name: string;
}

const Create: React.FC = () => {
  const [slug, setSlug] = useState('');
  const [name, setName] = useState('');
  const [permissions, setPermissions] = useState<number[]>([]);
  const [permissionsData, setPermissionsData] = useState<IPermission[]>([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    async function loadRole() {
      try {
        const { data: permissionsDataA } = await api.get(
          '/permissions?page=1&perpage=10000',
        );
        setPermissionsData(permissionsDataA.data);
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadRole();
  }, []); // eslint-disable-line

  function handleSelect(value: number) {
    if (!permissions.find(p => p === value)) {
      setPermissions([...permissions, value]);
    } else {
      setPermissions(permissions.filter(p => p !== value));
    }
  }

  async function handleAdd() {
    if (permissions.length === 0) {
      toastr.error('Validação', 'Nenhuma permissão selecionada');
      return;
    }
    setLoading(true);
    try {
      const response = await api.post(`/roles`, {
        name,
        slug,
        permissions,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/roles/list');
        toastr.success('Perfil criado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/roles/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="p-3 bg-white radius-4 shadow-sm">
        <h5>Criar Perfil</h5>
        <hr />
        <div>
          <div className="row mb-3">
            <div className="col-md-6">
              <Input
                label="Nome"
                name="name"
                required
                onChange={e => setName(String(e.value))}
                disabled={loading}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Slug"
                name="slug"
                required
                onChange={e => setSlug(String(e.value))}
                disabled={loading}
              />
            </div>
            <div className="col-md-12">
              <h5>Permissões *</h5>
              <div className="row">
                {permissionsData.map(p => (
                  <div key={p.id} className="col-md-3">
                    <button
                      type="button"
                      onClick={() => handleSelect(p.id)}
                      style={{
                        borderRadius: '4px',
                      }}
                      className="d-flex align-items-center justify-content-between mr-2 mt-2 border-1 border-muted p-2 w-100"
                    >
                      {p.name}
                      <div
                        className="bg-gray d-flex justify-content-center align-items-center"
                        style={{
                          width: '24px',
                          height: '24px',
                          marginLeft: '4px',
                        }}
                      >
                        {permissions.findIndex(i => i === p.id) > -1 ? (
                          <FaCheck color="#222" />
                        ) : (
                          ''
                        )}
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button type="button" disabled={loading} onClick={handleAdd}>
              {loading ? 'Salvando...' : 'Salvar'}
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Create;
