import React, { useState, useEffect } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import Modal from '~/components/Modal';
import api from '~/services/api';
import { FaTimes } from 'react-icons/fa';
import Button from '~/styles/components/Button';

import { Container } from './styles';

interface IBrand {
  image: File | null;
  name: string;
  preview: string;
  active: boolean;
  index: string;
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Detail: React.FC<IProps> = ({ match }) => {
  const [values, setValues] = useState<IBrand>({
    image: null,
    name: '',
    preview: '',
    active: false,
    index: '',
  });
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    async function loadStore() {
      try {
        // const { data } = await api.get(`/martketplaces/${match.params.id}`);
        // setValues({
        //   name: data.name,
        //   image: null,
        //   preview: data.image,
        //   active: data.active,
        //   index: String(data.index),
        // });
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadStore();
  }, [match.params.id]); // eslint-disable-line

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/martketplaces/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      {open && (
        <Modal size="big">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="m-0">Detalhe do produto</h6>
            <button
              type="button"
              onClick={() => {
                setOpen(false);
              }}
            >
              <FaTimes />
            </button>
          </div>
          <div className="d-flex align-items-center mt-3">
            <table className="small table table-striped">
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Conteúdo</th>
                  <th style={{ width: '150px' }}>Data</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Criação</td>
                  <td>-</td>
                  <td>28/02/2022 20:20:50</td>
                </tr>
                <tr>
                  <td>Edição</td>
                  <td>
                    Preço: R$ 100,00
                    <br />
                    Estoque: 1
                  </td>
                  <td>01/03/2022 12:20:50</td>
                </tr>
                <tr>
                  <td>Edição</td>
                  <td>
                    Preço: R$ 120,00
                    <br />
                    Estoque: 4
                  </td>
                  <td>02/03/2022 08:20:50</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal>
      )}
      <div className="block_white">
        <div className="header_block">
          <h3>Nome do marketplace</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row mb-3">
                <h5 className="ml-4 mr-4 mb-0 mt-2">Produtos</h5>
                <table className="ml-4 mr-4 mb-2 mt-2 small table table-striped">
                  <thead>
                    <tr>
                      <th>Imagem</th>
                      <th>SKU</th>
                      <th>Nome</th>
                      <th style={{ width: '120px' }}>Eventos</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <img
                          src="https://api.lojasimperio.com.br/product/magento/image/RE034K-0"
                          width="50px"
                          alt=""
                        />
                      </td>
                      <td>AC272K</td>
                      <td>
                        Combo Office - Gaveteiro 03 Gavetas Com Rodízios Branco
                        Fosco e Luminária LED Multilaser USB 3 Níveis De Luz
                        Preta
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-info btn-sm"
                          onClick={() => {
                            setOpen(true);
                          }}
                        >
                          Ver eventos
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default Detail;
