import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './Route';

import Main from '~/pages/Main';
import SignUp from '~/pages/Auth/SignUp';
import SignIn from '~/pages/Auth/SignIn';
import ForgotPassword from '~/pages/Auth/ForgotPassword';
import Reset from '~/pages/Auth/Reset';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/signup" component={SignUp} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset" component={Reset} />
        <Route path="/" component={Main} isPrivate />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
