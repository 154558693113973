import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import useSelector from '~/store/useSelector';

type Props = { component: React.FC; isPrivate?: boolean } & RouteProps;

const RouteWrapper: React.FC<Props> = ({
  component: Component,
  isPrivate = false,
  ...rest
}) => {
  const { signedIn } = useSelector(state => state.auth);

  if (!signedIn && isPrivate) {
    window.location.href = '/signin';
  }
  if (signedIn && !isPrivate) {
    window.location.href = '/';
  }

  return (
    <Route {...rest} render={(props: RouteProps) => <Component {...props} />} />
  );
};

export default RouteWrapper;
