import React from 'react';

import { Container, Label, Input } from './styles';

interface IProps {
  label: string;
  name: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (e: any) => void;
}

const InputElement: React.FC<IProps> = ({
  label,
  name,
  checked,
  disabled,
  onChange,
}) => {
  return (
    <Container htmlFor={name}>
      <Input
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled || false}
      />
      <Label>{label}</Label>
    </Container>
  );
};

export default InputElement;
