import { format, subHours } from 'date-fns';
import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Can from '~/components/Can';
import { TableComponent } from '~/components/TableV2';
import api from '~/services/api';
import Button from '~/styles/components/Button';

// import { Container } from './styles';

const AppTerms: React.FC = () => {
  const history = useHistory();

  return (
    <div>
      <Can checkPermission="criar-termos-e-condicoes">
        <div className="d-flex justify-content-end">
          <Button
            type="button"
            onClick={() => history.push('/app-terms/register')}
            className="mb-2"
          >
            Novo Termo
          </Button>
        </div>
      </Can>

      <Can checkPermission="atualizar-termos-e-condicoes">
        {(can: boolean) => (
          <TableComponent
            filters={[
              {
                key: 'active',
                title: 'Ativo',
                type: 'boolean',
              },
            ]}
            labels={[
              {
                key: 'title',
                title: 'Título',
              },
              {
                key: 'active',
                title: 'Ativo',
                formater: active => {
                  return active ? (
                    <FaCheckCircle className="text-success" />
                  ) : (
                    <FaTimesCircle className="text-danger" />
                  );
                },
              },
              {
                key: 'created_at',
                title: 'Data de inclusão',
                formater: date => {
                  return date
                    ? format(subHours(new Date(date), 3), 'dd/MM/yyyy HH:mm')
                    : '';
                },
              },
              {
                key: 'updated_at',
                title: 'Última atualização',
                formater: date => {
                  return date
                    ? format(subHours(new Date(date), 3), 'dd/MM/yyyy HH:mm')
                    : '';
                },
              },
            ]}
            getData={async params => {
              const res = await api.get('/app-terms', { params });
              return res;
            }}
            additionalActions={item => {
              return (
                <div>
                  {can ? (
                    <button
                      type="button"
                      onClick={() =>
                        history.push(`/app-terms/register/${item.id}`)
                      }
                      className="btn btn-primary btn-sm"
                    >
                      Editar <AiOutlineArrowRight />
                    </button>
                  ) : null}
                </div>
              );
            }}
          />
        )}
      </Can>
    </div>
  );
};

export default AppTerms;
