import { format, parseISO, subHours } from 'date-fns';
import React, { useEffect, useState } from 'react';
import {
  FaBox,
  FaCheckCircle,
  FaFilePdf,
  FaReceipt,
  FaShieldAlt,
  FaShippingFast,
  FaStore,
  FaTicketAlt,
  FaTimesCircle,
  FaUser,
  FaUserTie,
  FaWallet,
} from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import Input, { IChange } from '~/components/Input';
import api from '~/services/api';
import Button from '~/styles/components/Button';
import {
  normalizeCep,
  normalizeCpf,
  normalizeCurrency,
  normalizePhone,
} from '~/utils/normalize';

import Can from '~/components/Can';
import Checkbox from '~/components/Checkbox';
import Modal from '~/components/Modal';
import OrderStatusIndicator from '~/components/OrderStatus/OrderStatus';
import { states } from '~/utils/states';
import { Container } from './styles';
import ChangeProductModal from '~/components/ChangeProductModal';

interface IOrderChilds {
  id: number;
  marketplaceId: number;
}
interface ICustomer {
  id: number;
  name: string;
  lastname: string;
  cpf: string;
  email: string;
  phone: string;
  newsletter: boolean;
}

interface IOrderStatus {
  id: number;
  name: string;
}

export interface IProduct {
  id: number;
  name: string;
  marketplace_id: number;
  marketplace: {
    id: number;
    integration_id: number;
    name: string;
  };
  photos: any[];
  price: number;
  sku: string;
}

export interface IOrderProduct {
  id: number;
  order_id: number;
  price: number;
  product_id: number;
  qtd: number;
  sku: string;
  product: IProduct;
  productVariation?: any;
  fee_value: number;
  livelo_points: number;
  discount: number;
  discount_form_payment: number;
}

interface IService {
  name: string;
}

export interface IOrderService {
  price: number;
  service_id: number;
  service_sku: string;
  service: IService;
  product_sku: string;
}

interface IStore {
  name: string;
  code: string;
  fullAddress: string;
}

interface IOrderDelivery {
  days: number;
  delivery_type: string;
  price: number;
  store_code: string;
  store_id: number;
  store?: IStore;
}

interface IOrderDeliveryAddress {
  city: string;
  district: string;
  state: string;
  street: string;
  streetComplement: string;
  streetNumber: string;
  zipCode: string;
}

interface IOrderBillingAddress {
  city: string;
  district: string;
  state: string;
  street: string;
  streetComplement: string;
  streetNumber: string;
  zipCode: string;
}

interface IOrderPaymentPixRefund {
  id: string;
  value: number;
  return_id: string;
  created_at: string;
}
interface IOrderPayment {
  id: number;
  created_at: string;
  expire_at: string;
  form_payment_id: number;
  installments: number;
  marketplace: { id: number };
  orderPaymentBoleto?: {
    response: string;
  };
  orderPaymentCard: {
    holderName: string;
    end: string;
    type: 'paid' | 'refund' | 'pre-approval';
    imperio_card: 0 | 1 | 2;
    integrator: string;
    nsu: string;
    authorizationCode: string;
    responseCapture?: string;
    responseRefund?: string;
  } | null;
  orderPaymentPix: {
    id: number;
    tx_id: string;
    value: number;
    is_promoter: 1 | 0;
    e2e_id: string | null;
    qr_code_text: string;
    orderPaymentPixRefund?: IOrderPaymentPixRefund;
  } | null;
  order_id: number;
  status: string;
  total: number;
  type: string;
  updated_at: string;
}

interface IOrder {
  id: number;
  code?: number;
  customer: ICustomer;
  percentIntegrations?: any[];
  // customer_id: null;
  // seller_id: null;
  // order_status_id: null;
  code_coupon?: string;
  subtotal: number;
  fees: number;
  service: number;
  discount: number;
  discount_form_payment?: number;
  frete: number;
  total: number;
  orderStatus: IOrderStatus;
  // customer: null;
  seller?: {
    code: string;
    name: string;
  };
  form_payment: string;
  // orderPercent: null;
  orderProducts: IOrderProduct[];
  orderServices: IOrderService[];
  orderBillingAddress: IOrderBillingAddress;
  orderDeliveryAddress: IOrderDeliveryAddress;
  orderDelivery: IOrderDelivery;
  orderPayment: IOrderPayment[];
  created_at: string;
  finished_at: string;
  loading: boolean;
  orderChilds?: IOrderChilds[];
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const ModalAddSeller = ({
  onHide,
  onSave,
  orderId,
}: {
  onHide: () => void;
  onSave: () => void;
  orderId: number;
}) => {
  const [loading, setLoading] = useState(false);
  const [sellerCode, setSellerCode] = useState('');

  async function handleSave() {
    if (!sellerCode) {
      toastr.error('Erro', 'Informe o código do vendedor');
      return;
    }

    setLoading(true);

    try {
      await api.post('/admin/sellers', {
        code: sellerCode,
        order_id: orderId,
      });
    } catch (error: any) {
      if (error && error.data && error.data.message) {
        toastr.error('Erro', error.data.message);
      } else {
        toastr.error('Erro', 'Não foi possível adicionar o vendedor');
      }
      setLoading(false);
      return;
    }

    onHide();
    onSave();
    setLoading(false);
  }

  if (loading) {
    return (
      <Modal>
        <div className="d-flex justify-content-center">
          <div className="spinner spinner-border" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal>
      <h3>Adicionar Vendedor</h3>

      <div className="mt-3">
        <Input
          label="Código do vendedor"
          name="sellerCode"
          onChange={(e: IChange) => setSellerCode(e.value as string)}
        />
      </div>

      <Button onClick={handleSave} className="mr-2">
        Adicionar
      </Button>
      <Button onClick={onHide} color="gray">
        Cancelar
      </Button>
    </Modal>
  );
};

const ModalEditOrderAddress = ({
  onHide,
  onSave,
  order,
  setOrder,
}: {
  onHide: () => void;
  onSave: () => void;
  order: IOrder;
  setOrder: (_order: IOrder) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    city: order.orderDeliveryAddress.city || '',
    district: order.orderDeliveryAddress.district || '',
    state: order.orderDeliveryAddress.state || '',
    street: order.orderDeliveryAddress.street || '',
    streetComplement: order.orderDeliveryAddress.streetComplement || '',
    streetNumber: order.orderDeliveryAddress.streetNumber || '',
    zipCode: order.orderDeliveryAddress.zipCode || '',
  });
  const [
    shouldUpdateCustomerAddress,
    setShouldUpdateCustomerAddress,
  ] = useState(false);

  function validateAddress() {
    const errors = [];

    if (!values.street) {
      errors.push('Informe a rua');
    }

    if (!values.streetNumber) {
      errors.push('Informe o número');
    }

    if (!values.district) {
      errors.push('Informe o bairro');
    }

    if (!values.city) {
      errors.push('Informe a cidade');
    }

    if (!values.state) {
      errors.push('Informe o estado');
    }

    if (!values.zipCode) {
      errors.push('Informe o CEP');
    }

    if (errors.length > 0) {
      toastr.error('Erro', errors.join(', '));
      return false;
    }

    return true;
  }

  async function handleSave() {
    setLoading(true);

    const isValid = validateAddress();

    if (!isValid) {
      setLoading(false);
      return;
    }

    try {
      await api.put(`/admin/orders/${order.id}/address`, {
        ...values,
        shouldUpdateCustomerAddress,
      });
    } catch (error: any) {
      if (error && error.data && error.data.message) {
        toastr.error('Erro', error.data.message);
      } else {
        toastr.error('Erro', 'Não foi possível editar o endereço');
      }
      setLoading(false);
      return;
    }

    setOrder({
      ...order,
      orderDeliveryAddress: {
        ...values,
      },
    });

    onHide();
    onSave();
    setLoading(false);
  }

  function handleChange(e: IChange) {
    setValues({
      ...values,
      [e.name]: String(e.value),
    });
  }

  if (loading) {
    return (
      <Modal>
        <div className="d-flex justify-content-center">
          <div className="spinner spinner-border" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal size="xl">
      <h3>Editar Endereço do Pedido</h3>

      <div className="mt-3">
        <div className="row">
          <div className="col-md-6">
            <Input
              label="Rua"
              name="street"
              onChange={handleChange}
              disabled={loading}
              initialValue={values.street}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Número"
              name="streetNumber"
              onChange={handleChange}
              disabled={loading}
              initialValue={values.streetNumber}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Input
              label="Complemento"
              name="streetComplement"
              onChange={handleChange}
              disabled={loading}
              initialValue={values.streetComplement}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Input
              label="Bairro"
              name="district"
              onChange={handleChange}
              disabled={loading}
              initialValue={values.district}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Cidade"
              name="city"
              onChange={handleChange}
              disabled={loading}
              initialValue={values.city}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Input
              label="Estado"
              name="state"
              onChange={handleChange}
              disabled={loading}
              select
              options={states.map(state => ({
                title: state.desc,
                value: state.uf,
              }))}
              initialValue={values.state}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="CEP"
              name="zipCode"
              onChange={handleChange}
              disabled={loading}
              initialValue={values.zipCode}
              normalize={normalizeCep}
            />
          </div>
        </div>

        <div className="mt-2">
          <Checkbox
            name="shouldUpdateCustomerAddress"
            label="Atualizar Endereço do Cliente"
            checked={shouldUpdateCustomerAddress}
            onChange={(e: any) => {
              setShouldUpdateCustomerAddress(e.target.checked);
            }}
          />
        </div>

        <div className="mt-3">
          <Button onClick={handleSave} className="mr-2">
            Salvar
          </Button>
          <Button onClick={onHide} color="gray">
            Cancelar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const View: React.FC<IProps> = ({ match }) => {
  const [order, setOrder] = useState<IOrder>({
    id: 0,
    code: 0,
    customer: {
      id: 0,
      name: '',
      lastname: '',
      cpf: '',
      email: '',
      phone: '',
      newsletter: false,
    },
    orderBillingAddress: {
      city: '',
      district: '',
      state: '',
      street: '',
      streetComplement: '',
      streetNumber: '',
      zipCode: '',
    },
    orderDeliveryAddress: {
      city: '',
      district: '',
      state: '',
      street: '',
      streetComplement: '',
      streetNumber: '',
      zipCode: '',
    },
    orderPayment: [],
    orderDelivery: {
      days: 0,
      delivery_type: '',
      price: 0,
      store_code: '',
      store_id: 0,
    },
    orderProducts: [],
    orderServices: [],
    service: 0,
    subtotal: 0,
    fees: 0,
    discount: 0,
    discount_form_payment: 0,
    frete: 0,
    total: 0,
    orderStatus: {
      id: 0,
      name: '',
    },
    created_at: '',
    finished_at: '',
    loading: false,
    form_payment: '',
  });

  const [loading, setLoading] = useState(true);
  const [messageRefund, setMessageRefund] = useState('');
  const [linkBoleto, setLinkBoleto] = useState('');

  const [pixRefunds, setPixRefunds] = useState<IOrderPaymentPixRefund[]>([]);
  const history = useHistory();
  const [viewAddSellerModal, setViewAddSellerModal] = useState({
    open: false,
  });
  const [viewEditOrderAddressModal, setViewEditOrderAddressModal] = useState({
    open: false,
  });
  const [modalChangeProduct, setModalChangeProduct] = useState({
    visible: false,
    orderProduct: {} as IOrderProduct,
    orderService: {} as IOrderService,
  });

  async function loadPage() {
    try {
      const { data } = await api.get(`admin/orders/${match.params.id}`);
      setOrder({
        ...order,
        id: data.id,
        code: data.code,
        discount: data.discount,
        discount_form_payment: data.discount_form_payment,
        frete: data.frete,
        fees: data.fees,
        subtotal: data.subtotal,
        service: data.service,
        total: data.total,
        finished_at: data.finished_at,
        created_at: data.created_at,
        customer: data.customer,
        orderPayment: data.orderPayment,
        orderBillingAddress: data.orderBillingAddress,
        orderDeliveryAddress: data.orderDeliveryAddress,
        orderDelivery: data.orderDelivery,
        orderProducts: data.orderProducts,
        orderServices: data.orderServices,
        orderStatus: data.orderStatus,
        seller: data.seller,
        code_coupon: data.code_coupon,
        form_payment: data.form_payment,
        orderChilds: data.orderChilds,
        percentIntegrations: data.percentIntegrations,
      });

      if (data.orderPayment.length > 0) {
        if (data.orderPayment[0].orderPaymentBoleto) {
          const responseBoleto = JSON.parse(
            data.orderPayment[0].orderPaymentBoleto.response,
          );
          setLinkBoleto(responseBoleto.formats.pdf);
        }

        for (let i = 0; i < data.orderPayment.length; i += 1) {
          const payment = data.orderPayment[i];

          if (payment.orderPaymentPix) {
            if (payment.orderPaymentPix.orderPaymentPixRefund) {
              setPixRefunds([
                ...pixRefunds,
                payment.orderPaymentPix.orderPaymentPixRefund,
              ]);
            }
          }
        }
      }

      if (data.orderStatus.id === 5) {
        const { data: dataClearSale } = await api.get(
          `/clearsale/return/status/${match.params.id}`,
        );
        setMessageRefund(dataClearSale.message);
      }

      setLoading(false);
    } catch (err) {
      toastr.error(
        'Ocorreu um erro',
        'Durante a busca nossos servidores não puderam responder',
      );
    }
  }

  useEffect(() => {
    loadPage();
  }, [match.params.id]); // eslint-disable-line

  async function handleCancel() {
    setLoading(true);
    try {
      await api.put(`/admin/orders/cancel/${match.params.id}`);
      toastr.success(
        'Sucesso',
        'Pedido cancelado, pagamento deve ser estornado manualmente',
      );
    } catch (err) {
      if ((err as any)?.response?.data?.message) {
        toastr.error('Erro', (err as any)?.response?.data?.message);
      } else {
        toastr.error('Ocorreu um erro inesperado', (err as any)?.message);
      }
    }
    setLoading(false);
  }

  async function handleRefund() {
    setLoading(true);
    try {
      await api.post(`/orders/${match.params.id}/credit/refund`);
      toastr.success('Sucesso', 'Pedido cancelado, pagamentos estornados');
    } catch (err) {
      if ((err as any)?.response?.data.message) {
        toastr.error(
          (err as any)?.response?.data?.message,
          (err as any)?.response?.data?.detail?.join('\n') || ' ',
        );
      } else {
        toastr.error(
          'Ocorreu um erro inesperado',
          (err as any)?.message || ' ',
        );
      }
    }
    setLoading(false);
  }

  async function handleReintegrate() {
    setLoading(true);
    try {
      await api.put(`/admin/orders/integrate/${match.params.id}`);
      toastr.success('Sucesso', 'Pedido reintegrado');
    } catch (err) {
      if ((err as any)?.response?.data?.message) {
        toastr.error('Erro', (err as any)?.response?.data?.message);
      } else {
        toastr.error('Ocorreu um erro inesperado', (err as any)?.message);
      }
    }
    setLoading(false);
  }

  function switchTitleStatus(type: 'paid' | 'refund' | 'pre-approval') {
    switch (type) {
      case 'paid':
        return (
          <div
            className="badge badge-success mr-2"
            style={{
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            }}
          >
            DEBITADO
          </div>
        );
      case 'refund':
        return (
          <div
            className="badge badge-warning mr-2"
            style={{
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            }}
          >
            ESTORNADO
          </div>
        );
      case 'pre-approval':
        return (
          <div
            className="badge badge-primary mr-2"
            style={{
              borderTopLeftRadius: '0px',
              borderBottomLeftRadius: '0px',
            }}
          >
            PRÉ-AUTORIZADO
          </div>
        );
      default:
        return '';
    }
  }

  return (
    <Container>
      {modalChangeProduct.visible ? (
        <ChangeProductModal
          orderProduct={modalChangeProduct.orderProduct}
          orderService={modalChangeProduct.orderService}
          onHide={() => {
            setModalChangeProduct({
              visible: false,
              orderProduct: {} as IOrderProduct,
              orderService: {} as IOrderService,
            });
            loadPage();
          }}
        />
      ) : null}
      {viewEditOrderAddressModal.open ? (
        <ModalEditOrderAddress
          onHide={() =>
            setViewEditOrderAddressModal({
              open: false,
            })
          }
          onSave={() => {
            toastr.success('Sucesso', 'Endereço alterado');
          }}
          order={order}
          setOrder={setOrder}
        />
      ) : null}
      {viewAddSellerModal.open ? (
        <ModalAddSeller
          onHide={() =>
            setViewAddSellerModal({
              open: false,
            })
          }
          onSave={() => {
            toastr.success('Sucesso', 'Vendedor adicionado');
          }}
          orderId={order.id}
        />
      ) : null}
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/orders/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="bg-white p-3 radius-4 shadow">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Detalhamento do Pedido</h5>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-3">
            <small>Pedido </small>
            <h6 className="mb-1">
              <strong>
                {order.code}-
                {order.orderProducts.length > 0 &&
                order.orderProducts[0].product.marketplace_id === 1
                  ? 'IMP'
                  : 'MKT'}
                -
                {order.orderPayment.length > 0 &&
                order.orderPayment[0]?.form_payment_id === 1
                  ? 'B'
                  : 'C'}
                -{order.id}
              </strong>
            </h6>
            {OrderStatusIndicator(order.orderStatus.id)}
          </div>
          <div className="col-md-2">
            <small>Data</small>
            <h6>
              <strong>
                {order.finished_at
                  ? format(parseISO(order.finished_at), 'dd/MM/yyyy HH:mm')
                  : null}
                {!order.finished_at && order.created_at
                  ? format(parseISO(order.created_at), 'dd/MM/yyyy HH:mm')
                  : null}
              </strong>
            </h6>
          </div>
          <div className="col-md-2">
            <small>Prazo de Entrega</small>
            <h6>
              <strong>
                {order.orderDelivery?.days < 1
                  ? `3 horas`
                  : `${order.orderDelivery?.days} dia(s)`}
              </strong>
            </h6>
          </div>
          {order && order.orderDelivery && order.orderDelivery.store ? (
            <div className="col-md-2">
              <small>
                <FaStore /> Loja do Pedido
              </small>
              <h6>
                <strong>{order.orderDelivery.store.code}</strong>
              </h6>
            </div>
          ) : (
            <div className="col-md-3" />
          )}
          {order && order.seller ? (
            <div className="col-md-3">
              <small>
                <FaUserTie /> Vendedor
              </small>
              <h6 className="mb-0">
                <strong>
                  [{order.seller.code}] {order.seller.name}
                </strong>
              </h6>
              <Can checkPermission="order_seller_update">
                <button
                  type="button"
                  className="btn btn-link btn-sm mt-1 p-0"
                  onClick={() => {
                    setViewAddSellerModal({
                      open: true,
                    });
                  }}
                  disabled={loading}
                >
                  Alterar Vendedor
                </button>
              </Can>
            </div>
          ) : (
            <div className="col-md-3">
              <small>
                <FaUserTie /> Vendedor
              </small>
              <h6 className="mb-0">
                <strong>SEM VENDEDOR</strong>
              </h6>
              <Can checkPermission="order_seller_update">
                <button
                  type="button"
                  className="btn btn-link btn-sm mt-1 p-0"
                  onClick={() => {
                    setViewAddSellerModal({
                      open: true,
                    });
                  }}
                  disabled={loading}
                >
                  Adicionar Vendedor
                </button>
              </Can>
            </div>
          )}
          {linkBoleto ? (
            <div className="col-md-12 mt-3">
              <a href={linkBoleto} target="blank">
                <FaFilePdf /> Segunda via do boleto
              </a>
            </div>
          ) : null}
          {messageRefund ? (
            <div className="col-md-12 mt-3">
              <div className="alert alert-danger p-2">
                <i>{messageRefund}</i>
              </div>
            </div>
          ) : null}
          <div className="col-md-4 mt-3">
            <div
              className="border-1 border-muted p-3 radius-4"
              style={{ minHeight: '100%' }}
            >
              <h6 className="m-0">
                <FaUser className="mr-1" />
                <strong>Dados do Cliente</strong>
              </h6>
              <div className="row">
                <div className="mt-1 col-md-6">
                  <small>Nome</small>
                  <h6>
                    <strong>
                      {order.customer?.name} {order.customer?.lastname}
                    </strong>
                  </h6>
                </div>
                <div className="mt-1 col-md-6">
                  <small>CPF</small>
                  <h6>
                    <strong>{normalizeCpf(order.customer?.cpf)}</strong>
                  </h6>
                </div>
                <div className="mt-1 col-md-12">
                  <small>E-mail</small>
                  <h6>
                    <strong>{order.customer?.email}</strong>
                  </h6>
                </div>
                <div className="mt-1 col-md-12">
                  <small>Telefone</small>
                  <h6>
                    <strong>{normalizePhone(order.customer?.phone)}</strong>
                  </h6>
                </div>
                <div className="mt-1 col-md-12">
                  <small>Endereço</small>
                  <h6>
                    <strong>
                      {order.orderDeliveryAddress
                        ? `${order.orderDeliveryAddress.street}, ${
                            order.orderDeliveryAddress.streetNumber
                          }${
                            order.orderDeliveryAddress.streetComplement
                              ? `, ${order.orderDeliveryAddress.streetComplement}`
                              : ''
                          } - ${order.orderDeliveryAddress.district} - ${
                            order.orderDeliveryAddress.zipCode
                          } | ${order.orderDeliveryAddress.city} - ${
                            order.orderDeliveryAddress.state
                          }`
                        : 'Não informado'}
                    </strong>
                  </h6>
                  <Can checkPermission="atualizar_endereco_pedido">
                    <button
                      type="button"
                      className="btn btn-link btn-sm mt-1 p-0"
                      onClick={() => {
                        setViewEditOrderAddressModal({
                          open: true,
                        });
                      }}
                      disabled={loading}
                    >
                      Alterar Endereço
                    </button>
                  </Can>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 mt-3">
            <div className="border-1 border-muted p-3 radius-4">
              <h6 className="mb-2">
                <FaWallet className="mr-1" />
                <strong>Dados de Pagamento</strong>
              </h6>
              <div className="mb-0 mt-1">
                {order.form_payment === 'two_cards' &&
                  order.orderPayment.length > 1 && (
                    <div>
                      <h6>
                        <strong>2 Cartões de Crédito</strong>
                      </h6>
                      <div className="mt-1">
                        <div>
                          <div className="d-flex align-items-center">
                            <strong>Cartão de Crédito 1</strong>
                            {order.orderPayment[0]?.orderPaymentCard ? (
                              <span
                                className="badge badge-secondary ml-2"
                                style={{
                                  borderTopRightRadius: '0px',
                                  borderBottomRightRadius: '0px',
                                }}
                              >
                                {order.orderPayment[0]?.orderPaymentCard?.integrator?.toUpperCase()}
                              </span>
                            ) : null}
                            {order.orderPayment[0]?.orderPaymentCard
                              ? switchTitleStatus(
                                  order.orderPayment[0]?.orderPaymentCard?.type,
                                )
                              : null}
                          </div>
                          {order.orderPayment[0]?.orderPaymentCard
                            ?.imperio_card ? (
                            <img
                              src="/favicon.png"
                              alt="Cartão Lojas Império"
                              width={20}
                            />
                          ) : null}
                          {`**** **** **** ${
                            order.orderPayment[0]?.orderPaymentCard?.end
                          } (${order.orderPayment[0]?.installments}x) | ${
                            order.orderPayment[0]?.orderPaymentCard?.holderName
                          } | R$ ${normalizeCurrency(
                            String(order.orderPayment[0]?.total),
                          )}`}
                          <div className="d-flex align-items-center mt-2">
                            <span className="border-1 border-muted">
                              <strong>
                                NSU:{' '}
                                {order.orderPayment[0]?.orderPaymentCard?.nsu ||
                                  'SEM INFORMAÇÃO'}
                              </strong>
                            </span>
                            <span className="border-1 border-muted ml-2">
                              <strong>
                                Autorização:{' '}
                                {order.orderPayment[0]?.orderPaymentCard
                                  ?.authorizationCode || 'SEM INFORMAÇÃO'}
                              </strong>
                            </span>
                          </div>
                        </div>
                        {order.orderStatus.id === 6 && (
                          <div className="d-flex align-items-cente mt-2">
                            <button
                              className="btn btn-sm btn-outline-danger ml-2"
                              type="button"
                              onClick={() => {
                                alert(
                                  order.orderPayment[0]?.orderPaymentCard
                                    ?.responseCapture,
                                );
                              }}
                            >
                              Ver resposta de captura
                            </button>
                            <button
                              className="btn btn-sm btn-outline-danger ml-2"
                              type="button"
                              onClick={() => {
                                alert(
                                  order.orderPayment[0]?.orderPaymentCard
                                    ?.responseRefund,
                                );
                              }}
                            >
                              Ver resposta de estorno
                            </button>
                          </div>
                        )}
                        <hr />
                        <div>
                          <div className="d-flex align-items-center">
                            <strong>Cartão de Crédito 2</strong>
                            {order.orderPayment[1]?.orderPaymentCard ? (
                              <span
                                className="badge badge-secondary ml-2"
                                style={{
                                  borderTopRightRadius: '0px',
                                  borderBottomRightRadius: '0px',
                                }}
                              >
                                {order.orderPayment[1]?.orderPaymentCard?.integrator?.toUpperCase()}
                              </span>
                            ) : null}
                            {order.orderPayment[1]?.orderPaymentCard
                              ? switchTitleStatus(
                                  order.orderPayment[1]?.orderPaymentCard?.type,
                                )
                              : null}
                          </div>
                          {order.orderPayment[1]?.orderPaymentCard
                            ?.imperio_card ? (
                            <img
                              src="/favicon.png"
                              alt="Cartão Lojas Império"
                              width={20}
                            />
                          ) : null}
                          {`**** **** **** ${
                            order.orderPayment[1]?.orderPaymentCard?.end
                          } (${order.orderPayment[1]?.installments}x) | ${
                            order.orderPayment[1]?.orderPaymentCard?.holderName
                          } | R$ ${normalizeCurrency(
                            String(order.orderPayment[1]?.total),
                          )}`}
                          <div className="d-flex align-items-center mt-2">
                            <span className="border-1 border-muted">
                              <strong>
                                NSU:{' '}
                                {order.orderPayment[1]?.orderPaymentCard?.nsu ||
                                  'SEM INFORMAÇÃO'}
                              </strong>
                            </span>
                            <span className="border-1 border-muted ml-2">
                              <strong>
                                Autorização:{' '}
                                {order.orderPayment[1]?.orderPaymentCard
                                  ?.authorizationCode || 'SEM INFORMAÇÃO'}
                              </strong>
                            </span>
                          </div>
                        </div>
                      </div>
                      {order.orderStatus.id === 6 && (
                        <div className="d-flex align-items-cente mt-2">
                          <button
                            className="btn btn-sm btn-outline-danger ml-2"
                            type="button"
                            onClick={() => {
                              alert(
                                order.orderPayment[1]?.orderPaymentCard
                                  ?.responseCapture,
                              );
                            }}
                          >
                            Ver resposta de captura
                          </button>
                          <button
                            className="btn btn-sm btn-outline-danger ml-2"
                            type="button"
                            onClick={() => {
                              alert(
                                order.orderPayment[1]?.orderPaymentCard
                                  ?.responseRefund,
                              );
                            }}
                          >
                            Ver resposta de estorno
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                {order.orderPayment.length > 0 &&
                  order.orderPayment[0]?.form_payment_id === 1 && (
                    <h6>
                      <strong>Boleto Bancário</strong>
                    </h6>
                  )}
                {order.orderPayment.length > 0 &&
                  order.orderPayment[0]?.form_payment_id === 2 && (
                    <>
                      <h6>
                        <strong>Cartão de Crédito</strong>
                      </h6>
                      {order.orderPayment[0]?.orderPaymentCard ? (
                        <span
                          className="badge badge-secondary"
                          style={{
                            borderTopRightRadius: '0px',
                            borderBottomRightRadius: '0px',
                          }}
                        >
                          {order.orderPayment[0]?.orderPaymentCard?.integrator?.toUpperCase()}
                        </span>
                      ) : null}
                      {order.orderPayment[0]?.orderPaymentCard
                        ? switchTitleStatus(
                            order.orderPayment[0]?.orderPaymentCard?.type,
                          )
                        : null}
                      {order.orderPayment[0]?.orderPaymentCard?.imperio_card ? (
                        <img
                          src="/favicon.png"
                          alt="Cartão Lojas Império"
                          width={20}
                          title="Cartão Lojas Império"
                          className="mr-2"
                        />
                      ) : null}
                      {order.orderPayment[0]?.orderPaymentCard?.end
                        ? `**** **** **** ${order.orderPayment[0]?.orderPaymentCard?.end}`
                        : ''}{' '}
                      ({order.orderPayment[0]?.installments}x){' '}
                      {order.orderPayment[0]?.orderPaymentCard?.holderName
                        ? `|  ${order.orderPayment[0]?.orderPaymentCard?.holderName}`
                        : ''}
                      <div className="d-flex align-items-center mt-2">
                        <span className="border-1 border-muted">
                          <strong>
                            NSU:{' '}
                            {order.orderPayment[0]?.orderPaymentCard?.nsu ||
                              'SEM INFORMAÇÃO'}
                          </strong>
                        </span>
                        <span className="border-1 border-muted ml-2">
                          <strong>
                            Autorização:{' '}
                            {order.orderPayment[0]?.orderPaymentCard
                              ?.authorizationCode || 'SEM INFORMAÇÃO'}
                          </strong>
                        </span>
                      </div>
                      {order.orderStatus.id === 6 && (
                        <div className="d-flex align-items-cente mt-2">
                          <button
                            className="btn btn-sm btn-outline-danger ml-2"
                            type="button"
                            onClick={() => {
                              alert(
                                order.orderPayment[0]?.orderPaymentCard
                                  ?.responseCapture,
                              );
                            }}
                          >
                            Ver resposta de captura
                          </button>
                          <button
                            className="btn btn-sm btn-outline-danger ml-2"
                            type="button"
                            onClick={() => {
                              alert(
                                order.orderPayment[0]?.orderPaymentCard
                                  ?.responseRefund,
                              );
                            }}
                          >
                            Ver resposta de estorno
                          </button>
                        </div>
                      )}
                    </>
                  )}
                {order.orderPayment.length > 0 &&
                  order.orderPayment[0]?.form_payment_id === 5 && (
                    <>
                      <h6>
                        <strong>PIX</strong>
                      </h6>
                      {order.orderPayment.map(payment => (
                        <div
                          className="d-flex flex-column justify-content-start mt-2 align-items-start pt-2 pb-2 pl-3"
                          style={{ borderLeft: '2px solid #c9c9c9' }}
                          key={payment.id}
                        >
                          <strong className="mb-2">
                            {payment?.orderPaymentPix?.is_promoter === 1
                              ? 'QRCode Promotora'
                              : 'QRCode Império'}
                          </strong>
                          <span className="p-0 m-0">
                            <strong className="mr-2">tx_id:</strong>{' '}
                            {payment?.orderPaymentPix?.tx_id}
                          </span>
                          <span className="p-0 m-0 mt-1">
                            <strong className="mr-2">e2e:</strong>{' '}
                            {payment?.orderPaymentPix?.e2e_id}
                          </span>
                          <span className="p-0 m-0 mt-1">
                            <strong className="mr-2">Valor:</strong> R${' '}
                            {normalizeCurrency(
                              String(payment?.orderPaymentPix?.value) || '000',
                            )}
                          </span>
                          {!payment?.orderPaymentPix?.e2e_id &&
                            payment?.orderPaymentPix?.qr_code_text && (
                              <span className="p-0 m-0 mt-1">
                                <button
                                  type="button"
                                  className="text-primary"
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      payment?.orderPaymentPix?.qr_code_text ||
                                        '',
                                    );
                                    toastr.info('Aviso', 'Código Copiado');
                                  }}
                                >
                                  <strong>Copiar código copia e cola</strong>
                                </button>
                              </span>
                            )}
                        </div>
                      ))}
                      {pixRefunds.length > 0 && (
                        <div className="mt-2 p-2 bg-gray radius-4">
                          <strong>Devoluções</strong>
                          {pixRefunds.map(refund => (
                            <div
                              className="d-flex flex-column align-items-start justify-content-start"
                              key={refund.id}
                            >
                              <span className="p-0 m-0 mt-2">
                                <strong className="mr-2">id:</strong>{' '}
                                {refund.id}
                              </span>
                              <span className="p-0 m-0 mt-1">
                                <strong className="mr-2">return_id:</strong>{' '}
                                {refund.return_id}
                              </span>
                              <span className="p-0 m-0 mt-1">
                                <strong className="mr-2">Valor:</strong> R${' '}
                                {normalizeCurrency(
                                  String(refund.value) || '000',
                                )}
                              </span>

                              <span className="p-0 m-0 mt-1">
                                <strong className="mr-2">
                                  Data da devolução:
                                </strong>{' '}
                                {format(
                                  parseISO(refund.created_at),
                                  'dd/MM/yyyy HH:mm',
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    </>
                  )}
              </div>
            </div>
            <div className="border-1 border-muted p-3 radius-4 mt-3">
              <h6 className="mb-0">
                <FaShippingFast className="mr-1" />
                <strong>Dados de Entrega</strong>
              </h6>
              <div className="mb-0 mt-1">
                <small>
                  [{order?.orderDelivery?.store?.code}]{' '}
                  {order?.orderDelivery?.store?.name}
                </small>
                <h6 className="mb-1">
                  <strong>
                    {order.orderDelivery?.delivery_type === 'cliqueeretire'
                      ? 'Retirar na loja'
                      : 'Receber em casa'}{' '}
                    {order.orderDelivery?.days < 1
                      ? `a partir de 3 horas`
                      : `em até ${order.orderDelivery?.days} dia(s)`}
                  </strong>
                </h6>
                {order?.orderDelivery?.store?.fullAddress ? (
                  <div>
                    <small>Endereço</small>
                    <h6 className="m-0">
                      <strong>{order.orderDelivery.store.fullAddress}</strong>
                    </h6>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-3 mt-3">
            <div className="p-3 radius-4 border-1 border-muted">
              <h6>
                <strong>Totais</strong>
              </h6>
              <div className="d-flex justify-content-between align-items-center">
                <small>Subtotal</small>
                <small>
                  R$ {normalizeCurrency(String(order.subtotal - order.fees))}
                </small>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <small>Juros</small>
                <small>R$ {normalizeCurrency(String(order.fees))}</small>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <small>Serviços</small>
                <small>R$ {normalizeCurrency(String(order.service))}</small>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <small>Frete</small>
                <small>R$ {normalizeCurrency(String(order.frete))}</small>
              </div>
              <div className="d-flex justify-content-between align-items-center text-danger">
                <small>Desconto</small>
                <small>
                  -R${' '}
                  {normalizeCurrency(
                    String(
                      (order.discount || 0) +
                        (order.discount_form_payment || 0),
                    ),
                  )}
                </small>
              </div>
              {order.code_coupon ? (
                <div className="d-flex justify-content-end">
                  <div className="badge badge-light border-1 border-muted mt-1">
                    <FaTicketAlt /> <strong>Cupom: {order.code_coupon}</strong>
                  </div>
                </div>
              ) : null}
              <hr className="mt-2 mb-2" />
              <div className="d-flex justify-content-between align-items-center text-muted">
                <h6>
                  <strong>Total</strong>
                </h6>
                <h6>
                  <strong>R$ {normalizeCurrency(String(order.total))}</strong>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <hr />
          </div>
          <div className="col-md-4 mt-3">
            <div className="p-3 radius-4 border-muted border-1">
              <h6>
                <FaBox className="mr-1" />
                <strong>Produtos do Pedido</strong>
              </h6>
              <div style={{ height: '35vh', overflow: 'auto' }}>
                {order.orderProducts?.map(orderProduct => (
                  <div
                    className="border-1 border-muted mt-3"
                    key={orderProduct.product_id}
                  >
                    <div className="row m-0">
                      <div className="col-md-2 d-flex justify-content-center p-1">
                        <img
                          src={
                            orderProduct.product?.photos[0]
                              ? orderProduct.product?.photos[0]?.path
                              : ''
                          }
                          alt={orderProduct?.product?.name}
                          className="w-100"
                          style={{ maxWidth: '80px' }}
                        />
                      </div>
                      <div className="col-md-10 p-1">
                        {orderProduct?.product?.marketplace_id === 1 ? (
                          <img
                            src="/favicon.png"
                            alt="Produto Império"
                            style={{ maxWidth: '20px' }}
                          />
                        ) : null}
                        <small>
                          [{orderProduct?.sku}] {orderProduct?.product?.name}
                        </small>
                      </div>
                    </div>
                    <div className="bg-gray pl-3 pr-3 pb-2 pt-2">
                      <div className="row justify-content-around">
                        <div className="col-md-1">
                          <small>Qtd</small>
                          <h6>
                            <strong>{orderProduct?.qtd}</strong>
                          </h6>
                        </div>
                        <div className="col-md-2">
                          <small>Unidade</small>
                          <h6>
                            <strong>
                              {normalizeCurrency(
                                String(
                                  orderProduct?.price - orderProduct?.fee_value,
                                ),
                              )}
                            </strong>
                          </h6>
                        </div>
                        <div className="col-md-2">
                          <small>Total</small>
                          <h6>
                            <strong>
                              {normalizeCurrency(
                                String(
                                  (orderProduct?.price -
                                    orderProduct?.fee_value) *
                                    orderProduct?.qtd,
                                ),
                              )}
                            </strong>
                          </h6>
                        </div>
                        {orderProduct.livelo_points > 0 ? (
                          <div className="col-md-2 align-self-center">
                            <img
                              alt="Livelo"
                              title="Pontua com livelo"
                              src="https://lojasimperio.com.br/images/livelo.png"
                              style={{
                                maxWidth: '30px',
                                maxHeight: '30px',
                                marginLeft: '10px',
                              }}
                            />
                          </div>
                        ) : null}
                        <div className="col-md-3 align-self-center">
                          <Button
                            onClick={() =>
                              setModalChangeProduct({
                                visible: true,
                                orderProduct,
                                orderService:
                                  order.orderServices?.find(
                                    o => o.product_sku === orderProduct.sku,
                                  ) || ({} as IOrderService),
                              })
                            }
                          >
                            Editar
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-3">
            <div className="p-3 radius-4 border-muted border-1">
              <h6>
                <FaShieldAlt className="mr-1" />
                <strong>Serviços do Pedido</strong>
              </h6>
              <div style={{ height: '35vh', overflow: 'auto' }}>
                <table className="table table-hover w-100 m-0">
                  <thead>
                    <tr>
                      <th className="p-2">Código</th>
                      <th className="p-2">Item</th>
                      <th className="p-2">Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.orderServices?.length === 0 ? (
                      <tr>
                        <td colSpan={3}>Pedido sem serviço</td>
                      </tr>
                    ) : null}
                    {order.orderServices?.map(orderService => (
                      <tr key={orderService?.service_id}>
                        <td className="p-2">{orderService?.service_sku}</td>
                        <td className="p-2">{orderService?.service?.name}</td>
                        <td className="p-2">
                          R$ {normalizeCurrency(String(orderService?.price))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-3">
            <div className="p-3 radius-4 border-muted border-1">
              <h6>
                <FaReceipt className="mr-1" />
                <strong>Mensagens de Integração [PERCENT]</strong>
              </h6>
              <div style={{ height: '35vh', overflow: 'auto' }}>
                {order?.percentIntegrations?.length === 0 ? (
                  <span>Nenhuma integração realizada</span>
                ) : null}
                {order?.percentIntegrations?.map(integration => {
                  let message = '[NÃO INFORMADO]';
                  let success = false;
                  if (integration?.response) {
                    const r = JSON.parse(integration?.response);
                    if (r.msg) {
                      message = r.msg;
                    } else if (r.detail) {
                      message = '';
                      if (r.status) {
                        message += `[${r.status}] `;
                      }
                      if (r.title) {
                        message += r.title;
                      }
                      if (r.detail) {
                        message += `\n${r.detail}`;
                      }
                    }
                    if (r.sucess === 1) {
                      success = true;
                    }
                  }
                  return (
                    <div key={integration.id}>
                      {success ? (
                        <FaCheckCircle className="text-success mr-1" />
                      ) : (
                        <FaTimesCircle className="text-danger mr-1" />
                      )}
                      {message} -{' '}
                      {integration.created_at
                        ? format(
                            subHours(new Date(integration.created_at), 3),
                            'dd/MM/yyyy HH:mm:ss',
                          )
                        : ''}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {order &&
        !order.code &&
        order.orderChilds &&
        order?.orderChilds?.length > 0 ? (
          <div className="border-1 border-muted radius-4 p-3 mt-3">
            <small>Pedidos Gerados</small>
            <div>
              {order.orderChilds?.map(child => (
                <button
                  type="button"
                  onClick={() => {
                    history.push(`/orders/view/${child?.id}`);
                  }}
                >
                  <h5
                    style={{
                      color: '#1775FD',
                      textDecoration: 'underline',
                    }}
                  >
                    ({order.id}-{child?.marketplaceId === 1 ? 'IMP' : 'MKT'}-
                    {order?.orderPayment?.length > 0 &&
                    order?.orderPayment?.filter(
                      o => o?.marketplace?.id === child?.marketplaceId,
                    )[0]?.form_payment_id === 1
                      ? 'B'
                      : 'C'}
                    -{child?.id})
                  </h5>
                </button>
              ))}
            </div>
          </div>
        ) : null}
        <div className="d-flex justify-content-between mt-3 flex-wrap">
          {order.code && (
            <button
              type="button"
              className="m-1 text-primary pl-1 pr-1"
              onClick={() => history.push(`/orders/view/${order.code}`)}
              disabled={loading}
            >
              <strong>Ir para pedido principal</strong>
            </button>
          )}
          <div className="d-flex align-items-center flex-wrap">
            {order.code &&
              (order.orderStatus.id === 3 || order.orderStatus.id === 7) && (
                <button
                  type="button"
                  className="btn btn-danger btn-sm m-1"
                  onClick={() => {
                    const confirm = window.confirm(
                      'Você tem certeza que deseja cancelar o pedido?\nOBS: Não é realizado o estorno, apenas a mudança da situação do pedido',
                    );
                    if (confirm) {
                      handleCancel().then(() => loadPage());
                    }
                  }}
                  disabled={loading}
                >
                  Cancelar Pedido{' '}
                  <div className="badge badge-dark">Sem estorno automático</div>
                </button>
              )}
            {order.code &&
              order.orderPayment.find(
                orderPayment =>
                  orderPayment.orderPaymentCard?.integrator === 'safrapay' &&
                  orderPayment.orderPaymentCard?.type !== 'refund',
              ) && (
                <Can checkPermission="estornar_pedido">
                  <button
                    type="button"
                    className="btn btn-warning btn-sm m-1"
                    onClick={() => {
                      const confirm = window.confirm(
                        `Você tem certeza que deseja estornar o pedido?\n
O pedido terá o status atualizado para "Cancelado" e será emitido o estorno de todas as transações do pedido.\n
OBS: Em caso de nota já emitida, cancelar a nota na Percent.`,
                      );
                      if (confirm) {
                        handleRefund().then(() => loadPage());
                      }
                    }}
                    disabled={loading}
                  >
                    <strong>Estornar transação</strong>
                  </button>
                </Can>
              )}
            {order.code &&
              order.orderStatus.id >= 3 &&
              (order.percentIntegrations?.length === 0 ||
                !order.percentIntegrations?.find(i => {
                  const r = JSON.parse(i.response || '{}');
                  return r.msg === 'ok';
                })) && (
                <Can checkPermission="reenviar_pedidos">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm m-1"
                    onClick={() => {
                      const confirm = window.confirm(
                        'Você tem certeza que deseja enviar o pedido para integração?',
                      );
                      if (confirm) {
                        handleReintegrate().then(() => loadPage());
                      }
                    }}
                    disabled={loading}
                  >
                    Reenviar para Percent
                  </button>
                </Can>
              )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default View;
