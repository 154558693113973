import React, { useRef, useState, useEffect } from 'react';

import JoditEditor from 'jodit-react';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import { toastr } from 'react-redux-toastr';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container, Label } from './styles';

interface IPage {
  title: string;
  code: string;
  content: string;
  status: boolean;
  loading: boolean;
  device: 'app' | 'site';
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const editor = useRef(null);
  const [page, setPage] = useState<IPage>({
    title: '',
    content: '',
    code: '',
    device: 'app',
    status: false,
    loading: false,
  });
  const [device, setDevice] = useState('app');
  const [code, setCode] = useState('');
  const [content, setContent] = useState('');
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function loadPage() {
      try {
        const { data } = await api.get(`/pages/${match.params.id}`);
        setPage({
          ...page,
          code: data.code,
          title: data.title,
          content: data.content,
          status: data.status,
          device: data.device,
        });
        setContent(data.content);
        setTitle(data.title);
        setStatus(data.status);
        setDevice(data.device);
        setCode(data.code);
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadPage();
  }, [match.params.id]); // eslint-disable-line

  async function handleEdit() {
    setLoading(true);
    try {
      const response = await api.put(`/pages/${match.params.id}`, {
        title,
        code,
        content,
        status: status === 'true' || Number(status) === 1 ? 1 : 0,
        device,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/pages/list');
        toastr.success('Página atualizada', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/pages/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/pages/list');
        toastr.success('Página apagada', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/pages/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Editar Página</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row mb-2">
                <div className="col-md-2">
                  <Input
                    label="Status"
                    name="status"
                    initialValue={page.status ? 'true' : 'false'}
                    onChange={e => setStatus(String(e.value))}
                    required
                    select
                    options={[
                      {
                        title: 'Ativo',
                        value: 'true',
                      },
                      {
                        title: 'Inativo',
                        value: 'false',
                      },
                    ]}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Dispositivo"
                    name="device"
                    initialValue={page.device}
                    onChange={e => setDevice(String(e.value))}
                    required
                    select
                    options={[
                      {
                        title: 'APP',
                        value: 'app',
                      },
                      {
                        title: 'Site',
                        value: 'site',
                      },
                    ]}
                    disabled={loading}
                  />
                </div>
                <div className="col-md-2">
                  <Input
                    label="Código"
                    name="code"
                    initialValue={page.code}
                    onChange={e => setCode(String(e.value).toUpperCase())}
                    required
                    disabled={loading}
                  />
                </div>
                {code.length > 0 && (
                  <div className="col-md-6">
                    <small style={{ display: 'block' }}>Link</small>
                    <a
                      href={`https://lojasimperio.com.br/pagina/${String(
                        code,
                      ).toLowerCase()}`}
                      target="blank"
                    >
                      https://lojasimperio.com.br/pagina/
                      {String(code).toLowerCase()}
                    </a>
                  </div>
                )}
              </div>
              <div className="row mb-2">
                <div className="col-md-12">
                  <Input
                    label="Título"
                    name="title"
                    required
                    initialValue={page.title}
                    onChange={e => setTitle(String(e.value))}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-12">
                  <Label>Conteúdo</Label>
                  <JoditEditor
                    ref={editor}
                    value={content || page.content}
                    onChange={newContent => setContent(newContent)}
                  />
                </div>
              </div>
            </div>
            {!confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <Button type="button" disabled={loading} onClick={handleEdit}>
                  {loading ? 'Salvando...' : 'Salvar'}
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="ml-2"
                  disabled={loading}
                  onClick={() => setConfirmDelete(true)}
                >
                  {loading ? '...' : 'Excluir Página'}
                </Button>
              </div>
            )}
            {confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <div>
                  <h3 style={{ textAlign: 'right' }}>
                    Você tem certeza que deseja excluir esta página?
                  </h3>
                  <div>
                    <Button
                      type="button"
                      color="danger"
                      disabled={loading}
                      onClick={handleDelete}
                    >
                      {loading ? 'Excluindo...' : 'Excluir'}
                    </Button>
                    <Button
                      type="button"
                      color="gray"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmDelete(false)}
                    >
                      {loading ? '...' : 'Cancelar'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
