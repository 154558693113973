import React, { useRef, useState } from 'react';

import { FaTimesCircle } from 'react-icons/fa';
import JoditEditor from 'jodit-react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';
import Checkbox from '~/components/Checkbox';

import { Container, AutocompleteOptions, Label } from './styles';
import { states } from '~/utils/states';

interface IProduct {
  id: number;
  code: string;
  title: string;
  stock: number;
}

interface ICampaign {
  name: string;
  code: string;
  description: string;
  is_active: boolean;
  state: string;
}

const Create: React.FC = () => {
  const editor = useRef(null);
  const [values, setValues] = useState<ICampaign>({
    name: '',
    code: '',
    description: '',
    is_active: true,
    state: '',
  });
  const [products, setProducts] = useState<IProduct[]>([]);
  const [queryProduct, setQueryProduct] = useState('');
  const [productsData, setProductsData] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  async function handleSearchProduct(value: string) {
    setQueryProduct(value);
    if (value.length === 0) {
      setProductsData([]);
    } else {
      const { data } = await api.get(
        `products?name=${value}&status=1&perpage=5`,
      );
      setProductsData(
        data.data.map((p: any) => {
          return {
            ...p,
            id: p.id,
            title: p.name,
            code: p.sku,
            stock: p.stock,
            status: p.status,
          };
        }),
      );
    }
  }

  function handleDeleteProduct(index: number) {
    setProducts(products.filter((_, i) => index !== i));
  }

  function handleSelect(product: IProduct) {
    if (!products.find(p => p.id === product.id)) {
      setProducts([...products, product]);
      setQueryProduct('');
      setProductsData([]);
    }
  }

  async function handleAdd() {
    const { length: queryLength } = queryProduct.split(',');

    setLoading(true);

    try {
      const productsToAdd =
        queryLength > 1
          ? { products: queryProduct, isSku: true }
          : {
              products: products.map(({ id: productId }) => productId),
              isSku: false,
            };

      if (!productsToAdd.isSku && productsToAdd.products.length === 0) {
        toastr.error('Validação', 'Nenhum produto selecionado');
        setLoading(false);
        return;
      }

      const { status, data } = await api.post(`/campaigns`, {
        ...values,
        ...productsToAdd,
      });

      if (status === 203) {
        data?.forEach(({ message }: any) => toastr.error(message, ''));
      } else {
        history.push('/campaigns/list');
        toastr.success('Coleção atualizada', '');
      }
    } catch (error: any) {
      const { status, data } = error;

      if (status === 400) {
        toastr.error('Ocorreu um erro', data?.message);
      } else {
        toastr.error('Ocorreu um erro', '');
      }
    }

    setLoading(false);
  }

  async function handleGetByList(value: string) {
    const skus = value.split(';');
    const arr = [] as any[];
    setLoading(true);
    for (let i = 0; i < skus.length; i += 1) {
      const sku = skus[i];
      if (sku) {
        try {
          const { data } = await api.get(
            `products?name=${sku}&status=1&perpage=1`,
          );
          if (data.data[0] && !products.find(p => p.id === data.data[0].id)) {
            arr.push({
              ...data.data[0],
              code: data.data[0].sku,
              title: data.data[0].name,
            });
          }
        } catch (err) {
          //
        }
      }
    }
    setProducts([...products, ...arr]);
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/campaigns/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Criar Coleção</h3>
        </div>
        <div className="body_block">
          <div className="row mb-3">
            <div className="col-md-6">
              <Input
                label="Nome"
                name="name"
                required
                onChange={e => setValues({ ...values, name: String(e.value) })}
                disabled={loading}
                initialChanges
                initialValue={values.name}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Código"
                name="code"
                required
                onChange={e => setValues({ ...values, code: String(e.value) })}
                disabled={loading}
                initialChanges
                initialValue={values.code}
              />
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <Label>Conteúdo</Label>
                <JoditEditor
                  ref={editor}
                  value={values.description}
                  onChange={newContent => {
                    setValues({
                      ...values,
                      description: newContent,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-md-6" style={{ position: 'relative' }}>
              <Input
                label="Produtos (apenas ativos e em estoque)"
                name="productsQuery"
                initialValue={queryProduct}
                initialChanges
                required
                onChange={e => handleSearchProduct(String(e.value))}
                disabled={loading}
                autoComplete="off"
              />
              {queryProduct.length > 0 && productsData.length > 0 && (
                <AutocompleteOptions>
                  {productsData
                    .filter(p => p.stock > 0)
                    .map(p => (
                      <button
                        type="button"
                        key={p.code}
                        onClick={() => handleSelect(p)}
                      >
                        {p.code} - {p.title}
                      </button>
                    ))}
                </AutocompleteOptions>
              )}
              {products.map((p, index) => (
                <div
                  key={p.code}
                  className="d-flex justify-content-between product-added"
                >
                  <p className="mb-0">
                    <strong>{p.code}</strong> - {p.title}
                  </p>
                  <button
                    className="text-danger ml-2"
                    type="button"
                    onClick={() => handleDeleteProduct(index)}
                  >
                    <FaTimesCircle />
                  </button>
                </div>
              ))}
            </div>
            <div className="col-md-2 pt-3">
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  const list = window.prompt(
                    "Cole aqui a lista de SKU's separados por ;",
                  );
                  handleGetByList(list || '');
                }}
                disabled={loading}
              >
                {loading ? 'Carregando' : 'Inserir produtos por lista de SKU'}
              </button>
            </div>
            <div className="col-md-2">
              <Input
                label="Estado"
                name="state"
                initialChanges
                initialValue={values.state}
                onChange={e =>
                  setValues({
                    ...values,
                    state: String(e.value),
                  })
                }
                disabled={loading}
                select
                options={states.map(s => ({
                  title: s.desc,
                  value: s.uf,
                }))}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <Checkbox
                name="is_active"
                label="Ativo"
                checked={values.is_active}
                onChange={() => {
                  setValues({ ...values, is_active: !values.is_active });
                }}
              />
            </div>
          </div>
        </div>
        <div className="footer_block">
          <Button type="button" disabled={loading} onClick={handleAdd}>
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Create;
