import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';

import { useHistory } from 'react-router-dom';
import { addHours, format, parseISO, subHours } from 'date-fns';
import { endOfDay, startOfDay } from 'date-fns/esm';
import { TableComponent } from '~/components/TableV2';
import api from '~/services/api';

import { Container } from './styles';
import useSelector from '~/store/useSelector';

import { normalizeCpf, normalizeNumber } from '~/utils/normalize';

const List: React.FC = () => {
  const history = useHistory();
  const { permissions } = useSelector(state => state.auth);

  return (
    <Container>
      <TableComponent
        filters={[
          {
            key: 'order_id',
            title: 'Id',
            type: 'text',
            col: 2,
          },
          {
            key: 'customer_name',
            title: 'Nome do Cliente',
            type: 'text',
            col: 2,
          },
          {
            key: 'cpf',
            title: 'CPF do Cliente',
            normalize: normalizeCpf,
            type: 'text',
            col: 2,
          },
          {
            key: 'startDate',
            title: 'De',
            type: 'date',
            col: 2,
          },
          {
            key: 'endDate',
            title: 'Até',
            type: 'date',
            col: 2,
          },
          {
            key: 'origin',
            title: 'Origem',
            type: 'select',
            options: [
              {
                title: 'App',
                value: 'app',
              },
              {
                title: 'Site',
                value: 'site',
              },
              {
                title: 'Anymarket',
                value: 'anymarket',
              },
            ],
            col: 2,
          },
          {
            key: 'device',
            title: 'Dispositivo',
            type: 'select',
            options: [
              {
                title: 'Site',
                value: 'site',
              },
              {
                title: 'IOS',
                value: 'ios',
              },
              {
                title: 'Android',
                value: 'android',
              },
            ],
            col: 2,
          },
          {
            key: 'form_delivery',
            title: 'Forma de Entrega',
            type: 'select',
            options: [
              {
                title: 'Receba em Casa',
                value: 'frete',
              },
              {
                title: 'Receba na Loja',
                value: 'cliqueeretire',
              },
            ],
            col: 2,
          },
        ]}
        labels={[
          {
            key: 'id',
            title: 'Id',
          },
          {
            key: 'updated_at',
            title: 'Data e hora',
            formater: (date, s) => {
              return (
                s.updated_at &&
                format(
                  subHours(parseISO(String(s.updated_at)), 3),
                  'dd/MM/yyyy HH:mm',
                )
              );
            },
          },
          {
            key: 'customerName',
            title: 'Cliente',
            formater: (orderCustomer, s) => {
              return `${s.customer.name} ${s.customer.lastname}`;
            },
          },
          {
            key: 'total',
            title: 'Valor total',
            formater: (total, s) => {
              return `R$ ${(s.total / 100).toFixed(2)}`;
            },
          },
          {
            key: 'form_delivery',
            title: 'Forma de entrega',
            formater: form => {
              switch (form) {
                case 'frete':
                  return <span className="realizado">Receba em casa</span>;
                case 'cliqueeretire':
                  return <span className="warning">Retire na loja</span>;
                default:
                  return <span className="warning"> </span>;
              }
            },
          },
          {
            key: 'origin',
            title: 'Origem',
            formater: form => {
              switch (form) {
                case 'app':
                  return <span className="realizado">App</span>;
                case 'site':
                  return <span className="warning">Site</span>;
                case 'anymarket':
                  return (
                    <span style={{ background: '#ff9300', color: '#fff' }}>
                      Anymarket
                    </span>
                  );
                default:
                  return (
                    <span className="warning">
                      {' '}
                      Não foi Possivel encontrar origem
                    </span>
                  );
              }
            },
          },
          {
            key: 'device',
            title: 'Dispositivo',
            formater: (i, s) => {
              return s.device ? s.device : 'Site';
            },
          },
        ]}
        getData={async params => {
          const res = await api.get(`/abandoned/orders`, {
            params: {
              ...params,
              cpf: normalizeNumber(params.cpf),
              date:
                (params.startDate || params.endDate) &&
                `${
                  params.startDate
                    ? format(
                        addHours(startOfDay(new Date(params.startDate)), 3),
                        'yyyy-MM-dd HH:mm:ss',
                      )
                    : ''
                }|${
                  params.endDate
                    ? format(
                        addHours(endOfDay(new Date(params.endDate)), 3),
                        'yyyy-MM-dd HH:mm:ss',
                      )
                    : ''
                }`,
            },
          });
          return res;
        }}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            {permissions.find(p => p.slug === 'ver_pedidos_abandonados') ? (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() =>
                    history.push(`/orders/abandoned/view/${item.id}`)
                  }
                >
                  Editar
                  <AiOutlineArrowRight />
                </button>
              </>
            ) : null}
          </div>
        )}
      />
    </Container>
  );
};

export default List;
