import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import api from '~/services/api';
import { normalizeCnpj, normalizeCurrency } from '~/utils/normalize';
import useSelector from '~/store/useSelector';
import { TableComponent } from '~/components/TableV2';

const List: React.FC = () => {
  const { permissions } = useSelector(state => state.auth);

  const history = useHistory();

  return (
    <Container>
      <TableComponent
        filters={[]}
        labels={[
          {
            key: 'name',
            title: 'Nome',
            ordenable: false,
          },
          {
            key: 'code',
            title: 'Código',
            ordenable: false,
          },
          {
            key: 'cnpj',
            title: 'CNPJ',
            formater: (cnpj, d) => {
              return d.cnpj ? normalizeCnpj(d.cnpj) : '';
            },
            ordenable: false,
          },
          {
            key: 'origin',
            title: 'Integrador',
            ordenable: false,
          },
          {
            key: 'integrate_percent',
            title: 'Percentual',
            formater: (percent, d) => {
              return d.integrate_percent
                ? `${normalizeCurrency(String(d.integrate_percent))} %`
                : '0%';
            },
            ordenable: false,
          },
        ]}
        getData={async params => {
          const res = await api.get(`/marketplaces`, {
            params,
          });
          return res;
        }}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            {permissions.find(p => p.slug === 'ver_marketplace') ? (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() =>
                    history.push(`/marketplaces/detail/${item.id}`)
                  }
                >
                  Editar
                  <AiOutlineArrowRight />
                </button>
              </>
            ) : null}
          </div>
        )}
      />
    </Container>
  );
};

export default List;
