import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import api from '~/services/api';
import Can from '~/components/Can';
import useSelector from '~/store/useSelector';
import Button from '~/styles/components/Button';
import { TableComponent } from '~/components/TableV2';

const List: React.FC = () => {
  const { permissions } = useSelector(state => state.auth);

  const history = useHistory();

  return (
    <Container>
      <div className="d-flex justify-content-end">
        <Can checkPermission="store_store">
          <Button type="button" onClick={() => history.push('/stores/create')}>
            Nova loja
          </Button>
        </Can>
      </div>

      <TableComponent
        filters={[]}
        labels={[
          {
            key: 'name',
            title: 'Nome',
          },
          {
            key: 'code',
            title: 'Código',
          },
          {
            key: 'fullAddress',
            title: 'Endereço',
          },
        ]}
        getData={async params => {
          const res = await api.get(`/stores`, {
            params,
          });
          return res;
        }}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            {permissions.find(p => p.slug === 'store_update') ? (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => history.push(`/stores/edit/${item.id}`)}
                >
                  Editar
                  <AiOutlineArrowRight />
                </button>
              </>
            ) : null}
          </div>
        )}
      />
    </Container>
  );
};

export default List;
