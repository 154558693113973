import React, { useState, useEffect } from 'react';

import { format, subHours } from 'date-fns';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import axios from 'axios';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container } from './styles';

interface IVtex {
  name: string;
  appKey: string;
  appToken: string;
  seller: string;
  loading: boolean;
}

interface IReport {
  id: number;
  code: string;
  seller: string;
  total: number;
  finished_at: string;
  created_at: string;
  updated_at: string;
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const [vtex, setVtex] = useState<IVtex>({
    name: '',
    appKey: '',
    appToken: '',
    seller: '',
    loading: false,
  });
  const [name, setName] = useState('');
  const [appKey, setAppKey] = useState('');
  const [appToken, setAppToken] = useState('');
  const [seller, setSeller] = useState('');
  const [reports, setReports] = useState<IReport[]>([]);
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const history = useHistory();

  async function loadReport(s: string) {
    try {
      const { data } = await axios.get(
        `https://vtex.lojasimperio.com.br/seller/${s}/skus`,
      );
      setReports(data);
    } catch (err) {
      toastr.error(
        'Ocorreu um erro',
        'Durante a busca nossos servidores não puderam responder',
      );
    }
  }

  async function loadVtex() {
    try {
      const { data } = await api.get(`/vtex-sellers/${match.params.id}`);
      setVtex({
        ...vtex,
        name: data.name,
        appKey: data.app_key,
        appToken: data.app_token,
        seller: data.seller,
      });

      loadReport(data.seller);

      setLoading(false);
    } catch (err) {
      toastr.error(
        'Ocorreu um erro',
        'Durante a busca nossos servidores não puderam responder',
      );
    }
  }

  useEffect(() => {
    loadVtex();
  }, [match.params.id]); // eslint-disable-line

  async function handleEdit() {
    setLoading(true);
    try {
      const response = await api.put(`/vtex-sellers/${match.params.id}`, {
        name: name || vtex.name,
        app_key: appKey || vtex.appKey,
        app_token: appToken || vtex.appToken,
        seller: seller || vtex.seller,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/vtex/list');
        toastr.success('Vendedor atualizado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleNewReport() {
    setLoading(true);
    try {
      const response = await axios.post(
        `https://vtex.lojasimperio.com.br/seller/skus`,
        {
          app_key: vtex.appKey,
          app_token: vtex.appToken,
          seller: vtex.seller,
        },
      );
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        toastr.success(
          'Relatório gerado com sucesso, aguarde a finalização',
          '',
        );

        loadReport(vtex.seller);
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleReportCsv(id: number) {
    setLoading(true);
    try {
      const { data }: any = await axios.get(
        `https://vtex.lojasimperio.com.br/seller/${vtex.seller}/skus/${id}/csv`,
      );

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `report_${id}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/vtex-sellers/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/vtex/list');
        toastr.success('Vendedor deletado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/vtex/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Editar Vendedor (Vtex)</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row mb-3">
                <div className="col-md-4">
                  <Input
                    label="Nome"
                    name="name"
                    required
                    initialValue={vtex.name}
                    onChange={e => setName(String(e.value))}
                    disabled={loading}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4">
                  <Input
                    label="appKey"
                    name="appKey"
                    required
                    initialValue={vtex.appKey}
                    onChange={e => setAppKey(String(e.value))}
                    disabled={loading}
                  />
                </div>

                <div className="col-md-4">
                  <Input
                    label="appToken"
                    name="appToken"
                    required
                    initialValue={vtex.appToken}
                    onChange={e => setAppToken(String(e.value))}
                    disabled={loading}
                  />
                </div>

                <div className="col-md-4">
                  <Input
                    label="seller"
                    name="seller"
                    required
                    initialValue={vtex.seller}
                    onChange={e => setSeller(String(e.value))}
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
            {!confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <Button type="button" disabled={loading} onClick={handleEdit}>
                  {loading ? 'Salvando...' : 'Salvar'}
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="ml-2"
                  disabled={loading}
                  onClick={() => setConfirmDelete(true)}
                >
                  {loading ? '...' : 'Excluir Vendedor'}
                </Button>
              </div>
            )}
            {confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <div>
                  <h3 style={{ textAlign: 'right' }}>
                    Você tem certeza que deseja excluir este vendedor?
                  </h3>
                  <div>
                    <Button
                      type="button"
                      color="danger"
                      disabled={loading}
                      onClick={handleDelete}
                    >
                      {loading ? 'Excluindo...' : 'Excluir'}
                    </Button>
                    <Button
                      type="button"
                      color="gray"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmDelete(false)}
                    >
                      {loading ? '...' : 'Cancelar'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <>
              <div className="body_block">
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between">
                      <h5>Relatórios</h5>
                      <Button
                        type="button"
                        disabled={loading}
                        onClick={handleNewReport}
                      >
                        Gerar novo relatório
                      </Button>
                    </div>
                    <table className="table table-hover mt-3">
                      <thead className="table-light">
                        <tr>
                          <th>ID</th>
                          <th>Data de criação</th>
                          <th>Status</th>
                          <th>Ação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reports.map((report: any) => (
                          <tr key={report.id}>
                            <td>#{report.id}</td>
                            <td>
                              {report.created_at
                                ? format(
                                    subHours(new Date(report.created_at), 3),
                                    'dd/MM/yyyy HH:mm:ss',
                                  )
                                : ''}
                            </td>
                            <td>
                              {report.total === report.products.length
                                ? 'Finalizado'
                                : 'Em processamento'}
                            </td>
                            <td>
                              {report.total === report.products.length && (
                                <Button
                                  type="button"
                                  onClick={() => handleReportCsv(report.id)}
                                >
                                  Gerar csv
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
