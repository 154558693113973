import { takeLatest, call, put, all } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';

import { REHYDRATE, RehydrateAction } from 'redux-persist';

import history from '~/services/history';

import api from '~/services/api';

import {
  ISignInRequestAction,
  ISignUpRequestAction,
  SIGN_IN_REQUEST,
  SIGN_OUT,
  SIGN_UP_REQUEST,
} from './types';
import {
  signInSuccess,
  getPermissionsSuccess,
  signOut as signOutAction,
} from './actions';

type UnsafeReturnType<T> = T extends (...args: any[]) => infer R ? R : any; // eslint-disable-line

export function* signIn({ payload }: ISignInRequestAction): any {
  // eslint-disable-line
  try {
    const { tmp_token, code, g_response } = payload;
    const response = yield call(api.post, 'authentication/user-code', {
      tmp_token,
      code,
      g_response,
    });

    yield put(signInSuccess(response.data.token));
    api.defaults.headers.apptoken = response.data.token;
    const { permissions } = response.data;
    response.data.roles.forEach((role: any) => {
      role.permissions.forEach((permission: any) =>
        permissions.push(permission),
      );
    });
    yield put(getPermissionsSuccess(response.data.roles, permissions));
    window.location.href = '/';
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Erro',
        message: 'Código inválido',
        options: {
          showCloseButton: true,
          progressBar: true,
          timeOut: 5000,
        },
      }),
    );
  }
}

export function* signUp({ payload }: ISignUpRequestAction): any {
  // eslint-disable-line
  try {
    const { name, email, password } = payload;
    const response = yield call(api.post, 'users', {
      name,
      email,
      password,
    });

    yield put(signInSuccess(response.data.token));
    api.defaults.headers.apptoken = response.data.token;
    history.push('/');
  } catch (err) {
    yield put(
      toastrActions.add({
        type: 'error',
        title: 'Falha no Cadastro',
        message: 'Você foi convidado para algum time?',
        options: {
          showCloseButton: true,
          progressBar: true,
          timeOut: 5000,
        },
      }),
    );
  }
}

export function* setToken({ payload }: UnsafeReturnType<RehydrateAction>): any {
  // eslint-disable-line
  if (!payload) return;
  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.apptoken = token;

    try {
      const { data } = yield call(api.get, 'user/me');

      const { permissions } = data;
      data.roles.forEach((role: any) => {
        role.permissions.forEach((permission: any) =>
          permissions.push(permission),
        );
      });

      yield put(getPermissionsSuccess(data.roles, permissions));
      if (data.seller) {
        localStorage.setItem('seller_code', data.seller.code);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        yield put(signOutAction());
      }
    }
  }
}

export function signOut(): any {
  // eslint-disable-line
  delete api.defaults.headers.apptoken;
  history.push('/signin');
}

export function setInterceptor(): any {
  api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error?.response?.status === 401) {
        delete api.defaults.headers.apptoken;
        localStorage.setItem(
          'persist:@Omninative',
          JSON.stringify({ auth: {} }),
        );
        window.location.href = '/signin';
      }

      return Promise.reject(error?.response);
    },
  );
}

export default all([
  takeLatest(REHYDRATE, setInterceptor),
  takeLatest(REHYDRATE, setToken),
  takeLatest(SIGN_IN_REQUEST, signIn),
  takeLatest(SIGN_OUT, signOut),
  takeLatest(SIGN_UP_REQUEST, signUp),
]);
