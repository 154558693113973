import styled from 'styled-components';

export const Container = styled.label`
  display: block;
`;

interface ILabel {
  backgroundImage: string;
  minHeight: string;
}

export const Label = styled.span`
  color: #444;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-bottom: 2px;
  margin-left: 4px;
  padding: 15px;
  border: 2px dashed #cecece;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  min-height: ${(props: ILabel) => props.minHeight};
  background-image: url(${(props: ILabel) => props.backgroundImage});
  background-size: cover;
  transition: 300ms;
  &:hover {
    opacity: 0.6;
  }
`;

export const Input = styled.input``;

export const HelperText = styled.small`
  color: #e04848;
  display: block;
  margin-top: 6px;
`;

export const Preview = styled.img`
  width: 100%;
  max-width: 300px;
`;
