import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Container, SideMenu, SideMenuPretend, MenuItem } from './styles';

import useSelector from '~/store/useSelector';

interface IDropdownItem {
  title: string;
  route: string;
  roles: string[];
  permissions: string[];
}
interface IMenuItem {
  name: string;
  title: string;
  roles: string[];
  permissions: string[];
  route?: string;
  items?: IDropdownItem[];
}

const SideMenuLayout: React.FC = () => {
  const [open, setOpen] = useState(true);

  const history = useHistory();

  const { permissions } = useSelector(state => state.auth);

  const [menu] = useState<IMenuItem[]>([
    {
      name: '',
      title: 'Operacional',
      roles: [],
      permissions: [],
      items: [
        {
          title: 'Cartão Império',
          roles: [],
          permissions: ['ver_cartao_imperio'],
          route: '/imperio-card',
        },
        {
          title: 'Pedidos',
          roles: [],
          permissions: ['ver_pedidos'],
          route: '/orders/list',
        },
        {
          title: 'Pedidos abandonados',
          roles: [],
          permissions: ['ver_pedidos_abandonados'],
          route: '/orders/abandoned/list',
        },
        {
          title: 'Links de Pagamento',
          roles: [],
          permissions: ['ver_links_de_pagamento'],
          route: '/payment-links',
        },
        {
          title: 'Clientes',
          roles: [],
          permissions: ['ver_clientes'],
          route: '/customers/list',
        },
        {
          title: 'Solicitação de Exclusão de Conta',
          roles: [],
          permissions: ['ver_pedidos_de_exclusão'],
          route: '/delete-requests',
        },
        {
          title: 'Vendedores',
          roles: [],
          permissions: ['ver_vendedores'],
          route: '/sellers/list',
        },
        {
          title: 'Lojas',
          roles: [],
          permissions: ['ver_lojas'],
          route: '/stores/list',
        },
        {
          title: 'Produtos',
          roles: [],
          permissions: ['ver_produtos'],
          route: '/products/list',
        },
        {
          title: 'Cupons',
          roles: [],
          permissions: ['ver_cupons'],
          route: '/coupons/list',
        },
        {
          title: 'Marcas',
          roles: [],
          permissions: ['ver_marcas'],
          route: '/brands/list',
        },
        {
          title: 'Categoria',
          roles: [],
          permissions: ['ver_categoria'],
          route: '/categories/list',
        },
        {
          title: 'Relacionar Categoria',
          roles: [], // TODO
          permissions: ['ver_relacionar_categoria'], // TODO
          route: '/percent-categories/list',
        },
        {
          title: 'Coleções',
          roles: [],
          permissions: ['ver_colecao'],
          route: '/campaigns/list',
        },
        {
          title: 'Banner',
          roles: [],
          permissions: ['ver_banner'],
          route: '/banners/list',
        },
        {
          title: 'Páginas',
          roles: [],
          permissions: ['ver_paginas'],
          route: '/pages/list',
        },
        {
          title: 'Termos e Condições',
          roles: [],
          permissions: ['ver-termos-e-condicoes'],
          route: '/app-terms',
        },
        {
          title: 'Relatórios',
          roles: [],
          permissions: ['ver_relatorios'],
          route: '/reports',
        },
        {
          title: 'Relatório (VTEX)',
          roles: [],
          permissions: ['ver_relatorio_vtex'],
          route: '/vtex/list',
        },
        {
          title: 'Marketplace',
          roles: [],
          permissions: ['ver_marketplace'],
          route: '/marketplaces/list',
        },
        {
          title: 'Reviews',
          roles: [],
          permissions: ['ver-reviews'],
          route: '/reviews',
        },
        {
          title: 'Entrega Rápida por CEP',
          roles: [],
          permissions: ['ver_marketplace'],
          route: '/zipcode-fast-shipping',
        },
      ],
    },
    {
      name: '',
      title: 'Open Search',
      roles: [],
      permissions: [],
      items: [
        {
          title: 'Sinônimos',
          roles: [],
          permissions: ['ver_sinonimo'],
          route: '/synonyms/list',
        },
        {
          title: 'Score',
          roles: [],
          permissions: [],
          route: '/score-open-search',
        },
      ],
    },
    {
      name: '',
      title: 'Administrativo',
      roles: [],
      permissions: [],
      items: [
        {
          title: 'Usuários',
          roles: [],
          permissions: ['user_index'],
          route: '/users/list',
        },
        {
          title: 'Perfis',
          roles: [],
          permissions: ['ver_perfis'],
          route: '/roles/list',
        },
      ],
    },

    {
      name: '',
      title: 'Configurações',
      roles: [],
      permissions: [],
      items: [
        {
          title: 'Perfil',
          roles: [],
          permissions: [],
          route: '/settings',
        },
      ],
    },
  ]);

  function hasPermission(required: string[]): boolean {
    if (required.length === 0) {
      return true;
    }

    if (!permissions) {
      return false;
    }

    return permissions.some(permission =>
      required.find(r => r === permission.slug),
    );
  }

  return (
    <Container open={open}>
      <button type="button" id="burger" onClick={() => setOpen(!open)}>
        <i className="bar" />
        <i className="bar" />
        <i className="bar" />
      </button>
      <SideMenu>
        {menu
          .filter(
            item =>
              item.items &&
              item.items.filter(sub => hasPermission(sub.permissions)).length,
          )
          .map(item => (
            <SideMenuPretend key={item.name}>
              <span>{item.title}</span>
              {item.items &&
                item.items
                  .filter(sub => hasPermission(sub.permissions))
                  .map((sub, index) => (
                    <MenuItem
                      className={
                        window.location.href.indexOf(sub.route) > -1
                          ? 'active'
                          : ''
                      }
                      onClick={() => history.push(sub.route)}
                      type="button"
                      key={String(index)}
                    >
                      {sub.title}
                    </MenuItem>
                  ))}
            </SideMenuPretend>
          ))}
      </SideMenu>
    </Container>
  );
};

export default SideMenuLayout;
