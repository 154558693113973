import React, { useRef, useState, useEffect } from 'react';

import JoditEditor from 'jodit-react';
import { useHistory } from 'react-router-dom';
import { AiFillPicture, AiFillCloseCircle } from 'react-icons/ai';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { toastr } from 'react-redux-toastr';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';
import { normalizeCurrency } from '~/utils/normalize';

import { Container, Label, PhotoList } from './styles';

interface IBrand {
  id: number;
  name: string;
}

interface ICategory {
  id: number;
  category_id: number;
  name: string;
}

const Create: React.FC = () => {
  const editor = useRef(null);

  const [brands, setBrands] = useState<IBrand[]>();
  const [categories, setCategories] = useState<ICategory[]>();

  const [photos, setPhotos] = useState<File[]>([]);

  const [brand_id, setBrand_id] = useState('');
  const [sku, setSku] = useState('');
  const [name, setName] = useState('');
  const [reference, setReference] = useState('');
  const [max_installment, setMax_installment] = useState('');
  const [price, setPrice] = useState('');
  const [special_price, setSpecial_price] = useState('');
  const [description, setDescription] = useState('');
  const [short_description, setShort_description] = useState('');
  const [featured, setFeatured] = useState('');
  const [status, setStatus] = useState('');

  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    async function loadData() {
      try {
        const { data: dataBrand } = await api.get('/brands?perpage=1000');
        setBrands(dataBrand.data);

        const { data: dataCategory } = await api.get(
          '/categories?all=1&perpage=1000',
        );
        setCategories(dataCategory.data);

        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadData();
  }, []);

  function changeImagePosition(arr: File[], from: number, to: number): File[] {
    const n = [...arr];
    n.splice(to, 0, n.splice(from, 1)[0]);

    return n;
  }

  async function handleAdd() {
    setLoading(true);
    try {
      const response = await api.post(`/products`, {
        brand_id,
        sku,
        name,
        max_installment,
        price,
        special_price,
        description,
        short_description,
        featured: featured === 'true',
        status: status === 'true',
        reference,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        for (let i = 0; i < photos.length; i += 1) {
          try {
            const obj = new FormData();
            obj.append('file', photos[i]);
            await api.post(
              `/products/photo?order=${String(i + 1)}&product_id=${
                response.data.id
              }`,
              obj,
            );
          } catch (err) {
            toastr.error(`Falha no upload da imagem ${i + 1}`, '');
          }
        }
        toastr.success('Produto criado', '');
        history.push('/products/list');
      }
    } catch (err: any) {
      if (err.data && err.data.message) {
        toastr.error(err.data.message, '');
      } else {
        toastr.error('Ocorreu um erro', '');
      }
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/products/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Criar Produto</h3>
        </div>
        {!loading && (
          <div className="body_block">
            <div className="row mb-2">
              <div className="col-md-2">
                <Input
                  label="Destaque"
                  name="featured"
                  required
                  onChange={e => setFeatured(String(e.value))}
                  select
                  options={[
                    {
                      title: 'Sim',
                      value: 'true',
                    },
                    {
                      title: 'Não',
                      value: 'false',
                    },
                  ]}
                  disabled={loading}
                />
              </div>
              <div className="col-md-2">
                <Input
                  label="Status"
                  name="status"
                  required
                  onChange={e => setStatus(String(e.value))}
                  select
                  options={[
                    {
                      title: 'Ativo',
                      value: 'true',
                    },
                    {
                      title: 'Inativo',
                      value: 'false',
                    },
                  ]}
                  disabled={loading}
                />
              </div>
              <div className="col-md-2">
                <Input
                  label="Referência"
                  name="reference"
                  required
                  initialValue={reference}
                  onChange={e => setReference(String(e.value))}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-7">
                <Input
                  label="Nome do produto"
                  name="name"
                  required
                  onChange={e => setName(String(e.value))}
                  disabled={loading}
                />
              </div>
              <div className="col-md-2">
                <Input
                  label="Sku"
                  name="sku"
                  required
                  onChange={e => setSku(String(e.value))}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-4">
                <Input
                  label="Marca"
                  name="brand_id"
                  required
                  onChange={e => setBrand_id(String(e.value))}
                  select
                  options={
                    brands &&
                    brands.map(p => {
                      return {
                        title: p.name,
                        value: String(p.id),
                      };
                    })
                  }
                  disabled={loading}
                />
              </div>
              <div className="col-md-5">
                {/* <Input
                  label="Categoria"
                  name="category_id"
                  required
                  onChange={e => setCate(String(e.value))}
                  select
                  options={
                    categories &&
                    categories.map(p => {
                      return {
                        title: p.name,
                        value: String(p.id),
                      };
                    })
                  }
                  disabled={loading}
                /> */}
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-3">
                <Input
                  label="Preço"
                  name="price"
                  required
                  normalize={value => normalizeCurrency(value)}
                  onChange={e => setPrice(String(e.value))}
                  disabled={loading}
                />
              </div>
              <div className="col-md-3">
                <Input
                  label="Preço especial"
                  name="special_price"
                  required
                  normalize={value => normalizeCurrency(value)}
                  onChange={e => setSpecial_price(String(e.value))}
                  disabled={loading}
                />
              </div>
              <div className="col-md-3">
                <Input
                  label="Parcelamento máximo"
                  name="max_installment"
                  required
                  onChange={e => setMax_installment(String(e.value))}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-9">
                <Input
                  label="Descrição curta"
                  name="short_description"
                  required
                  onChange={e => setShort_description(String(e.value))}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-9">
                <Label>Descrição</Label>
                <JoditEditor
                  ref={editor}
                  value={description}
                  onChange={newDescription => setDescription(newDescription)}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <h3>Imagens do produto</h3>
              </div>
              <div className="col-md-12">
                <PhotoList>
                  {photos.map((p, index) => (
                    <div key={String(index)}>
                      <div
                        className="img"
                        style={{
                          background: `url(${URL.createObjectURL(
                            p,
                          )}) 50% 50% no-repeat`,
                        }}
                      >
                        <button
                          type="button"
                          onClick={() =>
                            setPhotos(photos.filter((_, i) => i !== index))
                          }
                        >
                          <AiFillCloseCircle />
                        </button>
                      </div>
                      <div className="d-flex justify-content-around mt-3">
                        <button
                          type="button"
                          onClick={() => {
                            setPhotos(
                              changeImagePosition(photos, index, index - 1),
                            );
                          }}
                          disabled={index === 0}
                        >
                          <IoIosArrowBack />
                        </button>
                        <button
                          onClick={() => {
                            setPhotos(
                              changeImagePosition(photos, index, index + 1),
                            );
                          }}
                          type="button"
                          disabled={index === photos.length - 1}
                        >
                          <IoIosArrowForward />
                        </button>
                      </div>
                    </div>
                  ))}
                  <label id="add" htmlFor="file_p">
                    <AiFillPicture />
                    Adicionar Imagem
                    <input
                      type="file"
                      id="file_p"
                      accept="image/*"
                      onChange={e => {
                        if (e.target && e.target.files && e.target.files[0]) {
                          setPhotos([...photos, e.target.files[0]]);
                        }
                      }}
                    />
                  </label>
                </PhotoList>
              </div>
            </div>
          </div>
        )}
        <div className="footer_block d-flex justify-content-end">
          <Button type="button" onClick={handleAdd}>
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Create;
