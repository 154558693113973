import styled from 'styled-components';

export const Container = styled.div`
  .nav-tabs {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    border-bottom: 1px solid #dee2e6;
  }
  .nav-item {
  }
  .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    &.active {
      color: #495057;
      background-color: #fff;
      border-color: #dee2e6 #dee2e6 #fff;
    }
  }
  .tab-content {
    padding: 10px;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    display: block;
  }
  .tab-pane {
    margin-top: 20px;
  }
  .address {
    border: 1px solid #dee2e6;
    padding: 10px;
  }
  .favorites {
    border: 1px solid #dee2e6;
    padding: 10px;
    display: flex;
    flex-direction: column;
    .priceOld {
      font-size: 14px;
      text-decoration: line-through;
      margin-bottom: 3px;
    }
    .price {
      font-size: 16px;
    }
    strong {
      margin-bottom: 7.5px;
      small {
      }
    }
  }
  hr {
    border: 1px solid #dce4e6;
    margin: 10px 50px 40px;
  }
  .header-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    margin: 50px 10px 30px;
    line-height: 1.5;
  }
  .order-customer {
    line-height: 1.25;
    strong {
    }
  }
  .order-store {
    line-height: 1.25;
    strong {
    }
  }
  .order-payment {
    line-height: 1.25;
    strong {
    }
  }
  .table {
    width: calc(100% - 100px);
    min-width: calc(100% - 100px);
    margin: auto;
    background-color: transparent;
    thead {
      tr {
        th {
          padding-top: 14px;
          padding-bottom: 14px;
          vertical-align: middle;
          border-bottom: 1px solid rgba(230, 230, 230, 0.7);
          border-top: none;
          line-height: 1.42857143;
          padding: 0.75rem;
          font-size: 14px;
          &.text-right {
            text-align: right !important;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid rgba(230, 230, 230, 0.7);
          border-top: 0px;
          padding: 20px;
          font-size: 13.5px;
          line-height: 1.42857143;
          &.text-right {
            text-align: right !important;
          }
        }
      }
    }
  }
  .table-calc {
    justify-content: flex-end !important;
    table {
      width: calc(100% - 50px);
      tbody {
        tr {
          td {
            border-bottom: 1px solid rgba(230, 230, 230, 0.7);
            border-top: 0px;
            padding: 20px;
            font-size: 13.5px;
            line-height: 1.42857143;
            &.text-right {
              text-align: right !important;
            }
          }
        }
      }
    }
  }
`;

export const Label = styled.span`
  color: #2d3047;
  font-weight: bold;
  display: block;
  font-size: 12px;
  margin-bottom: 2px;
  margin-left: 4px;
`;
