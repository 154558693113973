import React, { useState } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import api from '~/services/api';
import Input from '~/components/Input';
import InputFile from '~/components/InputFile';
import Button from '~/styles/components/Button';
import Checkbox from '~/components/Checkbox';
import { Container } from './styles';

interface IChange {
  name: string;
  value: string | string[];
}

interface IBrand {
  image: File | null;
  name: string;
  active: boolean;
  index: string;
}

const Create: React.FC = () => {
  const [values, setValues] = useState<IBrand>({
    image: null,
    name: '',
    active: false,
    index: '',
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  async function handleAdd() {
    setLoading(true);
    try {
      const obj = new FormData();
      if (values.image) {
        obj.append('file', values.image);
      }
      const response = await api.post(
        `/brands?name=${String(values.name)}&active=${String(
          values.active,
        )}&index=${String(values.index)}`,
        obj,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/brands/list');
        toastr.success('Marca adicionada', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  function handleChange(e: IChange) {
    setValues({
      ...values,
      [e.name]: String(e.value),
    });
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/brands/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Adicionar Marca</h3>
        </div>
        <div className="body_block">
          <div className="row mb-3">
            <div className="col-md-12">
              <InputFile
                label="Imagem"
                name="image"
                required
                onChange={e =>
                  setValues({
                    ...values,
                    image: e.value,
                  })
                }
                onError={() => console.log(1) /* eslint-disable-line */}
                onReset={() => console.log(1) /* eslint-disable-line */}
                disabled={loading}
                minHeight="200px"
              />
            </div>
            <div className="col-md-12">
              <Input
                label="Nome"
                name="name"
                required
                onChange={handleChange}
                disabled={loading}
              />
            </div>
            <div className="col-md-4">
              <Input
                initialValue={String(values.index)}
                initialChanges
                label="Ordem"
                name="index"
                required
                type="number"
                onChange={handleChange}
                disabled={loading}
              />
            </div>
            <div>
              <Checkbox
                name="active"
                label="Ativa*"
                checked={values.active}
                onChange={(e: any) => {
                  setValues({ ...values, active: e.target.checked });
                }}
              />
            </div>
          </div>
        </div>
        <div className="footer_block">
          <Button type="button" disabled={loading} onClick={handleAdd}>
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Create;
