import JoditEditor from 'jodit-react';
import React, { useEffect, useRef, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import api from '~/services/api';
import Button from '~/styles/components/Button';

// import { Container } from './styles';

const AppTermsRegister: React.FC<{ match: any }> = ({ match }) => {
  const editor = useRef(null);
  const [loading, setLoading] = useState(false);

  const [content, setContent] = useState('');

  const [values, setValues] = useState({
    title: '',
    active: false,
  });

  const history = useHistory();

  const { id } = match.params;

  useEffect(() => {
    async function handle() {
      setLoading(true);
      try {
        const { data } = await api.get(`/app-terms/${id}`);
        setValues({
          title: data.title,
          active: data.active,
        });
        setContent(data.content);
      } catch (err) {
        //
      }
      setLoading(false);
    }
    if (id) {
      handle();
    }
  }, [id]);

  async function handleSave() {
    setLoading(true);
    try {
      if (id) {
        const { data } = await api.post(`/app-terms`, {
          ...values,
          content,
        });
        setValues({
          title: data.title,
          active: data.active,
        });
        setContent(data.content);
      } else {
        const { data } = await api.post(`/app-terms`, {
          ...values,
          content,
        });
        setValues({
          title: data.title,
          active: data.active,
        });
        setContent(data.content);
        history.push(`/app-terms/register/${data.id}`);
      }
    } catch (err) {
      if ((err as any).response) {
        toastr.error('Erro', (err as any).response?.data?.message);
      } else {
        toastr.error('Erro desconhecido', 'Contate o suporte');
      }
    }
    setLoading(false);
  }

  if (loading) {
    return (
      <div>
        <h5>Termos e Condições</h5>
        <div className="bg-white p-3 radius-4 shadow-sm mt-3">
          Carregando...
        </div>
      </div>
    );
  }

  return (
    <div>
      <h5>Termos e Condições</h5>
      <button type="button" onClick={() => history.push('/app-terms')}>
        <FaChevronLeft /> Voltar
      </button>
      <div className="bg-white p-3 radius-4 shadow-sm mt-3">
        <div className="row">
          <div className="col-md-4 mb-2">
            <div>Título</div>
            <input
              value={values.title}
              onChange={e =>
                setValues({
                  ...values,
                  title: e.target.value,
                })
              }
              type="text"
              className="form-control"
            />
            <small>
              Opte para usar o título como controle de versão do termo de
              condição
            </small>
          </div>
          <div className="col-md-12 mb-3">
            <div>Conteúdo</div>
            <JoditEditor
              ref={editor}
              value={content}
              onChange={newContent => setContent(newContent)}
            />
            <div className="d-flex align-items-center mt-3">
              <input
                type="checkbox"
                checked={values.active}
                onChange={() =>
                  setValues({
                    ...values,
                    active: !values.active,
                  })
                }
              />{' '}
              <p className="ml-2 mb-0">Ativo</p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                onClick={() => {
                  let confirm = false;
                  if (!values.active) {
                    confirm = true;
                  } else {
                    confirm = window.confirm(
                      'Ao salvar este termo, todos os clientes que ainda não o aceitaram serão requisitados o aceite. Tem certeza que deseja continuar?',
                    );
                  }
                  if (confirm) {
                    handleSave();
                  }
                }}
              >
                Salvar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppTermsRegister;
