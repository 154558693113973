import React, { useState, useEffect } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { addHours, format, parseISO } from 'date-fns';
import { endOfDay, startOfDay } from 'date-fns/esm';
import api from '~/services/api';
import { Container } from './styles';
import useSelector from '~/store/useSelector';
import { normalizeCpf, normalizeNumber } from '~/utils/normalize';
import OrderStatusIndicator from '~/components/OrderStatus/OrderStatus';
import { TableComponent } from '~/components/TableV2';

const List: React.FC = () => {
  const history = useHistory();
  const { permissions } = useSelector(state => state.auth);

  const [integrators, setIntegrators] = useState<any[]>([]);

  useEffect(() => {
    async function loadIntegrators() {
      const { data: dataIntegrators } = await api.get('/integrations');

      setIntegrators(dataIntegrators);
    }

    loadIntegrators();
  }, []);

  function switchFormPayment(form: string) {
    switch (form) {
      case 'card':
        return <span className="realizado">Cartão</span>;
      case 'two_cards':
        return <span className="realizado">2 Cartões</span>;
      case 'pix':
        return <span className="realizado">PIX</span>;
      case 'boleto':
        return <span className="warning">Boleto</span>;

      default:
        return false;
    }
  }

  function integratorPaymentGateway(orderPayment: any[]) {
    const arr: string[] = [];

    orderPayment.forEach((payment: any) => {
      if (
        arr.findIndex(
          integrator => integrator === payment?.orderPaymentCard?.integrator,
        ) === -1
      ) {
        if (payment?.orderPaymentCard?.integrator) {
          arr.push(payment?.orderPaymentCard?.integrator);
        }
      }
    });

    if (arr.length === 0) {
      return null;
    }

    return <span className="ml-1 bg-gray">{arr.join('/')}</span>;
  }

  return (
    <Container>
      <TableComponent
        filters={[
          {
            key: 'order_id',
            title: 'Id',
            type: 'text',
            col: 2,
          },
          {
            key: 'customer_name',
            title: 'Nome do Cliente',
            type: 'text',
            col: 2,
          },
          {
            key: 'cpf',
            title: 'CPF do Cliente',
            type: 'text',
            normalize: normalizeCpf,
            col: 2,
          },
          {
            key: 'startDate',
            title: 'De',
            type: 'date',
            col: 2,
          },
          {
            key: 'endDate',
            title: 'Até',
            type: 'date',
            col: 2,
          },

          {
            key: 'status',
            title: 'Status',
            type: 'select',
            options: [
              {
                title: 'Realizado',
                value: '1',
              },
              {
                title: 'Em Analise',
                value: '2',
              },
              {
                title: 'Aguardando Pagamento',
                value: '10',
              },
              {
                title: 'Aprovado',
                value: '3',
              },
              {
                title: 'Cancelado',
                value: '4',
              },
              {
                title: 'Negado',
                value: '5',
              },
              {
                title: 'Agendamento da entrega',
                value: '7',
              },
              {
                title: 'Em Rota',
                value: '8',
              },
              {
                title: 'Confirmação de entrega ',
                value: '9',
              },
              {
                title: 'Expirado',
                value: '11',
              },
              {
                title: 'Aprovado e não integrado (bandeira)',
                value: '11',
              },
              {
                title: 'Aprovado e não integrado',
                value: '13',
              },
              {
                title: 'Estornado',
                value: '14',
              },
              {
                title: 'Nota Fiscal Emitida',
                value: '15',
              },
            ],
            col: 2,
          },
          {
            key: 'origin',
            title: 'Origem',
            type: 'select',
            options: [
              {
                title: 'App',
                value: 'app',
              },
              {
                title: 'Site',
                value: 'site',
              },
              {
                title: 'Anymarket',
                value: 'anymarket',
              },
            ],
            col: 2,
          },
          {
            key: 'device',
            title: 'Dispositivo',
            type: 'select',
            options: [
              {
                title: 'Site',
                value: 'site',
              },
              {
                title: 'IOS',
                value: 'ios',
              },
              {
                title: 'Android',
                value: 'android',
              },
            ],
            col: 2,
          },
          {
            key: 'form_delivery',
            title: 'Forma de Entrega',
            type: 'select',
            options: [
              {
                title: 'Receba em Casa',
                value: 'frete',
              },
              {
                title: 'Receba na Loja',
                value: 'cliqueeretire',
              },
            ],
            col: 2,
          },

          {
            key: 'form_payment',
            title: 'Forma de Pagamento',
            type: 'select',
            options: [
              {
                title: 'Boleto',
                value: 'boleto',
              },
              {
                title: 'Cartão',
                value: 'card',
              },
              {
                title: '2 Cartões',
                value: 'two_cards',
              },
              {
                title: 'PIX',
                value: 'pix',
              },
            ],
            col: 2,
          },
          {
            key: 'integration_id',
            title: 'Integrador',
            type: 'select',
            options: integrators.map(integrator => ({
              title: integrator.name,
              value: integrator.id,
            })),
            col: 2,
          },
          {
            key: 'gateway_credit',
            title: 'Gateway (Cartão)',
            type: 'select',
            options: [
              {
                title: 'Safrapay',
                value: 'safrapay',
              },
              {
                title: 'ERede',
                value: 'eRede',
              },
            ],
            col: 2,
          },
          {
            key: 'has_livelo_points',
            title: 'Pontua Livelo',
            type: 'select',
            options: [
              {
                title: 'Sim',
                value: true,
              },
              {
                title: 'Não',
                value: false,
              },
            ],
            col: 2,
          },
        ]}
        labels={[
          {
            key: 'id',
            title: 'Id',
          },
          {
            key: 'code',
            title: 'Nº DO PEDIDO',
          },
          {
            key: 'finished_at',
            title: 'Data e hora',
            formater: (date, s) => {
              return s.finished_at
                ? format(parseISO(String(s.finished_at)), 'dd/MM/yyyy HH:mm')
                : format(parseISO(String(s.created_at)), 'dd/MM/yyyy HH:mm');
            },
          },
          {
            key: 'customerName',
            title: 'Cliente',
            formater: (orderCustomer, s) => {
              return `${s.customer.name} ${s.customer.lastname}`;
            },
            ordenable: false,
          },
          {
            key: 'status',
            title: 'Status',
            ordenable: false,
            formater: (status, s) => {
              return OrderStatusIndicator(s.orderStatus?.id);
            },
          },
          {
            key: 'total',
            title: 'Valor total',
            formater: (total, s) => {
              return `R$ ${(s.total / 100).toFixed(2)}`;
            },
          },
          {
            key: 'form_payment',
            title: 'Forma de pagamento',
            formater: (form, s) => {
              return (
                <>
                  {switchFormPayment(s.form_payment)}
                  {integratorPaymentGateway(s.orderPayment)}
                </>
              );
            },
          },
          {
            key: 'form_delivery',
            title: 'Forma de entrega',
            formater: s => {
              switch (s) {
                case 'frete':
                  return <span className="realizado">Receba em casa</span>;
                case 'cliqueeretire':
                  return <span className="warning">Retire na loja</span>;
                default:
                  return <span className="warning"> </span>;
              }
            },
          },
          {
            key: 'origin',
            title: 'Origem',
            formater: form => {
              switch (form) {
                case 'site':
                  return <span className="warning">Site</span>;
                case 'app':
                  return <span className="realizado">App</span>;
                case 'anymarket':
                  return (
                    <span style={{ background: '#ff9300', color: '#fff' }}>
                      Anymarket
                    </span>
                  );
                default:
                  return <span className="warning"> </span>;
              }
            },
          },
          {
            key: 'device',
            title: 'Dispositivo',
            formater: (i, s) => {
              return s.device ? s.device : 'Site';
            },
            ordenable: false,
          },
          {
            key: 'integration',
            title: 'Integrador',
            formater: (i, s) => {
              return s.integration ? s.integration.name : '';
            },
            ordenable: false,
          },
        ]}
        getData={async params => {
          const res = await api.get(`/orders`, {
            params: {
              ...params,
              cpf: normalizeNumber(params.cpf),
              date:
                (params.startDate || params.endDate) &&
                `${
                  params.startDate
                    ? format(
                        addHours(startOfDay(new Date(params.startDate)), 3),
                        'yyyy-MM-dd HH:mm:ss',
                      )
                    : ''
                }|${
                  params.endDate
                    ? format(
                        addHours(endOfDay(new Date(params.endDate)), 3),
                        'yyyy-MM-dd HH:mm:ss',
                      )
                    : ''
                }`,
            },
          });
          return res;
        }}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            {permissions.find(p => p.slug === 'ver_pedidos') ? (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => history.push(`/orders/view/${item.id}`)}
                >
                  Editar
                  <AiOutlineArrowRight />
                </button>
              </>
            ) : null}
          </div>
        )}
      />
    </Container>
  );
};

export default List;
