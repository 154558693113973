import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { toastr } from 'react-redux-toastr';
import Modal from '~/components/Modal';
import api from '~/services/api';

// import { Container } from './styles';

export const Integration: React.FC<{
  onHide: () => void;
  onUpdate: () => void;
  id: number | null;
}> = ({ onHide, onUpdate, id }) => {
  const [type, setType] = useState<null | 'woocomerce' | 'custom'>(
    'woocomerce',
  );

  const [loading, setLoading] = useState(false);

  const [customData, setCustomData] = useState({
    endpoint: '',
    authenticationType: '',
    authenticationToken: '',
  });

  useEffect(() => {
    async function handle() {
      setLoading(true);
      try {
        const { data } = await api.get(`/marketplace-integration/config/${id}`);
        if (data.type === 'woocomerce') {
          setCustomData({
            endpoint: data.endpoint,
            authenticationToken: data.authenticationToken,
            authenticationType: data.authenticationType,
          });
          setType(data.type);
        }
      } catch (err) {
        //
      }
      setLoading(false);
    }
    if (id) {
      handle();
    }
  }, [id]);

  async function handleSubmit() {
    setLoading(true);
    try {
      let body: any = {};

      if (type === 'woocomerce') {
        body = {
          ...customData,
          type: 'woocomerce',
        };
      }
      if (id) {
        await api.put(`/marketplace-integration/config/${id}`, body);
      } else {
        await api.post(`/marketplace-integration/config`, body);
      }
      toastr.info(`Sucesso`, 'Integração configurada');
      onUpdate();
    } catch (err) {
      if ((err as any).response) {
        toastr.error(`Erro`, (err as any).response.data.message);
      } else {
        toastr.error(`Erro`, (err as any).message);
      }
    }
    setLoading(false);
  }

  return (
    <Modal size="xl">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="m-0">Configuração Integração</h6>
        <button type="button" onClick={onHide}>
          <FaTimes />
        </button>
      </div>
      <div className="d-flex align-items-center mt-3">
        <button
          type="button"
          className={`${
            type === 'woocomerce' ? 'bg-primary text-white' : 'bg-white'
          } pl-3 pr-3 pt-2 pb-2 radius-4 shadow-sm mr-2`}
          onClick={() => setType('woocomerce')}
          disabled={loading}
        >
          <strong>Woocomerce</strong>
        </button>
      </div>
      <div className="row mt-4">
        {type === 'woocomerce' && (
          <>
            <div className="col-md-6">
              Informe o endpoint base de sua <i>API</i>
              <input
                type="text"
                className="form-control mb-2"
                disabled={loading}
              />
              <div className="d-flex mb-2">
                <select
                  value={customData.authenticationType}
                  onChange={e =>
                    setCustomData({
                      ...customData,
                      authenticationType: e.target.value,
                    })
                  }
                  className="form-control"
                  disabled={loading}
                  style={{ maxWidth: '140px' }}
                >
                  <option value="">Autenticação</option>
                  <option value="Bearer">Bearer</option>
                  <option value="Basic">Basic</option>
                </select>
                <input
                  type="text"
                  placeholder="TOKEN"
                  value={customData.authenticationToken}
                  onChange={e =>
                    setCustomData({
                      ...customData,
                      authenticationToken: e.target.value,
                    })
                  }
                  disabled={customData.authenticationType === '' || loading}
                  className="form-control w-100 ml-2"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="bg-gray p-4 radius-4">
                As credenciais de seu Woocomerce serão utilizados para:
                <ul className="pl-4 pt-2 m-0">
                  <li>Buscar todos produtos ativos</li>
                  <li>Inserir pedidos novos em seu woocomerce</li>
                </ul>
              </div>
            </div>
          </>
        )}
      </div>
      {type !== null && (
        <div className="d-flex justify-content-center mt-2">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={loading}
            className="btn btn-primary"
          >
            Salvar
          </button>
          <button
            type="button"
            onClick={onHide}
            disabled={loading}
            className="btn btn-outline-secondary ml-2"
          >
            Cancelar
          </button>
        </div>
      )}
    </Modal>
  );
};
