import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import api from '~/services/api';
import Button from '~/styles/components/Button';
import Can from '~/components/Can';
import useSelector from '~/store/useSelector';
import { TableComponent } from '~/components/TableV2';

const List: React.FC = () => {
  const { permissions } = useSelector(state => state.auth);

  const history = useHistory();

  return (
    <Container>
      <div className="d-flex justify-content-end">
        <Can checkPermission="campaign_store">
          <Button
            type="button"
            onClick={() => history.push('/campaigns/create')}
          >
            Nova Coleção
          </Button>
        </Can>
      </div>

      <TableComponent
        filters={[
          {
            key: 'name',
            title: 'Nome',
            type: 'text',
            col: 2,
          },
        ]}
        labels={[
          {
            key: 'code',
            title: 'Código',
          },
          {
            key: 'name',
            title: 'Nome',
          },
          {
            key: 'productsLength',
            title: 'Nº de Produtos',
            formater: (Length, d) => {
              return d.productCampaign.length;
            },
            ordenable: false,
          },
        ]}
        getData={async params => {
          const res = await api.get(`/campaigns`, {
            params,
          });
          return res;
        }}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            {permissions.find(p => p.slug === 'campaign_update') ? (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => history.push(`/campaigns/edit/${item.id}`)}
                >
                  Editar
                  <AiOutlineArrowRight />
                </button>
              </>
            ) : null}
          </div>
        )}
      />
    </Container>
  );
};

export default List;
