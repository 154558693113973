import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Select } from '~/components/Input/styles';
import Modal from '~/components/Modal';
import useSelector from '~/store/useSelector';
import Button from '~/styles/components/Button';

// const BASE_URL = 'https://apibb.lojasimperio.com.br';
// const BASE_URL = 'http://localhost:3347';
const BASE_URL = 'https://bbsia-api.lojasimperio.com.br';

type CsvType =
  | 'clientes'
  | 'compras'
  | 'contas'
  | 'encerraCartao'
  | 'inad'
  | 'limite'
  | 'motivoNeg'
  | 'negAutz'
  | 'plastico'
  | 'vendas'
  | 'clientes_email';

const csvTypes = {
  clientes: 'Clientes',
  compras: 'Compras',
  contas: 'Contas',
  encerraCartao: 'Encerra Cartão',
  inad: 'Inadimplência',
  limite: 'Limite',
  motivoNeg: 'Motivo de Negativação',
  negAutz: 'Negativação Autorizada',
  plastico: 'Plástico',
  vendas: 'Vendas',
  clientes_email: 'Emails de Clientes',
};

const UploadBbReport: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { token } = useSelector(state => state.auth);
  const [type, setType] = useState<CsvType | ''>('');
  const [file, setFile] = useState<File | null>(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  function handleUploadClick() {
    fileInputRef.current?.click();
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const _file = e.target.files?.[0];
    if (!_file) return;

    setFile(_file);
  }

  async function handleUpload() {
    if (!file) {
      return;
    }

    setIsUploading(true);

    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', type);

      const { data } = await axios.post(`${BASE_URL}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        params: {
          type,
        },
        timeout: 1000 * 60 * 5, // 5 minutes
      });

      setUploadMessage(data);
    } catch (error: any) {
      setUploadMessage(error.message);
    } finally {
      setIsUploading(false);
    }
  }

  return (
    <>
      <Button
        type="button"
        color="gray"
        style={{ width: '100%', minHeight: '4rem' }}
        onClick={() => setOpen(true)}
      >
        Geral
      </Button>
      {open && (
        <Modal>
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: '1rem' }}
          >
            <Button
              type="button"
              onClick={() => handleUploadClick()}
              style={{ width: '100%' }}
            >
              Selecionar arquivo
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept=".csv"
              onChange={handleFileChange}
            />
          </div>
          {file && (
            <div className="mt-2">
              <p>
                Arquivo selecionado: <strong>{file?.name}</strong>
              </p>
              <Select
                value={type}
                name="Nome"
                id="123"
                onChange={e => setType(e.target.value as CsvType)}
                required
                error={false}
              >
                <>
                  <option key="" value="">
                    Selecione um tipo
                  </option>
                  {Object.keys(csvTypes).map(key => (
                    <option key={key} value={key}>
                      {csvTypes[key as CsvType]}
                    </option>
                  ))}
                </>
              </Select>
              {!isUploading && (
                <Button
                  className="mt-2"
                  disabled={!type}
                  type="button"
                  onClick={handleUpload}
                >
                  Enviar
                </Button>
              )}
            </div>
          )}

          {isUploading && (
            <p>
              <small>Fazendo upload...</small>
            </p>
          )}

          {uploadMessage && (
            <p>
              <small>{uploadMessage}</small>
            </p>
          )}
          {!isUploading && (
            <Button
              type="button"
              color="gray"
              style={{ width: '100%', marginTop: '1rem' }}
              onClick={() => {
                setOpen(false);
                setFile(null);
                setUploadMessage('');
              }}
            >
              Cancelar
            </Button>
          )}
        </Modal>
      )}
    </>
  );
};

export default UploadBbReport;
