import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { Container } from './styles';
import api from '~/services/api';
import useSelector from '~/store/useSelector';
import Button from '~/styles/components/Button';
import Can from '~/components/Can';
import { TableComponent } from '~/components/TableV2';
import { normalizeCurrency } from '~/utils/normalize';

const List: React.FC = () => {
  const { permissions } = useSelector(state => state.auth);

  const history = useHistory();

  return (
    <Container>
      <Can checkPermission="coupon_store">
        <div className="d-flex justify-content-end">
          <Button type="button" onClick={() => history.push('/coupons/create')}>
            Novo Cupom
          </Button>
        </div>
      </Can>
      <TableComponent
        filters={[
          {
            key: 'code',
            title: 'Código',
            type: 'text',
            col: 2,
          },
          {
            key: 'active',
            title: 'Ativo',
            type: 'select',
            options: [
              {
                title: 'Ativo',
                value: '1',
              },
              {
                title: 'Inativo',
                value: '0',
              },
            ],
            col: 2,
          },
        ]}
        labels={[
          {
            key: 'code',
            title: 'Código',
          },
          {
            key: 'usageLimit',
            title: 'Usos/Limite',
          },
          {
            key: 'value',
            title: 'Desconto',
            formater: (value, item) => {
              if (item.type === 'value') {
                return `R$ ${normalizeCurrency(String(value))}`;
              }
              return `${normalizeCurrency(String(value) || '0000')}%`;
            },
          },
          {
            key: 'active',
            title: 'Ativo',
            formater: status =>
              status ? (
                <span className="ativo">Ativo</span>
              ) : (
                <span className="inativo">Inativo</span>
              ),
            ordenable: false,
          },
          {
            key: 'startDate',
            title: 'Data de Início',
            formater: (date, d) => {
              return format(parseISO(d.startDate), 'dd/MM/yyyy');
            },
          },
          {
            key: 'endDate',
            title: 'Data de Término',
            formater: (date, d) => {
              return format(parseISO(d.endDate), 'dd/MM/yyyy');
            },
          },
        ]}
        getData={async params => {
          const res = await api.get(`/coupons`, {
            params,
          });
          return res;
        }}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            {permissions.find(p => p.slug === 'banner_update') ? (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => history.push(`/coupons/edit/${item.id}`)}
                >
                  <AiOutlineArrowRight />
                </button>
              </>
            ) : null}
          </div>
        )}
      />
    </Container>
  );
};

export default List;
