import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaLink } from 'react-icons/fa';
import { format, subHours } from 'date-fns';
import api from '~/services/api';
import Button from '~/styles/components/Button';
import Can from '~/components/Can';
import useSelector from '~/store/useSelector';
import { TableComponent } from '~/components/TableV2';
import { normalizeCurrency } from '~/utils/normalize';

const PaymentLinks: React.FC = () => {
  const history = useHistory();
  const { permissions } = useSelector(state => state.auth);

  const [hardRefresh, setHardRefresh] = useState(false);

  useEffect(() => {
    if (hardRefresh) {
      setHardRefresh(false);
    }
  }, [hardRefresh]);

  return (
    <div>
      <div className="d-flex justify-content-between mb-1">
        <h5>
          <FaLink /> Links de Pagamento
        </h5>
        <Can checkPermission="criar_links_de_pagamento">
          <Button
            type="button"
            onClick={() => history.push('/payment-links/register')}
          >
            Novo Link
          </Button>
        </Can>
      </div>
      <TableComponent
        filters={[
          {
            key: 'active',
            title: 'Ativo',
            type: 'select',
            options: [
              {
                title: 'Sim',
                value: 1,
              },
              {
                title: 'Não',
                value: 0,
              },
            ],
            col: 2,
          },
          {
            key: 'completed',
            title: 'Pago',
            type: 'select',
            options: [
              {
                title: 'Sim',
                value: 1,
              },
              {
                title: 'Não',
                value: 0,
              },
            ],
            col: 2,
          },
          {
            key: 'title',
            title: 'Título',
            col: 3,
          },
        ]}
        labels={[
          {
            key: 'title',
            title: 'Título',
            ordenable: false,
          },
          {
            key: 'value',
            title: 'Valor',
            ordenable: false,
            formater: value =>
              `R$ ${normalizeCurrency(String(value || '000'))}`,
          },
          {
            key: 'active',
            title: 'Ativo',
            ordenable: false,
            formater: active => (
              <>
                <strong>{active ? 'SIM' : 'NÃO'}</strong>
              </>
            ),
          },
          {
            key: 'completed',
            title: 'Pago',
            ordenable: false,
            formater: completed => (
              <>
                <strong>{completed ? 'SIM' : 'NÃO'}</strong>
              </>
            ),
          },
          {
            key: 'created_at',
            title: 'Data inclusão',
            formater: created_at => {
              if (!created_at) return '';
              return format(
                subHours(new Date(created_at), 3),
                'dd/MM/yyyy HH:mm',
              );
            },
          },
          {
            key: 'updated_at',
            title: 'Última atualização',
            formater: created_at => {
              if (!created_at) return '';
              return format(
                subHours(new Date(created_at), 3),
                'dd/MM/yyyy HH:mm',
              );
            },
          },
        ]}
        defaultOrder={{
          name: 'updated_at',
          direction: 'desc',
        }}
        getData={async params => {
          const res = await api.get(`/payment-links`, {
            params,
          });
          return res;
        }}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            {permissions.find(
              p => p.slug === 'atualizar_links_de_pagamento',
            ) ? (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-primary white-space-no-wrap"
                  onClick={() =>
                    history.push(`/payment-links/register/${item.id}`)
                  }
                >
                  Ver Detalhes
                </button>
              </>
            ) : null}
          </div>
        )}
        hardRefresh={hardRefresh}
      />
    </div>
  );
};

export default PaymentLinks;
