import React, { useState } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container } from './styles';

const Create: React.FC = () => {
  const [name, setName] = useState('');
  const [appKey, setAppKey] = useState('');
  const [appToken, setAppToken] = useState('');
  const [seller, setSeller] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  async function handleAdd() {
    setLoading(true);
    try {
      const response = await api.post(`/vtex-sellers`, {
        name,
        app_key: appKey,
        app_token: appToken,
        seller,
      });

      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/vtex/list');
        toastr.success('Vendedor vtex criado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/vtex/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Criar Vendedor (Vtex)</h3>
        </div>
        <div className="body_block">
          <div className="row mb-3">
            <div className="col-md-4">
              <Input
                label="Nome"
                name="name"
                required
                onChange={e => setName(String(e.value))}
                disabled={loading}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <Input
                label="appKey"
                name="appKey"
                required
                onChange={e => setAppKey(String(e.value))}
                disabled={loading}
              />
            </div>

            <div className="col-md-4">
              <Input
                label="appToken"
                name="appToken"
                required
                onChange={e => setAppToken(String(e.value))}
                disabled={loading}
              />
            </div>

            <div className="col-md-4">
              <Input
                label="seller"
                name="seller"
                required
                onChange={e => setSeller(String(e.value))}
                disabled={loading}
              />
            </div>
          </div>
        </div>
        <div className="footer_block d-flex justify-content-end">
          <Button type="button" onClick={handleAdd}>
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Create;
