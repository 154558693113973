import React, { useEffect } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import Can from '~/components/Can';
import Input from '~/components/Input';
import { TableComponent } from '~/components/TableV2';
import api from '~/services/api';
import useSelector from '~/store/useSelector';
import Button from '~/styles/components/Button';
import { Container } from './styles';

const List: React.FC = () => {
  const { permissions } = useSelector(state => state.auth);

  const history = useHistory();

  const [specialSellers, setSpecialSellers] = React.useState({
    codes: '',
    only_sundays: 'false',
  });
  const [refreshSpecialSellers, setRefreshSpecialSellers] = React.useState(
    false,
  );

  useEffect(() => {
    if (refreshSpecialSellers) {
      setRefreshSpecialSellers(false);
    }
  }, [refreshSpecialSellers]);

  return (
    <Container>
      <div className="d-flex justify-content-end">
        <Can checkPermission="seller_store">
          <Button type="button" onClick={() => history.push('/sellers/create')}>
            Novo Vendedor
          </Button>
        </Can>
      </div>

      <TableComponent
        filters={[]}
        labels={[
          {
            key: 'name',
            title: 'Nome',
          },
          {
            key: 'code',
            title: 'Código',
          },
        ]}
        getData={async params => {
          const res = await api.get(`/sellers`, {
            params,
          });
          return res;
        }}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            {permissions.find(p => p.slug === 'seller_update') ? (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => history.push(`/sellers/view/${item.id}`)}
                >
                  Editar
                  <AiOutlineArrowRight />
                </button>
              </>
            ) : null}
          </div>
        )}
      />

      <br />
      <br />
      <h2>Vendedores especiais</h2>
      <Can checkPermission="atualizar_vendedores_especiais">
        <div className="d-flex align-items-center flex-row row">
          <div className="col-8">
            <Input
              label="Adicionar ou editar códigos de vendedores (separdos por vírgula)"
              name="reference"
              initialValue={specialSellers.codes}
              onChange={e => {
                setSpecialSellers({
                  ...specialSellers,
                  codes: e.value.toString(),
                });
              }}
            />
          </div>
          <div className="col-2">
            <Input
              label="Apenas domingos"
              name="only_sundays"
              initialValue={specialSellers.only_sundays}
              onChange={e => {
                setSpecialSellers({
                  ...specialSellers,
                  only_sundays: e.value.toString(),
                });
              }}
              select
              options={[
                {
                  title: 'Sim',
                  value: 'true',
                },
                {
                  title: 'Não',
                  value: 'false',
                },
              ]}
            >
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </Input>
          </div>
          <div className="col-2 mt-2">
            <Button
              disabled={!specialSellers.codes}
              onClick={async () => {
                if (!specialSellers.codes) {
                  return;
                }
                try {
                  const codes = specialSellers.codes.split(',');
                  await api.post('/special-sellers', {
                    codes,
                    only_sundays: specialSellers.only_sundays === 'true',
                  });
                  setRefreshSpecialSellers(true);
                } catch (error) {
                  console.log(error);
                }
              }}
              type="button"
            >
              Adicionar
            </Button>
          </div>
        </div>
      </Can>
      <TableComponent
        hardRefresh={refreshSpecialSellers}
        filters={[]}
        labels={[
          {
            key: 'seller_code',
            title: 'Código do vendedor',
          },
          {
            key: 'only_sundays',
            title: 'Apenas domingos',
            formater: (value: boolean) =>
              value ? (
                <span className="aprovado">Sim</span>
              ) : (
                <span className="negado">não</span>
              ),
          },
        ]}
        getData={async () => {
          const res = await api.get(`/special-sellers`);
          return res;
        }}
        additionalActions={item => (
          <Can checkPermission="atualizar_vendedores_especiais">
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="btn btn-sm btn-danger"
                onClick={async () => {
                  await api.delete(`/special-sellers/${item.seller_code}`);
                  setRefreshSpecialSellers(true);
                }}
              >
                Remover
              </button>
            </div>
          </Can>
        )}
      />
    </Container>
  );
};

export default List;
