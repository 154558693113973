import { format, parseISO } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import { FaChevronDown, FaChevronUp, FaTimesCircle } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import Checkbox from '~/components/Checkbox';
import Input from '~/components/Input';
import Modal from '~/components/Modal';
import api from '~/services/api';
import Button from '~/styles/components/Button';
import { states } from '~/utils/states';
import JoditEditorComponent from './JoditEditorComponent';
import { AutocompleteOptions, Container, Label } from './styles';

interface IProduct {
  id: number;
  code: string;
  title: string;
  stock: number;
  order: number;
}

interface ICampaign {
  name: string;
  code: string;
  description: string;
  is_active: boolean;
  state: string;
  image_url?: string;
  startDate: string;
  endDate: string;
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const [values, setValues] = useState<ICampaign>({
    name: '',
    code: '',
    description: '',
    is_active: true,
    state: '',
    startDate: '',
    endDate: '',
  });
  const [products, setProducts] = useState<IProduct[]>([]);
  const [queryProduct, setQueryProduct] = useState('');
  const [productsData, setProductsData] = useState<IProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [orderActive, setOrderActive] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const history = useHistory();

  useEffect(() => {
    async function loadCampaign() {
      try {
        const { data } = await api.get(`/campaigns/${match.params.id}`);

        setProducts(
          data.productCampaign.map((i: any, index: number) => {
            return {
              id: i.product.id,
              order: i.order || index + 1,
              code: i.product.sku,
              title: i.product.name,
            };
          }),
        );

        console.log(data);

        setValues({
          name: data.name,
          code: data.code,
          description: data.description,
          is_active: data.is_active,
          state: data.state,
          image_url: data.image_url,
          startDate: data.startDate
            ? format(parseISO(data.startDate), 'yyyy-MM-dd')
            : '',
          endDate: data.endDate
            ? format(parseISO(data.endDate), 'yyyy-MM-dd')
            : '',
        });
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
      setLoading(false);
    }
    loadCampaign();
  }, [match.params.id]); // eslint-disable-line

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setValues({
          ...values,
          image_url: reader.result as string,
        });
      };
      reader.readAsDataURL(file);

      try {
        // form data: file
        const formData = new FormData();
        formData.append('file', file);

        await api.put(`/campaigns/${match.params.id}/image`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toastr.success('Imagem atualizada', '');
      } catch (error: any) {
        const { status, data } = error;

        if (status === 400) {
          toastr.error('Ocorreu um erro', data?.message);
        } else {
          toastr.error('Ocorreu um erro', '');
        }
      }
    }
  };

  const handleDeleteImage = async () => {
    try {
      await api.delete(`/campaigns/${match.params.id}/image`);
      setValues({
        ...values,
        image_url: '',
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      toastr.success('Imagem excluída', '');
    } catch (error: any) {
      const { status, data } = error;

      if (status === 400) {
        toastr.error('Ocorreu um erro', data?.message);
      } else {
        toastr.error('Ocorreu um erro', '');
      }
    }
  };

  async function handleEdit() {
    const { id } = match.params;
    const { length: queryLength } = queryProduct.split(',');

    setLoading(true);

    try {
      const productsToUpdate =
        queryLength > 1
          ? { products: queryProduct, isSku: true }
          : {
              products: products.map(({ id: productId, order }) => ({
                product_id: productId,
                order,
              })),
              isSku: false,
            };

      if (!productsToUpdate.isSku && productsToUpdate.products.length === 0) {
        toastr.error('Validação', 'Nenhum produto selecionado');
        setLoading(false);
        return;
      }

      const { status, data } = await api.put(`/campaigns/${id}`, {
        ...values,
        ...productsToUpdate,
      });

      if (status === 203) {
        data?.forEach(({ message }: any) => toastr.error(message, ''));
      } else {
        history.push('/campaigns/list');
        toastr.success('Coleção atualizada', '');
      }
    } catch (error: any) {
      const { status, data } = error;

      if (status === 400) {
        toastr.error('Ocorreu um erro', data?.message);
      } else {
        toastr.error('Ocorreu um erro', '');
      }
    }

    setLoading(false);
  }

  async function handleSearchProduct(value: string) {
    setQueryProduct(value);
    if (value.length === 0) {
      setProductsData([]);
    } else {
      const { data } = await api.get(
        `products?name=${value}&status=1&perpage=5`,
      );
      setProductsData(
        data.data.map((p: any) => {
          return {
            ...p,
            id: p.id,
            title: p.name,
            code: p.sku,
            stock: p.stock,
            status: p.status,
          };
        }),
      );
    }
  }

  function handleDeleteProduct(index: number) {
    setProducts(products.filter((_, i) => i !== index));
  }

  function handleSelect(product: IProduct) {
    if (!products.find(p => p.id === product.id)) {
      setProducts([...products, { ...product, order: products.length + 1 }]);
      setQueryProduct('');
      setProductsData([]);
    }
  }

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/campaigns/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          history.push('/campaigns/list');
          toastr.error(i.message, '');
        });
      } else {
        history.push('/campaigns/list');
        toastr.success('Coleção excluída', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleGetByList(value: string) {
    const skus = value.split(';');
    const arr = [] as any[];
    setLoading(true);
    for (let i = 0; i < skus.length; i += 1) {
      const sku = skus[i];
      if (sku) {
        try {
          const { data } = await api.get(
            `products?name=${sku}&status=1&perpage=1`,
          );
          if (data.data[0] && !products.find(p => p.id === data.data[0].id)) {
            arr.push({
              ...data.data[0],
              order: products.length + arr.length,
              code: data.data[0].sku,
              title: data.data[0].name,
            });
          }
        } catch (err) {
          //
        }
      }
    }
    setProducts([...products, ...arr]);
    setLoading(false);
  }

  return (
    <Container>
      {orderActive ? (
        <Modal size="xl">
          <div style={{ maxHeight: '40vh', overflow: 'auto' }}>
            <table className="table table-responsive-lg table-sm table-bordered table-striped table-hover">
              <thead>
                <tr>
                  <th>Ordem</th>
                  <th>Produto</th>
                </tr>
              </thead>
              <tbody>
                {products
                  ?.sort((a, b) => a.order - b.order)
                  ?.map((p, index) => (
                    <tr key={p.id}>
                      <td style={{ maxWidth: '80px' }}>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            type="button"
                            onClick={() => {
                              const arr = [...products];
                              arr[index].order = p.order - 1;
                              if (
                                arr[index - 1] &&
                                arr[index - 1].order === p.order
                              ) {
                                arr[index - 1].order = p.order + 1;
                              }

                              setProducts(arr);
                            }}
                            disabled={p.order === 1}
                          >
                            <FaChevronUp />
                          </button>
                          <strong>{p.order}</strong>
                          <button
                            type="button"
                            onClick={() => {
                              const arr = [...products];
                              arr[index].order = p.order + 1;
                              if (
                                arr[index + 1] &&
                                arr[index + 1].order === p.order
                              ) {
                                arr[index + 1].order = p.order - 1;
                              }

                              setProducts(arr);
                            }}
                            disabled={p.order >= products.length}
                          >
                            <FaChevronDown />
                          </button>
                        </div>
                      </td>
                      <td>
                        <strong>{p.code}</strong> - {p.title?.slice(0, 70)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="d-flex align-items-center">
              <Button type="button" onClick={() => setOrderActive(false)}>
                Fechar
              </Button>
            </div>
          </div>
        </Modal>
      ) : null}
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/campaigns/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Editar Coleção</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="body_block">
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Input
                      label="Nome"
                      name="name"
                      required
                      onChange={e =>
                        setValues({
                          ...values,
                          name: String(e.value),
                        })
                      }
                      disabled={loading}
                      initialChanges
                      initialValue={values.name}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      label="Código"
                      name="code"
                      required
                      onChange={e =>
                        setValues({
                          ...values,
                          code: String(e.value),
                        })
                      }
                      disabled={loading}
                      initialChanges
                      initialValue={values.code}
                    />
                  </div>

                  <div className="row mb-2">
                    <div className="col-md-12">
                      <Label>Conteúdo</Label>
                      <JoditEditorComponent
                        placeholder={values.description}
                        value={values.description}
                        onChange={newValue =>
                          setValues({
                            ...values,
                            description: String(newValue),
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-3">
                    <Input
                      label="Data de início"
                      name="startDate"
                      type="date"
                      required
                      onChange={e =>
                        setValues({
                          ...values,
                          startDate: String(e.value),
                        })
                      }
                      disabled={loading}
                      initialChanges
                      initialValue={values.startDate}
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      label="Data de término"
                      name="endDate"
                      type="date"
                      required
                      onChange={e =>
                        setValues({
                          ...values,
                          endDate: String(e.value),
                        })
                      }
                      disabled={loading}
                      initialChanges
                      initialValue={values.endDate}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6" style={{ position: 'relative' }}>
                    <Input
                      label="Produtos (apenas ativos e em estoque)"
                      name="productsQuery"
                      initialValue={queryProduct}
                      initialChanges
                      required
                      onChange={e => handleSearchProduct(String(e.value))}
                      disabled={loading}
                      autoComplete="off"
                    />
                    {queryProduct.length > 0 && productsData.length > 0 && (
                      <AutocompleteOptions>
                        {productsData
                          .filter(p => p.stock > 0)
                          .map(p => (
                            <button
                              type="button"
                              key={p.id}
                              onClick={() => handleSelect(p)}
                            >
                              {p.code} - {p.title}
                            </button>
                          ))}
                      </AutocompleteOptions>
                    )}
                    {products.length > 0 ? (
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm mb-2"
                        onClick={() => {
                          setProducts([]);
                        }}
                      >
                        Remover todos os produtos
                      </button>
                    ) : null}
                    <div style={{ maxHeight: '20vh', overflow: 'auto' }}>
                      {products.map((p, index) => (
                        <div
                          key={p.id}
                          className="d-flex justify-content-between product-added"
                        >
                          <p className="mb-0">
                            <strong>{p.code}</strong> - {p.title}
                          </p>
                          <button
                            className="text-danger ml-2"
                            type="button"
                            onClick={() => handleDeleteProduct(index)}
                          >
                            <FaTimesCircle />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-3 pt-3">
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary"
                      onClick={() => {
                        const list = window.prompt(
                          "Cole aqui a lista de SKU's separados por ;",
                        );
                        handleGetByList(list || '');
                      }}
                      disabled={loading}
                    >
                      {loading
                        ? 'Carregando'
                        : 'Inserir produtos por lista de SKU'}
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-info mt-2"
                      onClick={() => setOrderActive(true)}
                    >
                      Definir ordenação
                    </button>
                  </div>
                  <div className="col-md-2">
                    <Input
                      label="Estado"
                      name="state"
                      initialChanges
                      initialValue={values.state}
                      onChange={e =>
                        setValues({
                          ...values,
                          state: String(e.value),
                        })
                      }
                      disabled={loading}
                      select
                      options={states.map(s => ({
                        title: s.desc,
                        value: s.uf,
                      }))}
                    />
                  </div>
                  <div style={{ marginTop: '10px' }}>
                    <Checkbox
                      name="is_active"
                      label="Ativo"
                      checked={values.is_active}
                      onChange={() =>
                        setValues({
                          ...values,
                          is_active: !values.is_active,
                        })
                      }
                    />
                  </div>
                </div>
                <label
                  htmlFor="image"
                  style={{
                    border: '2px dashed #c9c9c9',
                    borderRadius: '4px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    cursor: 'pointer',
                    width: '250px',
                    height: '150px',
                    color: '#999',
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  <Label>
                    Adicionar imagem de recomendação de produto (200x40)
                  </Label>
                  <input
                    style={{ display: 'none' }}
                    id="image"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    ref={fileInputRef}
                  />
                  {values.image_url && (
                    <img
                      src={values.image_url}
                      alt="Preview"
                      style={{
                        width: 200,
                        height: 40,
                        borderRadius: 4,
                        border: '2px solid #c9c9c9',
                      }}
                    />
                  )}
                </label>
                {values.image_url && (
                  <Button
                    type="button"
                    color="danger"
                    onClick={handleDeleteImage}
                  >
                    Excluir imagem
                  </Button>
                )}
              </div>
            </div>
            {!confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <Button type="button" disabled={loading} onClick={handleEdit}>
                  {loading ? 'Salvando...' : 'Salvar'}
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="ml-2"
                  disabled={loading}
                  onClick={() => setConfirmDelete(true)}
                >
                  {loading ? '...' : 'Excluir Coleção'}
                </Button>
              </div>
            )}
            {confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <div>
                  <h3 style={{ textAlign: 'right' }}>
                    Você tem certeza que deseja excluir esta coleção?
                  </h3>
                  <div>
                    <Button
                      type="button"
                      color="danger"
                      disabled={loading}
                      onClick={handleDelete}
                    >
                      {loading ? 'Excluindo...' : 'Excluir'}
                    </Button>
                    <Button
                      type="button"
                      color="gray"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmDelete(false)}
                    >
                      {loading ? '...' : 'Cancelar'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
