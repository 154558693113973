import styled from 'styled-components';

export const Container = styled.div`
  .back-arrow {
    font-size: 2rem;
  }
  .forward-arrow {
    font-size: 2rem;
  }
`;

interface IPreview {
  size: 'carroussel' | 'banner-right' | 'banner-bottom';
  image?: string;
}

const prevSizes = {
  carroussel: {
    width: '809px',
    height: '317px',
  },
  'banner-right': {
    width: '410px',
    height: '215px',
  },
  'banner-bottom': {
    width: '100%',
    height: '100px',
  },
};

export const Preview = styled.label<IPreview>`
  cursor: pointer;
  width: ${props => prevSizes[props.size].width};
  height: ${props => prevSizes[props.size].height};
  border: 2px dashed #c9c9c9;
  border-radius: 4px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: ${props => (props.image ? `url(${props.image})` : '')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
