import React, { useEffect, useRef, useState } from 'react';

import JoditEditor from 'jodit-react';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import api from '~/services/api';
import Input from '~/components/Input';
import InputFile from '~/components/InputFile';
import Button from '~/styles/components/Button';

import { Container, Label } from './styles';

interface ICategory {
  id: number;
  name: string;
  categories: ICategory[];
}

const Create: React.FC = () => {
  const editor = useRef(null);
  const [category_id, setCategory_id] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [categories, setCategories] = useState<ICategory[]>([]);

  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    async function loadCategories() {
      try {
        const { data: categoriesData } = await api.get(
          '/categories?page=1&perpage=100000',
        );
        const categoriesArr = categoriesData.data.reduce(
          (categoryArr: ICategory[], c: ICategory) => {
            categoryArr.push(c);

            const subcategories = c.categories;

            if (subcategories.length > 0) {
              for (let i = 0; i < subcategories.length; i += 1) {
                const subcategory = subcategories[i];

                const subcategoryCopy = { ...subcategory };

                subcategoryCopy.name = `${c.name} > ${subcategory.name}`;

                categoryArr.push(subcategoryCopy);

                if (subcategory.categories.length > 0) {
                  subcategory.categories.forEach(subsubcategory => {
                    subsubcategory.name = `${c.name} > ${subcategory.name} > ${subsubcategory.name}`;

                    categoryArr.push(subsubcategory);
                  });
                }
              }
            }

            return categoryArr;
          },
          [],
        );
        categoriesArr.unshift({
          id: null,
          name: 'Raiz',
        });
        setCategories(categoriesArr);
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadCategories();
  }, []); // eslint-disable-line

  async function handleAdd() {
    setLoading(true);
    const obj = new FormData();
    if (image) {
      obj.append('file', image);
    }
    try {
      const response = await api.post(
        `/categories?name=${name}&description=${description}${
          category_id !== 'null' ? `&category_id=${category_id}` : ''
        }`,
        obj,
      );
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/categories/list');
        toastr.success('Categoria criada', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/categories/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Criar Categoria</h3>
        </div>
        <div className="body_block">
          <div className="row mb-3">
            <div className="col-md-12 mb-2">
              <InputFile
                label="Imagem"
                name="image"
                required
                onChange={e => setImage(e.value)}
                onError={() => console.log(1) /* eslint-disable-line */}
                onReset={() => console.log(1) /* eslint-disable-line */}
                disabled={loading}
                minHeight="200px"
              />
            </div>
            <div className="col-md-6 mb-2">
              <Input
                label="Categoria Pai"
                name="category_id"
                required
                select
                options={categories.map(p => {
                  return {
                    title: p.name,
                    value: String(p.id),
                  };
                })}
                onChange={e =>
                  typeof e.value === 'string' && setCategory_id(e.value)
                }
              />
            </div>
            <div className="col-md-6 mb-2">
              <Input
                label="Nome"
                name="name"
                required
                onChange={e => setName(String(e.value))}
                disabled={loading}
              />
            </div>
            <div className="col-md-12">
              <Label>Descrição</Label>
              <JoditEditor
                ref={editor}
                value={description}
                onChange={newContent => setDescription(newContent)}
              />
            </div>
          </div>
        </div>
        <div className="footer_block">
          <Button type="button" disabled={loading} onClick={handleAdd}>
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Create;
