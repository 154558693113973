import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'dotenv/config';

Sentry.init({
  dsn: process.env.SENTRY_DNS,
  environment: process.env.SENTRY_ENV,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
