import React from 'react';
import './styles.css';

export default function OrderStatusIndicator(statusId: number) {
  switch (statusId) {
    case 1:
      return <span className="realizado">Realizado</span>;
    case 2:
      return <span className="analise">Em Análise</span>;
    case 3:
      return <span className="aprovado">Aprovado</span>;
    case 4:
      return <span className="cancelado">Cancelado</span>;
    case 5:
      return <span className="negado">Negado</span>;
    case 6:
      return <span className="error">Error</span>;
    case 7:
      return <span className="analise">Agendamento da entrega</span>;
    case 8:
      return <span className="analise">Em rota</span>;
    case 9:
      return <span className="analise">Confirmação de entrega</span>;
    case 10:
      return <span className="analise">Aguardando Pagamento</span>;
    case 11:
      return <span className="cancelado">Expirado</span>;
    case 12:
      return (
        <span className="aprovado-nao-integrado">
          Aprovado e não integrado (bandeira)
        </span>
      );
    case 13:
      return (
        <span className="aprovado-nao-integrado">Aprovado e não integrado</span>
      );
    case 14:
      return <span className="cancelado">Estornado</span>;
    case 15:
      return <span className="aprovado">Nota Fiscal Emitida</span>;
    default:
      return <></>;
  }
}
