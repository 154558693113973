import styled, { css } from 'styled-components';

const sizes: any = { // eslint-disable-line
  small: css`
    height: 16px;
    font-size: 10px;
  `,
  default: css`
    height: 26px;
    font-size: 12px;
  `,
  big: css`
    height: 34px;
    font-size: 14px;
  `,
};

const colors: any = { // eslint-disable-line
  default: css`
    background: #ff9300;

    &:hover {
      background: #d88413;
    }
  `,
  danger: css`
    background: #e04848;

    &:hover {
      background: #a43d3d;
    }
  `,
  gray: css`
    background: #b9bbbe;
    color: #666;

    &:hover {
      background: #999;
    }
  `,
};

interface IButton {
  size?: 'small' | 'big' | 'default';
  color?: 'default' | 'danger' | 'gray';
  filled?: boolean;
}

const Button = styled.button`
  border-radius: 3px;
  transition: background-color 0.15s ease;
  background: #ff9300;
  border: 0;
  color: #fff;
  font-size: 12px;
  padding: 0px 10px;
  text-transform: uppercase;
  font-weight: 700;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  ${(props: IButton) => sizes[props.size || 'default']}
  ${(props: IButton) => colors[props.color || 'default']}

  ${(props: IButton) =>
    props.filled === false &&
    css`
      background: none;
      color: #444;
      &:hover {
        background: none;
        opacity: 0.6;
      }
    `}
`;

export default Button;
