import React, { useState, useEffect } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';
import { cpfIsValid } from '~/utils/validation';

import { Container } from './styles';
import { normalizeNumber } from '~/utils/normalize';

interface ISeller {
  code: string;
  name: string;
  cpf: string;
  loading: boolean;
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const [seller, setSeller] = useState<ISeller>({
    name: '',
    code: '',
    cpf: '',
    loading: false,
  });
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [cpf, setCpf] = useState('');
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const history = useHistory();

  useEffect(() => {
    async function loadSeller() {
      try {
        const { data } = await api.get(`/sellers/${match.params.id}`);
        setSeller({
          ...seller,
          name: data.name,
          code: data.code,
          cpf: data.cpf,
        });
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadSeller();
  }, [match.params.id]); // eslint-disable-line

  async function handleEdit() {
    if (!cpfIsValid(cpf)) {
      toastr.error('Validação', 'CPF Informado inválido');
      return;
    }
    setLoading(true);
    try {
      const response = await api.put(`/sellers/${match.params.id}`, {
        name: name || seller.name,
        code: code || seller.code,
        cpf: normalizeNumber(cpf || seller.cpf),
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/sellers/list');
        toastr.success('Vendedor atualizado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/sellers/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/sellers/list');
        toastr.success('Vendedor deletado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/sellers/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Editar Vendedor</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row mb-3">
                <div className="col-md-4">
                  <Input
                    label="Nome"
                    name="name"
                    required
                    initialValue={seller.name}
                    onChange={e => setName(String(e.value))}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    label="CPF"
                    name="cpf"
                    required
                    initialValue={seller.cpf}
                    onChange={e => setCpf(String(e.value))}
                  />
                </div>
                <div className="col-md-4">
                  <Input
                    label="Código"
                    name="code"
                    required
                    initialValue={seller.code}
                    onChange={e => setCode(String(e.value))}
                  />
                </div>
              </div>
            </div>
            {!confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <Button type="button" disabled={loading} onClick={handleEdit}>
                  {loading ? 'Salvando...' : 'Salvar'}
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="ml-2"
                  disabled={loading}
                  onClick={() => setConfirmDelete(true)}
                >
                  {loading ? '...' : 'Excluir Vendedor'}
                </Button>
              </div>
            )}
            {confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <div>
                  <h3 style={{ textAlign: 'right' }}>
                    Você tem certeza que deseja excluir este vendedor?
                  </h3>
                  <div>
                    <Button
                      type="button"
                      color="danger"
                      disabled={loading}
                      onClick={handleDelete}
                    >
                      {loading ? 'Excluindo...' : 'Excluir'}
                    </Button>
                    <Button
                      type="button"
                      color="gray"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmDelete(false)}
                    >
                      {loading ? '...' : 'Cancelar'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
