import { format, parseISO, subHours } from 'date-fns';
import React, { Fragment, useEffect, useState } from 'react';
import { IoMdAnalytics } from 'react-icons/io';
import {
  MdComputer,
  MdCreditCard,
  MdError,
  MdQueryBuilder,
  MdSmartphone,
  MdStore,
  MdWarning,
} from 'react-icons/md';
import api from '~/services/api';
import { normalizeCurrency } from '~/utils/normalize';

interface ERedeErrorOrder {
  id: number;
  total: number;
  created_at: Date;
  name: string;
  lastname: string;
}

interface PreApprovedOrder {
  id: number;
  total: number;
  created_at: Date;
  name: string;
  lastname: string;
}

interface DeniedOrder {
  id: number;
  total: number;
  created_at: Date;
  name: string;
  lastname: string;
  data: string;
}

interface IErrorOrder {
  order_id: number;
  detail: string;
  created_at: string;
}

interface INotBrandOrder {
  order_id: number;
  created_at: string;
}

interface ITopProducts {
  product_id: number;
  name: string;
  qtd: {
    type: string;
    data: number[];
  };
}

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [todaySite, setTodaySite] = useState(null);
  const [yesterdaySite, setYesterdaySite] = useState(null);
  const [weekSite, setWeekSite] = useState(null);
  const [averageTicketSite, setAverageTicketSite] = useState<{
    qtd: number;
    total: number;
  }>({
    qtd: 0,
    total: 0,
  });
  const [todayApp, setTodayApp] = useState(null);
  const [yesterdayApp, setYesterdayApp] = useState(null);
  const [weekApp, setWeekApp] = useState(null);
  const [averageTicketApp, setAverageTicketApp] = useState<{
    qtd: number;
    total: number;
  }>({
    qtd: 0,
    total: 0,
  });
  const [ordersError, setOrdersError] = useState<IErrorOrder[]>([]);
  // const [ordersNotBrand, setOrdersNotBrand] = useState<INotBrandOrder[]>([]);
  const [eRedeOrdersError, setERedeOrdersError] = useState<ERedeErrorOrder[]>(
    [],
  );
  const [preApprovedOrders, setPreApprovedOrders] = useState<
    PreApprovedOrder[]
  >([]);
  const [deniedOrders, setDeniedOrders] = useState<DeniedOrder[]>([]);
  const [qtdPercentCategory, setQtdPercentCategory] = useState<number>(0);
  const [topProductsImperio, setTopProductsImperio] = useState<ITopProducts[]>(
    [],
  );
  const [topProductsMarketplace, setTopProductsMarketplace] = useState<
    ITopProducts[]
  >([]);

  useEffect(() => {
    async function loadOrdersSite() {
      const { data } = await api.get(`/dashboard/orders?origin=site`);
      setYesterdaySite(data.yesterday.qtd);
      setWeekSite(data.week.qtd);
      setTodaySite(data.today.qtd);
      if (data.averageTicket) {
        setAverageTicketSite({
          qtd: data.averageTicket.qtd ? data.averageTicket.qtd : 0,
          total: data.averageTicket.total ? data.averageTicket.total : 0,
        });
      }
    }
    loadOrdersSite();
  }, []);

  useEffect(() => {
    async function loadOrdersApp() {
      const { data } = await api.get(`/dashboard/orders?origin=app`);
      setYesterdayApp(data.yesterday.qtd);
      setWeekApp(data.week.qtd);
      setTodayApp(data.today.qtd);
      if (data.averageTicket) {
        setAverageTicketApp({
          qtd: data.averageTicket.qtd ? data.averageTicket.qtd : 0,
          total: data.averageTicket.total ? data.averageTicket.total : 0,
        });
      }
    }
    loadOrdersApp();
  }, []);

  useEffect(() => {
    async function loadPercentCategories() {
      const { data } = await api.get(`/dashboard/percent-categories/error`);
      setQtdPercentCategory(data.length);
    }
    loadPercentCategories();
  }, []);

  useEffect(() => {
    async function loadOrdersError() {
      const { data } = await api.get(`/dashboard/percent/error`, {
        params: {
          min_date: '2024-05-20',
        },
      });
      setOrdersError(data);
    }
    loadOrdersError();
  }, []);

  // useEffect(() => {
  //   async function loadOrdersNotBrand() {
  //     const { data } = await api.get(`/dashboard/percent/not_brand`);
  //     setOrdersNotBrand(data);
  //   }
  //   loadOrdersNotBrand();
  // }, []);

  useEffect(() => {
    async function loadPreApprovedOrders() {
      const { data } = await api.get(`/dashboard/preapproved_orders`);
      setPreApprovedOrders(data);
    }
    loadPreApprovedOrders();
  }, []);

  useEffect(() => {
    async function loadDeniedOrders() {
      const { data } = await api.get(`/dashboard/denied_orders`);
      setDeniedOrders(data);
    }
    loadDeniedOrders();
  }, []);

  useEffect(() => {
    async function loadERedeOrdersError() {
      const { data } = await api.get(`/dashboard/error_orders`);
      setERedeOrdersError(data);
    }
    loadERedeOrdersError();
  }, []);

  async function handleInsertDav(order_id: number) {
    setLoading(true);
    try {
      await api.get(`/percent/insertDav/${order_id}`);
      const { data } = await api.get(`/dashboard/percent/error`);
      setOrdersError(data);
    } catch (error) {} // eslint-disable-line
    setLoading(false);
  }

  // async function handleBrandInsertDav(brand: string, order_id: number) {
  //   setLoading(true);
  //   try {
  //     await api.post(`/percent/insertDav/bandeira/`, {
  //       order_id,
  //       brand,
  //     });
  //     const { data } = await api.get(`/dashboard/percent/not_brand`);
  //     setOrdersNotBrand(data);
  //     setOpen(false);
  //     setOrderId(0);
  //   } catch (error) {} // eslint-disable-line
  //   setLoading(false);
  // }

  useEffect(() => {
    async function loadTopProducts() {
      const { data } = await api.get(`/dashboard/topproducts`);
      setTopProductsImperio(data.imperio);
      setTopProductsMarketplace(data.marketplace);
    }
    loadTopProducts();
  }, []);

  return (
    <>
      <header>
        <h1 className="mb-0">
          <IoMdAnalytics /> <strong>Dashboard</strong>
        </h1>
        <div />
      </header>
      {/* {open && (
        <Modal>
          <Input
            type="text"
            label="Bandeira"
            name="bandeira"
            onChange={e => {
              setBandeira(String(e.value));
            }}
          />
          <Button
            type="button"
            onClick={() => handleBrandInsertDav(bandeira, orderId)}
          >
            Enviar
          </Button>
          <Button
            type="button"
            color="gray"
            style={{ width: '100%', marginTop: '1rem' }}
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Modal>
      )} */}
      <div className="row">
        {qtdPercentCategory > 0 && (
          <div className="col-md-12">
            <h6 className="d-block alert alert-danger p-2">
              <MdError className="text-danger mr-1" />
              <i>
                Existe {qtdPercentCategory} categorias sem relacionamento,{' '}
                <a href="/percent-categories/list" target="blank">
                  clique para relaciona-las
                </a>{' '}
              </i>
            </h6>
          </div>
        )}
        <div className="col-md-6 mb-4">
          <div className="bg-white p-3 radius-4 shadow-sm">
            <h6>
              <MdComputer /> Resumo Site -{' '}
              <i className="text-muted">lojasimperio.com.br</i>
            </h6>
            <hr />
            <div className="row">
              <div className="col-md-4">
                <small className="d-block">
                  <i>Fluxo de pedidos</i>
                </small>
                <div className="mt-2">
                  <p className="mb-1">
                    <strong>{todaySite}</strong> Pedido(s) hoje
                  </p>
                  <p className="mb-1">
                    <strong>{yesterdaySite}</strong> Pedido(s) ontem
                  </p>
                  <p className="mb-1">
                    <strong>{weekSite}</strong> Pedido(s) há uma semana
                  </p>
                </div>
              </div>
              <div className="col-md-8">
                <small className="d-block">
                  <i>Faturamento</i>
                </small>
                <p className="mb-0 mt-2">
                  Total de <strong>{averageTicketSite.qtd}</strong> Pedidos, com
                  Ticket Médio de{' '}
                  <strong>
                    R${' '}
                    {normalizeCurrency(
                      String(
                        Math.floor(
                          averageTicketSite.total / averageTicketSite.qtd,
                        ),
                      ),
                    )}
                  </strong>
                  .<br />
                  Gerando um total de{' '}
                  <strong>
                    R$ {normalizeCurrency(String(averageTicketSite.total))}
                  </strong>{' '}
                  em receita.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="bg-white p-3 radius-4 shadow-sm">
            <h6>
              <MdSmartphone /> Resumo App
            </h6>
            <hr />
            <div className="row">
              <div className="col-md-4">
                <small className="d-block">
                  <i>Fluxo de pedidos</i>
                </small>
                <div className="mt-2">
                  <p className="mb-1">
                    <strong>{todayApp}</strong> Pedido(s) hoje
                  </p>
                  <p className="mb-1">
                    <strong>{yesterdayApp}</strong> Pedido(s) ontem
                  </p>
                  <p className="mb-1">
                    <strong>{weekApp}</strong> Pedido(s) há uma semana
                  </p>
                </div>
              </div>
              <div className="col-md-8">
                <small className="d-block">
                  <i>Faturamento</i>
                </small>
                <p className="mb-0 mt-2">
                  Total de <strong>{averageTicketApp.qtd}</strong> Pedidos, com
                  Ticket Médio de{' '}
                  <strong>
                    R${' '}
                    {normalizeCurrency(
                      String(
                        Math.floor(
                          averageTicketApp.total / averageTicketApp.qtd,
                        ),
                      ),
                    )}
                  </strong>
                  .<br />
                  Gerando um total de{' '}
                  <strong>
                    R$ {normalizeCurrency(String(averageTicketApp.total))}
                  </strong>{' '}
                  em receita.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="bg-white p-3 radius-4 shadow-sm">
            <div className="d-flex align-items-center">
              <img src="/favicon.png" alt="Império" />
              <h6 className="ml-2 mb-0 text-primary">
                <strong>Produtos mais vendidos da Império</strong>
              </h6>
            </div>
            <hr className="mb-0" />
            <div style={{ height: '400px', overflow: 'auto' }}>
              <table className="w-100 table table-hover">
                <thead>
                  <tr>
                    <th className="p-2">Qtd</th>
                    <th className="p-2">Produto</th>
                    <th className="p-2"> </th>
                  </tr>
                </thead>
                <tbody>
                  {topProductsImperio.map(p => (
                    <tr key={p.product_id}>
                      <td className="p-2">{p.qtd}</td>
                      <td className="p-2">
                        <small>{p.name}</small>
                      </td>
                      <td className="p-2">
                        <a
                          href={`https://lojasimperio.com.br/${p.product_id}`}
                          target="blank"
                        >
                          Ver
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="bg-white p-3 radius-4 shadow-sm">
            <div className="d-flex align-items-center">
              <MdStore size={30} />
              <h6 className="ml-2 mb-0 text-dark">
                <strong>Produtos mais vendidos Marketplace</strong>
              </h6>
            </div>
            <hr className="mb-0" />
            <div style={{ height: '400px', overflow: 'auto' }}>
              <table className="w-100 table table-hover">
                <thead>
                  <tr>
                    <th className="p-2">Qtd</th>
                    <th className="p-2">Produto</th>
                    <th className="p-2"> </th>
                  </tr>
                </thead>
                <tbody>
                  {topProductsMarketplace.map(p => (
                    <tr key={p.product_id}>
                      <td className="p-2">{p.qtd}</td>
                      <td className="p-2">
                        <small>{p.name}</small>
                      </td>
                      <td className="p-2">
                        <a
                          href={`https://lojasimperio.com.br/${p.product_id}`}
                          target="blank"
                        >
                          Ver
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="bg-white p-3 radius-4 shadow-sm border-1 border-danger">
            <div className="d-flex align-items-center">
              <MdError className="text-danger" size={30} />
              <h6 className="ml-2 mb-0 text-danger">
                <strong>Pedidos com erro de integração</strong>
              </h6>
            </div>
            <hr className="mb-0" />
            <div style={{ height: '400px', overflow: 'auto' }}>
              <table className="w-100 table table-hover">
                <thead>
                  <tr>
                    <th className="p-2">Pedido</th>
                    <th className="p-2">Data</th>
                    <th className="p-2"> </th>
                  </tr>
                </thead>
                <tbody>
                  {ordersError.map(p => (
                    <Fragment key={p.order_id}>
                      <tr>
                        <td
                          className="p-2"
                          style={{ borderTop: '1px solid #222' }}
                        >
                          <small>
                            <i>{p.order_id}</i>
                          </small>
                        </td>
                        <td
                          className="p-2"
                          style={{ borderTop: '1px solid #222' }}
                        >
                          <small>
                            {p.created_at
                              ? format(
                                  subHours(parseISO(p.created_at), 3),
                                  'dd/MM/yyyy HH:mm',
                                )
                              : ''}
                          </small>
                        </td>
                        <td
                          className="p-2 text-right"
                          style={{ borderTop: '1px solid #222' }}
                        >
                          <button
                            type="button"
                            className="btn btn-primary btn-sm pt-0 pb-0 pl-1 pr-1"
                            disabled={loading}
                            onClick={() => handleInsertDav(p.order_id)}
                          >
                            {loading ? 'Enviando...' : 'Reenviar'}
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm pt-0 pb-0 pl-1 pr-1 ml-1"
                            disabled={loading}
                            onClick={() =>
                              window.open(`/orders/view/${p.order_id}`)
                            }
                          >
                            Ver Pedido
                          </button>
                        </td>
                      </tr>
                      <tr className="bg-gray">
                        <td colSpan={3} className="p-2">
                          {p.detail.length > 170 ? (
                            <button
                              type="button"
                              className="pt-0 pb-0 pl-1 pr-1 btn btn-sm btn-light"
                              onClick={() => {
                                window.alert(
                                  `Mensagem pedido ${p.order_id}: ${p.detail}`,
                                );
                              }}
                            >
                              Ver Mensagem
                            </button>
                          ) : (
                            <small>
                              <i>{p.detail}</i>
                            </small>
                          )}
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-5">
          <div className="bg-white p-3 radius-4 shadow-sm">
            <div className="d-flex align-items-center">
              <MdCreditCard size={30} />
              <h5 className="mb-0 ml-2">Relatório E-rede</h5>
            </div>
            <hr />
            <div className="row">
              <div className="col-md-4">
                <h6 className="d-block p-1 alert alert-secondary">
                  <MdQueryBuilder className="mr-1" />
                  <i>
                    Pedidos Pré-aprovados (Em análise <strong>ClearSale</strong>
                    )
                  </i>
                </h6>
                <div
                  className="mt-2"
                  style={{ height: '250px', overflow: 'auto' }}
                >
                  <table className="w-100 table table-hover mb-0">
                    <thead>
                      <th className="p-2 small">ID</th>
                      <th className="p-2 small">Cliente</th>
                      <th className="p-2 small">Valor</th>
                      <th className="p-2 small">Data</th>
                      <th className="p-2"> </th>
                    </thead>
                    <tbody>
                      {preApprovedOrders.map(o => (
                        <tr key={o.id}>
                          <td className="p-2 small">{o.id}</td>
                          <td className="p-2 small">
                            {`${o.name} ${o.lastname}`}
                          </td>
                          <td className="p-2 small">
                            {normalizeCurrency(String(o.total) || '000')}
                          </td>
                          <td className="p-2 small">
                            {' '}
                            {format(
                              subHours(parseISO(String(o.created_at)), 3),
                              'dd/MM/yyyy HH:mm',
                            )}
                          </td>
                          <td className="p-2 small">
                            <a href={`/orders/view/${o.id}`} target="blank">
                              Ver
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-4">
                <h6 className="d-block alert alert-warning p-1">
                  <MdWarning className="text-warning mr-1" />
                  <i>Últimos Pedidos Negados</i>
                </h6>
                <div
                  className="mt-2"
                  style={{ height: '250px', overflow: 'auto' }}
                >
                  <table className="w-100 table table-hover mb-0">
                    <thead>
                      <th className="p-2 small">ID</th>
                      <th className="p-2 small">Cliente</th>
                      <th className="p-2 small">Valor</th>
                      <th className="p-2 small">Data</th>
                    </thead>
                    <tbody>
                      {deniedOrders
                        .filter(o => o.data && JSON.parse(o.data).msg)
                        .slice(0, 50)
                        .map(o => (
                          <Fragment key={o.id}>
                            <tr>
                              <td
                                style={{ borderTop: '1px solid #444' }}
                                className="p-2 small"
                              >
                                {o.id}
                              </td>
                              <td
                                style={{ borderTop: '1px solid #444' }}
                                className="p-2 small"
                              >
                                {`${o.name || ''} ${o.lastname || ''}`}
                              </td>
                              <td
                                style={{ borderTop: '1px solid #444' }}
                                className="p-2 small"
                              >
                                {normalizeCurrency(String(o.total) || '000')}
                              </td>
                              <td
                                style={{ borderTop: '1px solid #444' }}
                                className="p-2 small"
                              >
                                {' '}
                                {format(
                                  subHours(parseISO(String(o.created_at)), 3),
                                  'dd/MM/yyyy HH:mm',
                                )}
                              </td>
                            </tr>
                            <tr className="bg-gray">
                              <td className="p-2 small" colSpan={4}>
                                <i>{o.data ? JSON.parse(o.data).msg : ''}</i>
                              </td>
                            </tr>
                          </Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-4">
                <h6 className="d-block alert alert-danger p-1">
                  <MdError className="text-danger mr-1" />
                  <i>Pedidos com Erro</i>
                </h6>
                <div
                  className="mt-2"
                  style={{ height: '250px', overflow: 'auto' }}
                >
                  <table className="w-100 table table-hover mb-0">
                    <thead>
                      <th className="p-2 small">ID</th>
                      <th className="p-2 small">Cliente</th>
                      <th className="p-2 small">Valor</th>
                      <th className="p-2 small">Data</th>
                      <th className="p-2"> </th>
                    </thead>
                    <tbody>
                      {eRedeOrdersError.map(o => (
                        <tr key={o.id}>
                          <td className="p-2 small">{o.id}</td>
                          <td className="p-2 small">{`${o.name} ${o.lastname}`}</td>
                          <td className="p-2 small">
                            {normalizeCurrency(String(o.total) || '000')}
                          </td>
                          <td className="p-2 small">
                            {' '}
                            {format(
                              subHours(parseISO(String(o.created_at)), 3),
                              'dd/MM/yyyy HH:mm',
                            )}
                          </td>
                          <td className="p-2 small">
                            <a href={`/orders/view/${o.id}`} target="blank">
                              Ver
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
