import React from 'react';
import useSelector from '~/store/useSelector';
import { IInitialState } from '~/store/modules/auth/types';

interface IProps {
  checkRole?: string;
  checkPermission?: string;
}

function checkAuth(
  { permissions }: IInitialState,
  checkRole?: string,
  checkPermission?: string,
) {
  let hasPermission = true;
  if (
    checkPermission &&
    !permissions.map(p => p.slug).includes(checkPermission)
  ) {
    hasPermission = false;
  }
  return hasPermission;
}

const Can: React.FC<IProps> = ({ children, checkRole, checkPermission }) => {
  const auth = useSelector(state => state.auth);

  if (typeof children === 'function') {
    return children(checkAuth(auth, checkRole, checkPermission));
  }
  if (checkAuth(auth, checkRole, checkPermission)) {
    return children;
  }
  return null;
};

export default Can;
