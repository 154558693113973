import React, { useState, useRef, useEffect } from 'react';

import JoditEditor from 'jodit-react';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import api from '~/services/api';
import Input from '~/components/Input';
import InputFile from '~/components/InputFile';
import Button from '~/styles/components/Button';

import { Container, Label } from './styles';

interface ICategories {
  id: number;
  name: string;
}

interface ICategory {
  id: number;
  name: string;
  description: string;
  preview: string;
  categories: ICategory[];
  category_id: string;
  loading: boolean;
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const editor = useRef(null);
  const [category, setCategory] = useState<ICategory>({
    id: 0,
    name: '',
    description: '',
    category_id: '',
    preview: '',
    loading: false,
    categories: [],
  });
  const [category_id, setCategory_id] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmImageDelete, setConfirmImageDelete] = useState(false);

  const history = useHistory();

  useEffect(() => {
    async function loadCategory() {
      try {
        const { data } = await api.get(`/categories/${match.params.id}`);
        setCategory({
          ...category,
          name: data.name,
          description: data.description,
          preview: data.image,
          category_id: data.category_id === null ? '0' : data.category_id,
        });

        setCategory_id(data.category_id === null ? '0' : data.category_id);
        setName(data.name);
        setDescription(data.description);

        const { data: categoriesData } = await api.get(
          '/categories?page=1&perpage=100000',
        );

        const dataSelect = [
          {
            id: 0,
            name: 'Raiz',
          },
          ...categoriesData.data
            .filter((d: any) => d.id !== match.params.id)
            .reduce((categoryArr: ICategory[], c: ICategory) => {
              categoryArr.push(c);

              const subcategories = c.categories;

              if (subcategories.length > 0) {
                for (let i = 0; i < subcategories.length; i += 1) {
                  const subcategory = subcategories[i];

                  const subcategoryCopy = { ...subcategory };

                  subcategoryCopy.name = `${c.name} > ${subcategory.name}`;

                  categoryArr.push(subcategoryCopy);

                  if (subcategory.categories.length > 0) {
                    subcategory.categories.forEach(subsubcategory => {
                      subsubcategory.name = `${c.name} > ${subcategory.name} > ${subsubcategory.name}`;

                      categoryArr.push(subsubcategory);
                    });
                  }
                }
              }

              return categoryArr;
            }, []),
        ];

        setCategories(dataSelect);
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadCategory();
  }, [match.params.id]); // eslint-disable-line

  async function handleImageDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/categories/image/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        setCategory({ ...category, preview: '' });
        setConfirmImageDelete(false);
        toastr.success('Imagem excluida', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleEdit() {
    setLoading(true);
    try {
      const obj = new FormData();
      let url = '';
      if (image) {
        obj.append('file', image);
        url = `/categories/image/${
          match.params.id
        }?name=${name}&description=${description}&${
          category_id && category_id !== '0' ? `category_id=${category_id}` : ''
        }`;
      } else {
        url = `/categories/${
          match.params.id
        }?name=${name}&description=${description}&${
          category_id && category_id !== '0' ? `category_id=${category_id}` : ''
        }`;
      }

      const response = await api.put(url, obj);

      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/categories/list');
        toastr.success('Categoria atualizada', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/categories/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          history.push('/categories/list');
          toastr.error(i.message, '');
        });
      } else {
        history.push('/categories/list');
        toastr.success('Perfil excluído', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/categories/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Editar Categoria</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row mb-3">
                <div className="col-md-12 mb-2">
                  <InputFile
                    label="Imagem"
                    name="image"
                    required
                    onChange={e => setImage(e.value)}
                    onError={() => console.log(1) /* eslint-disable-line */}
                    onReset={() => console.log(1) /* eslint-disable-line */}
                    disabled={loading}
                    initialValue={category.preview}
                    minHeight="200px"
                  />
                </div>
                {category.preview && !confirmImageDelete && (
                  <div className="footer_block d-flex justify-content-end">
                    <Button
                      type="button"
                      color="danger"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmImageDelete(true)}
                    >
                      {loading ? '...' : 'Excluir Imagem'}
                    </Button>
                  </div>
                )}
                {category.preview && confirmImageDelete && (
                  <div className="footer_block d-flex justify-content-end">
                    <div>
                      <h3 style={{ textAlign: 'right' }}>
                        Você tem certeza que deseja excluir esta imagem?
                      </h3>
                      <div className="d-flex mt-2 justify-content-end">
                        <Button
                          type="button"
                          color="danger"
                          disabled={loading}
                          onClick={handleImageDelete}
                        >
                          {loading ? 'Excluindo...' : 'Excluir'}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-6 mb-2">
                  <Input
                    label="Categoria Pai"
                    name="category_id"
                    required
                    select
                    initialValue={category.category_id}
                    options={categories.map(p => {
                      return {
                        title: p.name,
                        value: String(p.id),
                      };
                    })}
                    onChange={e =>
                      typeof e.value === 'string' && setCategory_id(e.value)
                    }
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <Input
                    label="Nome"
                    name="name"
                    required
                    initialValue={category.name}
                    onChange={e => setName(String(e.value))}
                  />
                </div>
                <div className="col-md-12 mb-2">
                  <Label>Descrição</Label>
                  <JoditEditor
                    ref={editor}
                    value={category.description}
                    onChange={newContent => setDescription(newContent)}
                  />
                </div>
              </div>
            </div>
            {/* {!confirmDelete && ( */}
            {true && (
              <div className="footer_block d-flex justify-content-end">
                <Button type="button" disabled={loading} onClick={handleEdit}>
                  {loading ? 'Salvando...' : 'Salvar'}
                </Button>
                {false && (
                  <Button
                    type="button"
                    color="danger"
                    className="ml-2"
                    disabled={loading}
                    onClick={() => setConfirmDelete(true)}
                  >
                    {loading ? '...' : 'Excluir Perfil'}
                  </Button>
                )}
              </div>
            )}
            {confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <div>
                  <h3 style={{ textAlign: 'right' }}>
                    Você tem certeza que deseja excluir este perfil?
                  </h3>
                  <div>
                    <Button
                      type="button"
                      color="danger"
                      disabled={loading}
                      onClick={handleDelete}
                    >
                      {loading ? 'Excluindo...' : 'Excluir'}
                    </Button>
                    <Button
                      type="button"
                      color="gray"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmDelete(false)}
                    >
                      {loading ? '...' : 'Cancelar'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
