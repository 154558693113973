import { useSelector, TypedUseSelectorHook } from 'react-redux';

import { IInitialState as IAuth } from './modules/auth/types';

export interface RootState {
  auth: IAuth;
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default useTypedSelector;
