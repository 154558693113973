import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container } from './styles';

const Settings: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  async function handleSubmit() {
    try {
      setLoading(true);
      const { oldPassword, newPassword, confirmPassword } = values;

      if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
        toastr.error('Erro', 'Preencha todos os campos');
        return;
      }

      if (newPassword.length < 6) {
        toastr.error('Erro', 'A senha deve ter no mínimo 6 caracteres');
        return;
      }

      if (newPassword !== confirmPassword) {
        toastr.error('Erro', 'As senhas não são iguais');
        return;
      }

      await api.put('/user/me/resetPassword', {
        oldPassword,
        newPassword,
      });

      toastr.success('Sucesso', 'Senha alterada com sucesso');
    } catch (err: any) {
      if (err.data?.message) {
        toastr.error('Erro', err.data.message);
      } else {
        toastr.error('Erro', 'Erro ao alterar senha');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <h4>Perfil</h4>

      <div>
        <div className="row">
          <div className="col-md-3">
            <div className="bg-white p-3 radius-4 shadow-sm">
              <h5>Trocar senha</h5>
              <Input
                label="Senha antiga"
                name="oldPassword"
                type="password"
                onChange={e =>
                  setValues({
                    ...values,
                    oldPassword: String(e.value),
                  })
                }
                disabled={loading}
              />
              <Input
                label="Nova Senha"
                name="newPassword"
                type="password"
                onChange={e =>
                  setValues({
                    ...values,
                    newPassword: String(e.value),
                  })
                }
                disabled={loading}
              />
              <Input
                label="Confirmar Senha"
                name="confirmPassword"
                type="password"
                onChange={e =>
                  setValues({
                    ...values,
                    confirmPassword: String(e.value),
                  })
                }
                disabled={loading}
              />
              <Button type="button" onClick={() => handleSubmit()}>
                Trocar senha
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Settings;
