export const SIGN_IN_REQUEST = '@auth/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = '@auth/SIGN_IN_SUCCESS';
export const SIGN_OUT = '@auth/SIGN_OUT';
export const SIGN_UP_REQUEST = '@auth/SIGN_UP_REQUEST';
export const GET_PERMISSIONS_SUCCESS = '@auth/GET_PERMISSIONS_SUCCESS';

export interface IRole {
  id: number;
  slug: string;
  name: string;
}

export interface IInitialState {
  token: null | string;
  signedIn: boolean;
  permissions: IRole[];
  roles: IRole[];
}

export interface ISignInRequestAction {
  type: typeof SIGN_IN_REQUEST;
  payload: {
    tmp_token: string;
    code: string;
    g_response: string;
  };
}
export interface ISignInSuccessAction {
  type: typeof SIGN_IN_SUCCESS;
  payload: {
    token: string;
  };
}
export interface ISignOutAction {
  type: typeof SIGN_OUT;
}
export interface ISignUpRequestAction {
  type: typeof SIGN_UP_REQUEST;
  payload: {
    name: string;
    email: string;
    password: string;
  };
}
export interface IGetPermissionsSuccess {
  type: typeof GET_PERMISSIONS_SUCCESS;
  payload: {
    roles: IRole[];
    permissions: IRole[];
  };
}

export type AuthActionTypes =
  | ISignInRequestAction
  | ISignInSuccessAction
  | ISignOutAction
  | ISignUpRequestAction
  | IGetPermissionsSuccess;
