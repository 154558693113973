import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import { ImUserTie } from 'react-icons/im';
import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import Button from '~/styles/components/Button';

import { normalizeCpf, normalizePhone } from '~/utils/normalize';

import useSelector from '~/store/useSelector';
import { validatePass } from './util';
import api from '~/services/api';

const ImperioCardCustomer: React.FC<any> = ({
  match: {
    params: { id },
  },
}) => {
  const [data, setData] = useState({} as any);

  const [seller, setSeller] = useState<{ name: string; code: string }>(
    {} as any,
  );

  const [loading, setLoading] = useState(false);

  const { token } = useSelector(state => state.auth);

  useEffect(() => {
    async function handle() {
      setLoading(true);
      try {
        const { data: customer } = await axios.get(
          `https://apibb.lojasimperio.com.br/reports/${id}`,
          {
            params: {
              apptoken: token,
              authorization: 'on',
            },
          },
        );
        setData(customer);
        if (customer.sellerCode) {
          try {
            const { data: sellerData } = await api.get(
              `/seller-by-code/${customer.sellerCode}`,
              {
                params: {
                  apptoken: token,
                },
              },
            );
            setSeller(sellerData);
          } catch (err) {
            //
          }
        }
      } catch (err) {
        if ((err as any)?.response?.data?.message?.erros) {
          (err as any)?.response?.data?.message?.erros?.forEach((e: any) => {
            toastr.error('Erro no BB', e.mensagem);
          });
        }
      }
      setLoading(false);
    }
    if (id && token) {
      handle();
    }
  }, [id, token]);

  const history = useHistory();

  async function handleCancel() {
    setLoading(true);
    try {
      await axios.post(
        `https://apibb.lojasimperio.com.br/customers/${data.documentNumber}/cancel-request-card`,
        null,
        {
          params: {
            apptoken: token,
            authorization: 'on',
          },
        },
      );
      toastr.info('Sucesso', 'Solicitação Cancelada');
      history.push(`/imperio-card`);
    } catch (err) {
      //
    }
    setLoading(false);
  }

  if (loading) {
    return <h5 className="loading">Carregando...</h5>;
  }

  return (
    <div>
      <Button
        filled={false}
        type="button"
        onClick={() => history.push('/imperio-card')}
      >
        <IoIosArrowBack /> Voltar
      </Button>
      <div className="bg-white radius-4 shadow-sm p-3 mt-2">
        <h5>Pedido de Cartão Império</h5>
        <hr />
        <div className="row">
          <div className="col-md-4">
            <h6>
              <strong>
                <FaUser /> Dados Básicos
              </strong>
            </h6>
            <div className="row">
              <div className="col-md-12">
                <small>Nome</small>
                <h6>{data.name || '[SEM NOME]'}</h6>
              </div>
              <div className="col-md-6">
                <small>CPF</small>
                <h6>
                  {data.documentNumber
                    ? normalizeCpf(data.documentNumber.padStart(11, '0'))
                    : ''}
                </h6>
              </div>
              <div className="col-md-6">
                <small>Telefone</small>
                <h6>
                  {data.dddPhone && data.phone
                    ? normalizePhone(data.dddPhone + data.phone)
                    : ''}
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            {data.cardData && (
              <div className="badge badge-warning">Cartão Virtual Emitido</div>
            )}
            {data.cardLimit !== null ? (
              <div className="mt-2">
                <strong>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data.cardLimit)}
                </strong>{' '}
                de Limite aprovado
              </div>
            ) : null}
            {data.cardTotalLimit !== null ? (
              <div className="mt-2">
                <strong>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data.cardTotalLimit)}
                </strong>{' '}
                de Limite total
              </div>
            ) : null}
            {data.cardAvailableLimit !== null ? (
              <div className="mt-2">
                <strong>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(data.cardAvailableLimit)}
                </strong>{' '}
                de Limite disponível
              </div>
            ) : null}
            {!data.cardData && data?.messages?.length === 0 && (
              <div>
                <small>Próximo passo</small>
                <h6>
                  <strong>
                    {data.nextApi
                      ? `${validatePass(data.nextApi)} [${data.nextApi}]`
                      : ''}
                  </strong>
                </h6>
              </div>
            )}

            <div className="mt-2">
              {data?.messages?.map((message: any) => (
                <p className="text-danger" key={message.codigo}>
                  {message.mensagem}
                </p>
              ))}
            </div>

            <div className="mt-2">
              {data?.messages?.length === 0 && (
                <>
                  <small>Pendências</small>
                  <div className="mb-2">
                    {data.pendencies?.length > 0 ? (
                      <div>
                        {data.pendencies?.map((p: any) => (
                          <p key={p.id} className="mb-0">
                            <strong>{p.imageType?.name}</strong>:{' '}
                            {p.reason || p.status?.name}
                          </p>
                        ))}
                      </div>
                    ) : (
                      <span className="p-0">Nenhuma pendência</span>
                    )}
                  </div>
                </>
              )}

              {!data.cardData && (
                <button
                  type="button"
                  onClick={() => {
                    const confirmed = window.confirm(
                      'Tem certeza que deseja cancelar e excluir essa solicitação? Todo progresso será perdido.',
                    );
                    if (confirmed) {
                      handleCancel();
                    }
                  }}
                  className="btn btn-danger btn-sm"
                >
                  Cancelar Solicitação
                </button>
              )}
            </div>
          </div>
          <div className="col-md-3">
            {seller && seller.name && (
              <div className="border-1 border-muted p-3 radius-4">
                <div className="d-flex align-items-center">
                  <ImUserTie /> Vendedor
                </div>
                <h5 className="mt-2">
                  <strong>{seller.name}</strong>
                </h5>
                <h6>{seller.code}</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImperioCardCustomer;
