import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaChevronUp,
  FaFilter,
  FaInbox,
  FaTimes,
} from 'react-icons/fa';
import Dropdown from '../Dropdown';

// import { Container } from './styles';

interface Props {
  getData: (params: any) => Promise<any>;
  filters: {
    col?: number;
    key: string;
    title: string;
    defaultTo?: any;
    type?: 'text' | 'date' | 'select' | 'boolean';
    normalize?: (v: any) => any;
    options?: {
      title: string;
      value: string | number;
    }[];
  }[];
  labels: {
    key: string;
    title: string;
    ordenable?: boolean;
    formater?: (value: any, item: any) => string | number | Date | JSX.Element;
  }[];
  defaultOrder?: {
    name: string;
    direction: 'desc' | 'asc';
  };
  additionalActions?: (item: any) => JSX.Element;
  hardRefresh?: boolean;
  exportActions?: { title: string; onClick: (filters: any) => void }[];
  batchActions?: { title: string; onClick: (items: any[]) => void }[];
  showPagination?: boolean;
}

export const TableComponent: React.FC<Props> = ({
  filters: filtersOptions,
  labels,
  additionalActions,
  getData,
  defaultOrder,
  hardRefresh,
  exportActions = [],
  batchActions = [],
  showPagination = true,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [order, setOrder] = useState(
    defaultOrder || {
      name: 'id',
      direction: 'desc',
    },
  );
  const [filters, setFilters] = useState(() => {
    const obj = {} as any;
    filtersOptions.forEach(filter => {
      obj[filter.key] = filter.defaultTo || '';
    });

    return obj;
  });

  const [selected, setSelected] = useState<any[]>([]);

  useEffect(() => {
    setFilters((filtersData: any) => {
      const obj = {} as any;
      filtersOptions.forEach(filter => {
        obj[filter.key] = filtersData[filter.key] || filter.defaultTo || '';
      });

      return obj;
    });
  }, [filtersOptions]);

  const [loading, setLoading] = useState(false);

  const [meta, setMeta] = useState({
    total: 0,
    last_page: 1,
    page: 1,
    per_page: 25,
  });

  function handleFilter() {
    setSelected([]);
    setLoading(true);
    getData({
      ...filters,
      perpage: meta.per_page,
      page: meta.page,
      ordername: order.name,
      orderdirection: order.direction,
    })
      .then(res => {
        if (res) {
          if (Array.isArray(res.data)) {
            setData(res.data);
            setMeta({
              ...meta,
              total: res.data.length,
              last_page: 1,
              page: 1,
              per_page: res.data.length,
            });
          } else if (Array.isArray(res.data.data)) {
            setData(res.data.data);

            if (res.data.meta) {
              setMeta({
                ...meta,
                total: res.data.meta.total,
                last_page: res.data.meta.last_page,
              });
            } else {
              setMeta({
                ...meta,
                total: res.data.total,
                last_page: res.data.lastPage,
              });
            }
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (hardRefresh) {
      handleFilter();
    }
  }, [hardRefresh]);

  useEffect(() => {
    handleFilter();
  }, [meta.page, meta.per_page, order.name, order.direction]);

  function handlePage(page: number) {
    setMeta({
      ...meta,
      page,
    });
  }

  if (loading) {
    return (
      <div className="bg-white radius-4 shadow-sm p-3 mb-0">
        <div className="w-100 bg-gray radius-4 bg-load p-5 mb-4">
          <h5 className="mb-0">
            <div className="spinner spinner-border" /> Carregando... aguarde
          </h5>
        </div>
        <div style={{ height: '55vh', overflow: 'auto', fontSize: '0.8rem' }}>
          <table className="table">
            <thead>
              <tr>
                {labels.map(label => (
                  <th key={label.key}>{label.title}</th>
                ))}
                {additionalActions ? <th> </th> : null}
              </tr>
            </thead>
            <tbody>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(skeletonRow => (
                <tr key={skeletonRow}>
                  {labels.map(label => (
                    <td key={label.key}>
                      <div className="bg-gray p-2 bg-load radius-4 w-100" />
                    </td>
                  ))}
                  {additionalActions ? (
                    <td>
                      <div className="bg-gray p-2 bg-load radius-4 w-100" />
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <strong className="bg-load bg-gray p-3 w-25" />
          <div className="bg-load bg-gray p-3 w-25" />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white radius-4 shadow-sm p-3 mb-0">
      {filtersOptions.length > 0 ? (
        <form
          onSubmit={e => {
            e.preventDefault();
            handleFilter();
          }}
          className="border-1 border-muted radius-4 p-3 mb-3"
        >
          <div className="row">
            {filtersOptions?.map(filter => {
              const value = filters[filter.key];
              const onChange = (v: any) => {
                setFilters({
                  ...filters,
                  [filter.key]: filter.normalize ? filter.normalize(v) : v,
                });
              };
              if (filter.type === 'select') {
                return (
                  <div className={`col-md-${filter.col || 1}`} key={filter.key}>
                    <div className="form form-group">
                      <label htmlFor={`input-${filter.key}`}>
                        {filter.title}
                      </label>
                      <select
                        className="form-control"
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        id={`input-${filter.key}`}
                      >
                        <option value="">Selecionar</option>
                        {filter.options?.map(option => (
                          <option value={option.value} key={option.value}>
                            {option.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                );
              }
              if (filter.type === 'boolean') {
                return (
                  <div className={`col-md-${filter.col || 1}`} key={filter.key}>
                    <div className="form form-group">
                      <label htmlFor={`input-${filter.key}`}>
                        {filter.title}
                      </label>
                      <select
                        id={`input-${filter.key}`}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        className="form-control"
                      >
                        <option value="">Selecionar</option>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                      </select>
                    </div>
                  </div>
                );
              }
              if (filter.type === 'date') {
                return (
                  <div className={`col-md-${filter.col || 1}`} key={filter.key}>
                    <div className="form form-group d-flex flex-column w-100">
                      <label
                        htmlFor={`input-${filter.key}`}
                        className="d-block"
                      >
                        {filter.title}
                      </label>

                      <ReactDatePicker
                        className="form-control w-100"
                        selected={value ? new Date(value) : undefined}
                        dateFormat="dd/MM/yyyy"
                        onChange={(date: Date) => {
                          onChange(date);
                        }}
                      />
                    </div>
                  </div>
                );
              }
              return (
                <div className={`col-md-${filter.col || 1}`} key={filter.key}>
                  <div className="form form-group">
                    <label htmlFor={`input-${filter.key}`}>
                      {filter.title}
                    </label>
                    <input
                      id={`input-${filter.key}`}
                      className="form-control"
                      value={value}
                      onChange={e => onChange(e.target.value)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-between flex-wrap mt-3">
            <div className="d-flex flex-wrap align-items-center">
              {exportActions.map(action => {
                return (
                  <button
                    className="btn btn-outline-success btn-sm mr-2"
                    type="button"
                    key={action.title}
                    onClick={() =>
                      action.onClick({
                        ...filters,
                        ...order,
                      })
                    }
                  >
                    {action.title}
                  </button>
                );
              })}
            </div>
            <div className="d-flex flex-wrap">
              <button type="submit" className="btn btn-info">
                <FaFilter /> Filtrar
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary ml-2"
                onClick={() => {
                  setFilters(() => {
                    const obj = {} as any;
                    filtersOptions.forEach(filter => {
                      obj[filter.key] = filter.defaultTo || '';
                    });

                    return obj;
                  });
                }}
              >
                <FaTimes /> Esvaziar filtros
              </button>
            </div>
          </div>
        </form>
      ) : null}
      {batchActions?.length > 0 ? (
        <div className="row">
          <div className="col-md-3">
            <Dropdown
              title="Ações em lote"
              options={batchActions.map(action => ({
                title: action.title,
                onClick: () => {
                  action.onClick(selected);
                },
                disabled: selected.length === 0,
              }))}
            />
          </div>
        </div>
      ) : null}
      <div
        style={{
          height: filtersOptions.length > 0 ? '55vh' : '75vh',
          overflow: 'auto',
          fontSize: '0.8rem',
          position: 'relative',
        }}
      >
        <table className="table table-hover">
          <thead>
            <tr>
              {batchActions?.length > 0 ? (
                <th>
                  <input
                    type="checkbox"
                    checked={selected.length === data.length}
                    onClick={() => {
                      if (selected.length === data.length) {
                        setSelected([]);
                      } else {
                        setSelected(data);
                      }
                    }}
                  />
                </th>
              ) : null}
              {labels.map(label => (
                <th style={{ whiteSpace: 'nowrap' }} key={label.key}>
                  <div className="d-flex align-items-center justify-content-start">
                    {label.title}
                    {label.ordenable || label.ordenable === undefined ? (
                      <button
                        type="button"
                        className={`${
                          order.name === label.key
                            ? 'text-primary'
                            : 'text-muted'
                        } ml-1`}
                        onClick={() => {
                          if (label.key === order.name) {
                            setOrder({
                              ...order,
                              direction:
                                order.direction === 'desc' ? 'asc' : 'desc',
                            });
                          } else {
                            setOrder({
                              ...order,
                              name: label.key,
                            });
                          }
                        }}
                      >
                        {order.direction === 'desc' ? (
                          <FaChevronUp />
                        ) : (
                          <FaChevronDown />
                        )}
                      </button>
                    ) : null}
                  </div>
                </th>
              ))}
              {additionalActions ? (
                <th className="sticky-col floating-col"> </th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td
                  style={{ height: '48vh' }}
                  colSpan={
                    labels.length +
                    (additionalActions ? 1 : 0) +
                    (batchActions?.length > 0 ? 1 : 0)
                  }
                >
                  <div
                    className="d-flex justify-content-center align-items-center flex-column text-center"
                    style={{ height: '100%' }}
                  >
                    <FaInbox size={30} className="mb-2" />
                    <h4>Nenhum resultado encontrado</h4>
                    <p>Verique os filtros aplicados</p>
                  </div>
                </td>
              </tr>
            ) : null}
            {data.map(item => {
              return (
                <tr key={item?.id}>
                  {batchActions?.length > 0 ? (
                    <th>
                      <input
                        type="checkbox"
                        checked={selected.findIndex(i => i.id === item.id) > -1}
                        onClick={() => {
                          if (selected.findIndex(i => i.id === item.id) > -1) {
                            setSelected(
                              [...selected].filter(s => s.id !== item.id),
                            );
                          } else {
                            setSelected([...selected, item]);
                          }
                        }}
                      />
                    </th>
                  ) : null}
                  {labels.map(label => (
                    <td key={label.key}>
                      {label.formater
                        ? label.formater(item[label.key], item)
                        : item[label.key]}
                    </td>
                  ))}
                  {additionalActions ? (
                    <td className="text-right sticky-col floating-col">
                      {additionalActions(item)}
                    </td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-between flex-wrap">
        <strong>Total: {meta.total}</strong>
        {showPagination && (
          <div className="d-flex align-items-center">
            <select
              value={meta.per_page}
              onChange={e => {
                setMeta({
                  ...meta,
                  per_page: Number(e.target.value),
                });
              }}
              className="mr-2 form-control"
            >
              {[25, 50, 100].map(option => (
                <option value={option} key={option}>
                  {option} resultados por página
                </option>
              ))}
            </select>
            <div className="d-flex align-items-center">
              <button
                type="button"
                className="m-1 d-flex align-items-center"
                onClick={() => handlePage(1)}
                disabled={meta.page === 1}
              >
                <FaChevronLeft style={{ marginRight: '-8px' }} />
                <FaChevronLeft />
              </button>
              <button
                type="button"
                className="m-1"
                onClick={() => handlePage(meta.page - 1)}
                disabled={meta.page === 1}
              >
                <FaChevronLeft />
              </button>
              <span className="m-1 active">{meta.page}</span>
              <button
                type="button"
                className="m-1"
                onClick={() => handlePage(meta.page + 1)}
                disabled={meta.page + 1 > meta.last_page}
              >
                <FaChevronRight />
              </button>
              <button
                type="button"
                className="m-1 d-flex align-items-center"
                onClick={() => handlePage(meta.last_page)}
                disabled={meta.page === meta.last_page}
              >
                <FaChevronRight />
                <FaChevronRight style={{ marginLeft: '-8px' }} />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
