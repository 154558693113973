import React, { useState, useEffect } from 'react';

import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
import api from '~/services/api';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';

import { Container } from './styles';

interface IPermission {
  id: number;
  slug: string;
  name: string;
}

interface IRole {
  id: number;
  slug: string;
  name: string;
  permissions: IPermission[];
  loading: boolean;
}

interface IProps {
  match: {
    params: {
      id: string;
    };
  };
}

const Edit: React.FC<IProps> = ({ match }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [userMarketplaces, setUserMarketplaces] = useState<number[]>([]);
  const [roles, setRoles] = useState<string[]>([]);
  const [rolesData, setRolesData] = useState<IRole[]>([]);
  const [loading, setLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [marketplaces, setMarketplaces] = useState<
    { id: number; name: string }[]
  >([]);
  useEffect(() => {
    async function handle() {
      const { data } = await api.get('/marketplaces?per_page=1000');
      setMarketplaces(data.data);
    }
    handle();
  }, []);

  const history = useHistory();

  useEffect(() => {
    async function loadUser() {
      try {
        const { data } = await api.get(`/users/${match.params.id}`);
        setName(data.name);
        setEmail(data.email);
        setCpf(data.cpf);
        setUserMarketplaces(
          data.marketplaces.map((m: any) => m.marketplace_id),
        );
        setRoles(
          data.roles.length > 0
            ? data.roles.map((r: any) => String(r.pivot.role_id))
            : [],
        );
        const { data: rolesDataA } = await api.get(
          '/roles?page=1&perPage=10000',
        );
        setRolesData(rolesDataA.data);
        setLoading(false);
      } catch (err) {
        toastr.error(
          'Ocorreu um erro',
          'Durante a busca nossos servidores não puderam responder',
        );
      }
    }
    loadUser();
  }, [match.params.id]); // eslint-disable-line

  async function handleEdit() {
    setLoading(true);
    try {
      const response = await api.put(`/users/${match.params.id}`, {
        name,
        cpf,
        email,
        roles,
      });
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/users/list');
        toastr.success('Usuário atualizado', '');
      }
    } catch (err) {
      toastr.error(
        'Ocorreu um erro',
        (err as any)?.data?.message || 'Contate o suporte',
      );
    }
    setLoading(false);
  }

  async function handleDelete() {
    setLoading(true);
    try {
      const response = await api.delete(`/users/${match.params.id}`);
      if (response.status === 203) {
        response.data.forEach((i: any) => {
          // eslint-disable-line
          toastr.error(i.message, '');
        });
      } else {
        history.push('/users/list');
        toastr.success('Usuário deletado', '');
      }
    } catch (err) {
      toastr.error('Ocorreu um erro', '');
    }
    setLoading(false);
  }

  return (
    <Container>
      <div style={{ marginBottom: '10px' }}>
        <Button
          filled={false}
          type="button"
          onClick={() => history.push('/users/list')}
        >
          <IoIosArrowBack /> Voltar
        </Button>
      </div>
      <div className="block_white">
        <div className="header_block">
          <h3>Editar Usuário</h3>
        </div>
        {!loading && (
          <>
            <div className="body_block">
              <div className="row mb-3">
                <div className="col-md-6">
                  <Input
                    label="Nome"
                    name="name"
                    required
                    initialValue={name}
                    onChange={e => setName(String(e.value))}
                  />
                  <Input
                    label="E-mail"
                    name="email"
                    type="email"
                    required
                    initialValue={email}
                    onChange={e => setEmail(String(e.value))}
                  />
                  <Input
                    label="CPF"
                    name="cpf"
                    initialValue={cpf}
                    required={false}
                    onChange={e => setCpf(String(e.value))}
                    disabled={loading}
                  />
                  <Input
                    label="Marketplace"
                    name="marketplace_id"
                    select
                    initialValue={userMarketplaces?.map(u => String(u))}
                    options={marketplaces?.map(p => {
                      return {
                        title: p.name,
                        value: String(p.id),
                      };
                    })}
                    multipleSelect
                    onChange={e => {
                      if (typeof e.value !== 'string') {
                        setUserMarketplaces(e.value.map(v => Number(v)));
                      }
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    label="Perfís"
                    name="permissions"
                    required
                    select
                    initialValue={roles}
                    options={rolesData.map(p => {
                      return {
                        title: p.name,
                        value: String(p.id),
                      };
                    })}
                    multipleSelect
                    onChange={e =>
                      typeof e.value !== 'string' && setRoles(e.value)
                    }
                  />
                </div>
              </div>
            </div>
            {!confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <Button type="button" disabled={loading} onClick={handleEdit}>
                  {loading ? 'Salvando...' : 'Salvar'}
                </Button>
                <Button
                  type="button"
                  color="danger"
                  className="ml-2"
                  disabled={loading}
                  onClick={() => setConfirmDelete(true)}
                >
                  {loading ? '...' : 'Excluir Usuário'}
                </Button>
              </div>
            )}
            {confirmDelete && (
              <div className="footer_block d-flex justify-content-end">
                <div>
                  <h3 style={{ textAlign: 'right' }}>
                    Você tem certeza que deseja excluir este usuário?
                  </h3>
                  <div>
                    <Button
                      type="button"
                      color="danger"
                      disabled={loading}
                      onClick={handleDelete}
                    >
                      {loading ? 'Excluindo...' : 'Excluir'}
                    </Button>
                    <Button
                      type="button"
                      color="gray"
                      className="ml-2"
                      disabled={loading}
                      onClick={() => setConfirmDelete(false)}
                    >
                      {loading ? '...' : 'Cancelar'}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default Edit;
