import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { FiLogOut } from 'react-icons/fi';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import { Container, LateralMenu } from './styles';
import { signOut } from '~/store/modules/auth/actions';
import Modal from '../Modal';

const Header: React.FC = () => {
  const dispatch = useDispatch();

  const [modalPainel, setModalPainel] = useState(false);

  const ItemVerify: React.FC<{ url: string; title: string }> = ({
    url,
    title,
  }) => {
    const [status, setStatus] = useState<'pending' | 'off' | 'on'>('pending');

    useEffect(() => {
      setStatus('pending');
      async function handle() {
        try {
          await axios.get(url);
          setStatus('on');
        } catch (err) {
          if ((err as any)?.response?.status === 404) {
            setStatus('on');
          } else {
            setStatus('off');
          }
        }
      }
      handle();
    }, [url]);

    return (
      <tr>
        <td>{title}</td>
        <td>
          {status === 'pending' ? (
            <div
              style={{
                width: 16,
                height: 16,
                borderRadius: 8,
                backgroundColor: '#ffe600',
              }}
            />
          ) : null}
          {status === 'on' ? (
            <div
              style={{
                width: 16,
                height: 16,
                borderRadius: 8,
                backgroundColor: '#0dae48',
              }}
            />
          ) : null}
          {status === 'off' ? (
            <div
              style={{
                width: 16,
                height: 16,
                borderRadius: 8,
                backgroundColor: '#ff0066',
              }}
            />
          ) : null}
        </td>
      </tr>
    );
  };

  return (
    <>
      {modalPainel && (
        <Modal>
          <div>
            <h5 className="text-center">Disponibilidade de Serviços</h5>
            <table className="table table-hover table-sm">
              <tbody>
                <ItemVerify
                  title="API Lojas Império"
                  url="https://api.lojasimperio.com.br"
                />
                <ItemVerify
                  title="Site (www.lojasimperio.com.br)"
                  url="https://www.lojasimperio.com.br/.well-known/assetlinks.json"
                />
                <ItemVerify
                  title="Cartão Império"
                  url="https://apibb.lojasimperio.com.br"
                />
                <ItemVerify
                  title="e-Rede"
                  url="https://gateway-erede.lojasimperio.com.br"
                />
                <ItemVerify
                  title="Safrapay"
                  url="https://svc2.lojasimperio.com.br"
                />
              </tbody>
            </table>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                onClick={() => setModalPainel(false)}
                className="btn btn-secondary btn-sm"
              >
                <FaTimes /> Fechar
              </button>
            </div>
          </div>
        </Modal>
      )}
      <Container>
        <div>
          <a href="/">
            <img
              src="https://lojasimperio.com.br/images/logo.png"
              alt="Império"
            />
          </a>
          <h3 className="mb-0">Administrativo</h3>
        </div>
        <LateralMenu>
          {/* 
          <button type="button" onClick={() => setModalPainel(true)}>
            Serviços
          </button>
          */}
          <button type="button" onClick={() => dispatch(signOut())}>
            Sair <FiLogOut />
          </button>
        </LateralMenu>
      </Container>
    </>
  );
};

export default Header;
