import React, { useState, useMemo } from 'react';

import { Container, Label, Input, HelperText } from './styles';

interface IChange {
  name: string;
  value: File;
}

interface IProps {
  label: string;
  name: string;
  required?: boolean;
  initialValue?: string | string[];
  onChange: (e: IChange) => void;
  onError: (name: string) => void;
  onReset: (name: string) => void;
  disabled?: boolean;
  minHeight?: string;
}

const InputFileElement: React.FC<IProps> = ({
  label,
  name,
  required = false,
  initialValue,
  onError,
  onReset,
  onChange,
  disabled,
  minHeight = '80px',
}) => {
  const [value, setValue] = useState<File | null>(null);
  const preview = useMemo(() => {
    return value ? URL.createObjectURL(value) : null;
  }, [value]);

  const [error, setError] = useState('');

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target && e.target.files && e.target.files[0]) {
      setValue(e.target.files[0]);
      onChange({
        name,
        value: e.target.files[0],
      });
    }
  }

  function handleValidation() {
    setError('');
    onReset(name);
    if (value === null && required) {
      onError(name);
      return setError('Preencha este campo');
    }
    return onReset(name);
  }

  return (
    <Container htmlFor={name}>
      <Label
        backgroundImage={
          preview || (typeof initialValue === 'string' ? initialValue : '')
        }
        minHeight={minHeight}
      >
        {label}
      </Label>
      <Input
        type="file"
        name={name}
        id={name}
        onChange={handleChange}
        onBlur={handleValidation}
        required={required}
        disabled={disabled}
        style={{ display: 'none' }}
      />
      <HelperText>{error}</HelperText>
    </Container>
  );
};

export default InputFileElement;
