import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaStar, FaThumbsUp } from 'react-icons/fa';
import { toastr } from 'react-redux-toastr';
import { format, parseISO, subHours } from 'date-fns';
import api from '~/services/api';
import Button from '~/styles/components/Button';
import Can from '~/components/Can';
import { TableComponent } from '~/components/TableV2';
import Modal from '~/components/Modal';
import { Container } from './styles';

const ModalCategories = ({
  items,
  onHide,
  onSave,
  makeVisible,
}: {
  items: any[];
  onHide: () => void;
  onSave: () => void;
  makeVisible: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  async function handleSave() {
    setLoading(true);

    items.forEach(async item => {
      try {
        await api.put(`/product-customer-reviews/${item.id}`, {
          active: makeVisible,
        });
        toastr.success('Sucesso', `Comentário atualizado`);
      } catch (err) {
        //
        toastr.error('Erro', `Erro ao atualizar comentário`);
      }
    });

    await new Promise(resolve => setTimeout(resolve, 1000));

    onHide();
    onSave();
    setLoading(false);
  }

  if (loading) {
    return (
      <Modal>
        <div className="d-flex justify-content-center">
          <div className="spinner spinner-border" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal size="xl">
      <h3>Tornar {makeVisible ? 'visível' : 'invisível'}</h3>

      <p>
        Tem certeza que deseja tornar{' '}
        <strong>
          {makeVisible ? (
            <span className="ativo">visível</span>
          ) : (
            <span className="inativo">invisível</span>
          )}
        </strong>{' '}
        {items.length} comentário
        {items.length > 1 && 's'}?
      </p>

      <Button onClick={handleSave} className="mr-2">
        Confirmar
      </Button>
      <Button onClick={onHide} color="gray">
        Cancelar
      </Button>
    </Modal>
  );
};

const Reviews: React.FC = () => {
  const history = useHistory();
  // const { permissions } = useSelector(state => state.auth);

  const [viewCategories, setViewCategories] = useState({
    open: false,
    items: [] as any,
    makeVisible: false,
  });

  const [hardRefresh, setHardRefresh] = useState(false);

  useEffect(() => {
    if (hardRefresh) {
      setHardRefresh(false);
    }
  }, [hardRefresh]);

  const Stars: React.FC<{ amount: number }> = ({ amount }) => {
    return (
      <div className="d-flex align-items-center">
        {[1, 2, 3, 4, 5].map(i => (
          <FaStar
            key={i}
            className={i <= amount ? 'text-warning' : 'text-muted'}
          />
        ))}
      </div>
    );
  };

  return (
    <Container>
      {viewCategories.open ? (
        <ModalCategories
          items={viewCategories.items}
          makeVisible={viewCategories.makeVisible}
          onHide={() =>
            setViewCategories({
              open: false,
              items: [],
              makeVisible: false,
            })
          }
          onSave={() => setHardRefresh(true)}
        />
      ) : null}
      <div className="d-flex justify-content-between mb-1">
        <h5>
          <FaThumbsUp /> Reviews
        </h5>
        <Can checkPermission="product_store">
          <Button
            type="button"
            onClick={() => history.push('/products/create')}
          >
            Novo produto
          </Button>
        </Can>
      </div>
      <TableComponent
        filters={[
          {
            key: 'sku',
            title: 'SKU',
            col: 2,
          },
          {
            key: 'recommend',
            title: 'Recomendado',
            defaultTo: '',
            type: 'select',
            options: [
              {
                title: 'Sim',
                value: '1',
              },
              {
                title: 'Não',
                value: '0',
              },
            ],
            col: 3,
          },
          {
            key: 'active',
            title: 'Visível',
            defaultTo: '',
            type: 'select',
            options: [
              {
                title: 'Sim',
                value: '1',
              },
              {
                title: 'Não',
                value: '0',
              },
            ],
            col: 3,
          },
        ]}
        labels={[
          {
            key: 'product_sku',
            title: 'Skus',
            ordenable: false,
          },
          {
            key: 'customer_name',
            title: 'Cliente',
            ordenable: false,
          },
          {
            key: 'stars',
            title: 'Qtd. Estrelas',
            formater: stars => <Stars amount={Number(stars)} />,
            ordenable: false,
          },
          {
            key: 'title',
            title: 'Título',
            ordenable: false,
          },
          {
            key: 'description',
            title: 'Descrição',
            ordenable: false,
          },
          {
            key: 'recommend',
            title: 'Recomenda',
            formater: recommend =>
              recommend ? (
                <span className="ativo">Sim</span>
              ) : (
                <span className="inativo">Não</span>
              ),
            ordenable: false,
          },
          {
            key: 'active',
            title: 'Visível',
            formater: status =>
              status ? (
                <span className="ativo">Sim</span>
              ) : (
                <span className="inativo">Não</span>
              ),
            ordenable: false,
          },
          {
            key: 'created_at',
            title: 'Data de inclusão',
            formater: created_at =>
              format(subHours(parseISO(created_at), 3), 'dd/MM/yyyy HH:mm'),
          },
        ]}
        getData={async params => {
          const res = await api.get(`/product-customer-reviews`, {
            params,
          });
          return res;
        }}
        batchActions={[
          {
            title: 'Tornar visível',
            onClick: items => {
              setViewCategories({
                items,
                open: true,
                makeVisible: true,
              });
            },
          },
          {
            title: 'Tornar invisível',
            onClick: items => {
              setViewCategories({
                items,
                open: true,
                makeVisible: false,
              });
            },
          },
        ]}
        hardRefresh={hardRefresh}
      />
    </Container>
  );
};

export default Reviews;
