/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { normalizeCurrency } from '~/utils/normalize';
import api from '~/services/api';
import {
  IOrderProduct,
  IOrderService,
  IProduct,
} from '~/pages/Main/Orders/View';
import Modal from './Modal';

type ServiceType = {
  active: number;
  created_at: string;
  id: number;
  name: string;
  price: number;
  product: {
    id: number;
    marketplace_id: number;
    brand_id: number;
    sku: string;
    // Add other properties of product if any
  };
  product_id: number;
  product_sku: string;
  service_sku: string;
  tge: number;
  tgf: number;
  type: number;
  updated_at: string;
};

const mutedColor = '#788796';

function Input({
  value,
  onChange,
  placeholder,
  label,
  disabled,
}: {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  label: string;
  disabled?: boolean;
}) {
  return (
    <div>
      <div
        style={{
          fontSize: '14px',
        }}
      >
        {label}
      </div>
      <input
        value={value}
        onChange={onChange}
        type="text"
        placeholder={placeholder}
        style={{
          padding: '10px',
          width: '100%',
          borderWidth: '3px',
          borderRadius: '5px',
          borderColor: '#E2E7EB',
          borderStyle: 'solid',
        }}
        disabled={disabled}
      />
    </div>
  );
}

function Button({
  onClick,
  children,
  variant = 'primary',
  style,
}: {
  onClick: () => void;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
  style?: React.CSSProperties;
}) {
  return (
    <button
      type="button"
      onClick={onClick}
      style={{
        ...style,
        backgroundColor: variant === 'primary' ? '#FF9300' : '#B9BBBE',
        color: variant === 'primary' ? '#FFF' : '#555',
        padding: '10px',
        paddingRight: '40px',
        paddingLeft: '40px',
        borderRadius: '4px',
        border: '0',
        cursor: 'pointer',
      }}
    >
      <strong>{children}</strong>
    </button>
  );
}

function stringifyCurrency(value: number) {
  return `R$ ${normalizeCurrency(value.toString())}`;
}

function parseCurrency(value: string) {
  return parseInt(value.replace(/\D/g, ''), 10);
}

export default function ChangeProductModal({
  onHide,
  orderProduct,
  orderService,
}: {
  onHide: () => void;
  orderProduct: IOrderProduct;
  orderService: IOrderService;
}): JSX.Element {
  console.log(orderService);
  const [form, setForm] = useState<{
    productId: number;
    name: string;
    value: number;
    amount: number;
    discount: number;
    discountPayment: number;
    sku: string;
    photo: string;
    serviceSku: string | undefined;
    serviceValue: number | undefined;
    serviceId: number | undefined;
  }>({
    productId: orderProduct.product_id,
    name: orderProduct.product.name,
    value: orderProduct.price,
    amount: orderProduct.qtd,
    discount: orderProduct.discount,
    discountPayment: orderProduct.discount_form_payment,
    sku: orderProduct.sku,
    photo: orderProduct.product.photos[0]?.path,
    serviceSku: orderService?.service_sku,
    serviceValue: orderService?.price,
    serviceId: orderService?.service_id,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [productsQuery, setProductsQuery] = useState('');
  const [debouncedProdctsQuery] = useDebounce(productsQuery, 500);
  const [productsData, setProductsData] = useState<any[]>([]);
  const [isLoadingProductsQuery, setIsLoadingProductsQuery] = useState(false);

  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [servicesData, setServicesData] = useState<ServiceType[]>([]);

  const total = useMemo(() => {
    const calc =
      form.value * form.amount - form.discount - form.discountPayment;

    return calc > 0 ? calc : 0;
  }, [form]);

  async function handleSave() {
    setIsSaving(true);
    try {
      await api.put(`orders/change-product/${orderProduct.id}`, {
        qtd: form.amount,
        price: form.value,
        discount: form.discount,
        discount_form_payment: form.discountPayment,
        product_id: form.productId,
        order_id: orderProduct.order_id,
        service_id: form.serviceId,
        service_value: form.serviceValue,
        service_sku: form.serviceSku,
      });

      onHide();
    } catch (err) {
      //
    } finally {
      setIsSaving(false);
    }
  }

  async function loadProductServices(productSku: string): Promise<void> {
    if (!orderService?.service_id) {
      return;
    }

    setIsLoadingServices(true);
    try {
      const { data } = await api.get(`services`, {
        params: {
          productsku: productSku,
        },
      });

      setServicesData(data.data);

      if (!data.data.length) {
        setForm(f => ({
          ...f,
          serviceId: undefined,
          serviceValue: undefined,
          serviceSku: undefined,
        }));
        return;
      }

      const selectedService = data.data.find(
        (s: ServiceType) => s.product_sku === form.sku,
      );

      if (selectedService.id !== orderService.service_id) {
        setForm(f => ({
          ...f,
          serviceId: selectedService?.id,
          serviceValue: selectedService?.price,
          serviceSku: selectedService?.service_sku,
        }));
      }
    } catch (err) {
      setIsLoadingServices(false);
    } finally {
      setIsLoadingServices(false);
    }
  }

  useEffect(() => {
    if (!debouncedProdctsQuery?.length || debouncedProdctsQuery === '') {
      return setProductsData([]);
    }

    setIsLoadingProductsQuery(true);
    api
      .get(`products?name=${debouncedProdctsQuery}&status=1&perpage=5`)
      .then(response => {
        setProductsData(
          response.data.data.map((p: any) => {
            return {
              ...p,
              id: p.id,
              title: p.name,
              code: p.sku,
              stock: p.stock,
              status: p.status,
            };
          }),
        );
      })
      .catch(() => {
        //
      })
      .finally(() => {
        setIsLoadingProductsQuery(false);
      });

    return () => {
      setProductsData([]);
    };
  }, [debouncedProdctsQuery]);

  useEffect(() => {
    loadProductServices(form.sku);
  }, []);

  function handleSelectProduct(product: IProduct) {
    setForm(f => ({
      ...f,
      name: product.name,
      value: product.price,
      sku: product.sku,
      productId: product.id,
      photo: product.photos[0]?.path,
    }));
    setProductsQuery('');
    setProductsData([]);
    loadProductServices(product.sku);
  }

  return (
    <Modal size="big">
      <div
        style={{
          fontSize: '18px',
        }}
      >
        <strong>Alterar Produto</strong>
      </div>

      <div style={{ paddingTop: '20px' }}>
        <div>Produto Selecionado:</div>

        <div className="d-flex mt-2 mb-2">
          <img
            src={form.photo}
            alt={orderProduct.product?.name}
            style={{
              width: '100px',
              height: '100px',
              objectFit: 'cover',
            }}
          />
          <div style={{ paddingLeft: '10px' }}>
            <div>{form.name}</div>
            <div
              style={{
                fontSize: '14px',
                color: mutedColor,
                fontStyle: 'italic',
              }}
            >
              ({form.sku})
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          paddingBottom: '20px',
          paddingTop: '10px',
          position: 'relative',
        }}
      >
        <Input
          value={productsQuery}
          onChange={e => {
            setProductsQuery(e.target.value);
          }}
          placeholder="Pesquise o novo produto que deseja"
          label=""
        />
        <div
          style={{
            position: 'absolute',
            top: 60,
            width: '100%',
            backgroundColor: '#FFF',
            textAlign: 'left',
          }}
        >
          <>
            {isLoadingProductsQuery ? (
              <button
                className="btn btn-light btn-sm w-100 text-left"
                type="button"
              >
                Carregando produtos...
              </button>
            ) : null}
          </>
          {productsData
            .filter(p => p.stock > 0)
            .map(p => (
              <button
                // align
                className="btn btn-light btn-sm w-100 text-left"
                type="button"
                key={p.code}
                onClick={() => handleSelectProduct(p)}
              >
                {p.code} - {p.title}
              </button>
            ))}
        </div>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '10px',
          paddingBottom: '30px',
        }}
      >
        <Input
          value={stringifyCurrency(form.value)}
          onChange={e => {
            setForm({
              ...form,
              value: parseCurrency(e.target.value),
            });
          }}
          placeholder="R$ xxx,xx"
          label="Valor unitário"
        />
        <Input
          value={form.amount.toString()}
          onChange={e => {
            setForm({
              ...form,
              amount: parseInt(e.target.value.replace(/\D/g, ''), 10) || 0,
            });
          }}
          placeholder=""
          label="Quantidade"
        />
        <Input
          value={stringifyCurrency(form.discount)}
          onChange={e => {
            setForm({
              ...form,
              discount: parseCurrency(e.target.value),
            });
          }}
          placeholder="R$ xxx,xx"
          label="Desconto"
        />
        <Input
          value={stringifyCurrency(form.discountPayment)}
          onChange={e => {
            setForm({
              ...form,
              discountPayment: parseCurrency(e.target.value),
            });
          }}
          placeholder="R$ xxx,xx"
          label="Desconto forma de pagamento"
        />

        <div>
          <p
            style={{
              fontSize: '14px',
              color: mutedColor,
              marginBottom: '0px',
              paddingBottom: '0px',
            }}
          >
            Subtotal calculado:
          </p>
          <strong
            style={{
              fontSize: '20px',
              marginBottom: '0px',
              paddingBottom: '0px',
            }}
          >
            {stringifyCurrency(total || 0)}
          </strong>
        </div>
      </div>

      <div
        style={{
          border: '3px solid #E2E7EB',
          borderRadius: '5px',
          padding: '10px',
        }}
      >
        {isLoadingServices ? (
          <div
            style={{
              fontSize: '14px',
              color: mutedColor,
              marginBottom: '0px',
              paddingBottom: '0px',
            }}
          >
            {isLoadingServices ? 'Carregando...' : 'Serviço relacionado:'}
          </div>
        ) : !orderService?.service_id ? (
          <h5
            style={{
              color: mutedColor,
              marginBottom: '0px',
              paddingBottom: '0px',
            }}
          >
            <strong>Nenhum serviço relacionado</strong>
          </h5>
        ) : (
          <>
            <div
              style={{
                fontSize: '14px',
                color: mutedColor,
                marginBottom: '0px',
                paddingBottom: '0px',
              }}
            >
              Serviço relacionado:
            </div>
            <select
              style={{
                padding: '10px',
                width: '100%',
                borderWidth: '3px',
                borderRadius: '5px',
                borderColor: '#E2E7EB',
                borderStyle: 'solid',
              }}
              disabled={isLoadingServices}
              value={form.serviceSku || ''}
              onChange={e => {
                const selectedService = servicesData.find(
                  s => s.service_sku === e.target.value,
                );
                setForm({
                  ...form,
                  serviceId: selectedService?.id,
                  serviceValue: selectedService?.price,
                  serviceSku: selectedService?.service_sku,
                });
              }}
            >
              <>
                {servicesData.map((service: ServiceType) => (
                  <option key={service.service_sku} value={service.service_sku}>
                    ({service.service_sku}) {service.name}
                  </option>
                ))}
              </>
            </select>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridGap: '10px',
                paddingTop: '10px',
              }}
            >
              <Input
                value={stringifyCurrency(form.serviceValue || 0)}
                onChange={e => {
                  setForm({
                    ...form,
                    serviceValue: parseCurrency(e.target.value),
                  });
                }}
                placeholder="R$ xxx,xx"
                label="Valor unitário"
                disabled={!form.serviceSku || isLoadingServices}
              />
              <div>
                <p
                  style={{
                    fontSize: '14px',
                    color: mutedColor,
                    marginBottom: '0px',
                    paddingBottom: '0px',
                  }}
                >
                  Subtotal calculado:
                </p>
                <strong
                  style={{
                    fontSize: '20px',
                    marginBottom: '0px',
                    paddingBottom: '0px',
                  }}
                >
                  {stringifyCurrency(form.serviceValue || 0)}
                </strong>
              </div>
            </div>
          </>
        )}
      </div>

      <div style={{ paddingTop: '40px' }}>
        <Button onClick={handleSave} variant="primary">
          {isSaving ? 'Salvando...' : 'Salvar'}
        </Button>
        <Button
          onClick={onHide}
          variant="secondary"
          style={{ marginLeft: '10px' }}
        >
          Cancelar
        </Button>
      </div>
    </Modal>
  );
}
