import React, { useState } from 'react';

import { format, subDays, isDate, startOfDay, endOfDay } from 'date-fns';

import { toastr } from 'react-redux-toastr';
import Input from '~/components/Input';
import Button from '~/styles/components/Button';
import { normalizeDate, stringToDate } from '~/utils/normalize';
import Modal from '~/components/Modal';
import useSelector from '~/store/useSelector';

const Newsletter: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    dateStart: format(subDays(new Date(), 15), 'dd/MM/yyyy'),
    dateEnd: format(new Date(), 'dd/MM/yyyy'),
  });

  const [all, setAll] = useState(true);

  const { token } = useSelector(state => state.auth);

  function handleExport(type: 'csv' | 'xls') {
    if (!isDate(stringToDate(data.dateStart))) {
      toastr.error('Validação', 'Data Inicial Inválida');
      return;
    }
    if (!isDate(stringToDate(data.dateEnd))) {
      toastr.error('Validação', 'Data Final Inválida');
      return;
    }
    window.open(
      `${
        process.env.REACT_APP_API_BASE_URL
      }/reports/newsletter?exportType=${type}&dateStart=${startOfDay(
        stringToDate(data.dateStart) as Date,
      )}&dateEnd=${endOfDay(
        stringToDate(data.dateEnd) as Date,
      )}&token=${token}&all=${all ? 'on' : 'off'}`,
    );
  }

  return (
    <>
      <Button
        type="button"
        color="gray"
        style={{ width: '100%', minHeight: '4rem' }}
        onClick={() => setOpen(true)}
      >
        Newsletter
      </Button>
      {open && (
        <Modal>
          <label
            htmlFor="check_all"
            style={{ display: 'flex', marginBottom: '14px' }}
          >
            <input
              type="checkbox"
              id="check_all"
              checked={all}
              onChange={() => setAll(!all)}
            />
            <small style={{ display: 'block', marginLeft: '6px' }}>
              Todos os Registros
            </small>
          </label>
          <div style={{ opacity: all ? '0.3' : '1' }}>
            <Input
              type="text"
              normalize={normalizeDate}
              label="Data Inicial"
              name="dateStart"
              initialValue={data.dateStart}
              onChange={e => {
                setData({
                  ...data,
                  dateStart: String(e.value),
                });
              }}
              disabled={all}
            />
            <Input
              type="text"
              normalize={normalizeDate}
              label="Data Final"
              name="dateStart"
              initialValue={data.dateEnd}
              onChange={e => {
                setData({
                  ...data,
                  dateEnd: String(e.value),
                });
              }}
              disabled={all}
            />
          </div>
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: '1rem' }}
          >
            <Button type="button" onClick={() => handleExport('xls')}>
              Exportar em Excel
            </Button>
            <Button type="button" onClick={() => handleExport('csv')}>
              Exportar em CSV
            </Button>
          </div>
          <Button
            type="button"
            color="gray"
            style={{ width: '100%', marginTop: '1rem' }}
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Modal>
      )}
    </>
  );
};

export default Newsletter;
