import styled from 'styled-components';

export const Container = styled.div`
  .product-added {
    padding: 5px 15px;
    background: #f9f9f9;
    border-radius: 4px;
    font-size: 12px;
  }
  .product-added + .product-added {
    margin-top: 4px;
  }
`;

export const AutocompleteOptions = styled.div`
  position: absolute;
  top: 50px;
  width: 100%;
  background: #eee;
  z-index: 100;
  button {
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    padding: 5px 10px;
    color: #444;
    transition: 150ms;
    &:hover {
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
    }
  }
  button + button {
    border-top: 1px solid #c9c9c9;
  }
`;

export const Label = styled.span`
  color: #2d3047;
  font-weight: bold;
  display: block;
  font-size: 12px;
  margin-bottom: 2px;
  margin-left: 4px;
`;
