import styled, { keyframes } from 'styled-components';

interface IContainer {
  fullWidth: boolean;
}

export const Container = styled.div`
  position: relative;
  width: ${(props: IContainer) => (props.fullWidth ? '100%' : 'auto')};
`;

interface ITableFilterProps {
  loading?: boolean;
}

const loadAnimation = keyframes`
  0% {
    left: 0%;
    width: 0%;
  } 30% {
    width: 40%;
  } 75% {
    width: 25%;
  } 100% {
    left: 100%;
    width: 0%;
  }
`;

export const TableFilters = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 2px solid #016cb2;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0px;
    height: 2px;
    width: 0px;
    background: #fff;
    animation: ${(props: ITableFilterProps) =>
        props.loading ? loadAnimation : ''}
      1s infinite linear;
  }
  button {
    width: 35px;
    height: 35px;
    background: none;
    border: 2px solid #016cb2;
    border-radius: 50%;
    color: #016cb2;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 300ms;
    &:disabled {
      opacity: 0.6;
    }
    &.filled {
      background: #016cb2;
      color: #fff;
    }
  }
  button.export {
    width: auto;
    height: auto;
    margin-right: 1rem;
    background: #cce7de;
    color: #40594d;
    border-radius: 0.25rem;
    border: none;
    padding: 0.5rem 1rem;
    font-weight: 900;
    &.open {
      background: #40594d;
      color: #cce7de;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  thead {
    tr {
      background: rgb(1 108 178 / 17%);
      th {
        font-weight: bold;
        color: #016cb2;
        border-bottom: 1px solid #016cb2;
        text-align: left;
        padding: 12px;
        &:last-child {
          text-align: right;
        }
        font-size: 12px;
      }
    }
  }
  tbody {
    tr {
      transition: 150ms;
      td {
        font-size: 12px;
        vertical-align: middle;
        color: #444;
        padding: 12px;
        border-bottom: 1px solid #eee;
        &:last-child {
          text-align: right;
        }
      }
      &:nth-child(odd) {
        background: rgba(0, 0, 0, 0.001);
      }
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

export const TablePagination = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: #016cb2;
  color: #fff;
  strong {
    font-size: 14px;
  }
  #pages {
    display: flex;
    align-items: center;
    button {
      font-size: 18px;
      border: none;
      padding: 0px;
      margin: 0px;
      color: #fff;
      display: flex;
      justify-content: center;
      background: none;
      align-items: center;
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
    span {
      display: block;
      padding: 5px 15px;
      font-weight: bold;
    }
  }
`;

export const FilterContainer = styled.div`
  padding: 15px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 6px;
  position: absolute;
  right: 15px;
  top: 60px;
  z-index: 10;
  width: 100%;
  max-width: 270px;
  max-height: 50vh;
  overflow: auto;
  h4 {
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #999;
    color: #fff;
    background: #0071af;
    &.green {
      background: #cce7de;
      color: #40594d;
    }
  }
  .input + .input {
    margin-top: 10px;
  }
  .input {
    width: 100%;
    position: relative;
    border: 2px solid #c9c9c9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    p {
      color: #555;
      margin: 8px;
      font-weight: bold;
      width: 100%;
      font-size: 12px;
    }
    button {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 8px;
      font-size: 20px;
      color: #555;
    }
    input,
    select {
      border: none;
      width: 100%;
      padding: 6px;
      font-size: 12px;
      background: none;
      font-weight: bold;
      &::placeholder {
        font-weight: bold;
      }
    }
  }
  #action_filter {
    margin-top: 10px;
    button + button {
      margin-left: 6px;
    }
  }
`;
