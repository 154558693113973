import axios from 'axios';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useHistory } from 'react-router-dom';

import Table, { IFilter } from '~/components/Table';

import useSelector from '~/store/useSelector';
import {
  normalizeCpf,
  normalizeNumber,
  normalizePhone,
} from '~/utils/normalize';
import { validatePass } from './util';

const BASE_URL = 'https://apibb.lojasimperio.com.br';
// const BASE_URL = 'http://localhost:7821';

const ImperioCard: React.FC = () => {
  const [cards, setCards] = useState<any>({
    data: [],
    total: 0,
    perPage: 25,
    page: 1,
    lastPage: 1,
    loading: false,
    orderBy: 'createdAt',
    order: 'DESC',
    seller_code: localStorage.getItem('seller_code'),
    document_number: '',
    active: '',
  });

  const history = useHistory();

  const { token } = useSelector(state => state.auth);

  async function applyFilters(data: IFilter[]) {
    const dataFilter: any = {}; // eslint-disable-line
    data.forEach(i => {
      if (i.type === 'react-datepicker') {
        // convert timestamp to yyyy-MM-dd
        const date = new Date(parseInt(i.value, 10));
        if (i.value) {
          dataFilter[i.name] = format(date, 'yyyy-MM-dd');
        }
      }
      dataFilter[i.name] = i.value;
    });
    setCards({
      ...cards,
      ...dataFilter,
    });
  }

  function timestampToDate(date: string) {
    if (!date) return '';
    // js milliseconds to yyyy-MM-dd
    const d = new Date(parseInt(date, 10));

    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    async function loadData() {
      setCards({ ...cards, loading: true });
      try {
        const { data } = await axios.get(
          // `https://apibb.lojasimperio.com.br/reports`,
          `${BASE_URL}/reports`,
          {
            params: {
              apptoken: token,
              authorization: 'on',
              page: cards.page,
              per_page: cards.perPage,
              seller_code: cards.seller_code,
              document_number: normalizeNumber(cards.document_number),
              created_at: timestampToDate(cards.createdAt),
              end_date: timestampToDate(cards.endDate),
              order_by: cards.orderBy,
              order_direction: cards.order,
              complete: cards.active,
            },
          },
        );
        setCards({
          ...cards,
          data: data.data,
          lastPage: data.meta.last_page,
          total: data.meta.total,
        });
      } catch (err) {
        if ((err as any).response && (err as any).response.data.message) {
          toastr.error('Ocorreu um erro', (err as any).response.data.message);
        } else {
          toastr.error('Ocorreu um erro', 'Entre em contato com o suporte');
        }
      }
    }
    loadData();
  }, [
    cards.perPage,
    cards.orderBy,
    cards.active,
    cards.order,
    cards.page,
    cards.document_number,
    cards.seller_code,
    cards.createdAt,
    cards.endDate,
  ]);

  function onNextPage() {
    setCards({ ...cards, page: cards.page + 1 });
  }
  function onPreviousPage() {
    setCards({ ...cards, page: cards.page - 1 });
  }

  const filters = [
    {
      name: 'document_number',
      title: 'CPF do Cliente',
      type: 'text',
      normalize: normalizeCpf,
      value: '',
    },
    {
      name: 'createdAt',
      title: 'Data Início',
      type: 'react-datepicker',
      value: '',
    },
    {
      name: 'endDate',
      title: 'Data Final',
      type: 'react-datepicker',
      value: '',
    },
    {
      name: 'active',
      title: 'Solicitação Completa',
      type: 'select',
      value: '',
    },
  ];

  if (!localStorage.getItem('seller_code')) {
    filters.push({
      name: 'seller_code',
      title: 'Vendedor',
      type: 'text',
      normalize: normalizeNumber,
      value: '',
    });
  }

  return (
    <div>
      <Table
        data={
          cards.data?.map((d: any) => {
            return {
              ...d,
              name: d.name || '[SEM NOME]',
              documentNumber: normalizeCpf(d.documentNumber.padStart(11, '0')),
              phone: normalizePhone((d?.dddPhone || '') + (d?.phone || '')),
              type:
                d.type === 'account-holder' ? 'Correntista' : 'Não correntista',
              store: d.storeCode
                ? `${d.storeCode?.replace('510', '') || ''} - ${
                    d.storeName || ''
                  }`
                : '[SEM LOJA]',
              sellerCode: d.sellerCode
                ? `[${d.sellerCode || ''}] ${
                    d.sellerName?.split(' | ')[0] || ''
                  }`
                : '[SEM VENDEDOR]',
              createdAt: d.createdAt
                ? format(new Date(d.createdAt), 'dd/MM/yyyy HH:mm')
                : '',
              lastStepDateAt: d.lastStepDateAt
                ? format(new Date(d.lastStepDateAt), 'dd/MM/yyyy HH:mm')
                : '',
              cardAvailableLimit:
                typeof d.cardAvailableLimit === 'number' ? (
                  new Intl.NumberFormat('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                  }).format(d.cardAvailableLimit || 0)
                ) : (
                  <i>INDEFINIDO</i>
                ),
              lastStep: d.nextApi,
              nextApi: (
                <>
                  {d.cardData ? (
                    <div className="badge badge-success">Emitido</div>
                  ) : (
                    validatePass(d.nextApi, d.rejected)
                  )}
                </>
              ),
            };
          }) || []
        }
        total={cards.total}
        loading={cards.loading}
        onApplyFilters={(data: IFilter[]) => applyFilters(data)}
        perPageOptions={[25, 50, 100]}
        perPage={cards.perPage}
        page={cards.page}
        order={cards.order}
        orderBy={cards.orderBy}
        onFoward={onNextPage}
        onPrevious={onPreviousPage}
        labels={[
          {
            name: 'name',
            title: 'Nome',
          },
          {
            name: 'email',
            title: 'E-mail',
          },
          {
            name: 'phone',
            title: 'Telefone',
          },
          {
            name: 'cardAvailableLimit',
            title: 'Limite Disponível',
          },
          {
            name: 'documentNumber',
            title: 'CPF',
          },
          {
            name: 'type',
            title: 'Tipo',
          },
          {
            name: 'sellerCode',
            title: 'Vendedor',
          },
          {
            name: 'sellerOccupation',
            title: 'Cargo',
          },
          {
            name: 'store',
            title: 'Loja',
          },
          {
            name: 'lastStep',
            title: 'Passo',
          },
          {
            name: 'nextApi',
            title: 'Próximo Passo',
          },
          {
            name: 'createdAt',
            title: 'Data Início',
          },
          {
            name: 'lastStepDateAt',
            title: 'Última atualização',
          },
        ]}
        filtersFields={(filters as any[]) || []}
        lastPage={cards.lastPage}
        onClickElement={(data: any) => {
          history.push(`/imperio-card/${data.id}`);
        }}
        elementClickable
        title="Cartão Império"
        onExport2={(data, type) => {
          const cards: any = {}; // eslint-disable-line
          data.forEach(i => {
            cards[i.name] = i.value;
          });
          window.open(
            `${BASE_URL}/reports?apptoken=${token}&authorization=on&exporttype=${type}&document_number=${normalizeNumber(
              cards.document_number,
            )}&seller_code=${cards.seller_code}&order_by=${
              cards.orderBy
            }&order_direction=${cards.order}&complete=${
              cards.active
            }&created_at=${timestampToDate(
              cards.createdAt,
            )}&end_date=${timestampToDate(cards.endDate)}`,
          );
        }}
      />
    </div>
  );
};

export default ImperioCard;
