import React, { useState } from 'react';

// import { Container } from './styles';

const Dropdown: React.FC<{
  title: string;
  options: {
    title: string;
    onClick: () => void;
    disabled?: boolean;
  }[];
}> = ({ title, options }) => {
  const [open, setOpen] = useState(false);
  return (
    <div style={{ position: 'relative' }}>
      <button
        type="button"
        className="btn btn-sm btn-secondary"
        onClick={() => setOpen(!open)}
      >
        {title}
      </button>
      {open ? (
        <div
          style={{
            position: 'absolute',
            zIndex: 9,
            top: 34,
            left: 0,
            background: '#fff',
          }}
          className="p-2 radius-4 shadow w-100"
        >
          {options.map(option => (
            <button
              type="button"
              className="w-100 btn btn-light text-left"
              onClick={() => {
                option.onClick();
                setOpen(false);
              }}
              disabled={option.disabled}
              key={option.title}
            >
              <small>{option.title}</small>
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
