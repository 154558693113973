import React, { useEffect, useState } from 'react';

import { toastr } from 'react-redux-toastr';

import { FaTimes } from 'react-icons/fa';
import { IFilter } from '~/components/Table';
import api from '~/services/api';
import { Container } from './styles';

import Modal from '~/components/Modal';
import { TableComponent } from '~/components/TableV2';
import useSelector from '~/store/useSelector';
import { normalizePhone } from '~/utils/normalize';
import Button from '~/styles/components/Button';

interface ICustomer {
  id: number;
  name: string;
  lastname: string;
  email: string;
  cpf: string;
  phone: string;
  newsletter: string;
  status: boolean;
}
interface IDeleteRequest {
  id: number;
  customer: ICustomer;
  name: string;
  email: string;
  phone: string;
  reason: string;
  status: 'pending' | 'approved' | 'canceled';
}

interface IStatePage {
  data: IDeleteRequest[];
  total: number;
  perPage: number;
  page: number;
  lastPage: number;
  loading: boolean;
  orderBy: string;
  order: 'ASC' | 'DESC';
  name: string;
  cpf: string;
  email: string;
  phone: string;
}

const ModalBatchDeleteRequest = ({
  items,
  onHide,
  onSave,
}: {
  items: any[];
  onHide: () => void;
  onSave: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  async function handleSave() {
    setLoading(true);

    items.forEach(async item => {
      try {
        await api.put(`/admin/delete-requests/${item.id}`, {
          status: 'approved',
        });
        toastr.success('Sucesso', `Comentário atualizado`);
      } catch (err) {
        //
        toastr.error('Erro', `Erro ao atualizar comentário`);
      }
    });

    await new Promise(resolve => setTimeout(resolve, 1000));

    onHide();
    onSave();
    setLoading(false);
  }

  if (loading) {
    return (
      <Modal>
        <div className="d-flex justify-content-center">
          <div className="spinner spinner-border" />
        </div>
      </Modal>
    );
  }

  return (
    <Modal size="xl">
      <h3>Aprovar solicitação de exclusão</h3>

      <p>
        Você tem certeza que deseja aprovar {items.length}{' '}
        {items.length > 1 ? 'solicitações' : 'solicitação'} de exclusão?
      </p>

      <Button onClick={handleSave} className="mr-2">
        Confirmar
      </Button>
      <Button onClick={onHide} color="gray">
        Cancelar
      </Button>
    </Modal>
  );
};

const DeleteRequestsList: React.FC = () => {
  const [deleteRequests, setPages] = useState<IStatePage>({
    data: [],
    total: 0,
    perPage: 25,
    page: 1,
    lastPage: 1,
    loading: false,
    orderBy: 'id',
    order: 'DESC',
    name: '',
    cpf: '',
    email: '',
    phone: '',
  });

  const { permissions } = useSelector(state => state.auth);
  const [detail, setDetail] = useState({
    open: false,
    data: {} as IDeleteRequest,
  });

  const [
    viewBatchDeleteRequestModal,
    setViewBatchDeleteRequestModal,
  ] = useState({
    open: false,
    items: [] as any,
  });

  const [hardRefresh, setHardRefresh] = useState(false);

  async function applyFilters(data: IFilter[]) {
    const dataFilter: any = {}; // eslint-disable-line
    data.forEach(i => {
      dataFilter[i.name] = i.value;
    });
    setPages({
      ...deleteRequests,
      ...dataFilter,
    });
  }

  async function loadData() {
    setPages({ ...deleteRequests, loading: true });
    try {
      const query: string[] = [];
      query.push(`perPage=${deleteRequests.perPage}`);
      query.push(`page=${deleteRequests.page}`);
      const { data } = await api.get(
        `/admin/delete-requests?${query.map(q => q).join('&')}`,
      );
      setPages({
        ...deleteRequests,
        data: data.data,
        lastPage: data.lastPage,
        total: data.total,
        loading: false,
      });
    } catch (err) {
      toastr.error(
        'Ocorreu um erro',
        'Durante a busca nossos servidores não puderam responder',
      );
    }
  }

  useEffect(() => {
    loadData();
  }, [
    deleteRequests.perPage,
    deleteRequests.page,
    deleteRequests.orderBy,
    deleteRequests.order,
    deleteRequests.name,
    deleteRequests.email,
    deleteRequests.phone,
    deleteRequests.cpf,
  ]); // eslint-disable-line

  async function handleApprove() {
    setDetail({
      ...detail,
      open: false,
    });
    setPages({
      ...deleteRequests,
      loading: true,
    });
    try {
      await api.put(`/admin/delete-requests/${detail.data?.id}`, {
        status: 'approved',
      });
      toastr.success('Sucesso', 'Requisição aprovada');
      loadData();
    } catch (err) {
      toastr.error(
        'Erro',
        (err as any).response
          ? (err as any).response.data.message
          : (err as any).message,
      );
      setDetail({
        ...detail,
        open: true,
      });
    }
    setPages({
      ...deleteRequests,
      loading: false,
    });
  }

  async function handleCancel() {
    setDetail({
      ...detail,
      open: false,
    });
    setPages({
      ...deleteRequests,
      loading: true,
    });
    try {
      await api.put(`/admin/delete-requests/${detail.data?.id}`, {
        status: 'canceled',
      });
      toastr.success('Sucesso', 'Requisição cancelada');
      loadData();
    } catch (err) {
      toastr.error(
        'Erro',
        (err as any).response
          ? (err as any).response.data.message
          : (err as any).message,
      );
      setDetail({
        ...detail,
        open: true,
      });
    }
    setPages({
      ...deleteRequests,
      loading: false,
    });
  }

  function onNextPage() {
    setPages({ ...deleteRequests, page: deleteRequests.page + 1 });
  }
  function onPreviousPage() {
    setPages({ ...deleteRequests, page: deleteRequests.page - 1 });
  }

  return (
    <Container>
      {viewBatchDeleteRequestModal.open ? (
        <ModalBatchDeleteRequest
          items={viewBatchDeleteRequestModal.items}
          onHide={() =>
            setViewBatchDeleteRequestModal({
              open: false,
              items: [],
            })
          }
          onSave={() => setHardRefresh(true)}
        />
      ) : null}
      {detail.open && (
        <Modal>
          <div>
            <div className="d-flex justify-content-between">
              <h5>Dados do cliente:</h5>
              <button
                type="button"
                onClick={() => {
                  setDetail({
                    open: false,
                    data: {} as IDeleteRequest,
                  });
                }}
              >
                <FaTimes />
              </button>
            </div>

            <p>
              Nome: {detail.data?.name}
              <br />
              E-mail: {detail.data?.email}
              <br />
              Telefone: {detail.data?.phone}
            </p>
            <hr />
            <h5>Razão de exclusão</h5>
            <p>{detail.data?.reason}</p>
            <div className="alert alert-danger">
              Caso confirmado a exclusão, comunicar à equipe de desenvolvimento.
            </div>
            <br />
            <div className="d-flex justify-content-center">
              {detail.data?.status === 'pending' && (
                <>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      const res = window.confirm(
                        'Você tem certeza que deseja aprovar esta solicitação de exclusão?',
                      );
                      if (res) {
                        handleApprove();
                      }
                    }}
                    style={{ marginRight: '4px' }}
                  >
                    Aprovar Exclusão
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => {
                      const res = window.confirm(
                        'Você tem certeza que deseja cancelar esta solicitação de exclusão?',
                      );
                      if (res) {
                        handleCancel();
                      }
                    }}
                    style={{ marginRight: '4px' }}
                  >
                    Cancelar Exclusão
                  </button>
                </>
              )}
            </div>
          </div>
        </Modal>
      )}
      <TableComponent
        filters={[
          {
            key: 'status',
            title: 'Status',
            defaultTo: 'pending',
            type: 'select',
            options: [
              {
                title: 'Em análise',
                value: 'pending',
              },
              {
                title: 'Aprovado',
                value: 'approved',
              },
              {
                title: 'Cancelado',
                value: 'canceled',
              },
            ],
            col: 2,
          },
        ]}
        labels={[
          {
            key: 'name',
            title: 'Nome',
          },
          {
            key: 'email',
            title: 'Email',
          },
          {
            key: 'phone',
            title: 'Telefone',
            formater: (value: string) => normalizePhone(value),
          },
          {
            key: 'reason',
            title: 'Razão',
          },
          {
            key: 'status',
            title: 'Situação',
            formater: (status: 'pending' | 'approved' | 'canceled') => {
              switch (status) {
                case 'pending':
                  return <span className="warning">Em análise</span>;
                case 'approved':
                  return <span className="ativo">Aprovado</span>;
                case 'canceled':
                  return <span className="inativo">Cancelado</span>;
                default:
                  return <span className="warning">Não cadastrado</span>;
              }
            },
          },
        ]}
        getData={async params => {
          const res = await api.get(`/admin/delete-requests`, {
            params,
          });
          return res;
        }}
        additionalActions={item => (
          <div className="d-flex align-items-center">
            {permissions.find(p => p.slug === 'ver_pedidos_de_exclusão') && (
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                onClick={() => {
                  setDetail({
                    open: true,
                    data: item,
                  });
                }}
                style={{ marginRight: '4px' }}
              >
                Ver
              </button>
            )}
            {permissions.find(
              p => p.slug === 'excluir_pedidos_de_exclusão',
            ) && (
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => {
                  const res = window.confirm(
                    'Você tem certeza que deseja excluir este pedido de exclusão?',
                  );
                  if (res) {
                    api.delete(`/admin/delete-requests/${item.id}`);
                    toastr.success('Sucesso', 'Pedido de exclusão excluído');
                    loadData();
                  }
                }}
              >
                Excluir
              </button>
            )}
          </div>
        )}
        batchActions={[
          {
            title: 'Aprovar solicitação',
            onClick: items => {
              setViewBatchDeleteRequestModal({
                items,
                open: true,
              });
            },
          },
          // {
          //   title: 'Tornar invisível',
          //   onClick: items => {
          //     setViewBatchDeleteRequestModal({
          //       items,
          //       open: true,
          //     });
          //   },
          // },
        ]}
        hardRefresh={hardRefresh}
      />
    </Container>
  );
};

export default DeleteRequestsList;
