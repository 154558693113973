import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import Button from '~/styles/components/Button';
import { Container } from '../styles';

import { normalizeCpf, normalizeNumber } from '~/utils/normalize';
import api from '~/services/api';

const SignUp: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    code: '',
    cpf: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [errorMessage, setErrorMessage] = useState('');

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    let { value } = e.target;
    if (e.target.name === 'cpf') {
      value = normalizeCpf(e.target.value);
    }
    setValues({ ...values, [e.target.name]: value });
    setErrorMessage('');
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post('seller-admin-account', {
        code: values.code,
        cpf: normalizeNumber(values.cpf),
        email: values.email,
        password: values.password,
      });
      toastr.success('Sucesso', 'Conta criada, realize o login');
      history.push('/signin');
    } catch (err) {
      if ((err as any) && (err as any).data.message) {
        setErrorMessage((err as any).data.message);
      } else {
        setErrorMessage((err as any).message);
      }
    }
    setLoading(false);
  }

  if (loading) {
    return <Container>Carregando...</Container>;
  }

  return (
    <Container>
      <div className="content-form-block">
        <div className="row">
          <div className="col-md-4">
            <div className="logo-block">
              <span>Criar Conta</span>
            </div>
          </div>
          <div className="col-md-8">
            <form onSubmit={handleSubmit}>
              <div className="d-flex align-items-center">
                <img
                  src="https://lojasimperio.com.br/images/logo.png"
                  alt="Império"
                  className="mr-2"
                />
                <span>- Administrativo</span>
              </div>
              <span>Código de Funcionário</span>
              <input
                name="code"
                value={values.code}
                onChange={handleInputChange}
                required
              />
              <div className="row">
                <div className="col-md-5">
                  <span>CPF</span>
                  <input
                    name="cpf"
                    value={values.cpf}
                    onChange={handleInputChange}
                    required
                    className="w-100"
                  />
                </div>
                <div className="col-md-7">
                  <span>E-MAIL</span>
                  <input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    required
                    className="w-100"
                  />
                </div>
                <div className="col-md-6">
                  <span>SENHA</span>
                  <input
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleInputChange}
                    required
                    className="w-100"
                  />
                </div>
                <div className="col-md-6">
                  <span>CONFIRMAR SENHA</span>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={values.confirmPassword}
                    onChange={handleInputChange}
                    required
                    className="w-100"
                  />
                </div>
              </div>

              {values.password &&
              values.confirmPassword &&
              values.password !== values.confirmPassword ? (
                <div className="alert alert-danger mt-4">
                  Há divergência nas senhas
                </div>
              ) : null}

              {errorMessage ? (
                <div className="alert alert-danger mt-4">{errorMessage}</div>
              ) : null}

              <div className="d-flex mt-4">
                <Button size="big" type="submit">
                  Criar
                </Button>
                <Button
                  size="big"
                  type="button"
                  onClick={() => {
                    history.push('/signin');
                  }}
                  color="gray"
                  className="ml-2"
                >
                  Voltar para login
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SignUp;
