import React, { useEffect, useState } from 'react';
import { FaPlusCircle, FaTimes, FaTimesCircle } from 'react-icons/fa';
import { toastr } from 'react-redux-toastr';
import Modal from '~/components/Modal';
import api from '~/services/api';
import { normalizeNumber } from '~/utils/normalize';

// import { Container } from './styles';

export const Delivery: React.FC<{
  onHide: () => void;
  onUpdate: () => void;
  id: number | null;
}> = ({ onHide, onUpdate, id }) => {
  const [type, setType] = useState<null | 'correios' | 'custom'>(null);

  const [loading, setLoading] = useState(false);

  const [correiosAccessData, setCorreiosAccessData] = useState({
    idContrato: '',
    idCartaoPostagem: '',
    usuario: '',
    senha: '',
    nCdEmpresa: '',
    sDsSenha: '',
    sCepOrigem: '',
  } as any);

  const [freeParams, setFreeParams] = useState({
    id: '',
    title: '',
    code: '',
    range: [
      {
        start: '' as string | number,
        end: '' as string | number,
        days: '' as string | number,
      },
    ],
  });

  const [customData, setCustomData] = useState({
    endpoint: '',
    authenticationType: '',
    authenticationToken: '',
  });

  useEffect(() => {
    async function handle() {
      setLoading(true);
      try {
        const { data } = await api.get(`/marketplace-delivery/config/${id}`);
        if (data.type === 'correios') {
          setFreeParams(JSON.parse(data.freeParams));
          const obj: any = {};
          data.accessData.split('&').forEach((item: any) => {
            const [key, value] = item.split('=');
            obj[key] = value;
          });
          setCorreiosAccessData(obj);
        } else if (data.type === 'custom') {
          setCustomData({
            endpoint: data.endpoint,
            authenticationToken: data.authenticationToken,
            authenticationType: data.authenticationType,
          });
        }
      } catch (err) {
        //
      }
      setLoading(false);
    }
    if (id) {
      handle();
    }
  }, [id]);

  async function handleSubmit() {
    setLoading(true);
    try {
      let body: any = {};
      if (type === 'correios') {
        body = {
          accessData: Object.keys(correiosAccessData)
            .map(k => `${k}=${correiosAccessData[k]}`)
            .join('&'),
          type: 'correios',
          freeParams: JSON.stringify({
            ...freeParams,
            range: freeParams.range.filter(r => r.days && r.end && r.start),
          }),
        };
      } else if (type === 'custom') {
        body = {
          ...customData,
          type: 'custom',
        };
      }
      if (id) {
        await api.put(`/marketplace-delivery/config/${id}`, body);
      } else {
        await api.post(`/marketplace-delivery/config`, body);
      }
      toastr.info(`Sucesso`, 'Serviço de Entrega configurado');
      onUpdate();
    } catch (err) {
      if ((err as any).response) {
        toastr.error(`Erro`, (err as any).response.data.message);
      } else {
        toastr.error(`Erro`, (err as any).message);
      }
    }
    setLoading(false);
  }

  return (
    <Modal size="xl">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="m-0">Configuração Serviço de Entrega</h6>
        <button type="button" onClick={onHide}>
          <FaTimes />
        </button>
      </div>
      <div className="d-flex align-items-center mt-3">
        <button
          type="button"
          className={`${
            type === 'correios' ? 'bg-primary text-white' : 'bg-white'
          } pl-3 pr-3 pt-2 pb-2 radius-4 shadow-sm mr-2`}
          onClick={() => setType('correios')}
          disabled={loading}
        >
          <strong>Correios</strong>
        </button>
        <button
          type="button"
          className={`${
            type === 'custom' ? 'bg-primary text-white' : 'bg-white'
          } pl-3 pr-3 pt-2 pb-2 radius-4 shadow-sm`}
          onClick={() => setType('custom')}
          disabled={loading}
        >
          <strong>Personalizado</strong>
        </button>
      </div>
      <div className="row mt-4">
        {type === 'correios' && (
          <>
            <div className="col-md-4">
              <small className="d-block mb-3">
                Informe os seguintes dados para configurar corretamente o
                serviço dos correios
              </small>
              <div className="form-group mb-2">
                <strong>ID Contrato</strong>
                <input
                  type="text"
                  value={correiosAccessData.idContrato}
                  onChange={e =>
                    setCorreiosAccessData({
                      ...correiosAccessData,
                      idContrato: normalizeNumber(e.target.value),
                    })
                  }
                  className="form-control"
                  disabled={loading}
                />
              </div>
              <div className="form-group mb-2">
                <strong>ID Cartão Postagem</strong>
                <input
                  type="text"
                  value={correiosAccessData.idCartaoPostagem}
                  onChange={e =>
                    setCorreiosAccessData({
                      ...correiosAccessData,
                      idCartaoPostagem: normalizeNumber(e.target.value),
                    })
                  }
                  className="form-control"
                  disabled={loading}
                />
              </div>
              <div className="form-group mb-2">
                <strong>Usuário (SIGEP)</strong>
                <input
                  type="text"
                  value={correiosAccessData.usuario}
                  onChange={e =>
                    setCorreiosAccessData({
                      ...correiosAccessData,
                      usuario: e.target.value,
                    })
                  }
                  className="form-control"
                  disabled={loading}
                />
              </div>
              <div className="form-group mb-2">
                <strong>Senha (SIGEP)</strong>
                <input
                  type="text"
                  value={correiosAccessData.senha}
                  onChange={e =>
                    setCorreiosAccessData({
                      ...correiosAccessData,
                      senha: e.target.value,
                    })
                  }
                  className="form-control"
                  disabled={loading}
                />
              </div>
              <div className="form-group mb-2">
                <strong>Cod. Administrativo</strong>
                <input
                  type="text"
                  value={correiosAccessData.nCdEmpresa}
                  onChange={e =>
                    setCorreiosAccessData({
                      ...correiosAccessData,
                      nCdEmpresa: e.target.value,
                    })
                  }
                  className="form-control"
                  disabled={loading}
                />
              </div>
              <div className="form-group mb-2">
                <strong>Senha de Acesso</strong>
                <input
                  type="text"
                  value={correiosAccessData.sDsSenha}
                  onChange={e =>
                    setCorreiosAccessData({
                      ...correiosAccessData,
                      sDsSenha: e.target.value,
                    })
                  }
                  className="form-control"
                  disabled={loading}
                />
              </div>
              <div className="form-group mb-2">
                <strong>CEP Origem</strong>
                <input
                  type="text"
                  value={correiosAccessData.sCepOrigem}
                  onChange={e =>
                    setCorreiosAccessData({
                      ...correiosAccessData,
                      sCepOrigem: normalizeNumber(e.target.value.slice(0, 8)),
                    })
                  }
                  className="form-control"
                  disabled={loading}
                />
                <small className="d-block">
                  Informe o CEP de onde sairá os produtos para a agência dos
                  correios.
                </small>
              </div>
            </div>
            <div className="col-md-8">
              <div className="bg-gray p-3 radius-4">
                <strong>Atenção</strong>
                <p>Esses dados serão utilizados para:</p>
                <ul className="pl-5">
                  <li>Consultar prazos e preços</li>
                  <li>Consultar serviços disponíveis (PAC, Sedex, etc)</li>
                </ul>
              </div>
              <div className="bg-white mt-3 p-3 radius-4 shadow-sm">
                <strong className="d-block mb-0">Frete Grátis</strong>
                <small>
                  Informe abaixo os dados de identificação para uma entrega com
                  frete grátis. Isso servirá para sua empresa identificar no
                  detalhamento de pedidos.
                </small>
                <div className="row">
                  <div className="col-md-4">
                    <small>ID</small>
                    <input
                      type="text"
                      value={freeParams.id}
                      onChange={e =>
                        setFreeParams({
                          ...freeParams,
                          id: e.target.value,
                        })
                      }
                      placeholder="Ex: 31, 45"
                      className="form-control"
                      disabled={loading}
                    />
                  </div>
                  <div className="col-md-8" />
                  <div className="col-md-8">
                    <small>Título</small>
                    <input
                      type="text"
                      value={freeParams.title}
                      onChange={e =>
                        setFreeParams({
                          ...freeParams,
                          title: e.target.value,
                        })
                      }
                      placeholder="Ex: Padrão, Econômica ..."
                      className="form-control"
                      disabled={loading}
                    />
                  </div>
                  <div className="col-md-4">
                    <small>Código</small>
                    <input
                      type="text"
                      value={freeParams.code}
                      onChange={e =>
                        setFreeParams({
                          ...freeParams,
                          code: e.target.value,
                        })
                      }
                      className="form-control"
                      placeholder="Ex: 3184, 4829"
                      disabled={loading}
                    />
                  </div>
                </div>
                <small className="d-block mt-2">
                  Informe abaixo as faixas de CEP que possuem{' '}
                  <strong className="text-success">frete grátis</strong> em sua
                  loja.
                </small>
                <table className="mt-3">
                  <tbody>
                    {freeParams.range.map((r, index) => (
                      <tr key={String(index)}>
                        <td>
                          <strong>#{index + 1}</strong>
                        </td>
                        <td>
                          <div className="d-flex align-items-center ml-2 mr-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="00000"
                              value={r.start}
                              onChange={e => {
                                const arr = [...freeParams.range];
                                arr[index].start = Number(
                                  normalizeNumber(e.target.value.slice(0, 5)),
                                );
                                setFreeParams({
                                  ...freeParams,
                                  range: arr,
                                });
                              }}
                              disabled={loading}
                            />
                            <span className="ml-2 mr-2">até</span>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="00000"
                              value={r.end}
                              onChange={e => {
                                const arr = [...freeParams.range];
                                arr[index].end = Number(
                                  normalizeNumber(e.target.value.slice(0, 5)),
                                );
                                setFreeParams({
                                  ...freeParams,
                                  range: arr,
                                });
                              }}
                              disabled={loading}
                            />
                            <input
                              type="text"
                              className="form-control ml-2"
                              placeholder="Prazo (dias)"
                              value={r.days}
                              onChange={e => {
                                const arr = [...freeParams.range];
                                arr[index].days = Number(
                                  normalizeNumber(e.target.value),
                                );
                                setFreeParams({
                                  ...freeParams,
                                  range: arr,
                                });
                              }}
                              disabled={loading}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            {freeParams.range.length > 1 && (
                              <button
                                type="button"
                                onClick={() =>
                                  setFreeParams({
                                    ...freeParams,
                                    range: freeParams.range.filter(
                                      (ra, ind) => ind !== index,
                                    ),
                                  })
                                }
                                disabled={loading}
                              >
                                <FaTimesCircle className="text-danger" />
                              </button>
                            )}

                            {index === freeParams.range.length - 1 && (
                              <button
                                type="button"
                                className="ml-2"
                                onClick={() =>
                                  setFreeParams({
                                    ...freeParams,
                                    range: [
                                      ...freeParams.range,
                                      {
                                        start: '',
                                        end: '',
                                        days: '',
                                      },
                                    ],
                                  })
                                }
                                disabled={loading}
                              >
                                <FaPlusCircle />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
        {type === 'custom' && (
          <>
            <div className="col-md-12 mb-3">
              <div className="bg-warning p-3 radius-4">
                <strong>
                  Para realizar uma consulta personalizada, entre em contato com
                  o setor técnico de sua empresa para garantir uma configuração
                  correta.
                </strong>
              </div>
            </div>
            <div className="col-md-6">
              Informe um endpoint de sua <i>API</i>
              <input
                type="text"
                className="form-control mb-2"
                disabled={loading}
              />
              <div className="d-flex mb-2">
                <select
                  value={customData.authenticationType}
                  onChange={e =>
                    setCustomData({
                      ...customData,
                      authenticationType: e.target.value,
                    })
                  }
                  className="form-control"
                  disabled={loading}
                  style={{ maxWidth: '140px' }}
                >
                  <option value="">Autenticação</option>
                  <option value="Bearer">Bearer</option>
                  <option value="Basic">Basic</option>
                </select>
                <input
                  type="text"
                  placeholder="TOKEN"
                  value={customData.authenticationToken}
                  onChange={e =>
                    setCustomData({
                      ...customData,
                      authenticationToken: e.target.value,
                    })
                  }
                  disabled={customData.authenticationType === '' || loading}
                  className="form-control w-100 ml-2"
                />
              </div>
              Enviaremos uma requisição{' '}
              <div className="badge badge-primary">POST</div> com o seguinte
              formato de body:
              <pre className="bg-gray p-3 radius-4">
                <code>
                  {`{
    "zipcode": "51320490", // CEP Destino
    "products": [
          {
              "id": "13", // ID do Produto em seu sistema
              "sku": "A033418489Y831",
              "quantity": 1,
              "weight": 1,
              "height": 1,
              "width": 1,
              "length": 1
          }
    ]
}`}
                </code>
              </pre>
            </div>
            <div className="col-md-6">
              Responda a requisição nesse formato:
              <div>
                <div className="badge badge-success mb-2">STATUS: 200</div>
              </div>
              <pre className="bg-gray p-3 radius-4">
                <code>
                  {`[
    {
        "id": 4, // ID do Tipo de Entrega em seu Sistema (opcional)
        "code": "0481",
        "title": "Expresso",
        "value": 1000, // R$ 10,00
        "days": "2"
    }
]`}
                </code>
              </pre>
            </div>
          </>
        )}
      </div>
      {type !== null && (
        <div className="d-flex justify-content-center mt-2">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={loading}
            className="btn btn-primary"
          >
            Salvar
          </button>
          <button
            type="button"
            onClick={onHide}
            disabled={loading}
            className="btn btn-outline-secondary ml-2"
          >
            Cancelar
          </button>
        </div>
      )}
    </Modal>
  );
};
