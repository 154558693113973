import React, { useState, useEffect } from 'react';

import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import { FaFilter, FaTimesCircle } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import Button from '~/styles/components/Button';
import {
  Container,
  TableFilters,
  Table,
  TablePagination,
  FilterContainer,
} from './styles';
import api from '~/services/api';

export interface IFilter {
  title: string;
  name: string;
  type: 'text' | 'email' | 'number' | 'date' | 'select' | 'react-datepicker';
  value: string;
  normalize?: (value: string) => string;
}
interface ILabel {
  title: string;
  name: string;
  useFilter?: boolean;
}

interface IProps {
  loading?: boolean;
  filtersFields?: IFilter[];
  onApplyFilters?: (data: IFilter[]) => void;
  title?: string;
  total: number;
  data: any[]; // eslint-disable-line
  labels: ILabel[];
  elementClickable?: boolean;
  onClickElement?: (data: any) => void; // eslint-disable-line
  pagination?: boolean;
  page?: number;
  lastPage?: number;
  onPrevious?: () => void;
  onFoward?: () => void;
  showFilters?: boolean;
  perPageOptions?: number[];
  orderBy?: string;
  perPage?: number;
  order?: 'DESC' | 'ASC' | string;
  useDefaultFitlers?: boolean;
  fullWidth?: boolean;
  exportButton?: boolean;
  onExport?: (data: IFilter[]) => void;
  onExport2?: (data: IFilter[], type: string) => void;
}

const TableComponent: React.FC<IProps> = ({
  title,
  data,
  total,
  labels,
  filtersFields = [],
  elementClickable,
  pagination = true,
  page = 1,
  showFilters = true,
  useDefaultFitlers = true,
  perPageOptions = [10, 25, 50, 100],
  orderBy,
  perPage = 10,
  lastPage = 1,
  order = 'DESC',
  fullWidth = false,
  loading,
  onPrevious,
  onFoward,
  onApplyFilters,
  onClickElement,
  exportButton = false,
  onExport,
  onExport2,
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [filters, setFilters] = useState<IFilter[]>([...filtersFields]);
  const [exportFilters, setExportFilters] = useState<IFilter[]>([
    ...filtersFields,
    {
      name: 'type_import',
      title: 'Exportar em',
      type: 'select',
      value: '',
    },
  ]);
  const [defaultFilters, setDefaultFilters] = useState<IFilter[]>([
    {
      name: 'perPage',
      title: 'Results. p/página:',
      type: 'text',
      value: String(perPage) || String(perPageOptions[0]),
    },
    {
      name: 'orderBy',
      title: 'Ordenar por:',
      type: 'text',
      value: labels ? orderBy || String(labels[0].name) : '',
    },
    {
      name: 'order',
      title: 'Ordem:',
      type: 'text',
      value: order,
    },
  ]);

  const [integrators, setIntegrators] = useState<any[]>([]);

 
  function handleChangeFilterValue(name: string, newValue: string) {
    setFilters(
      filters.map(f => {
        if (f.name === name) {
          return {
            ...f,
            value: f.normalize ? f.normalize(newValue) : newValue,
          };
        }
        return f;
      }),
    );
  }

  function handleChangeFilterExportValue(name: string, newValue: string) {
    setExportFilters(
      exportFilters.map(f => {
        if (f.name === name) {
          return {
            ...f,
            value: f.normalize ? f.normalize(newValue) : newValue,
          };
        }
        return f;
      }),
    );
  }

  function handleChangeDefaultFilterValue(name: string, newValue: string) {
    setDefaultFilters(
      defaultFilters.map(f => {
        if (f.name === name) {
          return {
            ...f,
            value: f.normalize ? f.normalize(newValue) : newValue,
          };
        }
        return f;
      }),
    );
  }

  function handleCancel() {
    setOpenFilter(false);
    setFilters(filtersFields || []);
    setDefaultFilters([
      {
        name: 'perPage',
        title: 'Results. p/página:',
        type: 'text',
        value: perPageOptions
          ? String(perPage) || String(perPageOptions[0])
          : String(perPage),
      },
      {
        name: 'orderBy',
        title: 'Ordenar por:',
        type: 'text',
        value: labels ? orderBy || String(labels[0]) : '',
      },
      {
        name: 'order',
        title: 'Ordem:',
        type: 'text',
        value: order || 'DESC',
      },
    ]);
  }

  function handleEmptyFilterValue(name: string) {
    setFilters(
      filters.map(f => {
        if (f.name === name) {
          return {
            ...f,
            value: '',
          };
        }
        return f;
      }),
    );
  }

  function handleEmptyExportFilterValue(name: string) {
    setFilters(
      filters.map(f => {
        if (f.name === name) {
          return {
            ...f,
            value: '',
          };
        }
        return f;
      }),
    );
  }

  return (
    <Container fullWidth={fullWidth}>
      {openFilter && (
        <FilterContainer>
          <h4>Filtros</h4>
          {filters.map(filter =>
            filter.type === 'select' ? (
              <label className="input" htmlFor={filter.name} key={filter.name}>
                <p>{filter.title}</p>
                <select
                  id={filter.name}
                  name={filter.name}
                  value={filter.value}
                  onChange={e => {
                    handleChangeFilterValue(filter.name, e.target.value);
                  }}
                >
                  {filter.name === 'origin' && (
                    <>
                      <option value="">Todos</option>
                      <option value="app">App</option>
                      <option value="site">Site</option>
                      <option value="anymarket">Anymarket</option>
                    </>
                  )}
                  {filter.name === 'integration_id' && (
                    <>
                      <option value="">Todos</option>
                      {integrators?.map(integrator => (
                        
                        <option key={integrator.id} value={integrator.id}>
                          {integrator.name}
                        </option>
                      ))}
                    </>
                  )}
                  {filter.name === 'gateway_credit' && (
                    <>
                      <option value="">Todos</option>
                      <option value="safrapay">Safrapay</option>
                      <option value="erede">ERede</option>
                    </>
                  )}
                  {filter.name === 'device' && (
                    <>
                      <option value="">Todos</option>
                      <option value="site">Site</option>
                      <option value="ios">IOS</option>
                      <option value="android">Android</option>
                    </>
                  )}
                  {filter.name === 'active' && (
                    <>
                      <option value="">Todos</option>
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </>
                  )}
                  {filter.name === 'status' && (
                    <>
                      <option value="">Todos</option>
                      <option value="1">Realizado</option>
                      <option value="2">Em Análise</option>
                      <option value="10">Aguardando Pagamento</option>
                      <option value="3">Aprovado</option>
                      <option value="4">Cancelado</option>
                      <option value="5">Negado</option>
                      <option value="6">Error</option>
                      <option value="7">Agendamento da entrega</option>
                      <option value="8">Em rota</option>
                      <option value="9">Confirmação de entrega</option>
                      <option value="11">Expirado</option>
                      <option value="12">
                        Aprovado e não integrado (bandeira)
                      </option>
                      <option value="13">Aprovado e não integrado</option>
                      <option value="14">Estornado</option>
                      <option value="15">Nota Fiscal Emitida</option>
                    </>
                  )}
                  {filter.name === 'status_product' && (
                    <>
                      <option value="">Todos</option>
                      <option value="1">Ativo</option>
                      <option value="0">Inativo</option>
                    </>
                  )}
                  {filter.name === 'form_payment' && (
                    <>
                      <option value="">Todos</option>
                      <option value="boleto">Boleto</option>
                      <option value="card">Cartão</option>
                      <option value="two_cards">2 Cartões</option>
                      <option value="pix">PIX</option>
                    </>
                  )}
                  {filter.name === 'form_delivery' && (
                    <>
                      <option value="">Todos</option>
                      <option value="frete">Receba em casa</option>
                      <option value="cliqueeretire">Retira na loja</option>
                    </>
                  )}
                </select>
              </label>
            ) : (
              <label htmlFor={filter.name} key={filter.name} className="input">
                {(filter.type === 'date' ||
                  filter.type === 'react-datepicker') && <p>{filter.title}</p>}

                {filter.type === 'react-datepicker' ? (
                  <div>
                    <DatePicker
                      selected={
                        filter.value
                          ? new Date(parseInt(filter.value, 10))
                          : null
                      }
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date) => {
                        handleChangeFilterValue(
                          filter.name,
                          date.getTime().toString(),
                        );
                      }}
                    />
                    {filter.value !== '' && (
                      <button
                        type="button"
                        onClick={() => handleEmptyFilterValue(filter.name)}
                      >
                        <FaTimesCircle />
                      </button>
                    )}
                  </div>
                ) : (
                  <div>
                    <input
                      type={filter.type}
                      placeholder={filter.title}
                      id={filter.name}
                      value={filter.value}
                      onChange={e => {
                        handleChangeFilterValue(filter.name, e.target.value);
                      }}
                    />
                    {filter.value !== '' && (
                      <button
                        type="button"
                        onClick={() => handleEmptyFilterValue(filter.name)}
                      >
                        <FaTimesCircle />
                      </button>
                    )}
                  </div>
                )}
              </label>
            ),
          )}
          {useDefaultFitlers &&
            defaultFilters.map((filter, index) => (
              <label
                className="input"
                htmlFor={filter.name}
                key={String(index)}
              >
                <p>{filter.title}</p>
                <select
                  id={filter.name}
                  name={filter.name}
                  value={filter.value}
                  onChange={e => {
                    handleChangeDefaultFilterValue(filter.name, e.target.value);
                  }}
                >
                  {filter.name === 'perPage' && (
                    <>
                      {perPageOptions.map(option => (
                        <option key={option} value={String(option)}>
                          {option}
                        </option>
                      ))}
                    </>
                  )}
                  {filter.name === 'orderBy' &&
                    labels
                      .filter(l =>
                        typeof l.useFilter === 'boolean' ? l.useFilter : true,
                      )
                      .map(label => (
                        <option key={label.name} value={label.name}>
                          {label.title}
                        </option>
                      ))}
                  {filter.name === 'order' && (
                    <>
                      <option value="ASC">Crescente</option>
                      <option value="DESC">Decrescente</option>
                    </>
                  )}
                </select>
              </label>
            ))}
          {onExport2 && (
            <div id="action_filter">
              <Button
                type="button"
                onClick={() => {
                  if (onExport2) {
                    onExport2([...filters, ...defaultFilters], 'csv');
                    setOpenFilter(false);
                  }
                }}
                disabled={loading}
                style={{ background: '#006b48', color: '#fff', fontSize: 10 }}
              >
                Exportar CSV
              </Button>
              <Button
                type="button"
                onClick={() => {
                  if (onExport2) {
                    onExport2([...filters, ...defaultFilters], 'xlsx');
                    setOpenFilter(false);
                  }
                }}
                disabled={loading}
                style={{ background: '#006b48', color: '#fff', fontSize: 10 }}
              >
                Exportar XLSX
              </Button>
            </div>
          )}
          <div id="action_filter">
            <Button
              type="button"
              onClick={() => {
                if (onApplyFilters) {
                  onApplyFilters([...filters, ...defaultFilters]);
                  setOpenFilter(false);
                }
              }}
              disabled={loading}
            >
              Aplicar
            </Button>
            <Button
              type="button"
              color="gray"
              onClick={handleCancel}
              disabled={loading}
            >
              Cancelar
            </Button>
          </div>
        </FilterContainer>
      )}
      {openExport && (
        <FilterContainer>
          <h4 className="green">Exportar Lista</h4>
          {filters.map(filter =>
            filter.type === 'select' ? (
              <label className="input" htmlFor={filter.name} key={filter.name}>
                <p>{filter.title}</p>
                <select
                  id={filter.name}
                  name={filter.name}
                  value={filter.value}
                  onChange={e => {
                    handleChangeFilterExportValue(filter.name, e.target.value);
                  }}
                >
                  {filter.name === 'origin' && (
                    <>
                      <option value="">Todos</option>
                      <option value="app">App</option>
                      <option value="site">Site</option>
                    </>
                  )}
                  {filter.name === 'active' && (
                    <>
                      <option value="">Todos</option>
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </>
                  )}
                  {filter.name === 'status' && (
                    <>
                      <option value="">Todos</option>
                      <option value="1">Realizado</option>
                      <option value="2">Em Análise</option>
                      <option value="10">Aguardando Pagamento</option>
                      <option value="3">Aprovado</option>
                      <option value="4">Cancelado</option>
                      <option value="5">Negado</option>
                      <option value="6">Error</option>
                      <option value="7">Agendamento da entrega</option>
                      <option value="8">Em rota</option>
                      <option value="9">Confirmação de entrega</option>
                    </>
                  )}
                  {filter.name === 'type_import' && (
                    <>
                      <option value="xls">Excel (.xls)</option>
                      <option value="csv">CSV</option>
                    </>
                  )}
                </select>
              </label>
            ) : (
              <label htmlFor={filter.name} key={filter.name} className="input">
                {filter.type === 'date' && <p>{filter.title}</p>}
                <input
                  type={filter.type}
                  placeholder={filter.title}
                  id={filter.name}
                  value={filter.value}
                  onChange={e => {
                    handleChangeFilterExportValue(filter.name, e.target.value);
                  }}
                />
                {filter.value !== '' && (
                  <button
                    type="button"
                    onClick={() => handleEmptyExportFilterValue(filter.name)}
                  >
                    <FaTimesCircle />
                  </button>
                )}
              </label>
            ),
          )}
          <div id="action_filter">
            <Button
              type="button"
              onClick={() => {
                if (onExport) {
                  onExport([...filters, ...defaultFilters]);
                  setOpenFilter(false);
                }
              }}
              disabled={loading}
              style={{ background: '#006b48', color: '#fff' }}
            >
              Exportar
            </Button>
          </div>
        </FilterContainer>
      )}
      <TableFilters loading={loading}>
        <h3>{title}</h3>
        <div className="d-flex">
          {exportButton && (
            <button
              type="button"
              className={`export ${openExport ? 'open' : ''}`}
              onClick={() => {
                setOpenExport(!openExport);
                setOpenFilter(false);
              }}
            >
              Exportar Lista
            </button>
          )}

          {showFilters && (
            <button
              type="button"
              className={openFilter ? 'filled' : ''}
              onClick={() => {
                setOpenFilter(!openFilter);
                setOpenExport(false);
              }}
              disabled={loading}
            >
              <FaFilter />
            </button>
          )}
        </div>
      </TableFilters>
      <Table>
        <thead>
          <tr>
            {labels.map(label => (
              <th key={label.name}>{label.title}</th>
            ))}
            {elementClickable && <th>Ação</th>}
          </tr>
        </thead>
        <tbody>
          {data?.map((
            item: any, // eslint-disable-line
            index,
          ) => (
            <tr
              key={String(index)}
              style={{
                cursor: elementClickable && !loading ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (elementClickable && !loading) {
                  if (onClickElement) {
                    onClickElement(item);
                  }
                }
              }}
            >
              {labels.map(field => (
                <td key={field.name}>{item[field.name]}</td>
              ))}
              {elementClickable && (
                <td>
                  <IoIosArrowForward />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
      <TablePagination>
        <div>
          <strong>{total} Resultado(s)</strong>
        </div>
        {pagination && (
          <div id="pages">
            <button
              type="button"
              onClick={onPrevious}
              disabled={page === 1 || loading}
            >
              <IoIosArrowBack />
            </button>
            <span>
              {page} de {lastPage}
            </span>
            <button
              type="button"
              onClick={onFoward}
              disabled={loading || page === lastPage}
            >
              <IoIosArrowForward />
            </button>
          </div>
        )}
      </TablePagination>
    </Container>
  );
};

export default TableComponent;
